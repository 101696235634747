import React, { useState, FormEvent, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation, Icon } from "@blueprintjs/core";
import { DateInput2 } from "@blueprintjs/datetime2";
import { format, parse, addDays} from "date-fns";
import { IconNames } from "@blueprintjs/icons";

import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { SmStatInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;


interface StkTradingStatisticsViewProps {
    gsb: AxiosInstance,
    stk: SmStatInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}


function StkTradingStatisticsView({ gsb, stk = {} as SmStatInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: StkTradingStatisticsViewProps) {

    const { t, i18n } = useTranslation();    

    const [isDeletePress, setisDeletePress] = useState(false)

    const [StkNoValue, setStkNoValue] = useState(stk.d_StkNo)
    const [StkCodeValue, setStkCodeValue] = useState("")
    const [StkNameValue, setStkNameValue] = useState("")

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNoValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNoValue.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCodeValue(res.data.data[0].d_StkCode)
                    setStkNameValue(res.data.data[0].d_StkName)

                } else { 
                    setStkCodeValue("")
                    setStkNameValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNoValue])

    if (! ("d_StkNo" in stk)) return null

    
    return (
        <div>
            {/* define add,change,delete button */}
            <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div>

            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelInfo="*">
                    <InputGroup readOnly value={stk.d_StkNo} />
                </FormGroup>
                <FormGroup className="Col-2-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCodeValue}</div>
                    <div id="DispText">{StkNameValue}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Temp Stock No. For SPLIT/CONS" labelFor="inputStkNo" labelInfo="">
                    <InputGroup readOnly value={stk.d_StkTempNo} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Period (yymm)" labelFor="inputStkNo" labelInfo="*">
                    <InputGroup readOnly value={stk.d_Period} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Market Capitalization" labelFor="input_MktCap">
                    <InputGroup readOnly value={stk.d_MktCapStr} />
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="input_MktCap">
                    <InputGroup readOnly value={utils.formatNumber2(stk.d_MktCap)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Issued Shares" labelFor="input_IssShare">
                    <InputGroup readOnly value={stk.d_IssShareStr} />
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="input_IssShare">
                    <InputGroup readOnly value={utils.formatNumber2(stk.d_IssShare)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Turnover Value" labelFor="input_Turnover">
                    <InputGroup readOnly value={stk.d_TurnoverStr} />
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="input_Turnover">
                    <InputGroup readOnly value={utils.formatNumber2(stk.d_Turnover)} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Month-End Closing Price" labelFor="input_ClsPrice">
                    <InputGroup readOnly value={utils.formatNumber2(stk.d_ClsPrice)} />
                </FormGroup>
            </div>
        </div>
    )
}



interface StkTradingStatisticsEditProps {
    gsb: AxiosInstance,
    stk?: SmStatInfo,
    isNew?: boolean,
    onSave?: (stk: SmStatInfo) => void,
    onCancel?: () => void;
}

function StkTradingStatisticsEdit({ gsb, stk = {} as SmStatInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: StkTradingStatisticsEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<SmStatInfo>()
    const { t, i18n } = useTranslation();    

    const [StkNoValue, setStkNoValue] = useState(stk.d_StkNo)
    const [StkCodeValue, setStkCodeValue] = useState("")
    const [StkNameValue, setStkNameValue] = useState("")

    const [MktCapValue, setMktCapValue] = useState(utils.numToString(stk.d_MktCap))
    const [IssShareValue, setIssShareValue] = useState(utils.numToString(stk.d_IssShare))
    const [TurnoverValue, setTurnoverValue] = useState(utils.numToString(stk.d_Turnover))
    const [ClsPriceValue, setClsPriceValue] = useState(utils.numToString(stk.d_ClsPrice))


    useEffect(() => {
        // Call reset to get the values of record SmStatInfo 
        if ("d_StkNo" in stk) {
            reset(stk)
        }
    }, [stk, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Market" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNoValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNoValue.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCodeValue(res.data.data[0].d_StkCode)
                    setStkNameValue(res.data.data[0].d_StkName)

                } else { 
                    setStkCodeValue("")
                    setStkNameValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNoValue])

    
    if (! isNew && ! ("d_StkNo" in stk)) return null


    const onSubmit = (data: SmStatInfo) => {

        //console.log("StkTradingStatisticsedit onsubmit data: ", data)
        //console.log("StkTradingStatisticsedit onsubmit StkPrice: ", StkPrice)
        //console.log("StkTradingStatisticsedit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        data.d_StkNo = StkNoValue;

        data.d_MktCap = utils.StringToNum(MktCapValue);
        data.d_IssShare = utils.StringToNum(IssShareValue);
        data.d_Turnover = utils.StringToNum(TurnoverValue);
        data.d_ClsPrice = utils.StringToNum(ClsPriceValue);

        (async () => {
            try {
                //console.log("call StkTradingStatisticsedit data", data)
                //console.log("call StkTradingStatisticsedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "StkTradingStatistics", cAction: "StkTradingStatisticsValidate", isNew: isNew})
                //console.log("StkTradingStatisticsedit res.data.ok", res.data.ok)
                //console.log("StkTradingStatisticsedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

/*                        
                    setError("d_FeeCode", {
                        type: "manual",
                        message: "Dont Forget Your abc !"
                    }); 
                    console.log("StkTradingStatisticsedit onsubmit errors: ", errors)
*/                    
                    //console.log("StkTradingStatisticsedit onsubmit GsbErrors: ", GsbErrors)

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }





    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
             </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="input_StkNo" labelInfo="*">
                    <InputGroup disabled={! isNew} id="input_StkNo" value={StkNoValue} autoFocus={isNew} defaultValue="" placeholder="Stock No. ..." onChange={utils.handleStringChange(s => setStkNoValue(s)) } inputRef={register({ required: true , maxLength: 6}) } />
                        {errors.d_StkNo && errors.d_StkNo.type === "required" && <span id="Err_msg">Stock No. cannot be Blank</span>}
                        {errors.d_StkNo && errors.d_StkNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                        {errors.d_StkNo && errors.d_StkNo.types && <span id="Err_msg">{errors.d_StkNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCodeValue}</div>
                    <div id="DispText">{StkNameValue}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Temp Stock No. For SPLIT/CONS" labelFor="input_StkNo2" labelInfo="">
                    <InputGroup id="input_StkNo2" name="d_StkTempNo" defaultValue="" inputRef={register() } />
                    {errors.d_StkTempNo && errors.d_StkTempNo.type === "required" && (<span id="Err_msg">Temp Stock No. cannot be Blank</span>)}
                    {errors.d_StkTempNo && errors.d_StkTempNo.types && <span id="Err_msg">{errors.d_StkTempNo.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Period (yymm)" labelFor="inputStkNo" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputPeriod" name="d_Period" defaultValue="" inputRef={register({ required: true }) } />
                    {errors.d_Period && errors.d_Period.type === "required" && (<span id="Err_msg">Period cannot be Blank</span>)}
                    {errors.d_Period && errors.d_Period.types && <span id="Err_msg">{errors.d_Period.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Market Capitalization" labelFor="input_MktCap">
                    <InputGroup id="input_MktCap" name="d_MktCapStr" autoFocus={! isNew} defaultValue="" inputRef={register() } />
                    {errors.d_MktCapStr && errors.d_MktCapStr.type === "min" && <span id="Err_msg">Market Capitalization sholud be greater than 0 </span>}
                    {errors.d_MktCapStr && errors.d_MktCapStr.types && <span id="Err_msg">{errors.d_MktCapStr.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="input_MktCap">
                    <InputGroup disabled={true} id="input_MktCap" defaultValue={MktCapValue} value={MktCapValue} onChange={utils.handleStringChange(n => setMktCapValue(n))} />
                    {errors.d_MktCap && errors.d_MktCap.type === "min" && <span id="Err_msg">Market Capitalization sholud be greater than 0 </span>}
                    {errors.d_MktCap && errors.d_MktCap.types && <span id="Err_msg">{errors.d_MktCap.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Issued Shares" labelFor="input_IssShare">
                    <InputGroup id="input_IssShare" name="d_IssShareStr" defaultValue="" inputRef={register() } />
                    {errors.d_IssShareStr && errors.d_IssShareStr.type === "min" && <span id="Err_msg">Issued Shares sholud be greater than 0 </span>}
                    {errors.d_IssShareStr && errors.d_IssShareStr.types && <span id="Err_msg">{errors.d_IssShareStr.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="input_IssShare">
                    <InputGroup disabled={true} id="input_IssShare" defaultValue={IssShareValue} value={IssShareValue} onChange={utils.handleStringChange(n => setIssShareValue(n))} />
                    {errors.d_IssShare && errors.d_IssShare.type === "min" && <span id="Err_msg">Issued Shares sholud be greater than 0 </span>}
                    {errors.d_IssShare && errors.d_IssShare.types && <span id="Err_msg">{errors.d_IssShare.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Turnover Value" labelFor="input_Turnover">
                    <InputGroup id="input_Turnover" name="d_TurnoverStr" defaultValue="" inputRef={register() } />
                    {errors.d_TurnoverStr && errors.d_TurnoverStr.type === "min" && <span id="Err_msg">Turnover sholud be greater than 0 </span>}
                    {errors.d_TurnoverStr && errors.d_TurnoverStr.types && <span id="Err_msg">{errors.d_TurnoverStr.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="." labelFor="input_Turnover">
                    <InputGroup disabled={true} id="input_Turnover" defaultValue={TurnoverValue} value={TurnoverValue} onChange={utils.handleStringChange(n => setTurnoverValue(n))} />
                    {errors.d_Turnover && errors.d_Turnover.type === "min" && <span id="Err_msg">Turnover sholud be greater than 0 </span>}
                    {errors.d_Turnover && errors.d_Turnover.types && <span id="Err_msg">{errors.d_Turnover.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Month-End Closing Price" labelFor="input_ClsPrice">
                    <InputGroup id="input_ClsPrice" defaultValue={ClsPriceValue} value={ClsPriceValue} onChange={utils.handleStringChange(n => setClsPriceValue(n))} />
                    {errors.d_ClsPrice && errors.d_ClsPrice.type === "min" && <span id="Err_msg">Closing Price sholud be greater than 0 </span>}
                    {errors.d_ClsPrice && errors.d_ClsPrice.types && <span id="Err_msg">{errors.d_ClsPrice.types.message}</span>}
                </FormGroup>
            </div>
        </form>
    )
}

interface StkTradingStatisticsMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function StkTradingStatisticsMain({ gsb }: StkTradingStatisticsMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    const [data, setData] = useState([] as SmStatInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkName, setsearchStkName] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentStk, setCurrentStk] = useState({} as SmStatInfo)
    const [BlankStk, setBlankStk] = useState({} as SmStatInfo)
    const [CurrentLog, setCurrentLog] = useState({} as SmStatInfo)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankStk.d_StkNo = ""
    BlankStk.d_StkTempNo = " "
    BlankStk.d_Period = ""


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("StkTradingStatisticsMain disp user_key: ", res.data.Flag.user_key)
                //console.log("StkTradingStatisticsMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("StkTradingStatisticsMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("StkTradingStatisticsMain disp GsbFlag: ", GsbFlag)
                //console.log("StkTradingStatisticsMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("StkTradingStatisticsMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("StkTradingStatisticsMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("StkTradingStatisticsMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("StkTradingStatisticsMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    const PrevRecProcess = async (stk: SmStatInfo) => {
        try {
            //console.log("call PrevRecProcess", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "StkTradingStatistics", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo === res.data.data.d_StkNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setCurrentStk(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (stk: SmStatInfo) => {
        try {
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "StkTradingStatistics", cAction: "DelRec"})
            if (res.data.ok) {
                showSuccess("Stock No. " + stk.d_StkNo +","+ stk.d_Period + " record deleted")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_StkNo === res.data.data.d_StkNo) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentStk(res.data.data)
                setCurrentMode("show")
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (stk: SmStatInfo) => {
        try {
            //console.log("call NextRecProcess", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "StkTradingStatistics", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo === res.data.data.d_StkNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentStk(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (stk: SmStatInfo) => {
        try {
            //console.log("call newOnSave", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "StkTradingStatistics", cAction: "AddRec"})
            showSuccess("Stock No. " + stk.d_StkNo + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo === res.data.data.d_StkNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentStk(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (stk: SmStatInfo) => {
        try {
            //console.log("call editOnSave", stk)
            //console.log("stk.d_CcassStk: ", stk.d_CcassStk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "StkTradingStatistics", cAction: "UpdateRec"})
            showSuccess("Stock No. " + stk.d_StkNo + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo === res.data.data.d_StkNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    //console.log("editOnSave data", i, data)
                    setData(data)
                    //setCurrentStk(stk)
                    break
                }
            }
            //console.log("editOnSave stk", stk)
            //console.log("editOnSave res.data.data", res.data.data)
            setCurrentStk(res.data.data)
            setCurrentMode("show")
            //setisShowAlert1(true)
            //setShowAlert1Msg("Account No. " +ac.d_acCode + " record saved")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get StkTradingStatistics list if StkNo have value
        try {
            //console.log("StkTradingStatisticsMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            StkName: searchStkName,
                        }, 
                    },
                    cPgmId: "StkTradingStatistics",
                    cAction: "ListRec",
                });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentStk(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_StkNo: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "StkTradingStatistics",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentStk(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one StkTradingStatistics record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("StkTradingStatisticsMain useEffect length > 0  called", data)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "StkTradingStatistics",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentStk(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                StkName: searchStkName,
                            }, 
                        },
                        cPgmId: "StkTradingStatistics",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentStk(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <StkTradingStatisticsView gsb={gsb} stk={currentStk} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentStk)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentStk)} onNextRec={() => NextRecProcess(currentStk)} ></StkTradingStatisticsView>
    switch (currentMode) {
        //case "PrevRec":
        // ?? will call 2 times ??
        //    console.log("currentMode = PrevRec")
        //    PrevRecProcess(currentStk)
        //    break
        case "new":
            //console.log("currentMode = new")
            detail = <StkTradingStatisticsEdit gsb={gsb} stk={BlankStk} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></StkTradingStatisticsEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <StkTradingStatisticsEdit gsb={gsb} stk={currentStk} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></StkTradingStatisticsEdit>
            break
        //case "delete":
        //    console.log("currentMode = delete")
        //    DeleteProcess(currentStk)
        //    break
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-Period">Period</th>
                <th className="TCol-MktCap">Market Capitalization</th>
                <th className="TCol-Turnover">Turnover</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((stk, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-StkNo" onClick={() => { setCurrentStk(stk) ; MoblieDev && setSelectedTabId("detail") }}>{stk.d_StkNo}</td>
                        <td className="TCol-Period" onClick={() => { setCurrentStk(stk) ; MoblieDev && setSelectedTabId("detail") }}>{stk.d_Period}</td>
                        <td className="TCol-MktCap" onClick={() => { setCurrentStk(stk) }}>{utils.formatNumber2(stk.d_MktCap)}</td>
                        <td className="TCol-Turnover" onClick={() => { setCurrentStk(stk) }}>{utils.formatNumber2(stk.d_Turnover)}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    {/* define search key */}
                    <H3><div style={{justifyContent: 'flex-end'}}>232 Stock Trading Statistics</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="StkNo">Stock No</option>
                                    {/* <option value="StkCode">Stock Code</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            {/* <FormGroup className="Col-1-5" label="Stock Name" labelFor="searchStkName">
                                <InputGroup id="searchStkName" value={searchStkName} onChange={utils.handleStringChange(s => setsearchStkName(s))} />
                            </FormGroup> */}
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>
            </form>
        </>
    );
}

export default StkTradingStatisticsMain