import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, InputGroup, Button, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';

var GsbFlag: any;


interface ResetprocessMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


function ResetprocessMain({ gsb }: ResetprocessMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [ResetStatus, setResetStatus] = useState("")

    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)


    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    const onResetprocess = () => {
        //console.log("onResetprocess fileInfoList", fileInfoList);
        (async () => {
            setResetStatus("")
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "Resetprocess",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                if ( res.data.ok ) {
                    setResetStatus("Reset Process is OK") 
                } else {
                    setResetStatus("OK, (No xProcess file)") 
                }
            } catch (error) {
                //utils.showError(error)
                showErrorBox(error)
            }
        })()
    }


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <p><H3>Reset Process Status</H3> when the Process is Running</p>
                    <div>
                        <div>{ResetStatus}</div>
                    </div >
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button className="Col-1-2" icon={IconNames.LIST} onClick={onResetprocess}>Reset Process Status</Button>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <br></br>
                    <p>Notes</p>
                    <div>
                        <div>Reset Process Status</div>
                    </div >
                </Card>
            </form>
        </>
    );
}

export default ResetprocessMain