import React, { useState, FormEvent, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation, Icon } from "@blueprintjs/core";
import { DateInput2 } from "@blueprintjs/datetime2";
import { format, parse, addDays} from "date-fns";
import { IconNames } from "@blueprintjs/icons";

import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { StkInfo, StkCodeInfo, MarketInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "M0007" ;
var isReqAuth2:boolean = false ;

interface SmMastViewProps {
    gsb: AxiosInstance,
    stk: StkInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}


function SmMastView({ gsb, stk = {} as StkInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: SmMastViewProps) {

    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)


    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("ClMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, stk])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_StkNo" in stk)) return null

    
    return (
        <div>
            {/* define add,change,delete button */}
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div> }
            { ViewMode === "L" && <div>
                Stock No. : {stk.d_StkNo}
                { (logKey2.indexOf("*A1") > 0 ) && <div>Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div>Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div>Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelInfo="*">
                    <InputGroup readOnly value={stk.d_StkNo} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="Stock Code" labelInfo="*">
                    <InputGroup readOnly value={stk.d_StkCode} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label="Stock Name">
                    <InputGroup readOnly value={stk.d_StkName} />
                </FormGroup>
            </div>
            { ( GsbFlag.MULTI_LING >= 1 ) && <div className="Row">
                <FormGroup className="Col-1-5" label="Stock Chinese Name" labelFor="inputStkChiName">
                    <InputGroup readOnly value={stk.d_Chi_Name_bg5} />
                </FormGroup>
            </div>}

            {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                <div className="Row">
                    <FormGroup className="Col3-1-1" label="Market Code">
                        <InputGroup readOnly value={stk.d_Market} />
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Fee Code">
                        <InputGroup readOnly value={stk.d_FeeCode} />
                    </FormGroup>
                    <FormGroup className="Col-3-1" label="Currency">
                        <InputGroup readOnly value={stk.d_Currency} />
                    </FormGroup>
                </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-2" label="Isin Code">
                    <InputGroup readOnly value={stk.d_Isin} />
                </FormGroup>
                <FormGroup className="Col-3-1" label="Group">
                    <InputGroup readOnly value={stk.d_StkGorup} />
                </FormGroup>
                {(GsbFlag.SFC_EXCEL2) && <FormGroup className="Col-4-1" label="Group 2">
                    <InputGroup readOnly value={stk.d_StkGorup2} />
                </FormGroup> }
                {(GsbFlag.IFTT_TAX) &&
                    <FormGroup className="Col-5-1" label="IFTT Tax Code">
                        <InputGroup readOnly value={stk.d_IFTTTaxCode} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Type">
                    <InputGroup readOnly value={stk.d_StkType} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="High Risk">
                    {/* <InputGroup readOnly value={stk.d_Highrisk} /> */}
                    <Switch readOnly label="" innerLabelChecked="Yes" innerLabel="No" checked={(stk.d_Highrisk==="H")} />
                </FormGroup>
                <FormGroup className="Col-4-2" label="Warrant/Rights Expiry Date">
                    <InputGroup readOnly value={utils.dispDate(stk.d_ExpiryDate, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="CCASS Stock">
                    {/* <InputGroup readOnly value={stk.d_CcassStk} /> */}
                    {/* <Checkbox label="CCASS Stock" disabled={true} checked={(stk.d_CcassStk==="Y")} onChange={()  => {} } /> */}
                    {/* <input readOnly type="checkbox" checked={(stk.d_CcassStk==="Y")} /> CCASS */} 
                    {/* ( ViewMode === "V" || (ViewMode === "L" && stk.d_CcassStk.trim().length > 0) ) && <Switch readOnly id="inputCcassStk" label="" innerLabelChecked="CCASS" innerLabel="non-CCASS" checked={(stk.d_CcassStk==="Y")} /> */}
                    <RadioGroup
                        name="d_CcassStk"
                        disabled={true}
                        inline={true}
                        selectedValue={stk.d_CcassStk}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-3-2" label="CCASS Trade On">
                    <InputGroup readOnly value={utils.dispDate(stk.d_CcassTradeOn, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Contract Stamp">
                    <RadioGroup
                        label="" 
                        name="d_Stamp"
                        disabled={true}
                        inline={true}
                        selectedValue={stk.d_Stamp}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-3-2" label="Listing On">
                    {/* <InputGroup readOnly value={utils.hb_DtoS(stk.d_ListingDate)} /> */}
                    <InputGroup readOnly value={utils.dispDate(stk.d_ListingDate, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Trade Suspended">
                    {/* <InputGroup readOnly value={stk.d_TradeSuspend} /> */}
                    {/*  <RadioGroup
                        label="" 
                        name="d_TradeSuspended"
                        disabled={true}
                        inline={true}
                        selectedValue={stk.d_TradeSuspend}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup> */}
 {/*                    
                    <label>
                        <input readOnly type="radio" name="d_TradeSuspend" checked={stk.d_TradeSuspend=="Y"} />Yes
                    </label>
                    <label>
                        <input readOnly type="radio" name="d_TradeSuspend" checked={stk.d_TradeSuspend=="N"} />  No
                    </label>
 */}                    
                    <RadioGroup
                        label="" 
                        name="d_TradeSuspended"
                        disabled={true}
                        inline={true}
                        selectedValue={stk.d_TradeSuspend}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                 </FormGroup>
                <FormGroup className="Col-3-2" label="Suspended On">
                    <InputGroup readOnly value={utils.dispDate(stk.d_SuspendOn, GsbFlag.dateFormat)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Lot Size">
                    <InputGroup readOnly id="inputLotSize" name="d_LotSize" value={utils.formatNumber2(stk.d_LotSize)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Price">
                    <InputGroup readOnly value={utils.formatNumber2(stk.d_StkPrice)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Grade">
                    <InputGroup readOnly value={stk.d_Grade} />
                </FormGroup>
                { (stk.d_Grade === "S") &&
                    <FormGroup className="Col-2-2" label="Special Asset Ratio">
                       <InputGroup readOnly value={utils.formatNumber2(stk.d_SAssetRatio)} />
                   </FormGroup>
                }
            </div>
            {/*  <div className="Row">
                <FormGroup className="Col-1-2" label="Margin Ratio">
                    <InputGroup readOnly id="inputMarginRatio" name="d_MarginRatio" value={utils.formatNumber2(stk.d_MarginRatio)} />
                </FormGroup>
            </div> */ }
            { (! GsbFlag.MGNGP_USER) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Margin Ratio">
                        <InputGroup readOnly id="inputMarginRatio" name="d_MarginRatio" value={utils.formatNumber2(stk.d_MarginRatio)} />
                    </FormGroup>
                    { ( GsbFlag.SFC_EXCEL) && 
                        <FormGroup className="Col-3-1" label="Tier" labelFor="inputTier">
                            <InputGroup readOnly value={stk.d_Tier} />
                        </FormGroup>
                    }
                </div>
            }
            { (GsbFlag.MGNGP_USER) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Margin Ratio">
                        <InputGroup readOnly id="inputMarginRatio" name="d_MarginRatio" value={utils.formatNumber2(stk.d_MarginRatio)} />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Margin Ratio 2">
                        <InputGroup readOnly id="inputMarginRatio2" name="d_MarginRatio2" value={utils.formatNumber2(stk.d_MarginRatio2)} />
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Margin Ratio 3">
                        <InputGroup readOnly id="inputMarginRatio3" name="d_MarginRatio3" value={utils.formatNumber2(stk.d_MarginRatio3)} />
                    </FormGroup>
                    <FormGroup className="Col-7-2" label="Margin Ratio 4">
                        <InputGroup readOnly id="inputMarginRatio4" name="d_MarginRatio4" value={utils.formatNumber2(stk.d_MarginRatio4)} />
                    </FormGroup>
                    { ( GsbFlag.SFC_EXCEL) && 
                        <FormGroup className="Col-9-1" label="Tier" labelFor="inputTier">
                            <InputGroup readOnly value={stk.d_Tier} />
                        </FormGroup>
                    }
                </div>
            }
            <div className="Row">
                { (GsbFlag.UNIT_TRUST) && <FormGroup className="Col-1-2" label="Qty Decimal">
                    <InputGroup readOnly value={stk.d_Qty_Decimal} />
                </FormGroup> }
                { (GsbFlag.FLOATPRICE) && <FormGroup className="Col-3-2" label="Price Decimal">
                    <InputGroup readOnly value={stk.d_Price_Decimal} />
                </FormGroup> }
                <FormGroup className="Col-5-2" label="Warn Int Accrued" labelFor="inputWarn_Int_Accrued">
                   <RadioGroup
                        label="" 
                        name="d_Warn_Int_Accured"
                        disabled={true}
                        inline={true}
                        selectedValue={stk.d_Warn_Int_Accured}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>

        </div>
    )
}



interface SmMastEditProps {
    gsb: AxiosInstance,
    stk?: StkInfo,
    StkCodeList?: StkCodeInfo[],
    MarketList?: MarketInfo[],
    isNew?: boolean,
    onSave?: (stk: StkInfo) => void,
    onCancel?: () => void;
}

function SmMastEdit({ gsb, stk = {} as StkInfo, StkCodeList=[] as StkCodeInfo[], MarketList=[] as MarketInfo[], isNew = false, onSave = () => { }, onCancel = () => { } }: SmMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<StkInfo>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    const [HighriskValue, setHighriskValue] = useState(stk.d_Highrisk==="H")
    const [CCASSStockValue, setCCASSStockValue] = useState(stk.d_CcassStk)
    const [TradeSuspendedValue, setTradeSuspendedValue] = useState(stk.d_TradeSuspend)
    const [StampValue, setStampValue] = useState(stk.d_Stamp)
    const [StkPrice, setStkPrice] = useState(stk.d_StkPrice)
    const [StkGrade, setStkGrade] = useState(stk.d_Grade)
    const [Warn_Int_AccuredValue, setWarn_Int_AccuredValue] = useState(stk.d_Warn_Int_Accured)

    const [ListdateValue, setListdateValue] = useState(utils.formatNumber(new Date()))
    const Mindate1 = new Date(1800, 0, 1)
    const Maxdate1 = addDays(new Date(), 365*5)

    
    const dateFnsFormat = "dd-MM-yyyy";
    const formatDate = useCallback((date: Date) => format(date, dateFnsFormat), []);
    const parseDate = useCallback((str: string) => parse(str, dateFnsFormat, new Date()), []);


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, stk])


    useEffect(() => {
        // Call reset to get the values of record StkInfo 
        if ("d_StkNo" in stk) {
            reset(stk)
        }
    }, [stk, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        register({ name: "d_Market" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    useEffect(() => {
        try {
            if ( ! (stk.d_ListingDate == "    -  -  " ) ) {
                setListdateValue(format(parse(stk.d_ListingDate, "yyyy-MM-dd", new Date()), "yyyy-MM-dd"))
            }
        } catch (error) {
            showErrorBox(error)
        }
    }, [gsb, stk.d_StkNo, stk.d_ListingDate])



    if (! isNew && ! ("d_StkNo" in stk)) return null


    function handleSuspendChange(value: string) {
        stk.d_TradeSuspend = value.toString();
        setTradeSuspendedValue(value.toString());
        //console.log("handleSuspendChange stk.d_TradeSuspend ", stk.d_TradeSuspend )
        //console.log("handleSuspendChange TradeSuspendedValue ", TradeSuspendedValue )
        return null;
    }
    
    const stkCodeIsUnique = async (i_StkCode: string) => {
/*        
        (async () => {
            try {
                // get Stock Code List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "StkCodeList"})
                console.log("StkCodeList: ", res.data.data) 
                //setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                showErrorBox(error)
            }
        })()


        let i = 0 ;
        let l_stkcode = false;
        //console.log("SmMastEdit StkCodeList ", StkCodeList.length, StkCodeList) ;
        //console.log("SmMastEdit i_StkCode ", i_StkCode) ;
        //console.log("SmMastEdit stk.d_StkNo1: ", stk.d_StkNo) ;
        //console.log("SmMastEdit GsbFlag: ", GsbFlag)
        for (i = 0; i < StkCodeList.length; i++) {
            if ( StkCodeList[i].d_StkCode.trim() === i_StkCode.trim() ){
                //console.log("SmMastEdit StkCodeList.d_StkNo:", utils.STRVAL(GsbFlag, StkCodeList[i].d_StkNo) );
                //console.log("SmMastEdit        stk.d_StkNo2:", utils.STRVAL(GsbFlag, stk.d_StkNo) );
                if ( utils.STRVAL(GsbFlag, StkCodeList[i].d_StkNo) === utils.STRVAL(GsbFlag, stk.d_StkNo) )
                {
                    l_stkcode = true;
                    //console.log("SmMastEdit l_stkcode:  ", l_stkcode) ;
                    break
                }
                break
            }
        }
        console.log("SmMastEdit i:  ", i, StkCodeList.length) ;
        if ( i === StkCodeList.length ) 
        {
            l_stkcode = true;
        }
        return l_stkcode;
*/        
        return true;

    }

    const onSubmit = (data: StkInfo) => {

        //console.log("smmastedit onsubmit data: ", data)
        //console.log("smmastedit onsubmit StkPrice: ", StkPrice)
        //console.log("smmastedit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        // put value to json/field before write database dbf
        if ( typeof(data.d_Market) === "undefined") {
            data.d_Market = ""
        }

        data.d_StkCode = data.d_StkCode.toUpperCase();
        if (GsbFlag.MULTI_MKT && ! GsbFlag.SING_CURR) {
            data.d_Market = data.d_Market.toUpperCase();
            data.d_FeeCode = data.d_FeeCode.toUpperCase();
            data.d_Currency = data.d_Currency.toUpperCase();
        }
        if (GsbFlag.IFTT_TAX) {
            data.d_IFTTTaxCode = data.d_IFTTTaxCode.toUpperCase();
        }

        data.d_Highrisk = "H";
        if (HighriskValue) {
            data.d_Highrisk = "H";
        } else {
            data.d_Highrisk = " ";
        }

/*        
        data.d_CcassStk = "Y";
        if (CCASSStockValue) {
            data.d_CcassStk = "Y";
        } else {
            data.d_CcassStk = "N";
        }
*/        
        data.d_CcassStk = CCASSStockValue

        if ( typeof(data.d_Tier) === "undefined") {
            data.d_Tier = ""
        }

        data.d_Stamp = StampValue;
        data.d_TradeSuspend = TradeSuspendedValue;
        //data.d_StkPrice = StkPrice;
        data.d_Grade = StkGrade;
        data.d_Warn_Int_Accured = Warn_Int_AccuredValue;

/*
        data.d_ListingDate = ListdateValue
        if ( data.d_ListingDate === null) {
            data.d_ListingDate = "    -  -  "
        }
*/

        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }
        
        ;

/*
        if (data.d_Market.trim().length > 0 && data.d_FeeCode.trim().length === 0)
        {
            //GsbErrors.push({field_error: false, field_name: "d_FeeCode", field_type: "manual", field_message: "Fee Code cann ot be Blank"})
            //GsbErrors.push({field_error: false, field_name: "d_StkGorup", field_type: "manual", field_message: "Stk Gorup cann ot be Blank"})
            //setGError1({field_error: false, field_name: "d_FeeCode", field_type: "manual", field_message: "Fee Code cann ot be Blank"})

            GsbErrors[4].field_error = true;
            GsbErrors[4].field_name = "d_FeeCode";
            GsbErrors[4].field_message = "Fee Code cann ot be Blank";

            GsbErrors[7].field_error = true;
            GsbErrors[7].field_name = "d_StkGorup";
            GsbErrors[7].field_message = "Stk Gorup cann ot be Blank";

            console.log("smmastedit onsubmit GsbErrors: ", GsbErrors)

            //alert("Fee Code cann ot be Blank");
            return
        } 
*/
        (async () => {
            try {
                //console.log("call smmastedit data", data)
                //console.log("call smmastedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "Smmast", cAction: "SmValidate", isNew: isNew})
                //console.log("smmastedit res.data.ok", res.data.ok)
                //console.log("smmastedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

/*                        
                    setError("d_FeeCode", {
                        type: "manual",
                        message: "Dont Forget Your abc !"
                    }); 
                    console.log("smmastedit onsubmit errors: ", errors)
*/                    
                    //console.log("smmastedit onsubmit GsbErrors: ", GsbErrors)

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }


     const MktRenderer: ItemRenderer<MarketInfo> = (MarketList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${MarketList.d_MktName}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={MarketList.d_Market}
                key={MarketList.d_Market}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const MktPredicate: ItemPredicate<MarketInfo> = (query, MarketList, _index, exactMatch) => {
        const normalizedText = `${MarketList.d_Market} - ${MarketList.d_MktName}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const MktValueRenderer = (MarketList: MarketInfo) => {
        return MarketList.d_Market
    }

    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }





    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
             </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputStkNo" name="d_StkNo" autoFocus={isNew} defaultValue="" inputRef={register({ required: true }) } />
                    {errors.d_StkNo && errors.d_StkNo.type === "required" && (<span id="Err_msg">Stock No. cannot be Blank</span>)}
                    {/* GsbErrors[0].field_error &&<span id="Err_msg">{GsbErrors[0].field_message}</span> */}
                    {errors.d_StkNo && errors.d_StkNo.types && <span id="Err_msg">{errors.d_StkNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Stock Code" labelFor="inputStkCode" labelInfo="*">
                    <InputGroup id="inputStkCode" name="d_StkCode" autoFocus={! isNew} defaultValue="" placeholder="Stock Code is 15 chars ..." inputRef={register({ required: true , maxLength: 15, validate: stkCodeIsUnique }) } />
                    {errors.d_StkCode && errors.d_StkCode.type === "required" && <span id="Err_msg">Stock Code cannot be Blank</span>}
                    {errors.d_StkCode && errors.d_StkCode.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.d_StkCode && errors.d_StkCode.type === "validate" && <span id="Err_msg">Stock Code existed</span>}
                    {errors.d_StkCode && errors.d_StkCode.types && <span id="Err_msg">{errors.d_StkCode.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label="Stock Name" labelFor="inputStkName">
                    <InputGroup id="inputStkName" name="d_StkName" defaultValue="" placeholder="Stock Name ..." inputRef={register({ maxLength: 45}) } />
                    {errors.d_StkName && errors.d_StkName.type === "maxLength" && <span id="Err_msg">Max length is 45</span>}
                    {errors.d_StkName && errors.d_StkName.types && <span id="Err_msg">{errors.d_StkName.types.message}</span>}
                </FormGroup>
            </div>
            { ( GsbFlag.MULTI_LING >= 1 ) && <div className="Row">
                <FormGroup className="Col-1-5" label="Stock Chinese Name" labelFor="inputStkChiName">
                    <InputGroup id="inputStkChiName" name="d_Chi_Name_bg5" defaultValue="" placeholder="Stock Chinese Name (Big5) ..." inputRef={register({ maxLength: 20}) } />
                    {errors.d_Chi_Name_bg5 && errors.d_Chi_Name_bg5.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.d_Chi_Name_bg5 && errors.d_Chi_Name_bg5.types && <span id="Err_msg">{errors.d_Chi_Name_bg5.types.message}</span>}
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                <div className="Row">
                    <FormGroup className="Col3-1-1" label="Market Code" labelFor="inputMarketCode">
                        {/* <InputGroup id="inputMarketCode" name="d_Market" defaultValue="" placeholder="Market Code ..." inputRef={register({ maxLength: 4}) } />
                        {errors.d_Market && errors.d_Market.type === "maxLength" && <span id="Err_msg">Max length is 4</span>} */}
                        <Suggest
                            inputProps={{ placeholder: "Market Code ..." }}
                            activeItem={MarketList.find((mkt) => mkt.d_Market === stk.d_Market)}
                            defaultSelectedItem={MarketList.find((mkt) => mkt.d_Market === stk.d_Market)}
                            items={MarketList}
                            itemRenderer={MktRenderer}
                            itemPredicate={MktPredicate}
                            inputValueRenderer={MktValueRenderer}
                            onItemSelect={(stk) => { setValue("d_Market", stk.d_Market) }}
                        />
                        {errors.d_Market && errors.d_Market.types && <span id="Err_msg">{errors.d_Market.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Fee Code" labelFor="inputFeeCode">
                        <InputGroup id="inputFeeCode" name="d_FeeCode" defaultValue="" placeholder="Market Fee Code ..." inputRef={register({ maxLength: 2}) } />
                        {errors.d_FeeCode && errors.d_FeeCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                        {/* errors.d_FeeCode && <span id="Err_msg">Fee Code cannot be Blank</span> */}
                        {errors.d_FeeCode && errors.d_FeeCode.types && <span id="Err_msg">{errors.d_FeeCode.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-1" label="Currency" labelFor="inputCurency">
                        <InputGroup id="inputCurency" name="d_Currency" defaultValue="" placeholder="Currency ..." inputRef={register({ maxLength: 3}) } />
                        {errors.d_Currency && errors.d_Currency.type === "maxLength" && <span id="Err_msg">Max length is 3</span>}
                        {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>}
                    </FormGroup>
                </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-2" label="Isin Code" labelFor="inputIsin">
                    <InputGroup id="inputIsin" name="d_Isin" defaultValue="" placeholder="Isin Code ..." inputRef={register({ maxLength: 12}) } />
                    {errors.d_Isin && errors.d_Isin.type === "maxLength" && <span id="Err_msg">Max length is 12</span>}
                    {errors.d_Isin && errors.d_Isin.types && <span id="Err_msg">{errors.d_Isin.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-1" label="Group" labelFor="inputStkGroup">
                    <InputGroup id="inputStkGroup" name="d_StkGorup" defaultValue="" placeholder="Stock Group ..." inputRef={register({ maxLength: 6}) } />
                    {errors.d_StkGorup && errors.d_StkGorup.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_StkGorup && errors.d_StkGorup.types && <span id="Err_msg">{errors.d_StkGorup.types.message}</span>}
                </FormGroup>
                {(GsbFlag.SFC_EXCEL2) && <FormGroup className="Col-4-1" label="Group 2" labelFor="inputStkGroup2">
                    <InputGroup id="inputStkGroup2" name="d_StkGorup2" defaultValue="" placeholder="Stock Group 2..." inputRef={register({ maxLength: 6}) } />
                    {errors.d_StkGorup2 && errors.d_StkGorup2.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_StkGorup2 && errors.d_StkGorup2.types && <span id="Err_msg">{errors.d_StkGorup2.types.message}</span>}
                </FormGroup> }
                {(GsbFlag.IFTT_TAX) &&
                    <FormGroup className="Col-5-1" label="IFTT Tax Code" labelFor="inpuIFTTTaxCode">
                        <InputGroup id="inpuIFTTTaxCode" name="d_IFTTTaxCode" defaultValue="" placeholder="IFTT Tax Code ..." inputRef={register({ maxLength: 1}) } />
                        {errors.d_IFTTTaxCode && errors.d_IFTTTaxCode.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                        {errors.d_IFTTTaxCode && errors.d_IFTTTaxCode.types && <span id="Err_msg">{errors.d_IFTTTaxCode.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-1" label="Type" labelFor="inputStkType">
                    {/* <InputGroup id="inputStkType" name="d_StkType" inputRef={register} /> */}
                    <select  id="inputStkType" name="d_StkType" ref={register}>
                        <option value=" "> </option>
                        <option value="G">G: GEM</option>
                        <option value="B">B: BOND</option>
                        <option value="L">L: LOAN</option>
                        <option value="U">U: UNIT TRUST</option>
                        <option value="W">W: Warrant</option>
                        <option value="C">C: CCBC</option>
                    </select>
                    {errors.d_StkType && errors.d_StkType.types && <span id="Err_msg">{errors.d_StkType.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="High Risk" labelFor="inputHighrisk">
                    {/* <select  id="inputHighrisk" name="d_Highrisk" ref={register}>
                        <option value=" "> </option>
                        <option value="H">High Risk</option>
                    </select> */}
                    <Switch  id="inputHighrisk" label="" innerLabelChecked="Yes" innerLabel="No" checked={HighriskValue} onChange={utils.handleBooleanChange(v => setHighriskValue(v))} ref={register}/>
                    {errors.d_Highrisk && errors.d_Highrisk.types && <span id="Err_msg">{errors.d_Highrisk.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="Warrant/Rights Expiry Date"labelFor="inputExpiryDate">
                    <InputGroup id="inputExpiryDate" name="d_ExpiryDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_ExpiryDate && errors.d_ExpiryDate.types && <span id="Err_msg">{errors.d_ExpiryDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="CCASS Stock" labelFor="inputCcassStk">
                    {/* <InputGroup id="inputCcassStk" name="d_CcassStk" inputRef={register} /> */}
                    {/* <Checkbox name="d_CcassStk" label="CCASS Stock"  onChange={utils.handleBooleanChange(v => setCCASSStockValue(v))} /> */}
                    {/* <input id="inputCcassStk" type="checkbox" checked={CCASSStockValue} onChange={utils.handleBooleanChange(v => setCCASSStockValue(v))} ref={register}/> CCASS */}
                    {/* <Switch  id="inputCcassStk" label="" innerLabelChecked="CCASS" innerLabel="non-CCASS" checked={CCASSStockValue} onChange={utils.handleBooleanChange(v => setCCASSStockValue(v))} ref={register}/> */}
                    <RadioGroup
                        name="d_CcassStk"
                        inline={true}
                        selectedValue={CCASSStockValue}
                        onChange={utils.handleStringChange(s => setCCASSStockValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Stamp && errors.d_Stamp.types && <span id="Err_msg">{errors.d_Stamp.types.message}</span>}
                    {errors.d_CcassStk && errors.d_CcassStk.types && <span id="Err_msg">{errors.d_CcassStk.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="CCASS Trade On"labelFor="inputCcassTradeOn">
                    <InputGroup id="inputCcassTradeOn" name="d_CcassTradeOn" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_CcassTradeOn && errors.d_CcassTradeOn.types && <span id="Err_msg">{errors.d_CcassTradeOn.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Contract Stamp" labelFor="inputStamp">
                    <RadioGroup
                        name="d_Stamp"
                        inline={true}
                        selectedValue={StampValue}
                        onChange={utils.handleStringChange(s => setStampValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Stamp && errors.d_Stamp.types && <span id="Err_msg">{errors.d_Stamp.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Listing On"labelFor="inputListDate">
                    {/* <DateInput formatDate={date => date.toLocaleDateString("ko-KR")} parseDate={str => new Date(str) } placeholder="YYYY-MM-DD" closeOnSelection={true} /> */}
                    <InputGroup id="inputListDate" name="d_ListingDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} /> 
                    {/* <input id="inputListDate" name="d_ListingDate" type="date" placeholder="YYYY-MM-DD" ref={register} /> */}
                    {/* <DateInput2
                        canClearSelection={true}
                        clearButtonText="Clear"
                        closeOnSelection={true}
                        todayButtonText="Today"
                        showActionsBar={true}
                        popoverProps={{ placement: "bottom" }}
                        rightElement={<Icon icon="calendar" style={{ padding: 7, marginLeft: -5 }} />}
                        minDate={Mindate1}
                        maxDate={Maxdate1}
                        formatDate={formatDate}
                        onChange={(date: any) => {
                            setListdateValue(date)
                        } }
                        parseDate={parseDate}
                        placeholder={dateFnsFormat}
                        value={ListdateValue}
                    /> */}
                    {errors.d_ListingDate && errors.d_ListingDate.types && <span id="Err_msg">{errors.d_ListingDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Trade Suspended" labelFor="inputTradeSuspend">
                    {/* <InputGroup id="inputTradeSuspend" name="d_TradeSuspend" inputRef={register} /> */}
                    {/* <RadioGroup
                        name="d_TradeSuspend"
                        inline={true}
                        selectedValue={TradeSuspendedValue}
                        onChange={utils.handleStringChange(s => setTradeSuspendedValue(s)) }
                        //onChange={utils.handleStringChange(s => handleSuspendChange(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup> */}
{/*                    
                    <label>
                        <input id="inputTradeSuspend" type="radio" name="d_TradeSuspend" value="Y" ref={register} />Yes
                    </label>
                    <label>
                        <input id="inputTradeSuspend" type="radio" name="d_TradeSuspend" value="N" ref={register} />  No
                    </label>
 */}                    
                    <RadioGroup
                        name="d_TradeSuspend"
                        inline={true}
                        selectedValue={TradeSuspendedValue}
                        onChange={utils.handleStringChange(s => setTradeSuspendedValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_TradeSuspend && errors.d_TradeSuspend.types && <span id="Err_msg">{errors.d_TradeSuspend.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Suspended On"labelFor="inputSuspendOn">
                    <InputGroup id="inputSuspendOn" name="d_SuspendOn" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_SuspendOn && errors.d_SuspendOn.types && <span id="Err_msg">{errors.d_SuspendOn.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Lot Size" labelFor="inputLotSize">
                    {/* <InputGroup id="inputd_LotSize" name="d_LotSize" defaultValue="0" type="number" inputRef={register({min: 0})} /> */}
                    {/* <NumericInput id="inputLotSize" name="d_LotSize" defaultValue={1000} min={1} buttonPosition="none" inputRef={register} /> */}
                    <InputGroup id="inputLotSize" name="d_LotSize" defaultValue="0" type="number" inputRef={register({min: 0})} />
                    {errors.d_LotSize && errors.d_LotSize.type === "min" && <span id="Err_msg">Lot Size sholud be greater than 0 </span>}
                    {errors.d_LotSize && errors.d_LotSize.types && <span id="Err_msg">{errors.d_LotSize.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Price" labelFor="inputStkPrice">
                    {/* <InputGroup id="inputStkPrice" name="d_StkPrice" defaultValue="0" type="number" inputRef={register} /> */}
                    {/* <NumericInput id="inputStkPrice" name="d_StkPrice" value={StkPrice} defaultValue={0} min={-0.01} minorStepSize={0.01} buttonPosition="right" onValueChange={(s => setStkPrice(s))} inputRef={register} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputStkPrice" name="d_StkPrice" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_StkPrice && errors.d_StkPrice.types && <span id="Err_msg">{errors.d_StkPrice.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-1" label="Grade" labelFor="inputGrade">
                    <select  id="inputGrade" name="d_Grade" value={StkGrade} onChange={utils.handleStringChange(s => setStkGrade(s)) } ref={register}>
                        <option value=" "> : Others</option>
                        <option value="A">A: HSI</option>
                        <option value="B">B: HS HK MidCap Stock</option>
                        <option value="C">C: Other Stock</option>
                        <option value="D">D: Warrant</option>
                        <option value="E">E: User Defined</option>
                        <option value="F">F: User Defined</option>
                        <option value="S">S: Special Asset Ratio</option>
                    </select>
                    {errors.d_Grade && errors.d_Grade.types && <span id="Err_msg">{errors.d_Grade.types.message}</span>}
                </FormGroup>
                { (StkGrade === "S") &&                
                    <FormGroup className="Col-2-2" label="Special Asset Ratio" labelFor="inputSAssetRatio">
                        <InputGroup className="inputNumberNoSpin" id="inputSAssetRatio" name="d_SAssetRatio" defaultValue="0" type="number" inputRef={register} />
                        {errors.d_SAssetRatio && errors.d_SAssetRatio.types && <span id="Err_msg">{errors.d_SAssetRatio.types.message}</span>}
                    </FormGroup>
                }
            </div>
            { (! GsbFlag.MGNGP_USER) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Margin Ratio">
                        <InputGroup className="inputNumberNoSpin" id="inputMarginRatio" name="d_MarginRatio" defaultValue="0" type="number" inputRef={register} />
                        {errors.d_MarginRatio && errors.d_MarginRatio.types && <span id="Err_msg">{errors.d_MarginRatio.types.message}</span>}
                    </FormGroup>
                    { ( GsbFlag.SFC_EXCEL) && 
                        <FormGroup className="Col3-3-1" label="Tier" labelFor="inputTier">
                            <select  id="inputTier" name="d_Tier" ref={register}>
                                <option value=" "> N/A </option>
                                <option value="1">1: Tier 1 (50% benchmark</option>
                                <option value="2">2: Tier 2 (30% benchmark)</option>
                                <option value="3">3: Others (20% benchmark)</option>
                            </select>
                            {errors.d_Tier && errors.d_Tier.types && <span id="Err_msg">{errors.d_Tier.types.message}</span>}
                        </FormGroup>
                    }
                </div>
            }
            {(GsbFlag.MGNGP_USER) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Margin Ratio">
                        <InputGroup className="inputNumberNoSpin" id="inputMarginRatio" name="d_MarginRatio" defaultValue="0" type="number" inputRef={register} />
                        {errors.d_MarginRatio && errors.d_MarginRatio.types && <span id="Err_msg">{errors.d_MarginRatio.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Margin Ratio 2">
                        <InputGroup className="inputNumberNoSpin" id="inputMarginRatio2" name="d_MarginRatio2" defaultValue="0" type="number" inputRef={register} />
                        {errors.d_MarginRatio2 && errors.d_MarginRatio2.types && <span id="Err_msg">{errors.d_MarginRatio2.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Margin Ratio 3">
                        <InputGroup className="inputNumberNoSpin" id="inputMarginRatio3" name="d_MarginRatio3" defaultValue="0" type="number" inputRef={register} />
                        {errors.d_MarginRatio3 && errors.d_MarginRatio3.types && <span id="Err_msg">{errors.d_MarginRatio3.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-7-2" label="Margin Ratio 4">
                        <InputGroup className="inputNumberNoSpin" id="inputMarginRatio4" name="d_MarginRatio4" defaultValue="0" type="number" inputRef={register} />
                        {errors.d_MarginRatio4 && errors.d_MarginRatio4.types && <span id="Err_msg">{errors.d_MarginRatio4.types.message}</span>}
                    </FormGroup>
                    { ( GsbFlag.SFC_EXCEL) && 
                        <FormGroup className="Col3-9-1" label="Tier" labelFor="inputTier">
                            <select  id="inputTier" name="d_Tier" ref={register}>
                                <option value=" "> N/A </option>
                                <option value="1">1: Tier 1 (50% benchmark</option>
                                <option value="2">2: Tier 2 (30% benchmark)</option>
                                <option value="3">3: Others (20% benchmark)</option>
                            </select>
                            {errors.d_Tier && errors.d_Tier.types && <span id="Err_msg">{errors.d_Tier.types.message}</span>}
                        </FormGroup>
                    }
                </div>
            }
            <div className="Row">
                { (GsbFlag.UNIT_TRUST) && <FormGroup className="Col-1-2" label="Qty Decimal">
                    <InputGroup id="inputQty_Decimal" name="d_Qty_Decimal" defaultValue="" placeholder="Qty Decimal ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_Qty_Decimal && errors.d_Qty_Decimal.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Qty_Decimal && errors.d_Qty_Decimal.types && <span id="Err_msg">{errors.d_Qty_Decimal.types.message}</span>}
                </FormGroup> }
                { (GsbFlag.FLOATPRICE) && <FormGroup className="Col-3-2" label="Price Decimal">
                    <InputGroup id="inputPrice_Decimal" name="d_Price_Decimal" defaultValue="" placeholder="Price Decimal ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_Price_Decimal && errors.d_Price_Decimal.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_Price_Decimal && errors.d_Price_Decimal.types && <span id="Err_msg">{errors.d_Price_Decimal.types.message}</span>}
                </FormGroup> }
                <FormGroup className="Col-5-2" label="Warn Int Accrued" labelFor="inputWarn_Int_Accrued">
                     <RadioGroup
                        name="d_Warn_Int_Accured"
                        inline={true}
                        selectedValue={Warn_Int_AccuredValue}
                        onChange={utils.handleStringChange(s => setWarn_Int_AccuredValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Warn_Int_Accured && errors.d_Warn_Int_Accured.types && <span id="Err_msg">{errors.d_Warn_Int_Accured.types.message}</span>}
                </FormGroup>
            </div>
        </form>
    )
}

interface SmMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function SmMastMain({ gsb }: SmMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as StkInfo[])
    const [StkCodeList, setStkCodeList] = useState([] as StkCodeInfo[])
    const [MarketList, setMarketList] = useState([] as MarketInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkName, setsearchStkName] = useState("")
    const [searchMktCode, setsearchMktCode] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentStk, setCurrentStk] = useState({} as StkInfo)
    const [BlankStk, setBlankStk] = useState({} as StkInfo)
    const [CurrentLog, setCurrentLog] = useState({} as StkInfo)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankStk.d_Isin = "isin code"   //no work
    BlankStk.d_Highrisk = " "
    BlankStk.d_CcassStk = "Y"
    BlankStk.d_Stamp = "Y"
    BlankStk.d_TradeSuspend = "N"
    BlankStk.d_ListingDate = "    -  -  "
    BlankStk.d_Warn_Int_Accured = "N"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("SmMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "Smmast", 
                        cAction: "ViewLogRec"
                    })
                    console.log("SmMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog(res.data.data[0])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])


/*
    useEffect(() => {
        (async () => {
            try {
                // get Stock Code List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "StkCodeList"})
                //console.log("StkCodeList: ", res.data.data) 
                setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
*/

    useEffect(() => {
        (async () => {
            try {
                // get Market List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "Market",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false
                },
                cPgmId: "Mktmast",
                cAction: "ListRec",
            });

            //console.log("MarketList: ", res.data.data) 
            res.data.data.unshift({d_Market: "", d_MktName: "blank"})
            setMarketList(res.data.data)
            //console.log("set MarketList: ", MarketList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    const PrevRecProcess = async (stk: StkInfo) => {
        try {
            //console.log("call PrevRecProcess", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "Smmast", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo === res.data.data.d_StkNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setCurrentStk(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (stk: StkInfo) => {
        try {
            stk.d_AuthTran = ""
            if (isReqAuth2) {
                stk.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "Smmast", cAction: "DelRec"})
            if (res.data.ok) {
                if (isReqAuth2) {
                    showSuccess("Stock No. " + stk.d_StkNo + " record deleting, waiting for authorization")
                } else {
                    showSuccess("Stock No. " + stk.d_StkNo + " record deleted")
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].d_StkNo === res.data.data.d_StkNo) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    }
                }
                setCurrentStk(res.data.data)
                setCurrentMode("show")
                if (isReqAuth2) {
                    alert("Stock No. " + stk.d_StkNo + " record deleting, waiting for authorization")
                }
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (stk: StkInfo) => {
        try {
            //console.log("call NextRecProcess", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "Smmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_StkNo === res.data.data.d_StkNo) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentStk(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (stk: StkInfo) => {
        try {
            //console.log("call newOnSave", stk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "Smmast", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Stock No. " + stk.d_StkNo + " record adding, waiting for authorization")
            } else {
                showSuccess("Stock No. " + stk.d_StkNo + " record added")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_StkNo === res.data.data.d_StkNo) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentStk(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                alert("Stock No. " + stk.d_StkNo + " record adding, waiting for authorization")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (stk: StkInfo) => {
        try {
            //console.log("call editOnSave", stk)
            //console.log("stk.d_CcassStk: ", stk.d_CcassStk)
            const res = await gsb.post('/Master', {sb_data: stk, cPgmId: "Smmast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Stock No. " + stk.d_StkNo + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_StkNo === res.data.data.d_StkNo) {
                        setCurrentStk(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                alert("Stock No. " + stk.d_StkNo + " record saving, waiting for authorization")
            } else {
                showSuccess("Stock No. " + stk.d_StkNo + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_StkNo === res.data.data.d_StkNo) {
                        data[i] = { ...data[i], ...res.data.data }
                        //console.log("editOnSave data", i, data)
                        setData(data)
                        //setCurrentStk(stk)
                        break
                    }
                }
                //console.log("editOnSave stk", stk)
                //console.log("editOnSave res.data.data", res.data.data)
                setCurrentStk(res.data.data)
                setCurrentMode("show")
                //setisShowAlert1(true)
                //setShowAlert1Msg("Account No. " +ac.d_acCode + " record saved")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get SmMast list if StkNo have value
        try {
            //console.log("SmMastMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            StkName: searchStkName,
                            MktCode: searchMktCode,
                        }, 
                    },
                    cPgmId: "Smmast",
                    cAction: "ListRec",
                });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentStk(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_StkNo: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "Smmast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentStk(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one SmMast record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("SmMastMain useEffect length > 0  called", data)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "Smmast",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentStk(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                StkName: searchStkName,
                                MktCode: searchMktCode,
                            }, 
                        },
                        cPgmId: "Smmast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentStk(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <SmMastView gsb={gsb} stk={currentStk} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentStk)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentStk)} onNextRec={() => NextRecProcess(currentStk)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></SmMastView>
    switch (currentMode) {
        //case "PrevRec":
        // ?? will call 2 times ??
        //    console.log("currentMode = PrevRec")
        //    PrevRecProcess(currentStk)
        //    break
        case "new":
            //console.log("currentMode = new")
            detail = <SmMastEdit gsb={gsb} stk={BlankStk} StkCodeList={StkCodeList} MarketList={MarketList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></SmMastEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <SmMastEdit gsb={gsb} stk={currentStk} StkCodeList={StkCodeList} MarketList={MarketList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></SmMastEdit>
            break
        //case "delete":
        //    console.log("currentMode = delete")
        //    DeleteProcess(currentStk)
        //    break
    }

    let logKeyViewRec = <SmMastView gsb={gsb} stk={CurrentLog} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentStk)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentStk)} onNextRec={() => NextRecProcess(currentStk)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></SmMastView>


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-StkCode">Stock Code</th>
                <th className="TCol-StkName">Stock Name</th>
                <th className="TCol-CcassStk">CCASS</th>
                <th className="TCol-Stamp">Stamp</th>
                <th className="TCol-LotSize">Lot Size</th>
                <th className="TCol-StkNo">Martet Code</th>
                <th className="TCol-StkNo">Fee Code</th>
                <th className="TCol-StkNo">Currency</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((stk, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-StkNo" onClick={() => { setCurrentStk(stk) ; MoblieDev && setSelectedTabId("detail") }}>{stk.d_StkNo}</td>
                        <td className="TCol-StkCode" onClick={() => { setCurrentStk(stk) ; MoblieDev && setSelectedTabId("detail") }}>{stk.d_StkCode}</td>
                        <td className="TCol-StkName" onClick={() => { setCurrentStk(stk) }}>{stk.d_StkName}</td>
                        <td className="TCol-CcassStk" onClick={() => { setCurrentStk(stk) }}>{stk.d_CcassStk}</td>
                        <td className="TCol-Stamp" onClick={() => { setCurrentStk(stk) }}>{stk.d_Stamp}</td>
                        <td className="TCol-LotSize" onClick={() => { setCurrentStk(stk) }}>{stk.d_LotSize}</td>
                        <td className="TCol-StkNo" onClick={() => { setCurrentStk(stk) ; MoblieDev && setSelectedTabId("detail") }}>{stk.d_Market}</td>              
                        <td className="TCol-StkNo" onClick={() => { setCurrentStk(stk) ; MoblieDev && setSelectedTabId("detail") }}>{stk.d_FeeCode}</td>              
                        <td className="TCol-StkNo" onClick={() => { setCurrentStk(stk) ; MoblieDev && setSelectedTabId("detail") }}>{stk.d_Currency}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    {/* define search key */}
                    <H3><div style={{justifyContent: 'flex-end'}}>Stock Master</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="StkNo">Stock No</option>
                                    <option value="StkCode">Stock Code</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-5" label="Stock Name" labelFor="searchStkName">
                                <InputGroup id="searchStkName" value={searchStkName} onChange={utils.handleStringChange(s => setsearchStkName(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                        <div className="Row">
                            <FormGroup className="Col-1-1" label="Market Code" labelFor="searchMktCode">
                                <InputGroup id="searchMktCode" value={searchMktCode} onChange={utils.handleStringChange(s => setsearchMktCode(s))} />
                            </FormGroup>
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Stock Master Log View</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>
            </form>
        </>
    );
}

export default SmMastMain