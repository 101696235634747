import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { Checkbox } from "@blueprintjs/core";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useHistory } from "react-router-dom";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";
import "./DividendProcess.scss"

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { WithdrawalInfo, StkCodeInfo, AcCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;



interface StkGlobalWithdrawalMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function StkGlobalWithdrawalMain({ gsb }: StkGlobalWithdrawalMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    const history = useHistory()

    const [Data, setData] = useState([] as WithdrawalInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkNo, setsearchStkNo] = useState("")
    const [showButton, setshowButton] = useState(false)
    const [showCalcButton, setshowCalcButton] = useState(true)
    const [ShowWait, setShowWait] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [BlankWithdrawal, setBlankWithdrawal] = useState({} as WithdrawalInfo)
    const [currentWithdrawal, setcurrentWithdrawal] = useState({} as WithdrawalInfo)

    const [s_StkNo1, sets_StkNo1] = useState("")
    const [s_StkNo2, sets_StkNo2] = useState("")
    const [s_KeepAllocationValue, sets_KeepAllocationValue] = useState("Y")
    const [s_SplitConsolidate_Old, sets_SplitConsolidate_Old] = useState("")
    const [s_SplitConsolidate_New, sets_SplitConsolidate_New] = useState("")
    

    const [currentMode, setcurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")
    const [dateFormat, setdateFormat] = useState("")


    BlankWithdrawal.d_acCode = ""
    BlankWithdrawal.d_StkNo1 = ""
    BlankWithdrawal.d_StkNo2 = ""

    

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("StkGlobalWithdrawalMain disp user_key: ", res.data.Flag.user_key)
                //console.log("StkGlobalWithdrawalMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("StkGlobalWithdrawalMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("StkGlobalWithdrawalMain disp GsbFlag: ", GsbFlag)
                //console.log("StkGlobalWithdrawalMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("StkGlobalWithdrawalMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("StkGlobalWithdrawalMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("StkGlobalWithdrawalMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("StkGlobalWithdrawalMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}
                setEnter2tab(GsbFlag.Enter2tab) ;
                setdateFormat(GsbFlag.dateFormat);
 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])






    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
    }        



    const onChangeNewQty = (acCode: string, NewQty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeNewQty acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_New_qty = NewQty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(true)
        }
    }


    const onWithdrawalProcess = () => {
        setShowWait(true)
        setshowButton(false)
        setshowCalcButton(false)
        ;

        console.log("onWithdrawalProcess Data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Transaction', {
                    sb_data: Data, 
                    cPgmId: "StkDepWithTranEntry",
                    cAction: "WithdrawalProcess",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setshowCalcButton(true)
    }

    const onCaclWithdrawal = () => {
        setShowWait(true)
        setshowButton(false)
        setshowCalcButton(false)
        ;

        (async () => {
            setData([])
            ;
            try {
                const res = await gsb.post('/Transaction', {
                    sb_data: {
                        s_StkNo1: s_StkNo1,
                        s_StkNo2: s_StkNo2,
                        s_KeepAllocation: (s_KeepAllocationValue==="Y"),
                        s_SplitConsolidate_Old: s_SplitConsolidate_Old,
                        s_SplitConsolidate_New: s_SplitConsolidate_New,
                    }, 
                    cPgmId: "StkDepWithTranEntry",
                    cAction: "CaclWithdrawal",
                })                
                if ( res.data.ok ) {
                    setData(res.data.data)
                    if (res.data.count > 0) {
                        setcurrentWithdrawal(res.data.data[0])
                    }
                    console.log("onCaclWithdrawal res.data.data", res.data.data);
                    console.log("onCaclWithdrawal Data", Data);

                    //utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()


        setShowWait(false)
        setshowButton(true)
        setshowCalcButton(true)
    }
    
    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>





    let list = <>
        <div className="Row">
            <Button disabled={! showButton} className="Col-1-1" icon="confirm" onClick={() => { onWithdrawalProcess() } }>Process</Button> 
            <Button disabled={! showCalcButton}className="Col-2-2" icon="calculator" onClick={() => { onCaclWithdrawal() } }>Withdrawal Calculate</Button> 
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-oh_qty">On Hand Qty</th>
                <th className="TCol-New_Qty">New Stock Qty</th>
            </thead>
            <tbody id="ma_tr">
                {Data.map((WithdrawalRec, i) =>
                    <tr key={i} onDoubleClick={() => { } }>
                        <td className="TCol-ACCode" onClick={() => { } }>{WithdrawalRec.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { } }>{WithdrawalRec.d_acName1}</td>
                        <td className="TCol-oh_qty" onClick={() => { } }>{WithdrawalRec.d_oh_qty}</td>
                        {(WithdrawalRec.d_show_new_qty === "Y") && 
                            <td className="TCol-New_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputNew_qty" defaultValue={WithdrawalRec.d_New_qty}  type="number" onChange={utils.handleStringChange(s => onChangeNewQty(WithdrawalRec.d_acCode, s ))} /></td>
                        }
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>344 Global Withdrawal/ Transfer of a Stock</H3>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup inline className="Col-1-5" label="Stock No. for Global Withdrawal" labelFor="inputStkNo1">
                            <InputGroup
                                id="inputStkNo1"
                                autoFocus={true}
                                placeholder="Stock No ..."
                                value={s_StkNo1}
                                onChange={utils.handleStringChange(s => sets_StkNo1(s))}
                            />
                        </FormGroup>
                        <FormGroup className="Col-6-3">
                            <div id="DispText">{currentWithdrawal.d_StkCode1}</div>
                            <div id="DispText">{currentWithdrawal.d_StkName1}</div>
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup inline className="Col-1-5" label="To Transfer to Stock No." labelFor="inputStkNo1">
                            <InputGroup
                                id="inputStkNo2"
                                placeholder="Trsnaser Stock No ..."
                                value={s_StkNo2}
                                onChange={utils.handleStringChange(s => sets_StkNo2(s))}
                            />
                        </FormGroup>
                        <FormGroup className="Col-6-3">
                            <div id="DispText">{currentWithdrawal.d_StkCode2}</div>
                            <div id="DispText">{currentWithdrawal.d_StkName2}</div>
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup inline className="Col-1-4" label="Keep Stock Allocation Position" >
                            {/* <Switch id="inputs_KeepAllocationValue" label="" innerLabelChecked="Yes" innerLabel="No" checked={s_KeepAllocationValue} onChange={utils.handleBooleanChange(v => sets_KeepAllocationValue(v))} /> */}
                            <RadioGroup
                                name="s_KeepAllocationValue"
                                inline={true}
                                selectedValue={s_KeepAllocationValue}
                                onChange={utils.handleStringChange(s => sets_KeepAllocationValue(s)) }
                            >
                                <Radio label="Yes" value="Y" />
                                <Radio label="No"  value="N" />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup inline className="Col-1-3" label="Split/Consolidate" labelFor="inputSplitConsolidate_Old">
                            <InputGroup className="inputNumberNoSpin" type="number"
                                id="inputSplit_Old"
                                value={s_SplitConsolidate_Old}
                                onChange={utils.handleStringChange(s => sets_SplitConsolidate_Old(s))}
                            />
                        </FormGroup>
                        <FormGroup inline className="Col-4-3" label="into shares" labelFor="inputSplitConsolidate_New">
                            <InputGroup className="inputNumberNoSpin" type="number"
                                id="inputSplit_Old"
                                value={s_SplitConsolidate_New}
                                onChange={utils.handleStringChange(s => sets_SplitConsolidate_New(s))}
                            />
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-16" />
                    {list}
                    <div className="Spacing-V-16" />
                </Card>
            </form>
        </>
    );
}

export default StkGlobalWithdrawalMain