import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, ProgressBar } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait1 from './svg/wait1.svg'
import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { SystemHeader } from './CommonUI';


interface Account {
    d_acCode: string,
    d_acName1: string,
    d_acName2: string,
}


interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}

var GsbFlag: any;


interface CancelCorrectTradeRegisterMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function CancelCorrectTradeRegisterMain({ gsb }: CancelCorrectTradeRegisterMainProps) {

    let ProgressId2 = ""
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [AcCodeList, setAcCodeList] = useState([] as Account[])

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)
 
    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
    const [ShowWait, setShowWait] = useState(false)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)

    const [s_ClCode1, sets_ClCode1] = useState("")
    const [s_ClCode2, sets_ClCode2] = useState("")
    const [DataFormatValue, setDataFormatValue] = useState(false)
    const [CSVFormat, setCSVFormat] = useState("0")
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])


    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
                setCSVFormat(GsbFlag.CSVFormat) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    useEffect(() => {
        (async () => {
            try {
                // get AC List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "acCode",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false,
                    SortByName: true
                },
                cPgmId: "Clmast",
                cAction: "ListRec",
            });

                //console.log("res.data.data: ", res.data.data) 
                //setAcCodeList(utils.sortByColumn(res.data.data, "d_acName1"))
                setAcCodeList(res.data.data)
                //console.log("set AcCodeList: ", AcCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    const acRenderer: ItemRenderer<Account> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<Account> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: Account) => {
        return ac.d_acCode
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }


    useEffect(() => {
        setShowRpt(false)
        setShowNoRecord(false)
        setShowOK(true)
    }, [s_ClCode1, s_ClCode2, DataFormatValue])


    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            //console.log("Progresslist ProgressId: ", ProgressId2) 
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 20*1000)
        return () => {clearInterval(timer)}
    }, [gsb, ProgressId, nPage])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        sets_ClCode1(s_ClCode1.toUpperCase());
        sets_ClCode2(s_ClCode2.toUpperCase());

        setShowOK(false)
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptName("")
        setRptNameNo(0)
        setnPage(1)
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0)
        //console.log("SmMastMain onsubmit ProgressId: ", ProgressId2) 

        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        }

        
        (async () => {
            try {
                //console.log("SmMastMain onsubmit called", data)
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_ClCode1: s_ClCode1,
                            s_ClCode2: s_ClCode2,
                            DataFormat: DataFormatValue,
                            nPage: 1,
                            cPrintMode: "PS",
                        },
                        cPgmId: "CancelCorrectTrade",
                        cAction: "TradeRegister",
                    });

                //console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    setRptHead1(res.data.Rpthead1)
                    setRptName(res.data.RptName)
                    setRptNameNo(res.data.RptNameNo)
                    //console.log("RptName: ", RptName) 
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(res.data.TotalPageNo)
                        for ( let j = 1; j <= res.data.TotalPageNo; j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }
                    }
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }


    //Press page No 1 2 3 4
    useEffect(() => {
        if (nPage > 0 && nPage <= nTotalPage && RptName.trim().length > 0) {
            // console.log("calling API for Pagination");
            setShowWait(true)
            setShowNoRecord(false)
            setShowRpt(false)
            setRstdata([])

            ProgressId2 = utils.genUUID()
            setProgressId(ProgressId2)
            setProgressValue(0)

            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_ClCode1: s_ClCode1,
                            s_ClCode2: s_ClCode2,
                            s_RptName: RptName,
                            DataFormat: DataFormatValue,
                            nPage: nPage,
                            cPrintMode: "S",
                        },
                        cPgmId: "CancelCorrectTrade",
                        cAction: "TradeRegister",
                    });

                    //console.log("res.data.data: ", res.data.data) 
                    if ( res.data.count > 0 ) {
                        setRstdata2([])
                        for (let j = 0; j < res.data.Rptdata.length; j++) {
                            Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                        }
                        setRptHead1(res.data.Rpthead1)
                        setShowWait(false)
                        setShowNoRecord(false)
                        setShowRpt(true)
                        setRstdata(Rstdata2)
                        //console.log("Rstdata: ", Rstdata) 
                    } else {
                        setShowWait(false)
                        setShowNoRecord(true)
                        setShowRpt(false)
                    }
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let RptNameaRef = <>
        { RptNameNo > 0 && <div> Download / Print &nbsp;
            {aRptNameNo.map((no, j) => 
                <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}-${no.n}.pdf`}> <span id="RPageNo2" onClick={() => {} }> {no.n} &nbsp; </span> </a>
            )}
        </div> }
        { RptNameNo <= 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>}
    </>

    let list = <>
        <div className="Spacing-V-16" />
        {/* {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print </a>} */}
        {RptName.trim().length > 0 && RptNameaRef}
        {ShowRpt && ! DataFormatValue && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    { ! DataFormatValue && <th className="RCol-Head_p">
                        {/* {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download / Print</a>} */}
                        {RptName.trim().length > 0 && RptNameaRef}
                    </th>
                    }
                </tr>                    
                <tr>
                    <td className="RCol-Head1">{RptHead1}</td>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                {Rstdata.map((rpt, i) =>
                    <tr key={i} onDoubleClick={() => { }}>
                        <td className="RCol-Rpt" onClick={() => {  }}>{rpt.Rst_Line}</td>
                    </tr>
                )}
            </tbody>
            <div className="Spacing-V-16" />
            {ShowRpt && ! DataFormatValue && Pagination}
        </HTMLTable>
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>3F5 Cancel and Correct Trade Register</H3>
                    <div className="Spacing-V-16" />
                    {/*
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Start Account No." labelFor="inputClCode1" >
                            <InputGroup 
                                className="inputClCode"
                                id="inputClCode1"
                                placeholder=" Account No. ..."
                                value={s_ClCode1}
                                onChange={utils.handleStringChange(s => sets_ClCode1(s))}
                            />
                        </FormGroup>
                        <FormGroup className="Col-4-2" label="Account No." labelFor="inputClCode2">
                            <InputGroup
                                className="inputClCode"
                                id="inputClCode2"
                                placeholder=" Account No. ..."
                                value={s_ClCode2}
                                onChange={utils.handleStringChange(s => sets_ClCode2(s))}
                            />
                        </FormGroup>
                    </div>
                    */}
                    <FormGroup className="Col-1-2" label="">
                        <RadioGroup
                            inline={true}
                            selectedValue={DataFormatValue? "D": "P"}
                            onChange={utils.handleStringChange(s => setDataFormatValue(s == "D"))  }
                        >
                            <Radio label="PDF Format"  value="P" />
                            { (CSVFormat === "1") && <Radio label="DATA Format (EXCEL, CSV)" value="D" /> }
                            { ! (CSVFormat === "1") && <Radio label="DATA Format (EXCEL, TAB)" value="D" /> }
                        </RadioGroup>
                    </FormGroup>
                    <div className="Row">
                        <Button disabled={! ShowOK} type="submit">OK</Button>
                    </div>
                </Card>
                {/* {ShowWait && <div>{imgWait}</div> } */}
                {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
                {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }

                {ShowNoRecord && <p>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-3"  >
                            <span id="Err_msg">No Record found !!!</span>
                        </FormGroup>
                    </div>
                </p>
                }
                {ShowRpt && list}
            </form>
        </>
    );
}

export default CancelCorrectTradeRegisterMain