import React, { useState, FormEvent, useEffect, WheelEventHandler } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Callout, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
//import { DateInput3 } from "@blueprintjs/datetime2";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';
import * as utils from "./utils";
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import svgWait2 from './svg/wait2.gif'
import { Account, CRSInfo,CRSDataInfo,RegionCodeInfo,CCEPINFO} from './Types'
import { SystemHeader } from './CommonUI';
import i18next from 'i18next';
import { access } from 'fs';

interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}


var GsbFlag: any;

var UserID: string;
var PgmCode = "H0002" ;
var isReqAuth2:boolean = false ;

interface HkCrsMastViewProps {
    gsb: AxiosInstance,
    Crs: CRSInfo,
    Crs0: CRSInfo,
    CrsData: CRSDataInfo,
    CrsData0: CRSDataInfo,
    CountryList?: RegionCodeInfo[],
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onReplicate?: () => void,  
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
    PgmMode:any,
    SuspendAC:boolean,
}


function HkCrsMastView({ gsb, Crs = {} as CRSInfo, Crs0 = {} as CRSInfo,CrsData={} as CRSDataInfo,CrsData0={} as CRSDataInfo,CountryList = [] as RegionCodeInfo[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onReplicate = () => { },  onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 , PgmMode,SuspendAC}: HkCrsMastViewProps) {

    const [isReplicate, setisReplicate] = useState(false)
    const [isRegenbcan, setisRegenbcan] = useState(false)
    const [CountryDesc, setCountryDesc] = useState("")
    const [MailCountryDesc, setMailCountryDesc] = useState("")
    
    const [JurisdictionIncorpOrganisationDesc,setJurisdictionIncorpOrganisationDesc] = useState("")
    const [CountryIssuanceDesc, setCountryIssuanceDesc] = useState("")   
  
    const [JursidictionCountry1Desc, setJursidictionCountry1Desc] = useState("")
    const [JursidictionCountry2Desc, setJursidictionCountry2Desc] = useState("")
    const [JursidictionCountry3Desc, setJursidictionCountry3Desc] = useState("")
    const [JursidictionCountry4Desc, setJursidictionCountry4Desc] = useState("")
    const [JursidictionCountry5Desc, setJursidictionCountry5Desc] = useState("")

    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 


    const [showSIMore, setshowSIMore] = useState(true)

    //const [BlankCRSD, setBlankCRSD] = useState({} as CrsDataInfo)   

     const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkCrsMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Crs])


    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: Crs.d_acCode})
                console.log(" Crs.d_acCode=", Crs.d_acCode,res.data.count)

                let d_acCode = (typeof Crs.d_acCode === 'string')? Crs.d_acCode.trim() : ''

                if ( d_acCode.trim().length > 0 && res.data.count > 0 )
                {
                    //console.log(res.data.data[0].d_acName1)
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)

                    //console.log(res.data.data[0].d_status)
                    setAcStatusValue(res.data.data[0].d_status)
                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                }
            
            } catch (error) {
                showErrorBox(error)
            }
        })()

    }, [gsb, Crs.d_acCode])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    function setCountryIssu(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setCountryIssuanceDesc("")
       
        let CountryItem = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        console.log("ClMastView CountryIssuanceDesc.find CountryItem : ", CountryItem) 
        console.log("ClMastView CountryIssuanceDesc.find CountryItem.d_Country : ", CountryList[CountryItem]) 
        if ( CountryItem == 0 || CountryItem > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem].d_RegionCode) 
            setCountryDesc(CountryList[CountryItem].d_Country)
        }
        return setCountryIssu;
    }

    useEffect(() => {
        setCountryDesc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_ResidenceAddressCounrty)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setCountryDesc(CountryList[CountryItem3].d_Country)
        }       
    }, [Crs])

    
    useEffect(() => {
        setMailCountryDesc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_MailingAddressCounrty)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setMailCountryDesc(CountryList[CountryItem3].d_Country)
        }       
    }, [Crs])


    useEffect(() => {
        setJurisdictionIncorpOrganisationDesc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionIncorpOrganisation)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJurisdictionIncorpOrganisationDesc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])

    useEffect(() => {
        setJursidictionCountry1Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty1)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry1Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])

     useEffect(() => {
        setJursidictionCountry2Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty2)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry2Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])

    useEffect(() => {
        setJursidictionCountry3Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty3)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry3Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])

    useEffect(() => {
        setJursidictionCountry4Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty4)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry4Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])

    useEffect(() => {
        setJursidictionCountry5Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty5)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry5Desc(CountryList[CountryItem3].d_Country)
        } 
    }, [Crs])


    if (! ("d_acCode" in Crs)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>


    return (
        <div>
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="add" onClick={() => setisReplicate(true) } >Replicate Holder No</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Replicate"
                        canEscapeKeyCancel={true}
                        icon="duplicate"
                        intent={Intent.WARNING}
                        isOpen={isReplicate}
                        onCancel={ () => setisReplicate(false) }
                        onConfirm={ () => { onReplicate(); setisReplicate(false) } }
                    >
                        <p>
                            Do you Replicate NEW Account Holder No. ?                      ?
                        </p>
                    </Alert>

                <Button className="Col-4-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-5-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-6-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>

                { isReqAuth && 
                    <Callout className="Col-7-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Require Authority
                    </Callout>
                }
 
            </div> }
            { ViewMode === "L" && <div>
                Account No. : {Crs.d_acCode}
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }
            <div className="Row">
                <div className="Spacing-V-16" />
                    <FormGroup className="Col-1-5">
                        <H5><div style={{justifyContent: 'flex-end'}}>CRS Status</div></H5>    
                        <RadioGroup
                                label="" 
                                name="d_crsStatus"
                                disabled={true}
                                inline={true}
                                selectedValue={Crs.d_crsStatus}
                                onChange={(s) => { }}
                            >
                                <Radio className ="radio-custom" label=" " value=" " />
                                <Radio className ="radio-custom" label="0-NONE" value="0" />
                                <Radio className ="radio-custom" label="1-PROCESSING"  value="1"/>
                                <Radio className ="radio-custom" label="2-COMPLETED"  value="2" />
                            </RadioGroup>
                            { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_crsStatus.toString() == "") && 
                            <div id="DispText2" > {Crs0.d_crsStatus} </div> }
                    
                        <div className="Spacing-V-16" />
                    </FormGroup>    
                <div className="Spacing-V-16" />
            </div> 
            <div className="Spacing-V-16" />        
            <div className="Row" >
            <FormGroup className="Col-1-5" label="Holder Type">
                <RadioGroup
                        label="" 
                        name="d_HolderType"
                        disabled={true}
                        inline={true}
                        selectedValue={Crs.d_HolderType}
                        onChange={(s) => { }}
                    >
                        <Radio label="Individual" value="1" />
                        <Radio label="Entity" value="2" />
                        <Radio label={t('Controlling Person')}  value="3" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_HolderType.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_HolderType} </div> }
                </FormGroup>
            </div> 
            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelInfo="*">
                    <InputGroup readOnly value={Crs.d_acCode} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Account Holder No." labelFor="inputHolderNo" labelInfo="*">
                    <InputGroup readOnly value={Crs.d_acHolderNo} />
                </FormGroup>
                { GsbFlag.SUSPEND_AC && <FormGroup className="Col-5-1" label="Status" labelFor="inputStatus">
                    <div>
                        <div id="DispText">{AcStatusValue}</div>
                    </div>
                </FormGroup> }
                <FormGroup className="Col-6-3" label="Name in GSB" labelFor="inputName">
                    <div>
                        <div id="DispText">{AcName1Value}</div>
                        <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
            </div>
            {/* when clienttype 1,2 */}
            <div className="Row">
               <FormGroup className="Col-1-2" label="unDocument">
                    <RadioGroup
                            label="" 
                            name="d_UNDOC"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_UNDOC}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_UNDOC.toString() == "") && 
                        <div id="DispText2" > {Crs0.d_UNDOC} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Dormant">
                    <RadioGroup
                            label="" 
                            name="d_Dormant"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Dormant}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Dormant.toString() == "") && 
                        <div id="DispText2" > {Crs0.d_Dormant} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="Document Closed">
                    <RadioGroup
                            label="" 
                            name="d_Closed"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Closed}
                            onChange={(s) => { }}
                        >
                            <Radio label="Yes" value="Y" />
                            <Radio label="No"  value="N" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Closed.toString() == "") && 
                        <div id="DispText2" > {Crs0.d_Closed} </div> }
                </FormGroup>
                <FormGroup className="Col-1-2" label={t('Last Update Date')} labelFor="inputLUpdateTime">
                    <InputGroup readOnly value= {utils.dispDate(Crs.d_LUpdateTime, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_LUpdateTime.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(Crs0.d_LUpdateTime, GsbFlag.dateFormat)} </div> }
                </FormGroup>
            </div>
            { (Crs.d_HolderType === "1" || Crs.d_HolderType === "3" ) && <div className="Row">
                <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part 1. Identification of Individual Account Holder</div></H5>
                </FormGroup>

                
                {/*
             
                <FormGroup className="Col-1-2" label="Title.(Mr,Mrs,Ms,Miss)">
                    <InputGroup readOnly value={Crs.d_Title} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Title.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Title} </div> }
                </FormGroup> 
                */}

                <FormGroup className="Col-1-2" label="Title.(Mr,Mrs,Ms,Miss)">
                     <RadioGroup
                            label="" 
                            name="d_Title"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Title}
                            onChange={(s) => { }}
                        >
                            <Radio label="Mr" value="Mr" />
                            <Radio label="Mrs" value="Mrs" />
                            <Radio label="Ms" value="Ms" />
                            <Radio label="Miss" value="Miss" />
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Title.toString() == "") && 
                        <div id="DispText2" > {Crs0.d_Title} </div> }
                </FormGroup> 

                <FormGroup className="Col-1-2" label="Last Name or Surname.">
                    <InputGroup readOnly value={Crs.d_Last_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Last_Name.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Last_Name} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Middle Name.">
                    <InputGroup readOnly value={Crs.d_Middle_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Middle_Name.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Middle_Name} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="First or Given Name.">
                    <InputGroup readOnly value={Crs.d_First_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_First_Name.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_First_Name} </div> }
                </FormGroup> 
                <div className="Spacing-V-16" />
       
            </div>}      
            { (Crs.d_HolderType === "1" || Crs.d_HolderType === "3" ) && <div className="Row">
                <FormGroup className="Col-1-3" label="Hong Kong Identity Card or Passport Number">
                    <InputGroup readOnly value={Crs.d_ID_Number} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ID_Number.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ID_Number} </div> }
                </FormGroup>  
                <div className="Spacing-V-16" />
                </div>
            }

            { (Crs.d_HolderType === "2" ) && <div className="Row">
                <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part 1. Identification of Entity Account Holder</div></H5>
                </FormGroup>     
                <FormGroup className="Col-1-6" label="Legal Name of Entity or Branch(*)">
                    <InputGroup readOnly value={Crs.d_Legal_Entity_Name} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Legal_Entity_Name.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Legal_Entity_Name} </div> }
                </FormGroup> 
                <FormGroup className="Col-1-3" label="Jurisdiction of Incorporation or Organisation">
                    <InputGroup readOnly value={Crs.d_JurisdictionIncorpOrganisation} />
                    <div>
                     {JurisdictionIncorpOrganisationDesc} 
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_JurisdictionIncorpOrganisation.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_JurisdictionIncorpOrganisation} </div> }
                </FormGroup>
                <FormGroup className="Col-1-3" label="Hong Kong Business Registration Number.">
                    <InputGroup readOnly value={Crs.d_HKBusinessRegistrationNo} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_HKBusinessRegistrationNo.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_HKBusinessRegistrationNo} </div> }
                </FormGroup>
            </div>
            }
            <div className="Spacing-V-16" />
            { (Crs.d_HolderType === "1" || Crs.d_HolderType === "3")  && <div className="Row">
                <FormGroup className="Col-1-6" label="Current Business Address. (line1 e.g. Suite, Floor, Building Street,District)/line2 City */line3 e.g. Province, State">
                    <InputGroup readOnly value={Crs.d_ResidenceAddress1} />
                    <InputGroup readOnly value={Crs.d_ResidenceAddress2} />
                    <InputGroup readOnly value={Crs.d_ResidenceAddress3} />               
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddress1.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddress1} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddress2.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddress2} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddress3.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddress3} </div> }                      
                </FormGroup>         
                <FormGroup className="Col3-1-2" label="Country (*)">
                    <InputGroup readOnly value={Crs.d_ResidenceAddressCounrty} />
                    <div>
                     {CountryDesc} 
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddressCounrty.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddressCounrty} </div> }
                </FormGroup>
                <FormGroup className="Col3-3-2" label="Post Code/Zip Code">
                    <InputGroup readOnly value={Crs.d_ResidenceAddressZIPCode} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddressZIPCode.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddressZIPCode} </div> }
                </FormGroup>
            </div>
            }
            { (Crs.d_HolderType === "2" )  && <div className="Row">
                <FormGroup className="Col-1-6" label="Current Residence Address. (line1 e.g. Suite, Floor, Building Street,District)/line2 City */line3 e.g. Province, State">
                    <InputGroup readOnly value={Crs.d_ResidenceAddress1} />
                    <InputGroup readOnly value={Crs.d_ResidenceAddress2} />
                    <InputGroup readOnly value={Crs.d_ResidenceAddress3} />               
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddress1.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddress1} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddress2.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddress2} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddress3.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddress3} </div> }                      
                </FormGroup>         
                <FormGroup className="Col3-1-2" label="Country (*)">
                    <InputGroup readOnly value={Crs.d_ResidenceAddressCounrty} />
                    <div>
                     {CountryDesc} 
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddressCounrty.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddressCounrty} </div> }
                </FormGroup>
                <FormGroup className="Col3-3-2" label="Post Code/Zip Code">
                    <InputGroup readOnly value={Crs.d_ResidenceAddressZIPCode} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ResidenceAddressZIPCode.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ResidenceAddressZIPCode} </div> }
                </FormGroup>
            </div>
            }
            {(Crs.d_HolderType==="1" || Crs.d_HolderType ==="3") && <div className="Row">
                <FormGroup className="Col-1-2" label={t('Date of Birth')} labelFor="inputBirth_Date">
                    <InputGroup readOnly value= {utils.dispDate(Crs.d_Date_Of_Brih, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Date_Of_Brih.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(Crs0.d_Date_Of_Brih, GsbFlag.dateFormat)} </div> }
                </FormGroup> 
            </div>}
            {/*<div className="Row">
                <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Mailing Address(Complete if different to the current residence address)</div></H5>
                </FormGroup>
                <FormGroup className="Col-1-6" label="Line. (line1 e.g. Suite, Floor, Building Street,District), line2 City, line3 e.g. Province, State" labelFor="inputMailingAddress">
                    <InputGroup readOnly value={Crs.d_MailingAddress1} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_MailingAddress1.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_MailingAddress1} </div> }
                    <InputGroup readOnly value={Crs.d_MailingAddress2} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_MailingAddress2.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_MailingAddress2} </div> }
                    <InputGroup readOnly value={Crs.d_MailingAddress3} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_MailingAddress3.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_MailingAddress3} </div> }
                </FormGroup>
                <FormGroup className="Col3-1-2" label="Country (*)">
                    <InputGroup readOnly value={Crs.d_MailingAddressCounrty} />
                    <div>
                     {MailCountryDesc} 
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_MailingAddressCounrty.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_MailingAddressCounrty} </div> }
                </FormGroup>
                <FormGroup className="Col3-4-2" label="Post Code/ZIP Code" labelFor="inputMailingAddressZIPCode"> 
                    <InputGroup readOnly value={Crs.d_MailingAddressZIPCode} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_MailingAddressZIPCode.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_MailingAddressZIPCode} </div> }                         
                </FormGroup>
            </div> */}
            {Crs.d_HolderType === "2" &&  
            <div className="Row">             
                <FormGroup className="Col-1-8" labelFor="InputEntityType">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part 2 Entity Type</div></H5>   
                    <RadioGroup
                        name="d_EntityType"
                        inline={false}
						selectedValue={Crs.d_EntityType}
						onChange={(s) => { }}
                     >                   
                        <H6><div style={{justifyContent: 'flex-end'}}>- Financial Institution</div></H6>   
                        <Radio label="Custodial Institution, Depository Institution or Speicified Insurance Company" value="FI1"/>
                        <Radio label="Investment Entity, except an investment entity that is managed by another Financial Institution and located in a Non-Participating CRS Jurisdiction" value="FI2" /> 
                        {/*<H4><div style={{justifyContent: 'flex-end'}}>CRS Master</div></H4>*/}                         
                        <H6><div style={{justifyContent: 'flex-end'}}>- Active NFE</div></H6>   
                        <Radio value="ANFE1" >"NFE the stock of which is regularly trade on <u>&nbsp;&nbsp;a)&nbsp;&nbsp;</u> which is an established securities market</Radio>
                        <div className="Row">
                            <FormGroup className="Col-2-4" label="a.)" labelFor="inputANFE1">							
							    <InputGroup readOnly value={Crs.d_ANFE1} />
                             </FormGroup>
                        </div>
                        <Radio  value="ANFE2" >
                            {t('ACtive NFE -Related entity of a), the stock of which is regularly trade on')}                
                            {t(' b), which is an established sucurities market.')}
                        </Radio>
                        <div className="Row">
                            <FormGroup className="Col-2-4" label="a.)" labelFor="inputANFE2">
							    <InputGroup readOnly value={Crs.d_ANFE2} />
                            </FormGroup>                           
                            <FormGroup className="Col-2-4" label="b.)" labelFor="inputANFE3">                               
  							    <InputGroup readOnly value={Crs.d_ANFE3} />
                            </FormGroup>     
                        </div>
                        <Radio label ="NFE is a goverthe NFE is a governmental Entity, an international organisation, a central bank, or an. Entity wholly owned by one or more of the foregoing;" value="ANFE3" />
                        
                        <Radio label ="Active NFE other than the above (Please specify  a) )" value="ANFE4" />                        <div className="Row">
                            <FormGroup className="Col-2-4" label="a.)" labelFor="inputANFE4">
                                <InputGroup readOnly value={Crs.d_ANFE4} />
                            </FormGroup>
                        </div>                    
                        <H6><div style={{justifyContent: 'flex-end'}}>- Passive NFE</div></H6>   
                        <Radio label ="Investment entity that is managed by another financial institution and located in non-participating Jurisdiction" value="PNFE1" />
                        <Radio label ="NFE that is not an active NFE" value="PNFE2" />
                    </RadioGroup>
                </FormGroup>           
                <div className="Spacing-V-16" />
            </div>
            }

            {Crs.d_HolderType === "2" &&
            <div className="Row">
                <FormGroup className="Col-1-5">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part3 Controlling Person(Complete this when Passive NFE)</div></H5>   
                </FormGroup>
                <FormGroup className="Col-1-2" label="(1.)">
                    <InputGroup readOnly value={Crs.d_ControllingPerson1} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson1.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson1} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="(5.)">
                    <InputGroup readOnly value={Crs.d_ControllingPerson5} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson5.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson5} </div> }
                </FormGroup> 
                <FormGroup className="Col-1-2" label="(2.)">    
                    <InputGroup  readOnly value={Crs.d_ControllingPerson2}/>  
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson5.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson5} </div> }                    
                </FormGroup>
                <FormGroup className="Col-3-2" label="(6.)">    
                    <InputGroup  readOnly value={Crs.d_ControllingPerson6}/>  
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson6.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson6} </div> }
                </FormGroup>
                <FormGroup className="Col-1-2" label="(3.)">       
                    <InputGroup   readOnly value={Crs.d_ControllingPerson3}/>  
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson3.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson3} </div> }                    
                </FormGroup>
                <FormGroup className="Col-3-2" label="(7.)">
                    <InputGroup  readOnly value={Crs.d_ControllingPerson7}/>  
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson7.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson7} </div> }                      
                </FormGroup>
                <FormGroup className="Col-1-2" label="(4.)">
                    <InputGroup  readOnly value={Crs.d_ControllingPerson4}/>  
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson4.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson4} </div> }                        
                </FormGroup>
                <FormGroup className="Col-3-2" label="(8.)">       
                    <InputGroup   readOnly value={Crs.d_ControllingPerson8}/>  
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ControllingPerson8.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ControllingPerson8} </div> }                   
                </FormGroup>
            </div>
            }

            {Crs.d_HolderType  === "3" && 
            <div className="Row">
                <FormGroup className="Col-1-5">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part2 The Entity Account Holder(s) of which you are a controlling person</div></H5>   
                    <div style={{justifyContent: 'flex-end'}}>Enter the name of entity holder of which you are a controlling person</div>
                </FormGroup> 
                <FormGroup className="Col-1-3" label="(1.)" labelFor="inputNameOFEntityHolder1">
                    <InputGroup readOnly value={Crs.d_NameOFEntityHolder1}/>
                </FormGroup>            
                <FormGroup className="Col-1-3" label="(2.)" labelFor="inputNameOFEntityHolder2">       
                    <InputGroup readOnly value={Crs.d_NameOFEntityHolder2}/> 
                </FormGroup>            
                <FormGroup className="Col-1-3" label="(3.)" labelFor="inputNameOFEntityHolder3">       
                    <InputGroup readOnly value={Crs.d_NameOFEntityHolder3}/>
                </FormGroup>
            </div>
            }
            {Crs.d_HolderType === "1" &&
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div style={{justifyContent: 'flex-end'}}>Part2 Jurisdiction of Resisdence and Taxpapyer Identification Number or its Functional Equivalent("TIN")*</div></H5>    
                </FormGroup>
            </div>
            }

            {Crs.d_HolderType === "2" &&
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div style={{justifyContent: 'flex-end'}}>Part4 Jurisdiction of Resisdence and Taxpapyer Identification Number or its Functional Equivalent("TIN")*</div></H5>    
                </FormGroup>
            </div>
            }

            {Crs.d_HolderType === "3" &&
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div style={{justifyContent: 'flex-end'}}>Part3 Jurisdiction of Resisdence and Taxpapyer Identification Number or its Functional Equivalent("TIN")*</div></H5>    
                </FormGroup>
            </div>
            }
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div>If a Tin is unavailable, provide the appropriate reason A,B or C</div></H5>    
                   <H6><div>Reason A-The jurisdiction where the account holder is a resident for tax purposes does not issue TINs to its residents.</div></H6>  
                   <H6><div>Reason B-The account holder is unable to obtain a TIN.  Explain why the account holder is unable to obtain a TIN if you have selected this reason. </div></H6>  
                   <H6><div>Reason C-TIN is not required. Select this reason only if the authorities of the jurisdiction of residence do not require the TIN to be disclosed. </div></H6>  
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Jurisdiction of Residence">
                    <InputGroup readOnly value={Crs.d_JurisdictionCounrty1} />
                    <div>
                    <div id="DispText">{JursidictionCountry1Desc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_JurisdictionCounrty1.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_JurisdictionCounrty1} </div> }
                </FormGroup>
                <FormGroup className="Col3-3-2" label="TIN">
                    <InputGroup readOnly value={Crs.d_TIN1} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_TIN1.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_TIN1} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2"label="Reason A,B,C if not Tin">
                    <RadioGroup
                            label="" 
                            name="d_Reason1"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Reason1}
                            onChange={(s) => { }}
                        >
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B"/>
                            <Radio className ="radio-custom" label="C"  value="C"/>
                        </RadioGroup>               
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col-7-4" label="ExplainReason">
                    <InputGroup readOnly value={Crs.d_ExplainReason1} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ExplainReason1.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ExplainReason1} </div> }
                </FormGroup>
                <div className="Spacing-V-16" />
            </div>
            {/*TIN2*/}
            <div className="Row">
                <FormGroup className="Col-1-2" label="">
                    <InputGroup readOnly value={Crs.d_JurisdictionCounrty2} />
                    <div>
                    <div id="DispText">{JursidictionCountry2Desc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_JurisdictionCounrty2.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_JurisdictionCounrty2} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="">
                    <InputGroup readOnly value={Crs.d_TIN2} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_TIN2.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_TIN2} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason2"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Reason2}
                            onChange={(s) => { }}
                        >
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B"/>
                            <Radio className ="radio-custom" label="C"  value="C"/>
                        </RadioGroup>               
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col-7-4" label="">
                    <InputGroup readOnly value={Crs.d_ExplainReason2} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ExplainReason2.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ExplainReason2} </div> }
                </FormGroup>
                <div className="Spacing-V-16" />
            </div>
            {/*TIN3*/}
            <div className="Row">
                <FormGroup className="Col-1-2" label="">
                    <InputGroup readOnly value={Crs.d_JurisdictionCounrty3} />
                    <div>
                    <div id="DispText">{JursidictionCountry3Desc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_JurisdictionCounrty3.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_JurisdictionCounrty3} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="">
                    <InputGroup readOnly value={Crs.d_TIN3} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_TIN3.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_TIN3} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason3"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Reason3}
                            onChange={(s) => { }}
                        >
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B"/>
                            <Radio className ="radio-custom" label="C"  value="C"/>
                        </RadioGroup>               
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col-7-4" label="">
                    <InputGroup readOnly value={Crs.d_ExplainReason3} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ExplainReason3.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ExplainReason3} </div> }
                </FormGroup>
                <div className="Spacing-V-16" />
            </div>
            {/*TIN4*/}
            <div className="Row">
                <FormGroup className="Col-1-2" label="">
                    <InputGroup readOnly value={Crs.d_JurisdictionCounrty4} />
                    <div>
                    <div id="DispText">{JursidictionCountry4Desc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_JurisdictionCounrty4.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_JurisdictionCounrty4} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="">
                    <InputGroup readOnly value={Crs.d_TIN4} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_TIN4.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_TIN4} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason4"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Reason4}
                            onChange={(s) => { }}
                        >
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B"/>
                            <Radio className ="radio-custom" label="C"  value="C"/>
                        </RadioGroup>               
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col-7-4" label="">
                    <InputGroup readOnly value={Crs.d_ExplainReason4} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ExplainReason4.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ExplainReason4} </div> }
                </FormGroup>
                <div className="Spacing-V-16" />
            </div>
            {/*TIN5*/}
            <div className="Row">
                <FormGroup className="Col-1-2" label="">
                    <InputGroup readOnly value={Crs.d_JurisdictionCounrty5} />
                    <div>
                    <div id="DispText">{JursidictionCountry5Desc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_JurisdictionCounrty5.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_JurisdictionCounrty5} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="">
                    <InputGroup readOnly value={Crs.d_TIN5} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_TIN5.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_TIN5} </div> }
                </FormGroup>
                {/*
                <FormGroup className="Col-5-2" label="">
                    <InputGroup readOnly value={Crs.d_Reason5} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Reason5.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Reason5} </div> }
                </FormGroup>*/}

                <FormGroup className="Col-5-2">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason5"
                            disabled={true}
                            inline={true}
                            selectedValue={Crs.d_Reason5}
                            onChange={(s) => { }}
                        >
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" color='gray'/>
                            <Radio className ="radio-custom" label="B"  value="B"  color = 'yellow' />
                            <Radio className ="radio-custom" label="C"  value="C"  color ='green'/>
                        </RadioGroup>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_crsStatus.toString() == "") && 
                        <div id="DispText2" > {Crs0.d_Reason5} </div> }
                
                    <div className="Spacing-V-16" />
                </FormGroup>    

                <FormGroup className="Col-7-4" label="">
                    <InputGroup readOnly value={Crs.d_ExplainReason5} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_ExplainReason5.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_ExplainReason5} </div> }
                </FormGroup>
                <div className="Spacing-V-16" />
            </div>
            {Crs.d_HolderType === "3"  &&
                <div className="Row">
                    <FormGroup className="Col-1-7">
                        <H5><div style={{justifyContent: 'flex-end'}}>Part 4. Type of Controlling Person</div></H5>
                    </FormGroup>
                </div>
            }
            {Crs.d_HolderType === "3" && <HTMLTable striped interactive condensed>
                <thead>
                    <th className="TCol-ACCode">Type of Entity</th>
                    <th className="TCol-ACCode">Type of Controlling Person</th>
                    <th className="TCol-ACCode">Entity(1)</th>
                    <th className="TCol-ACCode">Entity(2)</th>
                    <th className="TCol-ACCode">Entity(3)</th>      
                </thead>
                    <tbody id="ma_tr">  
                        <tr>
                            <td className="TCol-Type_Of_Entity">
                                Legal Person
                            </td>                     
                            <td className="TCol-TYPE_OF_CONTROLLING">
                                <div>Individual who has a controlling ownership interest(i.e not less than 25% issued shared capital)</div>
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}  
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}                                  
                                >                                
                                    <Radio value="CRS801"/>
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS801"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}                                
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                >   
                                <Radio value="CRS801"/>    
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity" />
                            <td className="TCol-TYPE_OF_CONTROLLING">
                            <div>Individual who exercises control/is entitled to exercise control through other means(i.e. not less than 25% of voting rights)</div>
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS802"/>
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}                                
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS802"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS802"/>     
                                </RadioGroup> 
                            </td>                    
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"/> 
                            <td className="TCol-TYPE_OF_CONTROLLING">                  
                            Individual who has a controlling ownership interest(i.e 
                            not less than 25% issued shared capital)</td>                              
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                >                                
                                    <Radio value="CRS803"/>
                                </RadioGroup> 
                            </td> 
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS803"/> 
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS803"/>     
                                </RadioGroup> 
                            </td>
                        </tr>
                
                        <tr>
                            <td className="TCol-Type_Of_Entity">Trust</td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Selttlor</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_NameOFEntityHolder1}
                                    onChange={(s) => { }}
                                >                                 
                                    <Radio value="CRS804"/>       
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                >   
                                    <Radio value="CRS804"/>      
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS804"/>    
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Trustee</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                
                                    <Radio value="CRS805"/>       
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                >   
                                    <Radio value="CRS805"/>      
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                >  
                                    <Radio value="CRS805"/>    
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Protector</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                
                                    <Radio value="CRS806"/>       
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS806"/>      
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS806"/>    
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Beneficiary or member of the class of benficiaries</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                >
                                    <Radio value="CRS807"/> 
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS807"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                >  
                                    <Radio value="CRS807"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Other(e.g. individual who exercises control over another</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        disabled={true}
                                        inline={false}
                                        selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                        onChange={(s) => { }}
                                    >
                                    <Radio value="CRS808"/>
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                      disabled={true}
                                      inline={false}
                                      selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                      onChange={(s) => { }}
                                  >   
                                    <Radio value="CRS808"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                >  
                                    <Radio value="CRS808"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity">Legal Arrangement</td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Individual in a position equivalent/similator to settlor</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS809"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS809"/>      
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                       disabled={true}
                                       inline={false}
                                       selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                       onChange={(s) => { }}
                                   > 
                                    <Radio value="CRS809"/>    
                                </RadioGroup> 
                            </td>
                        </tr> 
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Individual in a position equivalent/similator to trustee</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS810"/>
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS810"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                >   
                                    <Radio value="CRS810"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Individual in a position equivalent/similator to protector</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS811"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS811"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                       disabled={true}
                                       inline={false}
                                       selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                       onChange={(s) => { }}
                                   > 
                                    <Radio value="CRS811"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">
                                <div>Individual in a position equivalent/similator to Beneficiary or memeber of the class of Beneficiaries</div></td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS812"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                >  
                                    <Radio value="CRS812"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                       disabled={true}
                                       inline={false}
                                       selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                       onChange={(s) => { }}
                                   >  
                                    <Radio value="CRS812"/>    
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">
                                <div>Other (e.g. individual who exercises control over another entity being equivalent/similar to settlor/trustee/protector or enforcer/beneficiary)</div>
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value="CRS813"/>
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                >  
                                    <Radio value="CRS813"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                >   
                                    <Radio value="CRS813"/>
                                </RadioGroup> 
                            </td>
                        </tr> 
                        <tr>
                            <td className="TCol-Type_Of_Entity">None</td>
                            <td className="TCol-TYPE_OF_CONTROLLING">
                                <div></div>
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder1}
                                    onChange={(s) => { }}
                                > 
                                    <Radio value=""/>
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder2}
                                    onChange={(s) => { }}
                                >  
                                    <Radio value=""/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                    disabled={true}
                                    inline={false}
                                    selectedValue={Crs.d_CtrlgPersonTypeofEntityHolder3}
                                    onChange={(s) => { }}
                                >   
                                    <Radio value=""/>
                                </RadioGroup> 
                            </td>
                        </tr>                         
                    </tbody>
                    <div className="Spacing-V-16" />
            </HTMLTable> 
            }           
           <div className="Row">
                {/*
                <FormGroup className="Col-1-2" label="Account Balance">
                    <InputGroup readOnly type="number" value={utils.formatNumber2(Crs.d_CrsBal)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_CrsBal.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Crs0.d_CrsBal)} </div> }
                </FormGroup> 
             
                <FormGroup className="Col-1-2" label="CRS501">
                    <InputGroup readOnly type="number" value={utils.formatNumber2(Crs.d_Crs501)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_First_Name.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Crs501} </div> }
                </FormGroup> 
                <FormGroup className="Col-3-2" label="CRS502">
                    <InputGroup readOnly type="number" value={utils.formatNumber2(Crs.d_Crs502)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Crs502.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Crs502} </div> }
                </FormGroup> 

                <FormGroup className="Col-5-2" label="CRS503">
                    <InputGroup readOnly type="number" value={utils.formatNumber2(Crs.d_Crs503)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Crs503.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Crs503} </div> }
                </FormGroup> 
                <FormGroup className="Col-7-2" label="CRS504">
                    <InputGroup readOnly type="number" value={utils.formatNumber2(Crs.d_Crs504)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Crs0.d_Crs504.toString() == "") && 
                    <div id="DispText2" > {Crs0.d_Crs504} </div> }
                </FormGroup> 
                */}
                <div className="Spacing-V-16" />
            </div>

        </div>
    )
}

interface HkCrsMastEditProps {
    gsb: AxiosInstance,
    Crs?: CRSInfo,
    CrsData?:CRSDataInfo,
    CountryList?: RegionCodeInfo[],  
    CCEPList?:CCEPINFO[],
    isNew?: boolean,
    onSave?: (Crs: CRSInfo) => void,
    onCancel?: () => void;
    PgmMode: any, 
    SuspendAC:boolean,
}


// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function HkCrsMastEdit({ gsb, Crs = {} as CRSInfo, CrsData = {} as CRSDataInfo, CountryList = [] as RegionCodeInfo[], CCEPList = [] as CCEPINFO[] ,isNew = false, onSave = () => { }, onCancel = () => { } , PgmMode,SuspendAC}: HkCrsMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<CRSInfo>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [Country1Desc, setCountry1Desc] = useState("")

    const [MailCountry1Desc, setMailCountry1Desc] = useState("")
    const [PlaceCountry1Desc, setPlaceCountry1Desc] = useState("")

 
    const [JurOranCountryDesc, setJurOranCountryDesc] = useState("")

    
    const [CountryIssuanceDesc, setCountryIssuanceDesc] = useState("")
   
    const [def_d_Title, setdef_d_Title] = useState(Crs.d_Title)    
  

    const [def_d_UNDOC, setdef_d_UNDOC] = useState(Crs.d_UNDOC)     
    const [def_d_Dormant, setdef_d_Dormant] = useState(Crs.d_Dormant)
    const [def_d_Closed, setdef_d_Closed] = useState(Crs.d_Closed)   
    
    const [JursidictionCountry1Desc, setJursidictionCountry1Desc] = useState("")
    const [JursidictionCountry2Desc, setJursidictionCountry2Desc] = useState("")
    const [JursidictionCountry3Desc, setJursidictionCountry3Desc] = useState("") 
    const [JursidictionCountry4Desc, setJursidictionCountry4Desc] = useState("")
    const [JursidictionCountry5Desc, setJursidictionCountry5Desc] = useState("")


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Crs])

   

    function setJurOranCountry(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setJurOranCountryDesc("")
       
        let CountryItem = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem : ", CountryItem) 
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem.d_Country : ", CountryList[CountryItem]) 
        if ( CountryItem == 0 || CountryItem > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem].d_RegionCode) 
            setJurOranCountryDesc(CountryList[CountryItem].d_Country)
        }

        return setJurOranCountry;
    }




    function setCountry1(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setCountry1Desc("")
       
        let CountryItem = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem : ", CountryItem) 
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem.d_Country : ", CountryList[CountryItem]) 
        if ( CountryItem == 0 || CountryItem > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem].d_RegionCode) 
            setCountry1Desc(CountryList[CountryItem].d_Country)
        }

        return setCountry1;
    }



    function setJurs1(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setJursidictionCountry1Desc("")
       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        console.log("ClMastView JursidictionCountry1Desc.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView JursidictionCountry1Desc.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem3].d_RegionCode) 
            setJursidictionCountry1Desc(CountryList[CountryItem3].d_Country)
        }

        return setJurs1;
    }

    function setJurs2(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setJursidictionCountry2Desc("")
       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        console.log("ClMastView JursidictionCountry1Desc.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView JursidictionCountry1Desc.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem3].d_RegionCode) 
            setJursidictionCountry2Desc(CountryList[CountryItem3].d_Country)
        }
        return setJurs2;
    }

    function setJurs3(text: string) {
        setJursidictionCountry3Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)

        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry3Desc(CountryList[CountryItem3].d_Country)
        }
        return setJurs3;
    }
    function setJurs4(text: string) {
        setJursidictionCountry4Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry4Desc(CountryList[CountryItem3].d_Country)
        }
        return setJurs4;
    }
    function setJurs5(text: string) {
        setJursidictionCountry5Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)

        if ( CountryItem3 == 0 || CountryItem3 > 0) {
            setJursidictionCountry5Desc(CountryList[CountryItem3].d_Country)
        }
        return setJurs5;
    }




    function setCountryIssu(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setCountryIssuanceDesc("")
       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        console.log("ClMastView CountryIssuanceDesc.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView CountryIssuanceDesc.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem3].d_RegionCode) 
            setCountryIssuanceDesc(CountryList[CountryItem3].d_Country)
        }

        return setCountryIssu;
    }


    useEffect(() => {
        setCountry1Desc("")
       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_ResidenceAddressCounrty)
        console.log("ClMastView d_ResidenceAddressCounrty.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView d_ResidenceAddressCounrty.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem3].d_RegionCode) 
       setCountry1Desc(CountryList[CountryItem3].d_Country)
        }

        console.log("ClMastView d_ResidenceAddressCounrty.find CountryDesc : ", Country1Desc) 
    }, [Crs])

 

    function setMailCountry1(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setMailCountry1Desc("")
       
        let CountryItem = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem : ", CountryItem) 
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem.d_Country : ", CountryList[CountryItem]) 
        if ( CountryItem == 0 || CountryItem > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem].d_RegionCode) 
            setMailCountry1Desc(CountryList[CountryItem].d_Country)
        }

        return setMailCountry1;
    }

    useEffect(() => {
        setMailCountry1Desc("")
       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_MailingAddressCounrty)
        console.log("ClMastView d_MailingAddressCounrty.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView d_MailingAddressCounrty.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem3].d_RegionCode) 
       setMailCountry1Desc(CountryList[CountryItem3].d_Country)
        }

        console.log("ClMastView d_ResidenceAddressCounrty.find CountryDesc : ", MailCountry1Desc) 
    }, [Crs])


    function setPlaceCountry1(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");

        setPlaceCountry1Desc("")
       
        let CountryItem = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === text)
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem : ", CountryItem) 
        //console.log("ClMastView JursidictionCountry1Desc.find CountryItem.d_Country : ", CountryList[CountryItem]) 
        if ( CountryItem == 0 || CountryItem > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem].d_RegionCode) 
            setPlaceCountry1Desc(CountryList[CountryItem].d_Country)
        }

        return setPlaceCountry1;
    }

    useEffect(() => {
        setJursidictionCountry1Desc("")
       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty1)
        console.log("ClMastView JursidictionCountry1Desc.find CountryItem3 : ", CountryItem3) 
        console.log("ClMastView JursidictionCountry1Desc.find CountryItem3.d_Country : ", CountryList[CountryItem3]) 
        if ( CountryItem3 == 0 || CountryItem3 > 0) {
       //     console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", CountryList[CountryItem3].d_RegionCode) 
            setJursidictionCountry1Desc(CountryList[CountryItem3].d_Country)
        }
        console.log("ClMastView JursidictionCountry1Desc.find CountryDesc : ", JursidictionCountry1Desc) 
    }, [Crs])


    useEffect(() => {
        setJursidictionCountry2Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty2)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {    
            setJursidictionCountry2Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])


    useEffect(() => {
        setJursidictionCountry3Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty3)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {    
            setJursidictionCountry3Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])

    useEffect(() => {
        setJursidictionCountry4Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty4)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {    
            setJursidictionCountry4Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])

    useEffect(() => {
        setJursidictionCountry5Desc("")       
        let CountryItem3 = CountryList.findIndex((CountryItem: RegionCodeInfo) => CountryItem.d_RegionCode === Crs.d_JurisdictionCounrty5)
        if ( CountryItem3 == 0 || CountryItem3 > 0) {    
            setJursidictionCountry5Desc(CountryList[CountryItem3].d_Country)
        }
    }, [Crs])


    const [AcCodeValue, setAcCodeValue] = useState(Crs.d_acCode)
    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")
    const [BCAN_Allow_Change, setBCAN_Allow_Change] = useState(false)


    const [def_d_HolderType, setdef_d_HolderType] = useState(Crs.d_HolderType) 
    const [def_d_crsStatus, setdef_d_crsStatus] = useState(Crs.d_crsStatus)
    const [def_d_EntityType, setdef_d_EntityType] = useState(Crs.d_EntityType) 

//CtrlgPersonTypeofEntityHolder1
//CtrlgPersonTypeofEntityHolder2
//CtrlgPersonTypeofEntityHolder3
    const [def_d_CtrlPersonEntity1, setdef_CtrlPersonEntity1] = useState(Crs.d_CtrlgPersonTypeofEntityHolder1) 
    const [def_d_CtrlPersonEntity2, setdef_CtrlPersonEntity2] = useState(Crs.d_CtrlgPersonTypeofEntityHolder2) 
    const [def_d_CtrlPersonEntity3, setdef_CtrlPersonEntity3] = useState(Crs.d_CtrlgPersonTypeofEntityHolder3)     
 

    const [def_d_Reason1, setdef_d_Reason1] = useState(Crs.d_Reason1)
    const [def_d_Reason2, setdef_d_Reason2] = useState(Crs.d_Reason2)
    const [def_d_Reason3, setdef_d_Reason3] = useState(Crs.d_Reason3)
    const [def_d_Reason4, setdef_d_Reason4] = useState(Crs.d_Reason4)
    const [def_d_Reason5, setdef_d_Reason5] = useState(Crs.d_Reason5)

    const [def_d_ExplainReason5, setdef_d_ExplainReason5] = useState("")

    const [showSIMore, setshowSIMore] = useState(true)
   // const [search, setse] = useState(Crs.d_acCode)
   const [isOpened, setIsOpened] = useState(false);

    //const [def_d_NetAsset, setdef_d_NetAsset] = useState(ac.d_NetAsset)

   // const [SendemailValue, setSendemailValue] = useState(Crs.d_sendemail==="Y")

    const [def_d_JurisdictionofResidence3, setdef_d_JurisdictionofResidence3] = useState("")
 
    const [searchKey, setSearchKey] = useState("acCode")

    
    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>
   
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in Crs) {
            reset(Crs)
        }
    }, [Crs, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
      
        register({ name: "d_JurisdictionCounrty1" })    
        register({ name: "d_JurisdictionIncorpOrganisation" })    
        register({ name: "d_JurisdictionCounrty2" })
        register({ name: "d_JurisdictionCounrty3" })
        register({ name: "d_JurisdictionCounrty4" })
        register({ name: "d_JurisdictionCounrty5" })
        //register({ name: "d_status" })
        
        register({ name: "d_ResidenceAddressCounrty" })    
      
        register({ name: "d_MailingAddressCounrty" })    
 
        
       // (Crs) => { setValue("d_ExeCCEP_FirmID", Crs.ParticipantID);  setValue("d_ExeCCEP_FirmName", Crs.ParticipantName)}}

    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    setAcStatusValue(res.data.data[0].d_status)

                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                }
            
            } catch (error) {
                showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])

    useEffect(() => {
        (async () => {
            try {
                // get BcanConfig Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "BcanConfig", cAction: "GetBcanConfigInfo", cAcCode: ""})
                
                if (  res.data.count > 0 )
                {
                    setBCAN_Allow_Change(res.data.data[0].BCAN_Allow_Change=="Y")

                } else { 
                    setBCAN_Allow_Change(false)
                }
            
            } catch (error) {
                showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])

    if (!isNew && !("d_acCode" in Crs)) return null

    let idNoLength = 40
//    if ( GsbFlag.user_key === "TATLEE" ) {
//        idNoLength = 22
//    }
 
    const onClearCtrlPersonEntity1 = () => {        
        // data.d_HolderType = def_d_HolderType;
        setdef_CtrlPersonEntity1("")


    }
    const onClearCtrlPersonEntity2 = () => {        
        // data.d_HolderType = def_d_HolderType;    
        setdef_CtrlPersonEntity2("")  

    }
    const onClearCtrlPersonEntity3 = () => {        
       // data.d_HolderType = def_d_HolderType;
  
       setdef_CtrlPersonEntity3("")

    }


    const onSubmit = (data: CRSInfo) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        data.d_acCode = data.d_acCode.toUpperCase();


        data.d_HolderType = def_d_HolderType;
        data.d_crsStatus = def_d_crsStatus;
        data.d_EntityType = def_d_EntityType;

       
        
        data.d_Title = def_d_Title;
        
        data.d_STATUS = AcStatusValue;
        data.d_UNDOC = def_d_UNDOC;
        data.d_Dormant = def_d_Dormant;
        data.d_Closed = def_d_Closed;

        data.d_Reason1 = def_d_Reason1;
        data.d_Reason2 = def_d_Reason2;
        data.d_Reason3 = def_d_Reason3;
        data.d_Reason4 = def_d_Reason4;
        data.d_Reason5 = def_d_Reason5;
        /*
        if (data.d_JurisdictionCounrty1.length >0 && data.d_TIN1.length > 0)
        {
            data.d_Reason1  = "";
            data.d_ExplainReason1  = "";
        }
        else */
        /*
        if (data.d_JurisdictionCounrty1.length >0 && (data.d_Reason1==="A" || data.d_Reason1==="C"))
        {           
            data.d_ExplainReason1  = "";
        }
        if (data.d_JurisdictionCounrty2.length >0 && data.d_TIN2.length > 0)
        {
            data.d_Reason2  = "";
            data.d_ExplainReason2  = "";
        }
        else if (data.d_JurisdictionCounrty2.length >0 && (data.d_Reason2==="A" || data.d_Reason2==="C"))
        {           
            data.d_ExplainReason2  = "";
        }
        if (data.d_JurisdictionCounrty3.length >0 && data.d_TIN3.length > 0)
        {
            data.d_Reason3  = "";
            data.d_ExplainReason3  = "";
        }
        else if (data.d_JurisdictionCounrty3.length >0 && (data.d_Reason3==="A" || data.d_Reason3==="C"))
        {           
            data.d_ExplainReason3  = "";
        }
        if (data.d_JurisdictionCounrty4.length >0 && data.d_TIN4.length > 0)
        {
            data.d_Reason4  = "";
            data.d_ExplainReason4  = "";
        }
        else if (data.d_JurisdictionCounrty4.length >0 && (data.d_Reason4==="A" || data.d_Reason4==="C"))
        {           
            data.d_ExplainReason4  = "";
        }
        if (data.d_JurisdictionCounrty5.length >0 && data.d_TIN5.length > 0)
        {
            data.d_Reason5  = "";
            data.d_ExplainReason5  = "";
        }
        else if (data.d_JurisdictionCounrty5.length >0 && (data.d_Reason5==="A" || data.d_Reason5==="C"))
        {           
            data.d_ExplainReason5 = "";
        }        
        */
        data.d_CtrlgPersonTypeofEntityHolder1 =def_d_CtrlPersonEntity1;
        data.d_CtrlgPersonTypeofEntityHolder2 =def_d_CtrlPersonEntity2;
        data.d_CtrlgPersonTypeofEntityHolder3 =def_d_CtrlPersonEntity3;

        if (def_d_HolderType ==="2")
        {
            if (data.d_EntityType === "PNFE1" ||data.d_EntityType === "PNFE2")
            {
                data.d_AcctHolderType ="CRS101";
            }
            else
            {
                data.d_AcctHolderType ="CRS102";
            }
        }
        else
        {
            data.d_AcctHolderType ="";
                
        }
        if( data.d_EntityType === "FI1" ||  data.d_EntityType === "FI2" || data.d_EntityType === "PNFE1" ||data.d_EntityType === "PNFE2" )
        {
            data.d_ANFE1 = "";
            data.d_ANFE2 = "";
            data.d_ANFE3 = "";
            data.d_ANFE4 = "";    
        
        }       
        else if( data.d_EntityType === "ANFE1" )
        {
            data.d_ANFE2 = "";
            data.d_ANFE3 = "";
            data.d_ANFE4 = "";
        }
        else if( data.d_EntityType === "ANFE2" )
        {
            data.d_ANFE1 = "";
           //data.d_ANFE2 = "";
           //data.d_ANFE3 = "";
            data.d_ANFE4 = "";
        }
        else if( data.d_EntityType === "ANFE3" ) 
        {
            data.d_ANFE1 = "";
            data.d_ANFE2 = "";
            //data.d_ANFE3 = "";
            data.d_ANFE4 = "";
        }
        else if( data.d_EntityType === "ANFE4" )
        {
            data.d_ANFE1 = "";
            data.d_ANFE2 = "";
            data.d_ANFE3 = "";
            //data.d_ANFE4 = "";
        }        

        if ( data.d_EntityType !=="PNFE1"  && data.d_EntityType !=="PNFE2"  ) 
        {
            data.d_ControllingPerson1 = "";
            data.d_ControllingPerson2 = "";
            data.d_ControllingPerson3 = "";
            data.d_ControllingPerson4 = "";
            data.d_ControllingPerson5 = "";
            data.d_ControllingPerson6 = "";
            data.d_ControllingPerson7 = "";
            data.d_ControllingPerson8 = "";
        }

        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }

        (async () => {
            try {
                //console.log("call HkCrsMastedit data", data)
                //console.log("call HkCrsMastedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "HkCrsMast", cAction: "HkCrsValidate", isNew: isNew})
                // console.log("HkCrsMastedit res.data.ok", res.data.ok)
                //console.log("HkCrsMastedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }
                onSave(data)
            } catch (error) {
                showErrorBox(error)
            }
        })()
    }

    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

 

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }



    const CountryRenderer: ItemRenderer<RegionCodeInfo> = (Crs, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${Crs.d_Country}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={Crs.d_RegionCode}
                key={Crs.d_RegionCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CountryPredicate: ItemPredicate<RegionCodeInfo> = (query, Crs, _index, exactMatch) => {
        const normalizedText = `${Crs.d_RegionCode} - ${Crs.d_Country}`.toLowerCase();
        
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CountryValueRenderer = (Crs: RegionCodeInfo) => {
        return Crs.d_RegionCode
    }
    const CCEPRenderer: ItemRenderer<CCEPINFO> = (Crs, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${Crs.ParticipantName}`;  // show in list
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={Crs.ParticipantID}
                key={Crs.ParticipantID}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };
    const  CCEPPredicate: ItemPredicate<CCEPINFO> = (query, Crs, _index, exactMatch) => {
        const normalizedText = `${Crs.ParticipantName} - ${Crs.ParticipantID}`.toLowerCase();      
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };    
    const CCEPValueRenderer = (Crs: CCEPINFO) => {
        return Crs.ParticipantID
    }    
    const CCEPNameRenderer: ItemRenderer<CCEPINFO> = (Crs, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${Crs.ParticipantName}`;  // show in list
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={Crs.ParticipantName}
                key={Crs.ParticipantID}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const  CCEPNamePredicate: ItemPredicate<CCEPINFO> = (query, Crs, _index, exactMatch) => {
        const normalizedText = `${Crs.ParticipantName} - ${Crs.ParticipantID}`.toLowerCase();      
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };
    const CCEPNameValueRenderer = (Crs: CCEPINFO) => {
        return Crs.ParticipantName
    }   
    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Require Authority
                    </Callout>
                }
            </div>

            <div className="Row">
                <div className="Spacing-V-16" />
                <FormGroup className="Col-1-5">
                    <H5><div style={{justifyContent: 'flex-end'}}>CRS Status</div></H5>    
                    <RadioGroup
                        name="d_crsStatus"
                        inline={true}
                        selectedValue={def_d_crsStatus}
                        onChange={utils.handleStringChange(s => setdef_d_crsStatus(s))}
                        ref={register}>
                        <Radio label="0-DONE" value="0" />
                        <Radio label="1-PROCESSING"  value="1" />
                        <Radio label="2-COMPLETED"  value="2" />
                    </RadioGroup>
                    {errors.d_crsStatus && errors.d_crsStatus.types && <span id="Err_msg">{errors.d_crsStatus.types.message}</span>}
                </FormGroup> 
                <div className="Spacing-V-16" />
            </div>
            
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-5" label="Holder Type "labelFor="Holder Type ">
                    <RadioGroup
                        name="d_HolderType"
                        inline={true}
                        selectedValue={def_d_HolderType}
                        onChange={utils.handleStringChange(s => setdef_d_HolderType(s)) }
                        ref={register}>
                        <Radio label="Individual" value="1" />
                        <Radio label="Entity"  value="2" />
                        <Radio label="Controlling Person"  value="3" />
                    </RadioGroup>
                    {errors.d_HolderType && errors.d_HolderType.types && <span id="Err_msg">{errors.d_HolderType.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Account No." labelFor="inputAcCode" labelInfo="*">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        {/* <InputGroup disabled={! isNew} id="inputAcCode" name="d_acCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } /> */}
                        <InputGroup disabled={! isNew} id="inputacCode" value={AcCodeValue} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s)) } inputRef={register({ required: true , maxLength: 8}) } />
                    </Tooltip>    
                    {errors.d_acCode && errors.d_acCode.type === "required" && (<span id="Err_msg">Account No. cannot be Blank</span>)}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Account Holder No." labelFor="inputHolderNo" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputHolderNo" name="d_acHolderNo" defaultValue="" placeholder="Holder No. ..." inputRef={register({ maxLength: idNoLength}) } />
                    {errors.d_acHolderNo && errors.d_acHolderNo.types && <span id="Err_msg">{errors.d_acHolderNo.types.message}</span>}
                    {errors.d_acHolderNo && errors.d_acHolderNo.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}
                </FormGroup>
                { GsbFlag.SUSPEND_AC && <FormGroup className="Col-5-1" label="Status" labelFor="inputStatus">
                    <div>
                        <div id="DispText">{AcStatusValue}</div>
                    </div>
                </FormGroup> }
                <FormGroup className="Col-6-3" label="Name in GSB" labelFor="inputName">
                    <div>
                        <div id="DispText">{AcName1Value}</div>
                        <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>


                
            </div>

       
            <div className="Row">
                <FormGroup className="Col-1-2" label="UnDocument " labelFor="UnDocument">
                    <RadioGroup
                        name="d_UNDOC"
                        inline={true}
                        selectedValue={def_d_UNDOC}
                        onChange={utils.handleStringChange(s => setdef_d_UNDOC(s)) }
                        ref={register}>
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_UNDOC && errors.d_UNDOC.types && <span id="Err_msg">{errors.d_UNDOC.types.message}</span>}
                </FormGroup>

                <FormGroup className="Col-3-2" label="Dormant" labelFor="Dormant">
                    <RadioGroup
                        name="d_Dormant"
                        inline={true}
                        selectedValue={def_d_Dormant}
                        onChange={utils.handleStringChange(s => setdef_d_Dormant(s)) }
                        ref={register}>
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Dormant && errors.d_Dormant.types && <span id="Err_msg">{errors.d_Dormant.types.message}</span>}
                </FormGroup>

                <FormGroup className="Col-5-2" label="Document Closed " labelFor="Document Closed">
                    <RadioGroup
                        name="d_Closed"
                        inline={true}
                        selectedValue={def_d_Closed}
                        onChange={utils.handleStringChange(s => setdef_d_Closed(s)) }
                        ref={register}>
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Closed && errors.d_Closed.types && <span id="Err_msg">{errors.d_Closed.types.message}</span>}
                </FormGroup>


                <FormGroup className="Col-1-2" label={t('Last Update Date')} labelFor="inputLUpdateTime">
                    {/* def_d_Consent_Agree === "Y" && <InputGroup id="inputDoB" name="d_Consent_Date" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} /> */}
                    {/* ! ( def_d_Consent_Agree === "Y" ) && <InputGroup readOnly value= {utils.dispDate(Bcan.d_Consent_Date, GsbFlag.dateFormat)} /> */}
                     <InputGroup  id="inputDoB" name="d_LUpdateTime" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                   {errors.d_LUpdateTime && errors.d_LUpdateTime.types && <span id="Err_msg">{errors.d_LUpdateTime.types.message}</span>}
                </FormGroup> 
            </div>
            {(def_d_HolderType === "1" || def_d_HolderType==="3") && <div className="Row">
                <FormGroup className="Col-1-4">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part 1. Identification of Individual Account Holder</div></H5>
                </FormGroup>


                {/*
                <FormGroup className="Col-1-2" label="Title" labelFor="inputTitle">
                        <InputGroup id="inputTitle" name="d_Title" autoFocus={true} defaultValue="" placeholder="Title (Mr,Mrs,Ms,Miss)..." inputRef={register({ maxLength: 80}) } />
                        {errors.d_Title && errors.d_Title.types && <span id="Err_msg">{errors.d_Title.types.message}</span>}
                        {errors.d_Title && errors.d_Title.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
                */}

                <FormGroup className="Col-1-2" label="Title" labelFor="inputTitle">
                    <RadioGroup
                        name="d_Title"
                        inline={true}
                        selectedValue={def_d_Title}
                        onChange={utils.handleStringChange(s => setdef_d_Title(s)) }
                        ref={register}>
                        <Radio label="Mir" value="Mr" />
                        <Radio label="Mrs"  value="Mrs" />
                        <Radio label="Ms"  value="Ms" />
                        <Radio label="Miss"  value="Miss" />
                    </RadioGroup>
                    {errors.d_Title && errors.d_Title.types && <span id="Err_msg">{errors.d_Title.types.message}</span>}
                </FormGroup>
                
                <FormGroup className="Col-1-2" label="Last Name or Surname." labelFor="inputLast_Name">
                    <InputGroup id="inputLast_Name" name="d_Last_Name" autoFocus={true} defaultValue="" placeholder="Last name..." inputRef={register({ maxLength: 80}) } />
                    {errors.d_Last_Name && errors.d_Last_Name.types && <span id="Err_msg">{errors.d_Last_Name.types.message}</span>}
                    {errors.d_Last_Name && errors.d_Last_Name.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
    
                <FormGroup className="Col-3-2" label="Middle Name." labelFor="inputMiddle_Name">
                    <InputGroup id="inputMiddle_Name" name="d_Middle_Name" autoFocus={true} defaultValue="" placeholder="Middle Name...." inputRef={register({ maxLength: 80}) } />
                    {errors.d_Middle_Name && errors.d_Middle_Name.types && <span id="Err_msg">{errors.d_Middle_Name.types.message}</span>}
                    {errors.d_Middle_Name && errors.d_Middle_Name.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="First or Given Name." labelFor="inputFirst_Name">
                    <InputGroup id="inputFirst_Name" name="d_First_Name" autoFocus={true} defaultValue="" placeholder="First or Given Name...." inputRef={register({ maxLength: 80}) } />
                    {errors.d_First_Name && errors.d_First_Name.types && <span id="Err_msg">{errors.d_First_Name.types.message}</span>}
                    {errors.d_First_Name && errors.d_First_Name.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
                <div className="Spacing-V-16" />
            </div>}
            {(def_d_HolderType === "2") && <div className="Row">
                 <FormGroup className="Col-1-6" label="Legal Name of Entity or Branch(*)" labelFor="inputLegal_Entity_Name">
                        <InputGroup id="inputTitle" name="d_Legal_Entity_Name" autoFocus={true} defaultValue="" placeholder="Legal Name of Entity or Branch(*)..." inputRef={register({ maxLength: 80}) } />
                        {errors.d_Legal_Entity_Name && errors.d_Legal_Entity_Name.types && <span id="Err_msg">{errors.d_Legal_Entity_Name.types.message}</span>}
                        {errors.d_Legal_Entity_Name && errors.d_Legal_Entity_Name.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
 
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-3" label={t('Jurisdiction of Incorporation or Organisation (*)')} labelFor="inputurisdictionIncorpOrganisation">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Jurisdiction of Incorporation or Organisation" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_JurisdictionIncorpOrganisation)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_JurisdictionIncorpOrganisation", Crs.d_RegionCode);setJurOranCountry(Crs.d_RegionCode) }}

                    />
                    <div>
                        {errors.d_JurisdictionIncorpOrganisation && errors.d_JurisdictionIncorpOrganisation.types && <span id="Err_msg">{errors.d_JurisdictionIncorpOrganisation.types.message}</span>}
                    </div>
                    <div>
                        <div id="DispText">{JurOranCountryDesc}</div>
                    </div>                    
                </FormGroup> 


                <FormGroup className="Col-1-3" label="Hong Kong Business Registration Number." labelFor="inputLegal_Entity_Name">
                        <InputGroup id="inputTitle" name="d_HKBusinessRegistrationNo" autoFocus={true} defaultValue="" placeholder="Hong Kong Business Registration Number...." inputRef={register({ maxLength: 80}) } />
                        {errors.d_HKBusinessRegistrationNo && errors.d_HKBusinessRegistrationNo.types && <span id="Err_msg">{errors.d_HKBusinessRegistrationNo.types.message}</span>}
                        {errors.d_HKBusinessRegistrationNo && errors.d_HKBusinessRegistrationNo.type === "maxLength" && <span id="Err_msg">Max length is 80</span>}
                </FormGroup>
            </div>
            }
 
            { (def_d_HolderType === "1" || def_d_HolderType === "3" ) && <div className="Row">
                <FormGroup className="Col-1-3" label="Hong Kong Identity Card or Passport Number" labelFor="inputIdNo">
                    <InputGroup id="inputIdNo" name="d_ID_Number" defaultValue="" placeholder="ID Number ..." inputRef={register({ maxLength: idNoLength}) } />
                    {errors.d_ID_Number && errors.d_ID_Number.types && <span id="Err_msg">{errors.d_ID_Number.types.message}</span>}
                    {errors.d_ID_Number && errors.d_ID_Number.type === "maxLength" && <span id="Err_msg">Max length is {idNoLength}</span>}
                </FormGroup>
            </div>
            }
             { (def_d_HolderType === "1" || def_d_HolderType === "3" ) && <div className="Row">
                {/* (def_d_HolderType === "1" || def_d_HolderType === "3" ) && <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Current Residence Address</div></H5>
                </FormGroup>}
                { (def_d_HolderType === "2" ) && <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Current Business Address</div></H5>
                </FormGroup>*/}                
                <FormGroup className="Col-1-6" label="Current Residence Address. (line1 e.g. Suite, Floor, Building Street,District) / line2 City * / line3 e.g. Province, State" labelFor="inputResidenceAddress">
                    <InputGroup id="inputResidenceAddress1" name="d_ResidenceAddress1" defaultValue="" placeholder="Line1(e.g. Suite, Floor, Building Street,District)...." inputRef={register({ maxLength: 255}) } />
                       {errors.d_ResidenceAddress1 && errors.d_ResidenceAddress1.types && <span id="Err_msg">{errors.d_ResidenceAddress1.types.message}</span>}
                    {errors.d_ResidenceAddress1 && errors.d_ResidenceAddress1.type === "maxLength" && <span id="Err_msg">Max length is {255}</span>}
                    <InputGroup id="inputResidenceAddress2" name="d_ResidenceAddress2" defaultValue="" placeholder="Line2(City)...." inputRef={register({ maxLength: 255}) } />
       
                    {errors.d_ResidenceAddress2 && errors.d_ResidenceAddress2.types && <span id="Err_msg">{errors.d_ResidenceAddress2.types.message}</span>}
                    {errors.d_ResidenceAddress2 && errors.d_ResidenceAddress2.type === "maxLength" && <span id="Err_msg">Max length is {255}</span>}
                    <InputGroup id="inputResidenceAddress3" name="d_ResidenceAddress3" defaultValue="" placeholder="Line3(e.g Province, State)..." inputRef={register({ maxLength: 255}) } />
                    {errors.d_ResidenceAddress3 && errors.d_ResidenceAddress3.types && <span id="Err_msg">{errors.d_ResidenceAddress3.types.message}</span>}
                    {errors.d_ResidenceAddress3 && errors.d_ResidenceAddress3.type === "maxLength" && <span id="Err_msg">Max length is {255}</span>}                       
                </FormGroup>    
               
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-3" label={t('Country (*)')} labelFor="inputResidenceAddressCounrty">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Country" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_ResidenceAddressCounrty)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_ResidenceAddressCounrty", Crs.d_RegionCode);setCountry1(Crs.d_RegionCode) }}

                    />
                    <div>
                        {errors.d_ResidenceAddressCounrty && errors.d_ResidenceAddressCounrty.types && <span id="Err_msg">{errors.d_ResidenceAddressCounrty.types.message}</span>}
                    </div>
                    <div>
                        <div id="DispText">{Country1Desc}</div>
                    </div>  
                </FormGroup>
          
                <FormGroup className="Col3-4-2" label="Post Code/ZIP Code" labelFor="inputResidenceAddressZIPCode">
                    <InputGroup id="inputResidenceAddressZIPCode" name="d_ResidenceAddressZIPCode" defaultValue="" placeholder="Post Code/ZIP..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_ResidenceAddressZIPCode && errors.d_ResidenceAddressZIPCode.types && <span id="Err_msg">{errors.d_ResidenceAddressZIPCode.types.message}</span>}
                    {errors.d_ResidenceAddressZIPCode && errors.d_ResidenceAddressZIPCode.type === "maxLength" && <span id="Err_msg">Max length is {10}</span>}
                </FormGroup>
               
            </div>
            }
            { (def_d_HolderType === "2" ) && <div className="Row">
                {/* (def_d_HolderType === "1" || def_d_HolderType === "3" ) && <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Current Residence Address</div></H5>
                </FormGroup>}
                { (def_d_HolderType === "2" ) && <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Current Business Address</div></H5>
                </FormGroup>*/}                
                <FormGroup className="Col-1-6" label="Current Business Address. (line1 e.g. Suite, Floor, Building Street,District) / line2 City * / line3 e.g. Province, State" labelFor="inputResidenceAddress">
                    <InputGroup id="inputResidenceAddress1" name="d_ResidenceAddress1" defaultValue="" placeholder="Line1(e.g. Suite, Floor, Building Street,District)...." inputRef={register({ maxLength: 255}) } />
                       {errors.d_ResidenceAddress1 && errors.d_ResidenceAddress1.types && <span id="Err_msg">{errors.d_ResidenceAddress1.types.message}</span>}
                    {errors.d_ResidenceAddress1 && errors.d_ResidenceAddress1.type === "maxLength" && <span id="Err_msg">Max length is {255}</span>}
                    <InputGroup id="inputResidenceAddress2" name="d_ResidenceAddress2" defaultValue="" placeholder="Line2(City)...." inputRef={register({ maxLength: 255}) } />
       
                    {errors.d_ResidenceAddress2 && errors.d_ResidenceAddress2.types && <span id="Err_msg">{errors.d_ResidenceAddress2.types.message}</span>}
                    {errors.d_ResidenceAddress2 && errors.d_ResidenceAddress2.type === "maxLength" && <span id="Err_msg">Max length is {255}</span>}
                    <InputGroup id="inputResidenceAddress3" name="d_ResidenceAddress3" defaultValue="" placeholder="Line3(e.g Province, State)..." inputRef={register({ maxLength: 255}) } />
                    {errors.d_ResidenceAddress3 && errors.d_ResidenceAddress3.types && <span id="Err_msg">{errors.d_ResidenceAddress3.types.message}</span>}
                    {errors.d_ResidenceAddress3 && errors.d_ResidenceAddress3.type === "maxLength" && <span id="Err_msg">Max length is {255}</span>}                       
                </FormGroup>    
               
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-3" label={t('Country (*)')} labelFor="inputResidenceAddressCounrty">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Country" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_ResidenceAddressCounrty)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_ResidenceAddressCounrty", Crs.d_RegionCode);setCountry1(Crs.d_RegionCode) }}

                    />
                    <div>
                        {errors.d_ResidenceAddressCounrty && errors.d_ResidenceAddressCounrty.types && <span id="Err_msg">{errors.d_ResidenceAddressCounrty.types.message}</span>}
                    </div>
                    <div>
                        <div id="DispText">{Country1Desc}</div>
                    </div>  
                </FormGroup>
          
                <FormGroup className="Col3-4-2" label="Post Code/ZIP Code" labelFor="inputResidenceAddressZIPCode">
                    <InputGroup id="inputResidenceAddressZIPCode" name="d_ResidenceAddressZIPCode" defaultValue="" placeholder="Post Code/ZIP..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_ResidenceAddressZIPCode && errors.d_ResidenceAddressZIPCode.types && <span id="Err_msg">{errors.d_ResidenceAddressZIPCode.types.message}</span>}
                    {errors.d_ResidenceAddressZIPCode && errors.d_ResidenceAddressZIPCode.type === "maxLength" && <span id="Err_msg">Max length is {10}</span>}
                </FormGroup>
               
            </div>
            }
            {/*<div className="Row">
                <FormGroup className="Col-1-7">
                    <H5><div style={{justifyContent: 'flex-end'}}>Mailing Address(Complete if different to the current residence address)</div></H5>
                </FormGroup>                             
                <FormGroup className="Col-1-6" label="Line. (line1 e.g. Suite, Floor, Building Street,District),line2 City, line3 e.g. Province, State" labelFor="inputMailingAddress">
                    <InputGroup id="inputMailingAddress1" name="d_MailingAddress1" defaultValue="" placeholder="Line1(e.g. Suite, Floor, Building Street,District)...." inputRef={register({ maxLength: 80}) } />
                    {errors.d_MailingAddress1 && errors.d_MailingAddress1.types && <span id="Err_msg">{errors.d_MailingAddress1.types.message}</span>}
                    {errors.d_MailingAddress1 && errors.d_MailingAddress1.type === "maxLength" && <span id="Err_msg">Max length is {40}</span>}
                    <InputGroup id="inputMailingAddress2" name="d_MailingAddress2" defaultValue="" placeholder="Line2(City)...." inputRef={register({ maxLength: 40}) } />
                    {errors.d_MailingAddress2 && errors.d_MailingAddress2.types && <span id="Err_msg">{errors.d_MailingAddress2.types.message}</span>}
                    {errors.d_MailingAddress2 && errors.d_MailingAddress2.type === "maxLength" && <span id="Err_msg">Max length is {40}</span>}
                    <InputGroup id="inputMailingAddress3" name="d_MailingAddress3" defaultValue="" placeholder="Line3(e.g Province, State)..." inputRef={register({ maxLength: 40}) } />
                     {errors.d_MailingAddress3 && errors.d_MailingAddress3.types && <span id="Err_msg">{errors.d_MailingAddress3.types.message}</span>}
                    {errors.d_MailingAddress3 && errors.d_MailingAddress3.type === "maxLength" && <span id="Err_msg">Max length is {40}</span>}                       
                </FormGroup>
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-3" label={t('Country (*)')} labelFor="inputMailCountry">
                    <Suggest
                        inputProps={{ placeholder: "Please choose Country" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_MailingAddressCounrty)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_MailingAddressCounrty", Crs.d_RegionCode);setMailCountry1(Crs.d_RegionCode) }}
                    />
                    <div>
                    {errors.d_MailingAddressCounrty && errors.d_MailingAddressCounrty.types && <span id="Err_msg">{errors.d_MailingAddressCounrty.types.message}</span>}
                    </div> 
                    <div>
                        <div id="DispText">{MailCountry1Desc}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col3-4-2" label="Post Code/ZIP Code" labelFor="inputMailingAddressZIPCode">
                    <InputGroup id="inputMailingAddressZIPCode" name="d_MailingAddressZIPCode" defaultValue="" placeholder="Post Code/ZIP..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_MailingAddressZIPCode && errors.d_MailingAddressZIPCode.types && <span id="Err_msg">{errors.d_MailingAddressZIPCode.types.message}</span>}
                    {errors.d_MailingAddressZIPCode && errors.d_MailingAddressZIPCode.type === "maxLength" && <span id="Err_msg">Max length is {10}</span>}
                </FormGroup>
            </div>*/}
 
            {(def_d_HolderType==="1" || def_d_HolderType==="3") && <div className="Row">            
                <FormGroup className="Col-1-2" label={t('Date of Birth')} labelFor="inputBirth_Date">                      
                    <InputGroup id="inputDoB" name="d_Date_Of_Brih" type="date"   placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_Date_Of_Brih && errors.d_Date_Of_Brih.types && <span id="Err_msg">{errors.d_Date_Of_Brih.types.message}</span>}
                </FormGroup> 
            </div>}
            {/*(def_d_HolderType==="1" || def_d_HolderType==="3") && <div className="Row">   
                <FormGroup className="Col-1-2" label="Place of Birth" labelFor="inputPlaceOfbirth">
                    <InputGroup id="inputPlaceofCity" name="d_PlaceofCity" defaultValue="" placeholder="Town/City" inputRef={register} />
                    {errors.d_PlaceofCity && errors.d_PlaceofCity.types && <span id="Err_msg">{errors.d_PlaceofCity.types.message}</span>}
                    <InputGroup id="inputPlaceofCity" name="d_PlaceofProvince" defaultValue="" placeholder="Province/State" inputRef={register} />
                    {errors.d_PlaceofProvince && errors.d_PlaceofProvince.types && <span id="Err_msg">{errors.d_PlaceofProvince.types.message}</span>}

                    <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-3" label={t('Country (*)')} labelFor="inputPlaceCountry">
                        <Suggest
                            inputProps={{ placeholder: "Please choose Country" }}
                            defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_PlaceofCounrty)}
                            items={CountryList}
                            itemRenderer={CountryRenderer}
                            itemPredicate={CountryPredicate}
                            inputValueRenderer={CountryValueRenderer}                  
                            onItemSelect={(Crs) => { setValue("d_PlaceofCounrty", Crs.d_RegionCode);setPlaceCountry1(Crs.d_RegionCode) }}
                        />
                        {errors.d_PlaceofCounrty && errors.d_PlaceofCounrty.types && <span id="Err_msg">{errors.d_PlaceofCounrty.types.message}</span>}
                        <div>
                            <div id="DispText">{PlaceCountry1Desc}</div>
                        </div>
                    </FormGroup>  

                </FormGroup>
            </div>*/}
            {def_d_HolderType === "2" &&
            <div className="Row">             
                <FormGroup className="Col-1-8" labelFor="InputEntityType">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part 2 Entity Type</div></H5>   
                    <RadioGroup
                        name="d_EntityType"
                        inline={false}
                        selectedValue={def_d_EntityType}
                        onChange={utils.handleStringChange(s => setdef_d_EntityType(s)) }
                        ref={register}>                   
                        <H6><div style={{justifyContent: 'flex-end'}}>- Financial Institution</div></H6>   
                        <Radio label="Custodial Institution, Depository Institution or Speicified Insurance Company" value="FI1"/>
                        <Radio label="Investment Entity, except an investment entity that is managed by another Financial Institution and located in a Non-Participating CRS Jurisdiction" value="FI2" /> 
                        {/*<H4><div style={{justifyContent: 'flex-end'}}>CRS Master</div></H4>*/}                         
                        <H6><div style={{justifyContent: 'flex-end'}}>- Active NFE</div></H6>   
                        <Radio value="ANFE1" >"NFE the stock of which is regularly trade on <u>&nbsp;&nbsp;a)&nbsp;&nbsp;</u> which is an established securities market</Radio>
                        <div className="Row">
                            <FormGroup className="Col-2-4" label="a.)" labelFor="inputANFE1">
                                <InputGroup  id="inputANFE1" name="d_ANFE1" defaultValue="" inputRef={register() } />
                            </FormGroup>
                        </div>
                        <Radio  value="ANFE2" >
                            {t('ACtive NFE -Related entity of a), the stock of which is regularly trade on')}                
                            {t(' b), which is an established sucurities market.')}
                        </Radio>
                        <div className="Row">
                            <FormGroup className="Col-2-4" label="a.)" labelFor="inputANFE2">                               
                                <InputGroup  id="inputANFE2" name="d_ANFE2" defaultValue="" inputRef={register() } />
                            </FormGroup>                           
                            <FormGroup className="Col-2-4" label="b.)" labelFor="inputANFE3">                               
                                <InputGroup  id="inputANFE3" name="d_ANFE3" defaultValue="" inputRef={register() } />
                            </FormGroup>     
                        </div>
                        <Radio label ="NFE is a goverthe NFE is a governmental Entity, an international organisation, a central bank, or an. Entity wholly owned by one or more of the foregoing;" value="ANFE3" />
                     
                        <Radio label ="Active NFE other than the above (Please specify  a) )" value="ANFE4" />                                                  
                        <div className="Row">
                            <FormGroup className="Col-2-4" label="a.)" labelFor="inputANFE4">                               
                                <InputGroup  id="inputANFE4" name="d_ANFE4" defaultValue="" inputRef={register() } />
                            </FormGroup>
                        </div>      
                    
                        <H6><div style={{justifyContent: 'flex-end'}}>- Passive NFE</div></H6>   
                        <Radio label ="Investment entity that is managed by another financial institution and located in non-participating Jurisdiction" value="PNFE1" />
                        <Radio label ="NFE that is not an active NFE" value="PNFE2" />  
                  
                    </RadioGroup>
                    {errors.d_EntityType && errors.d_EntityType.types && <span id="Err_msg">{errors.d_EntityType.types.message}</span>}
                </FormGroup>
            </div>
            }

            {def_d_HolderType === "2"  && (def_d_EntityType ==="PNFE1" || def_d_EntityType ==="PNFE2") &&
            <div className="Row">
                <FormGroup className="Col-1-5">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part3 Controlling Person(Complete this when Passive NFE)</div></H5>   
                </FormGroup>
                <FormGroup className="Col-1-2" label="(1.)" labelFor="inpuControllingPerson1">       
                    <InputGroup  id="inpuControllingPerson1" name="d_ControllingPerson1" defaultValue="" inputRef={register() } />  
                    {errors.d_ControllingPerson1 && errors.d_ControllingPerson1.types && <span id="Err_msg">{errors.d_ControllingPerson1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="(5.)" labelFor="inpuControllingPerson5">       
                    <InputGroup  id="inpuControllingPerson5" name="d_ControllingPerson5" defaultValue="" inputRef={register() } />  
                </FormGroup>
                <FormGroup className="Col-1-2" label="(2.)" labelFor="inpuControllingPerson2">       
                    <InputGroup  id="inpuControllingPerson2" name="d_ControllingPerson2" defaultValue="" inputRef={register() } />  
                </FormGroup>
                <FormGroup className="Col-3-2" label="(6.)" labelFor="inpuControllingPerson6">       
                    <InputGroup  id="inpuControllingPerson6" name="d_ControllingPerson6" defaultValue="" inputRef={register() } />  
                </FormGroup>
                <FormGroup className="Col-1-2" label="(3.)" labelFor="inpuControllingPerson3">       
                    <InputGroup  id="inpuControllingPerson3" name="d_ControllingPerson3" defaultValue="" inputRef={register() } />  
                </FormGroup>
                <FormGroup className="Col-3-2" label="(7.)" labelFor="inpuControllingPerson7">       
                    <InputGroup  id="inpuControllingPerson7" name="d_ControllingPerson7" defaultValue="" inputRef={register() } />  
                </FormGroup>
                <FormGroup className="Col-1-2" label="(4.)" labelFor="inpuControllingPerson4">       
                    <InputGroup  id="inpuControllingPerson4" name="d_ControllingPerson4" defaultValue="" inputRef={register() } />  
                </FormGroup>
                <FormGroup className="Col-3-2" label="(8.)" labelFor="inpuControllingPerson8">       
                    <InputGroup  id="inpuControllingPerson8" name="d_ControllingPerson8" defaultValue="" inputRef={register() } />  
                </FormGroup>
            </div>
            }

            {def_d_HolderType === "3" && 
            <div className="Row">
                <FormGroup className="Col-1-5">
                    <H5><div style={{justifyContent: 'flex-end'}}>Part2 The Entity Account Holder(s) of which you are a controlling person</div></H5>   
                    <div style={{justifyContent: 'flex-end'}}>Enter the name of entity holder of which you are a controlling person</div>
                </FormGroup>
                {/*
                    <FormGroup className="Col-7-2" label="ExplainReason1" labelFor="InputExplainReason1">
                    <InputGroup id="InputExplainReason1" name="d_ExplainReason1" defaultValue="" placeholder="Explain Why the account holder is unable to obtain a Tin (When Reason B)" inputRef={register({ maxLength: 80}) } />
                    {errors.d_ExplainReason1 && errors.d_ExplainReason1.types && <span id="Err_msg">{errors.d_ExplainReason1.types.message}</span>}
                    {errors.d_ExplainReason1 && errors.d_ExplainReason1.type === "maxLength" && <span id="Err_msg">Max length is {80}</span>}
 
                */}
                <FormGroup className="Col-1-3" label="(1.)" labelFor="inputNameOFEntityHolder1">       
                    <InputGroup  id="inputNameOFEntityHolder1" name="d_NameOFEntityHolder1" defaultValue="" inputRef={register() } />  
                    {errors.d_NameOFEntityHolder1 && errors.d_NameOFEntityHolder1.types && <span id="Err_msg">{errors.d_NameOFEntityHolder1.types.message}</span>}
                </FormGroup>            
                <FormGroup className="Col-1-3" label="(2.)" labelFor="inputNameOFEntityHolder2">       
                    <InputGroup  id="inputNameOFEntityHolder2" name="d_NameOFEntityHolder2" defaultValue="" inputRef={register() } />  
                    {errors.d_NameOFEntityHolder2 && errors.d_NameOFEntityHolder2.types && <span id="Err_msg">{errors.d_NameOFEntityHolder2.types.message}</span>}
                </FormGroup>            
                <FormGroup className="Col-1-3" label="(3.)" labelFor="inputNameOFEntityHolder3">       
                    <InputGroup  id="inputNameOFEntityHolder3" name="d_NameOFEntityHolder3" defaultValue="" inputRef={register() } />  
                    {errors.d_NameOFEntityHolder3 && errors.d_NameOFEntityHolder3.types && <span id="Err_msg">{errors.d_NameOFEntityHolder3.types.message}</span>}
                </FormGroup>                           
            </div>
            }

            {def_d_HolderType === "1"  &&
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div style={{justifyContent: 'flex-end'}}>Part2 Jurisdiction of Resisdence and Taxpapyer Identification Number or its Functional Equivalent("TIN")*</div></H5>    
                </FormGroup>
            </div>      
            }   

            {def_d_HolderType === "2"  &&
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div style={{justifyContent: 'flex-end'}}>Part4 Jurisdiction of Resisdence and Taxpapyer Identification Number or its Functional Equivalent("TIN")*</div></H5>    
                </FormGroup>
            </div>      
            }       
            {def_d_HolderType === "3"  &&
            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div style={{justifyContent: 'flex-end'}}>Part3 Jurisdiction of Resisdence and Taxpapyer Identification Number or its Functional Equivalent("TIN")*</div></H5>    
                </FormGroup>
            </div>      
            }         

            <div className="Row">
                <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-1-12">
                   <H5><div>If a Tin is unavailable, provide the appropriate reason A,B or C</div></H5>    
                   <H6><div>Reason A-The jurisdiction where the account holder is a resident for tax purposes does not issue TINs to its residents.</div></H6>  
                   <H6><div>Reason B-The account holder is unable to obtain a TIN.  Explain why the account holder is unable to obtain a TIN if you have selected this reason. </div></H6>  
                   <H6><div>Reason C-TIN is not required. Select this reason only if the authorities of the jurisdiction of residence do not require the TIN to be disclosed. </div></H6>  
                </FormGroup>
            </div>          
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Jurisdiction of Residence">
                    <Suggest
                        inputProps={{ placeholder: "Jurisdiction of Residence" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_JurisdictionCounrty1)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_JurisdictionCounrty1", Crs.d_RegionCode);setJurs1(Crs.d_RegionCode) }}
                    />  
                   
                    <div>
                        {errors.d_JurisdictionCounrty1 && errors.d_JurisdictionCounrty1.types &&   <span id="Err_msg">{errors.d_JurisdictionCounrty1.types.message}</span>}
                    </div>
                    <div>
                    <div id="DispText">{JursidictionCountry1Desc}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col3-3-2" label="TIN">
                    <InputGroup id="inputTIN1" name="d_TIN1" defaultValue="" placeholder="TIN" inputRef={register({ maxLength: 80}) } />
                    {errors.d_TIN1 && errors.d_TIN1.types && <span id="Err_msg">{errors.d_TIN1.types.message}</span>}
                </FormGroup>
                

                <FormGroup className="Col-5-2" label="Reason A,B,C if not Tin">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason1"
                            inline={true}
                            selectedValue={def_d_Reason1}                     
                            onChange={utils.handleStringChange(s => setdef_d_Reason1(s)) }                            
                        ref={register}>
                             <Radio className ="radio-custom" label="none " value="" /> 
                            <Radio className ="radio-custom" label="A" value="A"  checked={false} />
                            <Radio className ="radio-custom" label="B"  value="B" checked={false} />
                            <Radio className ="radio-custom" label="C"  value="C" checked={false}/>
                        </RadioGroup>                 
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col3-7-4"  label="ExplainReason">
                    <InputGroup id="InputExplainReason1" name="d_ExplainReason1"  defaultValue="" placeholder="Explain Why the account holder is unable to obtain a Tin. (When Reason B)" inputRef={register({ maxLength: 80}) } />
                    {errors.d_ExplainReason1 && errors.d_ExplainReason1.types && <span id="Err_msg">{errors.d_ExplainReason1.types.message}</span>}
                </FormGroup>

            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="">
                    <Suggest
                        inputProps={{ placeholder: "Jurisdiction of Residence" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_JurisdictionCounrty2)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_JurisdictionCounrty2", Crs.d_RegionCode);setJurs2(Crs.d_RegionCode) }}
                    />
                    <div>
                       {errors.d_JurisdictionCounrty2 && errors.d_JurisdictionCounrty2.types && <span id="Err_msg">{errors.d_JurisdictionCounrty2.types.message}</span>}     
                    <div id="DispText">{JursidictionCountry2Desc}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col3-3-2" label="">
                    <InputGroup id="inputTIN2" name="d_TIN2" defaultValue="" placeholder="TIN" inputRef={register({ maxLength: 80}) } />
                    {errors.d_TIN2 && errors.d_TIN2.types && <span id="Err_msg">{errors.d_TIN2.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason2"                          
                            inline={true}                         
                            selectedValue={def_d_Reason2}
                            onChange={utils.handleStringChange(s => setdef_d_Reason2(s)) }
                        ref={register}>                        
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B" />
                            <Radio className ="radio-custom" label="C"  value="C" />
                        </RadioGroup>                 
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col3-7-4" label="">
                    <InputGroup id="InputExplainReason2" name="d_ExplainReason2"  defaultValue="" placeholder="Explain Why the account holder is unable to obtain a Tin. (When Reason B)" inputRef={register({ maxLength: 80}) } />
                    {errors.d_ExplainReason2 && errors.d_ExplainReason2.types && <span id="Err_msg">{errors.d_ExplainReason2.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col3-1-2" label="">
                    <Suggest
                        inputProps={{ placeholder: "Jurisdiction of Residence" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_JurisdictionCounrty3)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_JurisdictionCounrty3", Crs.d_RegionCode);setJurs3(Crs.d_RegionCode) }}
                    />
                    <div>
                        {errors.d_JurisdictionCounrty3 && errors.d_JurisdictionCounrty3.types && <span id="Err_msg">{errors.d_JurisdictionCounrty3.types.message}</span>}   
                    </div>
                    <div>
                    <div id="DispText">{JursidictionCountry3Desc}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col3-3-2" label="">
                    <InputGroup id="inputTIN3" name="d_TIN3" defaultValue="" placeholder="TIN" inputRef={register({ maxLength: 80}) } />
                    {errors.d_TIN3 && errors.d_TIN3.types && <span id="Err_msg">{errors.d_TIN3.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2">
                    <RadioGroup
                            label="" 
                            name="d_Reason3"                          
                            inline={true}                         
                            selectedValue={def_d_Reason3}
                            onChange={utils.handleStringChange(s => setdef_d_Reason3(s)) }
                        ref={register}>                        
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B" />
                            <Radio className ="radio-custom" label="C"  value="C" />
                        </RadioGroup>                 
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col3-7-4" label="">
                    <InputGroup id="InputExplainReason3" name="d_ExplainReason3"  defaultValue="" placeholder="Explain Why the account holder is unable to obtain a Tin. (When Reason B)" inputRef={register({ maxLength: 80}) } />
                    {errors.d_ExplainReason3 && errors.d_ExplainReason3.types && <span id="Err_msg">{errors.d_ExplainReason3.types.message}</span>}
                </FormGroup>
                <div className="Spacing-V-16" />
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="">
                    <Suggest
                        inputProps={{ placeholder: "Jurisdiction of Residence" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_JurisdictionCounrty4)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_JurisdictionCounrty4", Crs.d_RegionCode);setJurs4(Crs.d_RegionCode) }}
                    />
                    <div>
                        {errors.d_JurisdictionCounrty4 && errors.d_JurisdictionCounrty4.types && <span id="Err_msg">{errors.d_JurisdictionCounrty4.types.message}</span>}
                    </div>

                    <div>
                    <div id="DispText">{JursidictionCountry4Desc}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col3-3-2" label="">
                    <InputGroup id="inputTIN4" name="d_TIN4" defaultValue="" placeholder="TIN" inputRef={register({ maxLength: 80}) } />
                    {errors.d_TIN4 && errors.d_TIN4.types && <span id="Err_msg">{errors.d_TIN4.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason4"                          
                            inline={true}                         
                            selectedValue={def_d_Reason4}
                            onChange={utils.handleStringChange(s => setdef_d_Reason4(s)) }
                        ref={register}>                        
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B" />
                            <Radio className ="radio-custom" label="C"  value="C" />
                        </RadioGroup>       
                
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col3-7-4" label="">
                    <InputGroup id="InputExplainReason4" name="d_ExplainReason4"  defaultValue="" placeholder="Explain Why the account holder is unable to obtain a Tin. (When Reason B)" inputRef={register({ maxLength: 80}) } />
                    {errors.d_ExplainReason4 && errors.d_ExplainReason4.types && <span id="Err_msg">{errors.d_ExplainReason4.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col3-1-2" label="">
                    <Suggest
                        inputProps={{ placeholder: "Jurisdiction of Residence" }}
                        defaultSelectedItem={CountryList.find((aj) => aj.d_RegionCode === Crs.d_JurisdictionCounrty5)}
                        items={CountryList}
                        itemRenderer={CountryRenderer}
                        itemPredicate={CountryPredicate}
                        inputValueRenderer={CountryValueRenderer}                  
                        onItemSelect={(Crs) => { setValue("d_JurisdictionCounrty5", Crs.d_RegionCode);setJurs5(Crs.d_RegionCode) }}
                    />
                    <div>
                        {errors.d_JurisdictionCounrty5 && errors.d_JurisdictionCounrty5.types && <span id="Err_msg">{errors.d_JurisdictionCounrty5.types.message}</span>}
                    </div>
                    <div>
                    <div id="DispText">{JursidictionCountry5Desc}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col3-3-2" label="">
                    <InputGroup id="inputTIN5" name="d_TIN5" defaultValue="" placeholder="TIN" inputRef={register({ maxLength: 80}) } />
                    {errors.d_TIN5 && errors.d_TIN5.types && <span id="Err_msg">{errors.d_TIN5.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2">                       
                    <RadioGroup
                            label="" 
                            name="d_Reason5"                          
                            inline={true}                         
                            selectedValue={def_d_Reason5}
                            onChange={utils.handleStringChange(s => setdef_d_Reason5(s)) }
                        ref={register}>                        
                            <Radio className ="radio-custom" label="none " value="" />
                            <Radio className ="radio-custom" label="A" value="A" />
                            <Radio className ="radio-custom" label="B"  value="B" />
                            <Radio className ="radio-custom" label="C"  value="C" />
                        </RadioGroup>       
                
                    <div className="Spacing-V-16" />
                </FormGroup>    
                <FormGroup className="Col3-7-4" label="">
                    <InputGroup id="InputExplainReason5" name="d_ExplainReason5"  defaultValue="" placeholder="Explain Why the account holder is unable to obtain a Tin. (When Reason B)" inputRef={register({ maxLength: 80}) } />
                    {errors.d_ExplainReason5 && errors.d_ExplainReason5.types && <span id="Err_msg">{errors.d_ExplainReason5.types.message}</span>}
                </FormGroup>
            </div>
            {def_d_HolderType === "3"  &&
                <div className="Row">
                    <FormGroup className="Col-1-7">
                        <H5><div style={{justifyContent: 'flex-end'}}>Part 4. Type of Controlling Person</div></H5>
                    </FormGroup>
                </div>
            }
            {def_d_HolderType === "3" && <HTMLTable striped interactive condensed>
                <thead>
                    <th className="TCol-ACCode">Type of Entity</th>
                    <th className="TCol-ACCode">Type of Controlling Person</th>
                    <th className="TCol-ACCode">Entity(1)</th>
                    <th className="TCol-ACCode">Entity(2)</th>
                    <th className="TCol-ACCode">Entity(3)</th>      
                </thead>
                    <tbody id="ma_tr">  
        
                        <tr>
                            <td className="TCol-Type_Of_Entity">
                                Legal Person
                            </td>                     
                            <td className="TCol-TYPE_OF_CONTROLLING">
                                <div>Individual who has a controlling ownership interest(i.e not less than 25% issued shared capital)</div>
                            </td>

                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}                                
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                
                                    <Radio value="CRS801"/>
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}                                
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>   
                                    <Radio value="CRS801"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}                                
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>   
                                    <Radio value="CRS801"/>    
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity" />                     
                            <td className="TCol-TYPE_OF_CONTROLLING">

                            <div>Individual who exercises control/is entitled to exercise control through other means(i.e. not less than 25% of voting rights)</div>
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value="CRS802"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}> 
                                    <Radio value="CRS802"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>
                                    <Radio value="CRS802"/>     
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"/> 
                            <td className="TCol-TYPE_OF_CONTROLLING">                  
                            <div>Individual who has a controlling ownership interest(i.e not less than 25% issued shared capital)</div></td>                              
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}                                
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value="CRS803"/>
                                </RadioGroup> 
                            </td> 
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>
                                    <Radio value="CRS803"/> 
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}> 
                                    <Radio value="CRS803"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                
                        <tr>
                            <td className="TCol-Type_Of_Entity">Trust</td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Selttlor</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}                                
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                
                                    <Radio value="CRS804"/>       
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}                                        
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>   
                                    <Radio value="CRS804"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>   
                                    <Radio value="CRS804"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Trustee</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                
                                    <Radio value="CRS805"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}                                        
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>   
                                    <Radio value="CRS805"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>
                                    <Radio value="CRS805"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Protector</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>                                
                                    <Radio value="CRS806"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}                                        
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>   
                                    <Radio value="CRS806"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>   
                                    <Radio value="CRS806"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Beneficiary or member of the class of benficiaries</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value="CRS807"/> 
                                </RadioGroup> 
                            </td>               
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>   
                                    <Radio value="CRS807"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>   
                                    <Radio value="CRS807"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Other(e.g. individual who exercises control over another</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value="CRS808"/> 
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>   
                                    <Radio value="CRS808"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>
                                    <Radio value="CRS808"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity">Legal Arrangement</td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Individual in a position equivalent/similator to settlor</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value="CRS809"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>   
                                    <Radio value="CRS809"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>
                                    <Radio value="CRS809"/>
                                </RadioGroup> 
                            </td>
                        </tr> 
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Individual in a position equivalent/similator to trustee</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>                                
                                    <Radio value="CRS810"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}                                        
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>
                                    <Radio value="CRS810"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>
                                    <Radio value="CRS810"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Individual in a position equivalent/similator to protector</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                
                                    <Radio value="CRS811"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>
                                    <Radio value="CRS811"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>
                                    <Radio value="CRS811"/>
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>                    
                            <td className="TCol-TYPE_OF_CONTROLLING">Individual in a position equivalent/similator to 
                            Beneficiary or memeber of the class of Beneficiaries</td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value="CRS812"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>
                                    <Radio value="CRS812"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}                                
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s)) }
                                ref={register}>   
                                    <Radio value="CRS812"/>    
                                </RadioGroup> 
                            </td>
                        </tr>
                        <tr>
                            <td className="TCol-Type_Of_Entity"></td>
                            <td className="TCol-TYPE_OF_CONTROLLING">Other (e.g. individual who exercises control over another
                            entity being equivalent/similar to settlor/trustee/protector or enforcer/beneficiary)
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value="CRS813"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>
                                    <Radio value="CRS813"/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy3"
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s))}
                                    
                                ref={register}>
                                    <Radio value="CRS813"/>
                                </RadioGroup> 
                            </td>
                        </tr>  



                        <tr>
                            <td className="TCol-Type_Of_Entity">None</td>
                            <td className="TCol-TYPE_OF_CONTROLLING">
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy1"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity1}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity1(s)) }
                                ref={register}>
                                    <Radio value=""/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name="d_EntityTy2"
                                        inline={false} 
                                        selectedValue={def_d_CtrlPersonEntity2}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity2(s)) }
                                ref={register}>
                                    <Radio value=""/>
                                </RadioGroup> 
                            </td>
                            <td className="TCol-Entity">
                                <RadioGroup  name=""
                                        inline={false}
                                        selectedValue={def_d_CtrlPersonEntity3}
                                        onChange={utils.handleStringChange(s => setdef_CtrlPersonEntity3(s))}
                                    
                                ref={register}>
                                    <Radio value=""/>
                                </RadioGroup> 
                            </td>
                        </tr>






















                        {/*
                        <tr>
                            <td className="TCol-Entity"/>
                            <td className="TCol-TYPE_OF_CONTROLLING"/>
                            <td className="TCol-Entity">
                                <Button onClick={() => onClearCtrlPersonEntity1()} >clear </Button>
                            </td>
                            <td className="TCol-Entity">
                                <Button onClick={() => onClearCtrlPersonEntity2()} >clear </Button>
                            </td>
                            <td className="TCol-Entity">
                                <Button  onClick={() => onClearCtrlPersonEntity3()} >clear </Button>
                            </td>
                        </tr>
                        */}
                        <tr>
                            <td colSpan={5}>
                                {errors.d_CtrlgPersonTypeofEntityHolder1 && errors.d_CtrlgPersonTypeofEntityHolder1.types && <span id="Err_msg">{errors.d_CtrlgPersonTypeofEntityHolder1.types.message}</span>}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5}>
                                {errors.d_CtrlgPersonTypeofEntityHolder2 && errors.d_CtrlgPersonTypeofEntityHolder2.types && <span id="Err_msg">{errors.d_CtrlgPersonTypeofEntityHolder2.types.message}</span>}
                            </td>
                        </tr>
                    </tbody>
            </HTMLTable> 
            }

{/*                <FormGroup className="Col3-7-4" label="">
                    <InputGroup id="InputExplainReason5" name="d_ExplainReason5"  defaultValue="" placeholder="Explain Why the account holder is unable to obtain a Tin. (When Reason B)" inputRef={register({ maxLength: 80}) } />
                    {errors.d_ExplainReason5 && errors.d_ExplainReason5.types && <span id="Err_msg">{errors.d_ExplainReason5.types.message}</span>}
                </FormGroup>


                <FormGroup className="Col-3-2" label="Loan Limit" labelFor="inputloanLimit">
                    <InputGroup className="inputNumberNoSpin" id="inputloanLimit" name="d_loanLimit" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_loanLimit && errors.d_loanLimit.types && <span id="Err_msg">{errors.d_loanLimit.types.message}</span>}
                </FormGroup>
*/
}
            {/*<div className="Row">
                <FormGroup className="Col-1-2" label="Account Balance"> 
                    <InputGroup className="inputNumberNoSpin" id="InputCrsBal" name="d_CrsBal" defaultValue="0" placeholder="Account Balances" type="number" inputRef={register}/>
                    {errors.d_CrsBal && errors.d_CrsBal.types && <span id="Err_msg">{errors.d_CrsBal.types.message}</span>}
                </FormGroup>              
                <div className="Spacing-V-16" />
            </div>*/}

        </form>
    )
}

interface HkCrsMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function HkCrsMastMain({ gsb }: HkCrsMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [data, setData] = useState([] as CRSInfo[])
    const [data1, setData1] = useState([] as CRSDataInfo[])
    
    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchSMPID,setSearchSMPID] = useState("")
    //const [searchEcpRdStatus,setsearchEcpRdStatus] = useState("")
    const [searchCrsStatus,setsearchCrsStatus] = useState("")
    const [searchHolderType,setsearchHolderType] = useState("")
   
    

    const [searchName, setSearchName] = useState("")
   // const [searchCENumber, setSearchCENumber] = useState("")
   // const [searchConsent, setsearchConsent] = useState("")
  //  const [searchClientType, setsearchClientType] = useState("")
  //  const [searchIDType, setsearchIDType] = useState("")
   // const [searchCNConsent, setsearchCNConsent] = useState("")

    const [def_id_Type, setid_Type] = useState("")

    const [searchTel, setSearchTel] = useState("")


    const [s_AcStatus, sets_AcStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as CRSInfo)
    const [BlankAc, setBlankAc] = useState({} as CRSInfo)
    const [CurrentLog1, setCurrentLog1] = useState({} as CRSInfo)
    const [CurrentLog0, setCurrentLog0] = useState({} as CRSInfo)
    const [ViewMode, setViewMode] = useState("V")

    //CRSDataInfo
    const [currentAcData, setCurrentAcData] = useState({} as CRSDataInfo)
    const [BlankAcData, setBlankAcData] = useState({} as CRSDataInfo)
    const [CurrentCRSDataLog1, setCurrentCRSDataLog1] = useState({} as CRSDataInfo)
    const [CurrentCRSDataLog0, setCurrentCRSDataLog0] = useState({} as CRSDataInfo)

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
    
    const { t, i18n } = useTranslation();
 
    
    // 
    const [CountryList, setCountryList] = useState([] as RegionCodeInfo[])
 

    const [CCEPList, setCCEPList] = useState([] as CCEPINFO[])


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');        
                GsbFlag = res.data.Flag;
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
             
                UserID = res.data.Flag.UserID  
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setSUSPEND_AC(GsbFlag.SUSPEND_AC);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkCrsMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }
        
        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "HkCrsMast", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("HkCrsMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])

 

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetRegionCodeList"})
                //console.log("ClMastView GetRegionCodeList data : ", res.data.data, res.data.count) 
                
                if ( res.data.count > 0 )
                {
                    setCountryList(res.data.data)
                }
        
                } catch (error) {
                showErrorBox(error)
            }
        })()
    }, [gsb])

    const PrevRecProcess = async (Crs: CRSInfo) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")

            //CRSData
            /*
            const res1 = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "PrevRec_CRSData"})
            for (let i = 0; i < data1.length; i++) {
                if (data1[i].d_acCode === res.data.data.d_acCode) {
                    data1[i] = { ...data1[i], ...res.data.data }
                    setData1(data1)
                    break
                }
            }
            setCurrentAcData(res.data.data)
            */


        } catch (error) {
            utils.showError(error)
        }
    }

    const ReplicateProcess = async (Crs: CRSInfo) => {
        try {
            //console.log("call ReplicateProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "ReplicateRec"})
            showSuccess("Account No. " + Crs.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Crs.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

 
  
    const DeleteProcess = async (Crs: CRSInfo) => {
        try {
            Crs.d_AuthTran = ""
            if (isReqAuth2) {
                Crs.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "DelRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + " record deleting, wait for Authority")
            } else {
                showSuccess("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo  +"] is record deleted")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + " record deleting, wait for Authority")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + "] record deleting, wait for Authority")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Crs: CRSInfo) => {
        try {
            //console.log("call NextRecProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (Crs: CRSInfo) => {
        try {
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "AddRec"})

        
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode +  " ["+ Crs.d_acHolderNo + "] record adding, wait for Authority")
            } else {
                showSuccess("Account Code " + Crs.d_acCode +  " ["+ Crs.d_acHolderNo + "] record added")

                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }    

            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Crs.d_acCode + " record adding, wait for Authority")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " record adding, wait for Authority")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (Crs: CRSInfo) => {
        try {
            console.log("call editOnSave", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + "] record saving, wait for Authority")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + Crs.d_acCode + " record saving, wait for Authority")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + "] record saving, wait for Authority")
            } else {
                showSuccess("Account Code " +Crs.d_acCode + " ["+ Crs.d_acHolderNo + "] record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        PageShowInfo: aPageShowInfo,
                        refreshPageShow: true,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            AcStatus:s_AcStatus,                          
                            Holdertype: searchHolderType, 
                            CrsStatus:searchCrsStatus,
                        },
                    },
                    cPgmId: "HkCrsMast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                setData(res.data.data)
                setaPageShowInfo(res.data.PageShowInfo)

                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    {/*
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                    */}
                    const aPageNumber1 = []
                    let k = 0
                    for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                        if ( res.data.PageShowInfo[j-1].pageShow ) {
                            k++
                            aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                            if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                setPageReachEnd(true)
                            }
                        }
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
                    //console.log("ListRec k", k, aPageNumber1)
                } else {
                    setnPage(0)
                    setnTotalPage(0)
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "HkCrsMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            SortByName: false,
                        },
                        cPgmId: "HkCrsMast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && selectedTabId == "list" ) {
    
            setShowWait(true)
            setPageReachEnd(false)

            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                AcStatus:s_AcStatus,                          
                                Holdertype: searchHolderType, 
                                CrsStatus:searchCrsStatus,
                               },
                            },
                        cPgmId: "HkCrsMast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)

                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }

                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }

                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <HkCrsMastView gsb={gsb} Crs={currentAc} Crs0={currentAc} CrsData={currentAcData} CrsData0={currentAcData} CountryList={CountryList}  baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} PgmMode="HKCRS"  SuspendAC={SUSPEND_AC} ></HkCrsMastView>
    switch (currentMode) {
        case "edit":
            detail = <HkCrsMastEdit gsb={gsb} Crs={currentAc}   CountryList={CountryList} CCEPList ={CCEPList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="HKCRS" SuspendAC={SUSPEND_AC} ></HkCrsMastEdit>
            break
        case "new":
            detail = <HkCrsMastEdit gsb={gsb} Crs={BlankAc}   CountryList={CountryList} CCEPList ={CCEPList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } PgmMode="HKCRS" SuspendAC={SUSPEND_AC} ></HkCrsMastEdit>
            break
    }

    let logKeyViewRec = <HkCrsMastView gsb={gsb} Crs={CurrentLog1} Crs0={CurrentLog0}  CrsData={CurrentCRSDataLog1} CrsData0={CurrentCRSDataLog0} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")}  onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} PgmMode="HKCRS" SuspendAC={SUSPEND_AC}  ></HkCrsMastView>


    function PageNo(n: number) {
        if (n > 0 ) {
            if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


 
 
    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                 {aPageNumber.map((no, j) => 
                    (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
                { (nPage > 0) && ! ( PageReachEnd ) &&
                    <Button minimal >...</Button>
                }
            </FormGroup>
        </div>
    </>
 

    
    let list = <>
      
        <div className="Row">
       
            {/* <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
             
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACCode">Holder No</th>
                <th className="TCol-ACCode">Status</th>                
                <th className="TCol-ACCode">Holder Type</th>          
                <th className="TCol-ACName2">First Name /leagl entity</th>        
                <th className="TCol-ACName">Idno.</th> 
                <th className="TCol-ACName">JurisdictionCountry1</th>             
                <th className="TCol-ACName">Tin1</th>
                <th className="TCol-ACName">JurisdictionCountry2</th>             
                <th className="TCol-ACName">Tin2</th>
                <th className="TCol-ACName">JurisdictionCountry3</th>             
                <th className="TCol-ACName">Tin3</th>           
                {/*<th className="TCol-ACName">AcctHolderType</th>*/}
                {/*<th className="TCol-ACName">CP</th>*/} 
                {/*<th className="TCol-ACName">TYPE OF ENTIY CP</th>*/}
                <th className="TCol-ACName">CRS Status</th>
         
               {/* <th className="TCol-ACName">CNConsent</th> 
                <th className="TCol-ACName">CNConsent DATE</th> */}
            </thead>
            <tbody id="ma_tr">
                {data.map((Crs, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_acCode}</td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_acHolderNo}</td>

                      {/*(Bcan.d_ClientType === "1" ||Bcan.d_ClientType === "2" || Bcan.d_ClientType === "")
                            ? <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_English_Last_Name} {Bcan.d_English_First_Middle_Name} &nbsp; {Bcan.d_Chinese_Name}</td>    
                            : <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>{Bcan.d_English_Legal_Entity_Name} &nbsp; {Bcan.d_Chinese_Entity_Name}</td>
                       */ }       
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_ACStatus}</td> 
                        {(Crs.d_HolderType === "1" ) && <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>Individual</td> }
                        {(Crs.d_HolderType === "2" ) && <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>Entity</td> }
                        {(Crs.d_HolderType === "3" ) && <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>Controlling Person</td> }
                        {(Crs.d_HolderType === "1"  || Crs.d_HolderType === "3" )                        
                        ?<td className="TCol-ACName2" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{(Crs.d_Last_Name  + " " + Crs.d_Middle_Name + " " + Crs.d_First_Name).trim()} </td> 
                        :<td className="TCol-ACName" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_Legal_Entity_Name}</td>                         
                        }                  
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_ID_Number}</td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_JurisdictionCounrty1}</td> 
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_TIN1}</td>   
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_JurisdictionCounrty2}</td> 
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_TIN2}</td>                           
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_JurisdictionCounrty3}</td> 
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_TIN3}</td>     

                        {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_AcctHolderType}</td>*/}
                         {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_CtrlgPersonTypeofEntityHolder1}</td>*/}
                         {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_CtrlgPersonTypeofEntityHolder2}</td>*/}
                         {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_CtrlgPersonTypeofEntityHolder3}</td>*/}
                                             
                         {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>{Crs.d_CP}</td>*/}
                        {(Crs.d_crsStatus === "0" ) &&
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>0-None</td> 
                        }
                        {(Crs.d_crsStatus === "1" ) &&
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>1-Processing</td> 
                        }

                        {(Crs.d_crsStatus === "2" ) &&
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}>2-Completed</td> 
                        }
                     </tr>
                )}
            </tbody>
        </HTMLTable>      
    

        {ShowWait && nPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H4><div style={{justifyContent: 'flex-end'}}>CRS Master</div></H4>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Name</option>                                    
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>


                        { SUSPEND_AC && <div className="Row">
                            <FormGroup className="Col3-1-1" label="A/C Status" labelFor="inputAcStatus">
                                <select  id="inputAcStatus" value={s_AcStatus} onChange={utils.handleStringChange(s => sets_AcStatus(s))}>
                                    <option value=""> </option>
                                    <option value="A">Active</option>
                                    <option value="NA">Non-Active</option>
                                    {/*<option value="C">Closed</option>*/}
                                    {/*<option value="S">Suspend</option>*/}                                                                        
                                </select>
                            </FormGroup>
                        </div> }
                        <div className="Row">
                 
                            <FormGroup className="Col3-1-2" label="HolderType" labelFor="searchHolderType">
                                <select  id="searchClientType" value={searchHolderType} onChange={utils.handleStringChange(s => setsearchHolderType(s))}>
                                    <option value="">{t('')}</option>
                                      <option value="1">{t('Individual')}</option>
                                    <option value="2">{t('Entity & Controller Person ')}</option>
 									<option value="3">{t('Controller Person')}</option>  
                                </select>
                            </FormGroup>       
   
       
                            <FormGroup className="Col-3-4" label="CRS status" labelFor="searchCrsStatus">
                                <select  id="searchCrsStatus" value={searchCrsStatus} onChange={utils.handleStringChange(s => setsearchCrsStatus(s))}>
                                    <option value="">{t('')}</option>
                                    <option value="0">0-NONE </option>
                                    <option value="1">1-Processing</option>
                                    <option value="12">1-Processing & 2-Done</option>
                                    <option value="2">2-Done</option>
                                </select>    

                            </FormGroup>         
                                   
                        </div>

                    </Collapse>
                  
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>HKCRS Master Log View</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default HkCrsMastMain




interface CLHkCrsMastViewProps {
    gsb: AxiosInstance,
    acCode:  any,
}

export function CLHkCrsMastView({ gsb, acCode }: CLHkCrsMastViewProps) {

    const { t, i18n } = useTranslation();
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [currentMode, setCurrentMode] = useState("show")

    const [data, setData] = useState([] as   CRSInfo[])
    const [currentAc, setCurrentAc] = useState({} as CRSInfo)
    const [BlankAc, setBlankAc] = useState({} as CRSInfo)


    const [currentAcData, setCurrentAcData] = useState({} as CRSDataInfo)



    const [CountryList, setCountryList] = useState([] as RegionCodeInfo[])
    const [CCEPList, setCCEPList] = useState([] as CCEPINFO[])


    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
    
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)
                UserID = res.data.Flag.UserID

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
                setSUSPEND_AC(GsbFlag.SUSPEND_AC);
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)
                UserID = res.data.Flag.UserID

                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkidrMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])
/*
    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "HkCrsMast",
                        cAction: "ListCountryTwoLetter",
                    });
                setCountryList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
*/


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetRegionCodeList"})
                //console.log("ClMastView GetRegionCodeList data : ", res.data.data, res.data.count) 
                
                if ( res.data.count > 0 )
                {
                    setCountryList(res.data.data)
                }
        
                } catch (error) {
                showErrorBox(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: acCode,
                        nPage: 1,
                        limit: 1,
                    },
                    cPgmId: "HkCrsMast",
                    cAction: "GetRec_CL",
                });

                if ( res.data.ok ) {
                    console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                     
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
               
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, acCode])


    const PrevRecProcess = async (Crs: CRSInfo) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "PrevRec_CL"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReplicateProcess = async (Crs: CRSInfo) => {
        try {
            //console.log("call ReplicateProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "ReplicateRec"})
            showSuccess("Account No. " + Crs.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Crs.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

 
 
    const DeleteProcess = async (Crs: CRSInfo) => {
        try {
            Crs.d_AuthTran = ""
            if (isReqAuth2) {
                Crs.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "DelRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + " record deleting, waiting for authorization")
            } else {
                showSuccess("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo  +"] is record deleted")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Bcan.d_acCode + " ["+ Bcan.d_acHolderNo + " record deleting, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + " record deleting, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Crs: CRSInfo) => {
        try {
            //console.log("call NextRecProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "NextRec_CL"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (Crs: CRSInfo) => {
        try {
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkidrMast", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode + " record adding, waiting for authorization")
            } else {
                showSuccess("Account Code " + Crs.d_acCode + " record added")

                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }    

            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Crs.d_acCode + " record adding, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " record adding, waiting for authorization")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (Crs: CRSInfo) => {
        try {
            console.log("call editOnSave", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsMast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " record saving, waiting for authorization")
            } else {
                showSuccess("Account Code " +Crs.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <HkCrsMastView gsb={gsb} Crs={currentAc} Crs0={currentAc} CrsData={currentAcData} CrsData0={currentAcData} CountryList={CountryList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")}  onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode="V" logKey2="" PgmMode="fmCL" SuspendAC={SUSPEND_AC}></HkCrsMastView>
    switch (currentMode) {
        case "edit":
            detail = <HkCrsMastEdit gsb={gsb} Crs={currentAc} CountryList={CountryList} CCEPList ={CCEPList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="fmCL" SuspendAC={SUSPEND_AC} ></HkCrsMastEdit>
            break
        case "new":
            detail = <HkCrsMastEdit gsb={gsb} Crs={BlankAc} CountryList={CountryList} CCEPList ={CCEPList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show")} } PgmMode="fmCL" SuspendAC={SUSPEND_AC} ></HkCrsMastEdit>
            break
    }
    
    return (
        <>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >            
                <div><H3>HKCRS Master</H3></div>
                {detail}
                <div className="Spacing-V-16" />
            </Card>
            <div className="Spacing-V-16"></div>
            <div className="Spacing-V-16"></div>
            <Alert
                confirmButtonText="OK"
                icon="tick-circle"
                intent={Intent.SUCCESS}
                isOpen={isShowAlert1}
                onCancel={ () => setisShowAlert1(false) }
                onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
            >
                <p>
                    {ShowAlert1Msg}
                </p>
            </Alert>
            <Alert
                confirmButtonText="OK"
                icon="cross"
                intent={Intent.WARNING}
                isOpen={isShowAlert2}
                onCancel={ () => setisShowAlert2(false) }
                onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
            >
                <p>
                    {ShowAlert2Msg}
                </p>
            </Alert>
        </>
    )

}