import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, InputGroup, Button, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';


var GsbFlag: any;


interface AboutMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


interface VerLogInfo {
    Rst_log: string,
}


function AboutMain({ gsb }: AboutMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)


    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [PressUpgrade, setPressUpgrade] = useState(false)
    const [CheckUpgStatus, setCheckUpgStatus] = useState(false)
    

    const [SysName, setSysName] = useState("GSBce")
    const [isRental, setisRental] = useState(false)
    const [Expire_Date, setExpire_Date] = useState(utils.formatNumber(new Date()))
    const [isExpired, setisExpired] = useState(false)
    const [ShowUpgrade, setShowUpgrade] = useState(true)
    const [isUpgrade, setisUpgrade] = useState(false)
    const [VerNo, setVerNo] = useState("")
    const [aVerLog, setaVerLog] = useState([] as VerLogInfo[])
    const [aVerLog2, setaVerLog2] = useState([] as VerLogInfo[])
    const [UpgMsg, setUpgMsg] = useState("")
    const [showbuttonName, setshowbuttonName] = useState("Upgrade")


    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [Sys_VerNo, setSys_VerNo] = useState("")
    const [userKey, setuserKey] = useState("")
    const [dateFormat, setdateFormat] = useState("1")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setSys_VerNo("v"+GsbFlag.Sys_VerNo)
                setuserKey(GsbFlag.user_key) ;
                setdateFormat(GsbFlag.dateFormat)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

/*
    useEffect(() => {
        if ( CheckUpgStatus && showbuttonName === "Download" ) {
            const refreshTasks = async () => {
                try {
                    const res = await gsb.post('/SysMaint',
                        {   sb_data: {
                            //
                        },
                        cPgmId: "SysUpgrade2",
                    });                
                    if (res.data.ok ) {
                        clearInterval(timer)
                    }
                    setUpgMsg(res.data.data[0].UpgMsg)
                    //console.log("SysUpgrade2 res.data.data", res.data.data)

                } catch (error) {
                    utils.showError(error)
                }
            }
            let timer = setInterval(refreshTasks, 3*1000)
            return () => {clearInterval(timer)}
        }
    }, [gsb, PressUpgrade, ShowUpgrade, CheckUpgStatus, showbuttonName])
*/

    useEffect(() => {
        setaVerLog([])
        setshowbuttonName("Upgrade")
        ;
        (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "GetSysInfo",
                });
                if ( res.data.ok ) {
                    //console.log("GetSysInfo res.data.data", res.data.data)
                    setSysName(res.data.data[0].SysName) 
                    setisRental(res.data.data[0].isRental) 
                    setExpire_Date(res.data.data[0].Expire_Date) 
                    setisExpired(res.data.data[0].isExpired) 
                    setisUpgrade(res.data.data[0].isUpgrade) 
                    setVerNo(res.data.data[0].VerNo) 
                    setUpgMsg(res.data.data[0].UpgMsg) 
                    if (res.data.data[0].showbuttonDownload) {
                        setshowbuttonName("Download")
                    }
          
                    //console.log("GetSysInfo res.data.data[0].VerLog", res.data.data[0].VerLog)
                    setaVerLog2([])
                    for (let j = 0; j < res.data.data[0].VerLog.length; j++) {
                        aVerLog2.push({Rst_log: res.data.data[0].VerLog[j]})
                    }
                    setaVerLog(aVerLog2)

                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    
    const onRenew = () => {
        (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "RentalRenew",
                });                
                //console.log("onRenew res.data.data", res.data.data)
                if ( res.data.ok ) {
                } else {
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onUpgrade = () => {
        setShowUpgrade(false)
        setCheckUpgStatus(true)
        setUpgMsg("")
        ;
        (async () => {

            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "SysUpgrade",
                });                
                //console.log("onUpgrade res.data.data", res.data.data)
                if ( res.data.ok ) {
                    setUpgMsg(res.data.data[0].UpgMsg)
                } else {
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }
    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader> 
    
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div> 
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <div>{SysName} System &nbsp;&nbsp; Version : {Sys_VerNo}</div>
                    </div >
                    <br></br>
                    <br></br>

                    { isRental && <div>
                        <div><b>RENTAL</b></div>
                    </div > }
                    { isRental && <div>
                        <div>Expire Date : {utils.dispDate(Expire_Date, dateFormat)}</div>
                    </div > }
                    { isRental && isExpired && <div>
                        <br></br>
                        <div id="Err_msg">Expired</div>
                    </div > }

{/*
                    { isRental && <div className="Row">
                        <br></br><br></br>
                        <Button className="Col-1-2" icon={IconNames.LIST} onClick={onRenew}>Renew</Button>
                    </div> }
*/}               

                    <div id="Err_msg">{UpgMsg}</div>
                    { isUpgrade && (! isExpired) && <div>
                        <div style={{justifyContent: 'flex-end'}} ><H5>{SysName} System has new Version  {VerNo}</H5></div>
                        <br></br>
                        <H4>Version Log</H4>
                        <br></br>
                        {/* <div>{aVerLog}</div> */}
                        {aVerLog.map((verlog, j) => 
                            <div>{verlog.Rst_log}&nbsp;</div>
                        )}
                    </div > }

                    { isUpgrade && (! isExpired) && <div className="Row">
                        <br></br><br></br>
                        <Button disabled={! ShowUpgrade} className="Col-1-2" icon={IconNames.LIST} onClick={() =>setPressUpgrade(true)}>{showbuttonName}</Button>
                            <Alert
                            cancelButtonText="Cancel"
                            confirmButtonText="Upgrade"
                            canEscapeKeyCancel={true}
                            icon="build"
                            intent={Intent.DANGER}
                            isOpen={PressUpgrade}
                            onCancel={ () => setPressUpgrade(false) }
                            onConfirm={ () => { onUpgrade(); setPressUpgrade(false) } }
                        >
                            <p>
                               System Upgrade
                            </p>
                            <p>
                               Please inform ALL other user(s) exit the system !
                            </p>
                            { showbuttonName == "Upgrade" && <p>
                               And wait POP Upgrade Programme START !
                            </p> }
                            { showbuttonName == "Download" && <p>
                               And wait for download !
                            </p> }
                        </Alert>
                    </div> }


                    <div className="Spacing-V-16"></div>
                    <br></br>
                    <p>Notes</p>
                    <div>
                        <div>{SysName} System</div>
                        {/* <div>upgrade testing</div>*/}
                    </div >
                </Card>
            </form>
        </>
    );
}

export default AboutMain