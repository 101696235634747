import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { Checkbox } from "@blueprintjs/core";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useHistory } from "react-router-dom";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";
import "./DividendProcess.scss"

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { SmEntitInfo, PfEntitInfo, StkCodeInfo, AcCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;



interface DividendProcess02MainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function DividendProcess02Main({ gsb }: DividendProcess02MainProps) {

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    //let Stkno = ""
    let Stkno = utils.getUrlParameter('Stkno', url); 
    let { Stkno: defaultSearchValue = "" }: { Stkno: string } = useParams()
    let dProcess = utils.getUrlParameter('dProcess', url); 


    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    const history = useHistory()

    const [Data, setData] = useState([] as PfEntitInfo[])
    const [Data2, setData2] = useState([] as SmEntitInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkNo, setsearchStkNo] = useState("")
    const [showButton, setshowButton] = useState(true)
    const [showCalcButton, setshowCalcButton] = useState(true)
    const [ShowWait, setShowWait] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentPfentit, setcurrentPfentit] = useState({} as PfEntitInfo)
    const [BlankPfentit, setBlankPfentit] = useState({} as PfEntitInfo)
    const [currentSmentit, setcurrentSmentit] = useState({} as SmEntitInfo)

    const [currentMode, setcurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")
    const [dateFormat, setdateFormat] = useState("")


    BlankPfentit.d_StkNo = ""
    BlankPfentit.d_AnnouncementNo = ""
    BlankPfentit.d_acCode = ""

    console.log("DividendProcess02Main dProcess: ", dProcess)
    

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("DividendProcess02Main disp user_key: ", res.data.Flag.user_key)
                //console.log("DividendProcess02Main disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("DividendProcess02Main disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("DividendProcess02Main disp GsbFlag: ", GsbFlag)
                //console.log("DividendProcess02Main disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("DividendProcess02Main disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("DividendProcess02Main disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("DividendProcess02Main disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("DividendProcess02Main disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
                setdateFormat(GsbFlag.dateFormat);
 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    const refreshPfentitData = async () => {
        if ( qString.trim().length > 7 && selectedTabId == "list" ) {
            setshowButton(true)
            setShowWait(true)
            setData([])
            ;
            try {
                //console.log("SmMastMain onsubmit called", data)
                const res = await gsb.post('/Dividend',
                {   sb_data: {
                        stkNo: qString,
                        nPage: 1,
                        limit: 0,
                    },
                    cPgmId: "DividendProcess",
                    cAction: "AdjBookClosedList",
                });

                //console.log("PfEntit res.data.data: ", res.data.data, res.data.count) 
                //console.log("SmEntit res.data.data2: ", res.data.data2) 
                setData2(res.data.data2)
                setcurrentSmentit(res.data.data2[0])
                //console.log("currentSmentit: ", currentSmentit) 

                if ( res.data.count > 0 ) {
                    setData(res.data.data)

                    setshowButton(true)
                    setshowCalcButton(true)
                    setcurrentMode("show")

                } else {
                    setshowButton(false)
                    setshowCalcButton(false)
                    setcurrentMode("show")
                }
                setShowWait(false)
            } catch (error) {
                showError(error)
            }
            setShowWait(false)
        }
    }

    useEffect(() => {
        refreshPfentitData()
    }, [gsb, selectedTabId])



    const newOnSave = async (Announcement: PfEntitInfo) => {

    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
    }        


    const onChangeBCQty = (acCode: string, qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeBCQty acCode", acCode, qty);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode === acCode ) {
                    Data[j].d_Book_Closed_Qty = qty
                }
            }
            //setData(newData)
            //console.log("onChangeBCQty Data", Data);
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeLogRegister = (acCode: string, log: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeLogRegister acCode", acCode, log);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Log_to_Register = log
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeDividendAmount = (acCode: string, DivAmount: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeDividendAmount acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Dividend_Amount_full = DivAmount
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeCollectionFee = (acCode: string, fee: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeCollectionFee acCode", acCode, fee);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Dividend_Collection_Fee = fee
                    break
                }
            }
            //setData(newData)
                        setshowCalcButton(false)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeScrip_Fee = (acCode: string, fee: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeScrip_Fee acCode", acCode, fee);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Scrip_Fee = fee
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeHandling_Fee = (acCode: string, fee: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeHandling_Fee acCode", acCode, fee);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Dividend_Handling_Fee = fee
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeSelectDivtoBonusShare = (acCode: string) => {
        if (acCode.length > 0 ) {
            console.log("onChangeSelectDivtoBonusShare acCode", acCode);
            console.log("onChangeSelectDivtoBonusShare Data", Data);
            let newData = [...Data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_acCode == acCode ) {
                    if ( newData[j].d_Div_to_Bonus_Share_Option == "Y" ) {
                        newData[j].d_Div_to_Bonus_Share_Option = "N"
                    } else {
                        newData[j].d_Div_to_Bonus_Share_Option = "Y"
                    }
                    break
                }
            }
            setData(newData);
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeQtyforBonus = (acCode: string, qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeQtyforBonus acCode", acCode, qty);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Qty_Reserved_for_Div_to_Bonus = qty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeSelectDivCheque = (acCode: string) => {
        if (acCode.length > 0 ) {
            console.log("onChangeSelectDivCheque acCode", acCode);
            console.log("onChangeSelectDivCheque Data", Data);
            let newData = [...Data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_acCode == acCode ) {
                    if ( newData[j].d_Print_Div_Cheque_Option == "Y" ) {
                        newData[j].d_Print_Div_Cheque_Option = "N"
                    } else {
                        newData[j].d_Print_Div_Cheque_Option = "Y"
                    }
                    setshowCalcButton(false)
                    break
                }
            }
            setData(newData);
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onDivAdjSaveRec = () => {
        setShowWait(true)
        setshowButton(false)
        setshowCalcButton(false)
        ;

        console.log("onDivAdjSaveRec data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: Data, 
                    cPgmId: "DividendProcess",
                    cAction: "DividendAdjSave",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
                refreshPfentitData()
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setshowCalcButton(true)
    }

    const onCaclDividend = () => {
        setShowWait(true)
        setshowButton(false)
        setshowCalcButton(false)
        ;

        console.log("onCaclDividend data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: Data, 
                    cPgmId: "DividendProcess",
                    cAction: "CalcDividend",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
                refreshPfentitData()
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setshowButton(true)
        setshowCalcButton(false)
    }
    
    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

/*    
    // currentMode = show
    let detail = <PfentitEntryEdit gsb={gsb} currentPfentit={BlankPfentit} currentSmentit={currentSmentit} isNew onSave={newOnSave} onCancel={() => { setcurrentMode("show"); setSelectedTabId("list") } } ></PfentitEntryEdit>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            detail = <PfentitEntryEdit gsb={gsb} currentPfentit={BlankPfentit} currentSmentit={currentSmentit} isNew onSave={newOnSave} onCancel={() => { setcurrentMode("show"); setSelectedTabId("list") } } ></PfentitEntryEdit>
            break
        }
*/

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let SmEntitRec = <>
        {Data2.map((SmEntit, i) =>
            <div >
                <div >
                    <tbody >
                        <tr><td>Stock No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_StkNo} &nbsp; &nbsp; {SmEntit.d_StkCode}</td></tr>
                        <tr><td>Announcement No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_AnnouncementNo}</td></tr>
                        <tr><td>Summary :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary1}</td></tr>
                        <tr><td></td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary2}</td></tr>
                    </tbody>
                </div>
                <div className="Spacing-V-16" />
                <div >
                    Ex date: &nbsp; {utils.dispDate(SmEntit.d_Ex_date, dateFormat)} &nbsp;
                    Book Close Date: &nbsp; {utils.dispDate(SmEntit.d_BC_date, dateFormat)} &nbsp;
                    Payable date: &nbsp; {utils.dispDate(SmEntit.d_PY_date, dateFormat)} &nbsp;
                </div>
                { ( dProcess === "D" ) && <div >
                    Dividend per share: &nbsp; {SmEntit.d_Dividend_perShare} &nbsp;
                    Payin Currency: &nbsp; {SmEntit.d_Dividend_Currency} &nbsp;
                </div> }
                { ( dProcess === "D" ) && <div >
                    Convert Dividend into Bonus Share at Unit Price: &nbsp; {SmEntit.d_Dividend_Bonus_unitPrice} &nbsp;
                    &nbsp; Stock: &nbsp; {SmEntit.d_Dividend_Bonus_OhterStock} &nbsp;
                </div> }
                { ( dProcess === "WR" ) && <div >
                    <tbody >
                        <tr><td>Bonus :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Bonus_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Bonus_Base}&nbsp;</td><td> Entitled Stock :</td><td> &nbsp;{SmEntit.d_Bonus_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Bonus_StockNo_lot}&nbsp;</td></tr>
                        <tr><td>1st Warrant :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Warrant_1_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Warrant_1_Base}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Warrant_1_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Warrant_1_Lot}&nbsp;</td></tr>
                        <tr><td>2nd Warrant :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Warrant_2_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Warrant_2_Base}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Warrant_2_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Warrant_2_Lot}&nbsp;</td></tr>
                        <tr><td>Rights :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Rights_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Rights_Base}&nbsp;</td><td> Subscription Price :</td><td> &nbsp;{SmEntit.d_Rights_Price}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Rights_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Rights_Lot}&nbsp;</td></tr>
                        <tr><td>Split :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Split_Old}&nbsp;</td><td> into shares</td><td> &nbsp;{SmEntit.d_Split_New}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Split_combine_StockNo}&nbsp;</td></tr>
                        <tr><td>Consolidate :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_combine_Old}&nbsp;</td><td> into shares</td><td> &nbsp;{SmEntit.d_combine_New}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Split_combine_StockNo}&nbsp;</td></tr>
                    </tbody>
                </div> }
                <div >
                    { (SmEntit.d_Scrip_indicator === "Y" )
                    ? <tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td><td> &nbsp;{SmEntit.d_Scrip_Fee_Rate}&nbsp;</td></tr>
                    </tbody>
                    : <tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td></tr>
                    </tbody>
                    }
                </div>
            </div>
        )}
        <div className="Spacing-V-16" />
    </>


    let list = <>
        <div className="Row">
            <Button disabled={true} className="Col-1-1" icon="add" onClick={() => { setcurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
            <Button disabled={! showButton} className="Col-2-1" icon="confirm" onClick={() => { onDivAdjSaveRec() } }>Save</Button> 
            <Button disabled={! showCalcButton}className="Col-3-2" icon="calculator" onClick={() => { onCaclDividend() } }>Calculate Dividend</Button> 
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-oh_qty">On Hand Qty</th>
                <th className="TCol-Book_Closed_Qty">Book Closed Qty</th>
                <th className="TCol-Log_to_Register">Log to Register</th>
                <th className="TCol-Dividend_Amount">Dividend Amount</th>
                <th className="TCol-Dividend_Collection_Fee">Collection Fee</th>
                <th className="TCol-Scrip_Fee">Scrip Fee </th>
                <th className="TCol-Dividend_Handling_Fee">Handling Charges</th>
                <th className="TCol-Net_Dividend_Amount">Net Dividend Amount</th>
                <th className="TCol-Div_to_Bonus_Share_Option">Convert Div to Bonus</th>
                <th className="TCol-Qty_Reserved_for_Div_to_Bonus">Qty for Bonus</th>
                <th className="TCol-Print_Div_Cheque_Option">Div Cheque</th>
            </thead>
            <tbody id="ma_tr">
                {Data.map((PfEntit, i) =>
                    <tr key={i} onDoubleClick={() => { } }>
                        <td className="TCol-ACCode" onClick={() => { } }>{PfEntit.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { } }>{PfEntit.d_acName1}</td>
                        <td className="TCol-oh_qty" onClick={() => { } }>{PfEntit.d_oh_qty}</td>
                        <td className="TCol-Book_Closed_Qty" onClick={() => { } }>{PfEntit.d_Book_Closed_Qty}</td>
                        <td className="TCol-Log_to_Register" onClick={() => { } }>{PfEntit.d_Log_to_Register}</td>
                        <td className="TCol-Dividend_Amount" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputDividend_Amount_full" defaultValue={PfEntit.d_Dividend_Amount_full} onChange={utils.handleStringChange(s => onChangeDividendAmount(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-Dividend_Collection_Fee" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputDividend_Collection_Fee" defaultValue={PfEntit.d_Dividend_Collection_Fee} onChange={utils.handleStringChange(s => onChangeCollectionFee(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-Scrip_Fee" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputScrip_Fee" defaultValue={PfEntit.d_Scrip_Fee} onChange={utils.handleStringChange(s => onChangeScrip_Fee(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-Dividend_Handling_Fee" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputDividend_Handling_Fee" defaultValue={PfEntit.d_Dividend_Handling_Fee} onChange={utils.handleStringChange(s => onChangeHandling_Fee(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-Net_Dividend_Amount" onClick={() => { } }>{utils.formatNumber2(PfEntit.d_Net_Dividend_Amount)}</td>
                        <td className="TCol-Div_to_Bonus_Share_Option"><Checkbox checked={PfEntit.d_Div_to_Bonus_Share_Option=="Y"} onChange={() => onChangeSelectDivtoBonusShare(PfEntit.d_acCode)} /></td>
                        <td className="TCol-Qty_Reserved_for_Div_to_Bonus" onClick={() => { } }><InputGroup className="inputNumberNoSpin" disabled={! (PfEntit.d_Div_to_Bonus_Share_Option=="Y")} id="inputBQtyforBonus" defaultValue={PfEntit.d_Qty_Reserved_for_Div_to_Bonus} onChange={utils.handleStringChange(s => onChangeQtyforBonus(PfEntit.d_acCode, s ))} /></td>
                        {/* PfEntit.isMarginClient && <td className="TCol-Print_Div_Cheque_Option"><Checkbox checked={PfEntit.d_Print_Div_Cheque_Option=="Y"} onChange={() => onChangeSelectDivCheque(PfEntit.d_acCode)} /></td> */}
                        <td className="TCol-Print_Div_Cheque_Option"><Checkbox checked={PfEntit.d_Print_Div_Cheque_Option=="Y"} onChange={() => onChangeSelectDivCheque(PfEntit.d_acCode)} /></td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>94 Dividend Process, 
                        Handling Charges & Margin Client Cheque Option &nbsp; &nbsp; &nbsp;</H3>
                        <Button icon="arrow-left" onClick={() => history.goBack()}>Back</Button>
                    </div>
                    <div className="Spacing-V-16" />
                    {SmEntitRec}
                    <div className="Spacing-V-16" />
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        {/* <Tab id="detail" title="New" panel={detail} /> */}
                    </Tabs>
                    <p>Notes</p>
                    <div>
                        <div>
                            Calculate Dividend
                        </div>
                        <div>
                            - The Dividend Amount, Collection Fee and Scrip Fee will NOT be Re-Calculate
                            after amended
                        </div>
                        <div>
                            - The Handling Charges will NOT be Re-Calculate
                        </div>
                    </div >

                </Card>
            </form>
        </>
    );
}

export default DividendProcess02Main