import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { BrokerTranInfo, StkCodeInfo, CurrencyInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface BrokerTranEntryViewProps {
    gsb: AxiosInstance,
    BrokerTran: BrokerTranInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}


function BrokerTranEntryView({ gsb, BrokerTran = {} as BrokerTranInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: BrokerTranEntryViewProps) {

    const { t, i18n } = useTranslation();    
    
    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)


    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)



    if (! ("d_Seq" in BrokerTran)) return null


    return (
        <div>
            {/* define add,change,delete button */}
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No." labelInfo="*">
                    <InputGroup readOnly value={utils.formatNumber2(BrokerTran.d_Seq)} />
                </FormGroup>
                <FormGroup className="Col3-2-1" label="Trade Type" labelInfo="*">
                    <InputGroup readOnly value={BrokerTran.d_Trade_type} />
                </FormGroup>
                <FormGroup className="Col-5-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup readOnly value={BrokerTran.d_Input_User} />
                </FormGroup>
                <FormGroup className="Col-6-1" label="Action" labelFor="inputAction">
                    <InputGroup readOnly value={BrokerTran.d_Action} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                    <InputGroup readOnly value={BrokerTran.d_StkNo} />
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{BrokerTran.d_StkCode}</div>
                    <div id="DispText"></div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <RadioGroup
                        name="d_CcassStk"
                        disabled={true}
                        inline={true}
                        selectedValue={BrokerTran.d_CcassStk}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-10-2" label="Market">
                    <div>
                    <div id="DispText">{BrokerTran.d_Market}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Price" labelFor="inputTrade_Price">
                    <InputGroup readOnly value={utils.formatNumber2(BrokerTran.d_Trade_Price)} />
                </FormGroup>
                <FormGroup className="Col-2-2" label="Quantity" labelFor="inputTrade_Qty">
                    <InputGroup readOnly value={utils.formatNumber2(BrokerTran.d_Trade_Qty)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Broker No." labelFor="inputBkCode" labelInfo="*">
                    <InputGroup readOnly value={BrokerTran.d_BkrNo} />
                </FormGroup>
                <FormGroup className="Col-2-5" label="Broker Name" labelFor="inputBkName">
                    <div>
                    <div id="DispText">{BrokerTran.d_bkName1}</div>
                    <div id="DispText"></div>
                    </div>
                </FormGroup>
                </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Interest Accrual" labelFor="inputInterest_Accrual">
                    <InputGroup readOnly value={utils.formatNumber2(BrokerTran.d_Interest_Accrual)} />
                </FormGroup>
            </div>

        </div>
    )
}

interface BrokerTranEntryEditProps {
    gsb: AxiosInstance,
    BrokerTran?: BrokerTranInfo,
    isNew?: boolean,
    onSave?: (stk: BrokerTranInfo) => void,
    onCancel?: () => void;
}

function BrokerTranEntryEdit({ gsb, BrokerTran = {} as BrokerTranInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: BrokerTranEntryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<BrokerTranInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)


    const [BkrCodeValue, setBkrCodeValue] = useState(BrokerTran.d_BkrNo)
    const [BkrName1Value, setBkrName1Value] = useState(BrokerTran.d_bkName1)
    const [BkrName2Value, setBkrName2Value] = useState(BrokerTran.d_bkName2)
    const [StkNoValue, setStkNoValue] = useState(BrokerTran.d_StkNo)
    const [Caretdown2, setCaretdown2] = useState(false)
    const [StkCodeList, setStkCodeList] = useState([] as StkCodeInfo[])
    const [StkCodeValue, setStkCodeValue] = useState(BrokerTran.d_StkCode)
    const [StkNameValue, setStkNameValue] = useState("")
    const [CCASSStockValue, setCCASSStockValue] = useState(BrokerTran.d_CcassStk)
    const [MarketValue, setMarketValue] = useState(BrokerTran.d_Market)
    const [FeeCodeValue, setFeeCodeValue] = useState(BrokerTran.d_FeeCode)
    const [CurrencyValue, setCurrencyValue] = useState(BrokerTran.d_Currency)
    const [QtyValue, setQtyValue] = useState(utils.numToString(BrokerTran.d_Trade_Qty))
    const [PriceValue, setPriceValue] = useState(utils.numToString(BrokerTran.d_Trade_Price))


    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)


    const [TradeTypeValue, setTradeTypeValue] = useState(BrokerTran.d_Trade_type)
    


    useEffect(() => {
        // Call reset to get the values of record BrokerTranInfo 
        if ("d_Seq" in BrokerTran) {
            reset(BrokerTran)
        }
    }, [BrokerTran, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Channel" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    
    
/*    
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const dataIP = await response.json();
                setIPAddr(dataIP["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })() 
    }, [gsb])
*/    

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, BkrCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Bkmast", cAction: "GetBkrInfo", cBkrCode: BkrCodeValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( BkrCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    //setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    //setLatestQtyValue(res.data.data[0].acbal_LatestBal) 
                    setBkrName1Value(res.data.data[0].d_bkName1)
                    setBkrName2Value(res.data.data[0].d_bkName2)

                } else { 
                    setBkrName1Value("")
                    setBkrName2Value("")
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, BkrCodeValue])


    useEffect(() => {
        (async () => {
            try {
                // get AC List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "Broker",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false,
                    SortByName: true
                },
                cPgmId: "Bkmast",
                cAction: "ListRec",
            });

                //console.log("res.data.data: ", res.data.data) 
                //setAcCodeList(res.data.data)
                //console.log("set AcCodeList: ", AcCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, BkrCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNoValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNoValue.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCodeValue(res.data.data[0].d_StkCode)
                    setStkNameValue(res.data.data[0].d_StkName)
                    setCCASSStockValue(res.data.data[0].d_CcassStk)
                    setMarketValue(res.data.data[0].d_Market)
                    setFeeCodeValue(res.data.data[0].d_FeeCode)
                    setCurrencyValue(res.data.data[0].d_Currency)

                } else { 
                    setStkCodeValue("")
                    setStkNameValue("")
                    setCCASSStockValue("N")
                    setMarketValue("")
                    setFeeCodeValue("")
                    setCurrencyValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNoValue])


    useEffect(() => {
        (async () => {
            try {
                // get Stock Code List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "StkCodeList"})
                //console.log("StkCodeList: ", res.data.data) 
                setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    if (! isNew && ! ("d_Seq" in BrokerTran)) return null


     
    const onSubmit = (data: BrokerTranInfo) => {

        //console.log("BrokerTranEntryedit onsubmit data: ", data)
        //console.log("BrokerTranEntryedit onsubmit StkPrice: ", StkPrice)
        //console.log("BrokerTranEntryedit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        // put value to json/field before write database dbf
        if ( typeof(data.d_Seq) === "undefined") {
            data.d_Seq = 0
        }

        data.d_Trade_type = TradeTypeValue;
        data.d_Trade_type = data.d_Trade_type.toUpperCase();
//console.log("BrokerTranEntryedit onsubmit data.d_Tran_type: ", data.d_Tran_type)

        data.d_BkrNo = BkrCodeValue;
        if ( typeof(data.d_BkrNo) === "undefined" ) {
            data.d_BkrNo = ""
        }
        data.d_BkrNo = data.d_BkrNo.toUpperCase();
        data.d_bkName1 = BkrName1Value
        data.d_bkName2 = BkrName2Value

        data.d_StkNo = StkNoValue ;
        data.d_StkCode = StkCodeValue;
        data.d_CcassStk = CCASSStockValue
        data.d_Market = MarketValue;
        data.d_FeeCode = FeeCodeValue;
        data.d_Currency = CurrencyValue;
    
       
        data.d_Trade_Qty = utils.StringToNum(QtyValue);
        data.d_Trade_Price = utils.StringToNum(PriceValue);


        (async () => {
            try {
                console.log("call BrokerTranEntryedit data", data)
                //console.log("call BrokerTranEntryedit isNew", isNew)
                const res = await gsb.post('/Transaction', {sb_data: data, cPgmId: "BrokerTranEntry", cAction: "BrokerTranValidate", isNew: isNew})
                //console.log("BrokerTranEntryedit res.data.ok", res.data.ok)
                //console.log("BrokerTranEntryedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }


   

    const StkRenderer: ItemRenderer<StkCodeInfo> = (stk, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.BkrName1}`;
        const text = `${stk.d_StkCode}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={stk.d_StkNo}
                key={stk.d_StkNo}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StkPredicate: ItemPredicate<StkCodeInfo> = (query, stk, _index, exactMatch) => {
        const normalizedText = `${stk.d_StkNo} - ${stk.d_StkCode}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StkValueRenderer = (stk: StkCodeInfo) => {
        return stk.d_StkNo
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }





    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }

             </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No." labelFor="inputSeqNo" labelInfo="*">
                    <InputGroup className="inputNumberNoSpin" disabled={true} id="inputSeqNo" name="d_Seq" autoFocus={isNew} defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Seq && errors.d_Seq.type === "required" && (<span id="Err_msg">Seq No. cannot be Blank</span>)}
                    {errors.d_Seq && errors.d_Seq.types && <span id="Err_msg">{errors.d_Seq.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col3-2-1" label="Trade Type" labelFor="inputTradeType" labelInfo="*">
                    {/*
                    <InputGroup id="inputTranType" name="d_Tran_type" autoFocus={! isNew} defaultValue="" placeholder="Tran Type ..." inputRef={register({ required: true , maxLength: 1 }) } />
                    {errors.d_Tran_type && errors.d_Tran_type.type === "required" && <span id="Err_msg">Tran Type cannot be Blank</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.types && <span id="Err_msg">{errors.d_Tran_type.types.message}</span>}
                    */}
                    <select  id="inputTradeType"  
                        value={TradeTypeValue}
                        autoFocus
                        onChange={utils.handleStringChange(s => setTradeTypeValue(s)) }
                    >
                        <option value="B">B: Buy</option>
                        <option value="S">S: Sell</option>
                    </select>
                </FormGroup>
                <FormGroup className="Col-5-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup disabled={true} id="inputInputUser" name="d_Input_User" defaultValue="" placeholder="Input User ..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_Input_User && errors.d_Input_User.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_Input_User && errors.d_Input_User.types && <span id="Err_msg">{errors.d_Input_User.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-6-1" label="Action" labelFor="inputAction">
                    <InputGroup disabled={true} id="inputAction" name="d_Action" defaultValue="" placeholder="Action ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_Action && errors.d_Action.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Action && errors.d_Action.types && <span id="Err_msg">{errors.d_Action.types.message}</span>}
                </FormGroup>
             </div>
            <div className="Row">
                { ! Caretdown2 && 
                    <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                        <InputGroup id="inputStkNo" value={StkNoValue} defaultValue="" placeholder="Stock No. ..." onChange={utils.handleStringChange(s => setStkNoValue(s)) } inputRef={register({ required: true , maxLength: 6}) } />
                        {errors.d_StkNo && errors.d_StkNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                        {errors.d_StkNo && errors.d_StkNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                        {errors.d_StkNo && errors.d_StkNo.types && <span id="Err_msg">{errors.d_StkNo.types.message}</span>}
                    </FormGroup>
                }
                { Caretdown2 && 
                    <FormGroup className="Col3-1-1" label="Stock No." labelFor="inputStkNo" >
                        <Suggest
                            inputProps={{ placeholder: "Please choose Stock No. ..." }}
                            defaultSelectedItem={StkCodeList.find((stk1) => stk1.d_StkNo === StkNoValue)}
                            items={StkCodeList}
                            itemRenderer={StkRenderer}
                            itemPredicate={StkPredicate}
                            inputValueRenderer={StkValueRenderer}
                            onItemSelect={(Stk) => { setStkNoValue(Stk.d_StkNo) ; setStkCodeValue(Stk.d_StkCode) ; setStkNameValue(Stk.d_StkName) ; setCaretdown2(!Caretdown2) }}
                        />
                    </FormGroup>
                }
                {/* <span onClick={() => { setCaretdown2(!Caretdown2) } }> {imgCaretdown} </span> */}
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCodeValue}</div>
                    <div id="DispText">{StkNameValue}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <RadioGroup
                        name="d_CcassStk"
                        disabled={true}
                        inline={true}
                        selectedValue={CCASSStockValue}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
                <FormGroup className="Col-10-2" label="Market">
                    <div>
                    <div id="DispText">{MarketValue}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Price" labelFor="inputTrade_Price">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Price"  defaultValue="0" value={PriceValue} onValueChange={(n => setPriceValue(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputTrade_Price" defaultValue={PriceValue} value={PriceValue} type="number" onChange={utils.handleStringChange(n => setPriceValue(n))}  />
                    {errors.d_Trade_Price && errors.d_Trade_Price.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                    {errors.d_Trade_Price && errors.d_Trade_Price.types && <span id="Err_msg">{errors.d_Trade_Price.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Quantity" labelFor="inputTrade_Qty">
                    {/* <NumericInput className="inputNumberNoSpin" id="inputTrade_Qty"  defaultValue="0" value={QtyValue} onValueChange={(n => setQtyValue(n))} type="number" buttonPosition="none" inputRef={register} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputTrade_Qty" defaultValue={QtyValue} value={QtyValue} type="number" onChange={utils.handleStringChange(n => setQtyValue(n))}  />
                    {errors.d_Trade_Qty && errors.d_Trade_Qty.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_Trade_Qty && errors.d_Trade_Qty.types && <span id="Err_msg">{errors.d_Trade_Qty.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Broker No." labelFor="inputBkCode" labelInfo="*">
                    <InputGroup id="inputBkCode" value={BkrCodeValue} defaultValue="" placeholder="Broker No. ..." onChange={utils.handleStringChange(s => setBkrCodeValue(s)) } inputRef={register({ required: true , maxLength: 4}) } />
                        {errors.d_BkrNo && errors.d_BkrNo.type === "required" && <span id="Err_msg">Broker No. cannot be Blank</span>}
                        {errors.d_BkrNo && errors.d_BkrNo.type === "maxLength" && <span id="Err_msg">Max length is 4</span>}
                        {errors.d_BkrNo && errors.d_BkrNo.types && <span id="Err_msg">{errors.d_BkrNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-5" label="Broker Name" labelFor="inputBkName">
                    <div>
                    <div id="DispText">{BkrName1Value}</div>
                    <div id="DispText">{BkrName2Value}</div>
                    </div>
                </FormGroup>
                </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Interest Accrual" labelFor="inputInterest_Accrual">
                    <InputGroup className="inputNumberNoSpin" id="inputInterest_Accrual" name="d_Interest_Accrual" defaultValue="0" type="number" inputRef={register({min: 0})} />
                    {errors.d_Interest_Accrual && errors.d_Interest_Accrual.type === "min" && <span id="Err_msg">Interest Accrual sholud be greater than 0 </span>}
                    {errors.d_Interest_Accrual && errors.d_Interest_Accrual.types && <span id="Err_msg">{errors.d_Interest_Accrual.types.message}</span>}
                </FormGroup>
            </div>


        </form>
    )
}

interface BrokerTranEntryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function BrokerTranEntryMain({ gsb }: BrokerTranEntryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    const [data, setData] = useState([] as BrokerTranInfo[])

    const [searchKey, setSearchKey] = useState("SeqNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkNo, setsearchStkNo] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentBrokerTran, setcurrentBrokerTran] = useState({} as BrokerTranInfo)
    const [BlankBrokerTran, setBlankBrokerTran] = useState({} as BrokerTranInfo)

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [showChannel, setshowChannel] = useState(false)

    //gsb default value
    //BlankBrokerTran.d_Currency = "HKD"   //no work
    BlankBrokerTran.d_BkrNo = ""
    BlankBrokerTran.d_Trade_type = "B"
    BlankBrokerTran.d_StkNo = ""
    BlankBrokerTran.d_Trade_Qty = 0
    BlankBrokerTran.d_Trade_Price = 0


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("BrokerTranEntryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("BrokerTranEntryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("BrokerTranEntryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("BrokerTranEntryMain disp GsbFlag: ", GsbFlag)
                //console.log("BrokerTranEntryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("BrokerTranEntryMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("BrokerTranEntryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("BrokerTranEntryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("BrokerTranEntryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])




    const PrevRecProcess = async (BrokerTran: BrokerTranInfo) => {
        try {
            //console.log("call PrevRecProcess", BrokerTran)
            const res = await gsb.post('/Transaction', {sb_data: BrokerTran, cPgmId: "BrokerTranEntry", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_Seq)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentBrokerTran(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (BrokerTran: BrokerTranInfo) => {
        try {
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Transaction', {sb_data: BrokerTran, cPgmId: "BrokerTranEntry", cAction: "DelRec"})
            showSuccess("Seq No. " + BrokerTran.d_Seq+BrokerTran.d_SeqC + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentBrokerTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (BrokerTran: BrokerTranInfo) => {
        try {
            //console.log("call NextRecProcess", BrokerTran)
            const res = await gsb.post('/Transaction', {sb_data: BrokerTran, cPgmId: "BrokerTranEntry", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentBrokerTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (BrokerTran: BrokerTranInfo) => {
        try {
            //console.log("call newOnSave", BrokerTran)
            const res = await gsb.post('/Transaction', {sb_data: BrokerTran, cPgmId: "BrokerTranEntry", cAction: "AddRec"})
            //showSuccess("Seq No. " + BrokerTran.d_Seq + " record added")
            showSuccess("Seq No. " + res.data.data.d_Seq+BrokerTran.d_SeqC + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentBrokerTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (BrokerTran: BrokerTranInfo) => {
        try {
            //console.log("call editOnSave", BrokerTran)
            //console.log("stk.d_CcassStk: ", stk.d_CcassStk)
            const res = await gsb.post('/Transaction', {sb_data: BrokerTran, cPgmId: "BrokerTranEntry", cAction: "UpdateRec"})
            showSuccess("Seq No. " + BrokerTran.d_Seq+BrokerTran.d_SeqC + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    //console.log("editOnSave data", i, data)
                    setData(data)
                    //setCurrentStk(stk)
                    break
                }
            }
            //console.log("editOnSave stk", stk)
            //console.log("editOnSave res.data.data", res.data.data)
            setcurrentBrokerTran(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get BrokerTranEntry list if StkNo have value
        try {
            //console.log("BrokerTranEntryMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            StkNo: searchStkNo,
                        }, 
                    },
                    cPgmId: "BrokerTranEntry",
                    cAction: "ListRec",
                });

                //console.log("onSubmit res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentBrokerTran(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        d_Seq: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "BrokerTranEntry",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setcurrentBrokerTran(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one BrokerTranEntry record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("BrokerTranEntryMain useEffect length > 0  called", data)
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "BrokerTranEntry",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentBrokerTran(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                StkNo: searchStkNo,
                            }, 
                        },
                        cPgmId: "BrokerTranEntry",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentBrokerTran(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <BrokerTranEntryView gsb={gsb} BrokerTran={currentBrokerTran} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentBrokerTran)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentBrokerTran)} onNextRec={() => NextRecProcess(currentBrokerTran)} ></BrokerTranEntryView>
    switch (currentMode) {
        //case "PrevRec":
        // ?? will call 2 times ??
        //    console.log("currentMode = PrevRec")
        //    PrevRecProcess(currentStk)
        //    break
        case "new":
            //console.log("currentMode = new")
            detail = <BrokerTranEntryEdit gsb={gsb} BrokerTran={BlankBrokerTran} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></BrokerTranEntryEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <BrokerTranEntryEdit gsb={gsb} BrokerTran={currentBrokerTran} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></BrokerTranEntryEdit>
            break
        //case "delete":
        //    console.log("currentMode = delete")
        //    DeleteProcess(currentStk)
        //    break
        }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-SeqNo">Seq No</th>
                <th className="TCol-TradeType">Trade Type</th>
                <th className="TCol-BkrNo">Broker No.</th>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-StkCode">Stock Code</th>
                <th className="TCol-Qty">Qty</th>
                <th className="TCol-Price">Price</th>
                <th className="TCol-Interest_Accrual">Interest Accrual</th>
                <th className="TCol-Action">Action</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((BrokerTran, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-SeqNo" onClick={() => { setcurrentBrokerTran(BrokerTran) ; MoblieDev && setSelectedTabId("detail") }}>{BrokerTran.d_Seq}</td>
                        <td className="TCol-TradeType" onClick={() => { setcurrentBrokerTran(BrokerTran) ; MoblieDev && setSelectedTabId("detail") }}>{BrokerTran.d_Trade_type}</td>
                        <td className="TCol-BkrNo" onClick={() => { setcurrentBrokerTran(BrokerTran) ; MoblieDev && setSelectedTabId("detail") }}>{BrokerTran.d_BkrNo}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentBrokerTran(BrokerTran) ; MoblieDev && setSelectedTabId("detail") }}>{BrokerTran.d_StkNo}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentBrokerTran(BrokerTran) ; MoblieDev && setSelectedTabId("detail") }}>{BrokerTran.d_StkCode}</td>
                        <td className="TCol-Qty" onClick={() => { setcurrentBrokerTran(BrokerTran) }}>{BrokerTran.d_Trade_Qty}</td>
                        <td className="TCol-Price" onClick={() => { setcurrentBrokerTran(BrokerTran) }}>{BrokerTran.d_Trade_Price}</td>
                        <td className="TCol-Interest_Accrual" onClick={() => { setcurrentBrokerTran(BrokerTran) }}>{BrokerTran.d_Interest_Accrual}</td>
                        <td className="TCol-Action" onClick={() => { setcurrentBrokerTran(BrokerTran) }}>{BrokerTran.d_Action}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Broker Transaction Entry</H3>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for Broker Transaction " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="SeqNo">Seq No</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                        <Button className="Col-9-1 Button-LabeledFormGroup" icon="document" onClick={() => { window.open(`/#/BrokerTranCheckList`, '_blank') } } >Check List</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-1" label="Stock No." labelFor="searchStkNo">
                                <InputGroup id="searchStkNo" value={searchStkNo} onChange={utils.handleStringChange(s => setsearchStkNo(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>*/}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default BrokerTranEntryMain