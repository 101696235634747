import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { Checkbox } from "@blueprintjs/core";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useHistory } from "react-router-dom";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";
import "./WarrantRightsProcess.scss"

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { SmEntitInfo, PfEntitInfo, StkCodeInfo, AcCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

interface CalcWRRtnProps {
    gsb: AxiosInstance,
    currentSmentit?: SmEntitInfo,
    onCalcWRProcess?: (currentSmentit: SmEntitInfo) => void,
    onCancel?: () => void;
}

function CalcWRRtn({ gsb, currentSmentit = {} as SmEntitInfo, onCalcWRProcess = () => { }, onCancel = () => { } }: CalcWRRtnProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<SmEntitInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [Enter2tab, setEnter2tab] = useState("Y")

    const [IPAddr, setIPAddr] = useState("")

    
    const [notshow_B_ISSUE, setnotshow_B_ISSUE] = useState(currentSmentit.d_Bonus_Unit == "0"  || currentSmentit.d_Bonus_Base == "0")
    const [StkNo_b_Value, setStkNo_b_Value] = useState(currentSmentit.d_Bonus_StockNo)
    const [StkCodeList, setStkCodeList] = useState([] as StkCodeInfo[])
    const [StkCode_b_Value, setStkCode_b_Value] = useState("")
    const [StkName_b_Value, setStkName_b_Value] = useState("")
    const [CCASSStock_b_Value, setCCASSStock_b_Value] = useState(false)
    const [StampFlag_b_Value, setStampFlag_b_Value] = useState(false)
    const [Market_b_Value, setMarket_b_Value] = useState("")
    const [Currency_b_Value, setCurrency_b_Value] = useState("")
    const [Bonus_StockNo_lotValue, setBonus_StockNo_lotValue] = useState(currentSmentit.d_Bonus_StockNo_lot)

    const [notshow_W1_ISSUE, setnotshow_W1_ISSUE] = useState(currentSmentit.d_Warrant_1_Unit == "0"  || currentSmentit.d_Warrant_1_Base == "0")
    const [StkNo_w1_Value, setStkNo_w1_Value] = useState(currentSmentit.d_Warrant_1_StockNo)
    const [StkCode_w1_Value, setStkCode_w1_Value] = useState("")
    const [StkName_w1_Value, setStkName_w1_Value] = useState("")
    const [CCASSStock_w1_Value, setCCASSStock_w1_Value] = useState(false)
    const [StampFlag_w1_Value, setStampFlag_w1_Value] = useState(false)
    const [Market_w1_Value, setMarket_w1_Value] = useState("")
    const [Currency_w1_Value, setCurrency_w1_Value] = useState("")
    const [Warrant_1_LotValue, setWarrant_1_LotValue] = useState(currentSmentit.d_Warrant_1_Lot)

    const [notshow_W2_ISSUE, setnotshow_W2_ISSUE] = useState(currentSmentit.d_Warrant_2_Unit == "0"  || currentSmentit.d_Warrant_2_Base == "0")
    const [StkNo_w2_Value, setStkNo_w2_Value] = useState(currentSmentit.d_Warrant_2_StockNo)
    const [StkCode_w2_Value, setStkCode_w2_Value] = useState("")
    const [StkName_w2_Value, setStkName_w2_Value] = useState("")
    const [CCASSStock_w2_Value, setCCASSStock_w2_Value] = useState(false)
    const [StampFlag_w2_Value, setStampFlag_w2_Value] = useState(false)
    const [Market_w2_Value, setMarket_w2_Value] = useState("")
    const [Currency_w2_Value, setCurrency_w2_Value] = useState("")
    const [Warrant_2_LotValue, setWarrant_2_LotValue] = useState(currentSmentit.d_Warrant_2_Lot)

    const [notshow_R_ISSUE, setnotshow_R_ISSUE] = useState(currentSmentit.d_Rights_Unit == "0"  || currentSmentit.d_Rights_Base == "0")
    const [StkNo_r_Value, setStkNo_r_Value] = useState(currentSmentit.d_Rights_StockNo)
    const [StkCode_r_Value, setStkCode_r_Value] = useState("")
    const [StkName_r_Value, setStkName_r_Value] = useState("")
    const [CCASSStock_r_Value, setCCASSStock_r_Value] = useState(false)
    const [StampFlag_r_Value, setStampFlag_r_Value] = useState(false)
    const [Market_r_Value, setMarket_r_Value] = useState("")
    const [Currency_r_Value, setCurrency_r_Value] = useState("")
    const [Rights_LotValue, setRights_LotValue] = useState(currentSmentit.d_Rights_Lot)

    const [notshow_S_ISSUE, setnotshow_S_ISSUE] = useState(currentSmentit.d_Split_Old == "0"  || currentSmentit.d_Split_New == "0")
    const [notshow_C_ISSUE, setnotshow_C_ISSUE] = useState(currentSmentit.d_combine_Old == "0"  || currentSmentit.d_combine_New == "0")
    const [StkNo_sc_Value, setStkNo_sc_Value] = useState(currentSmentit.d_Split_combine_StockNo)
    const [StkCode_sc_Value, setStkCode_sc_Value] = useState("")
    const [StkName_sc_Value, setStkName_sc_Value] = useState("")
    const [CCASSStock_sc_Value, setCCASSStock_sc_Value] = useState(false)
    const [StampFlag_sc_Value, setStampFlag_sc_Value] = useState(false)
    const [Market_sc_Value, setMarket_sc_Value] = useState("")
    const [Currency_sc_Value, setCurrency_sc_Value] = useState("")


    useEffect(() => {
        // Call reset to get the values of record PfEntitInfo 
        if ("d_StkNo" in currentSmentit) {
            reset(currentSmentit)
        }
    }, [currentSmentit, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Dividend_Currency" })
    }, [register])


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                setEnter2tab(GsbFlag.Enter2tab)               

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, currentSmentit, GsbFlag])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [gsb, currentSmentit, GsbFlag, Enter2tab])

    //console.log("CalcWRRtn StkNo_b_Value: ", StkNo_b_Value, currentSmentit.d_Bonus_StockNo) 
    //console.log("CalcWRRtn StkNo_w1_Value: ", StkNo_w1_Value, currentSmentit.d_Warrant_1_StockNo) 
    
    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: currentSmentit.d_Bonus_StockNo})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( currentSmentit.d_Bonus_StockNo.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_b_Value(res.data.data[0].d_StkCode)
                    setStkName_b_Value(res.data.data[0].d_StkName)
                    setCCASSStock_b_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_b_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_b_Value(res.data.data[0].d_Market)
                    setCurrency_b_Value(res.data.data[0].d_Currency)
                    setBonus_StockNo_lotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_b_Value("")
                    setStkName_b_Value("")
                    setCCASSStock_b_Value(false)
                    setStampFlag_b_Value(false)
                    setMarket_b_Value("")
                    setCurrency_b_Value("")
                    setBonus_StockNo_lotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, currentSmentit.d_Bonus_StockNo])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNo_b_Value})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNo_b_Value.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_b_Value(res.data.data[0].d_StkCode)
                    setStkName_b_Value(res.data.data[0].d_StkName)
                    setCCASSStock_b_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_b_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_b_Value(res.data.data[0].d_Market)
                    setCurrency_b_Value(res.data.data[0].d_Currency)
                    setBonus_StockNo_lotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_b_Value("")
                    setStkName_b_Value("")
                    setCCASSStock_b_Value(false)
                    setStampFlag_b_Value(false)
                    setMarket_b_Value("")
                    setCurrency_b_Value("")
                    setBonus_StockNo_lotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNo_b_Value])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: currentSmentit.d_Warrant_1_StockNo})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( currentSmentit.d_Warrant_1_StockNo.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_w1_Value(res.data.data[0].d_StkCode)
                    setStkName_w1_Value(res.data.data[0].d_StkName)
                    setCCASSStock_w1_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_w1_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_w1_Value(res.data.data[0].d_Market)
                    setCurrency_w1_Value(res.data.data[0].d_Currency)
                    setWarrant_1_LotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_w1_Value("")
                    setStkName_w1_Value("")
                    setCCASSStock_w1_Value(false)
                    setStampFlag_w1_Value(false)
                    setMarket_w1_Value("")
                    setCurrency_w1_Value("")
                    setWarrant_1_LotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, currentSmentit.d_Warrant_1_StockNo])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNo_w1_Value})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNo_w1_Value.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_w1_Value(res.data.data[0].d_StkCode)
                    setStkName_w1_Value(res.data.data[0].d_StkName)
                    setCCASSStock_w1_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_w1_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_w1_Value(res.data.data[0].d_Market)
                    setCurrency_w1_Value(res.data.data[0].d_Currency)
                    setWarrant_1_LotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_w1_Value("")
                    setStkName_w1_Value("")
                    setCCASSStock_w1_Value(false)
                    setStampFlag_w1_Value(false)
                    setMarket_w1_Value("")
                    setCurrency_w1_Value("")
                    setWarrant_1_LotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNo_w1_Value])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: currentSmentit.d_Warrant_2_StockNo})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( currentSmentit.d_Warrant_2_StockNo.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_w2_Value(res.data.data[0].d_StkCode)
                    setStkName_w2_Value(res.data.data[0].d_StkName)
                    setCCASSStock_w2_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_w2_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_w2_Value(res.data.data[0].d_Market)
                    setCurrency_w2_Value(res.data.data[0].d_Currency)
                    setWarrant_2_LotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_w2_Value("")
                    setStkName_w2_Value("")
                    setCCASSStock_w2_Value(false)
                    setStampFlag_w2_Value(false)
                    setMarket_w2_Value("")
                    setCurrency_w2_Value("")
                    setWarrant_2_LotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, currentSmentit.d_Warrant_2_StockNo])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNo_w2_Value})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNo_w2_Value.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_w2_Value(res.data.data[0].d_StkCode)
                    setStkName_w2_Value(res.data.data[0].d_StkName)
                    setCCASSStock_w2_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_w2_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_w2_Value(res.data.data[0].d_Market)
                    setCurrency_w2_Value(res.data.data[0].d_Currency)
                    setWarrant_2_LotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_w2_Value("")
                    setStkName_w2_Value("")
                    setCCASSStock_w2_Value(false)
                    setStampFlag_w2_Value(false)
                    setMarket_w2_Value("")
                    setCurrency_w2_Value("")
                    setWarrant_2_LotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNo_w2_Value])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: currentSmentit.d_Rights_StockNo})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( currentSmentit.d_Rights_StockNo.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_r_Value(res.data.data[0].d_StkCode)
                    setStkName_r_Value(res.data.data[0].d_StkName)
                    setCCASSStock_r_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_r_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_r_Value(res.data.data[0].d_Market)
                    setCurrency_r_Value(res.data.data[0].d_Currency)
                    setRights_LotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_r_Value("")
                    setStkName_r_Value("")
                    setCCASSStock_r_Value(false)
                    setStampFlag_r_Value(false)
                    setMarket_r_Value("")
                    setCurrency_r_Value("")
                    setRights_LotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, currentSmentit.d_Rights_StockNo])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNo_r_Value})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNo_r_Value.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_r_Value(res.data.data[0].d_StkCode)
                    setStkName_r_Value(res.data.data[0].d_StkName)
                    setCCASSStock_r_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_r_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_r_Value(res.data.data[0].d_Market)
                    setCurrency_r_Value(res.data.data[0].d_Currency)
                    setRights_LotValue(res.data.data[0].d_LotSize)
                } else { 
                    setStkCode_r_Value("")
                    setStkName_r_Value("")
                    setCCASSStock_r_Value(false)
                    setStampFlag_r_Value(false)
                    setMarket_r_Value("")
                    setCurrency_r_Value("")
                    setRights_LotValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNo_r_Value])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: currentSmentit.d_Split_combine_StockNo})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( currentSmentit.d_Split_combine_StockNo.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_sc_Value(res.data.data[0].d_StkCode)
                    setStkName_sc_Value(res.data.data[0].d_StkName)
                    setCCASSStock_sc_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_sc_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_sc_Value(res.data.data[0].d_Market)
                    setCurrency_sc_Value(res.data.data[0].d_Currency)

                } else { 
                    setStkCode_sc_Value("")
                    setStkName_sc_Value("")
                    setCCASSStock_sc_Value(false)
                    setStampFlag_sc_Value(false)
                    setMarket_sc_Value("")
                    setCurrency_sc_Value("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, currentSmentit.d_Split_combine_StockNo])

    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNo_sc_Value})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNo_sc_Value.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCode_sc_Value(res.data.data[0].d_StkCode)
                    setStkName_sc_Value(res.data.data[0].d_StkName)
                    setCCASSStock_sc_Value(res.data.data[0].d_CcassStk==="Y")
                    setStampFlag_sc_Value(res.data.data[0].d_StampFlag==="Y")
                    setMarket_sc_Value(res.data.data[0].d_Market)
                    setCurrency_sc_Value(res.data.data[0].d_Currency)

                } else { 
                    setStkCode_sc_Value("")
                    setStkName_sc_Value("")
                    setCCASSStock_sc_Value(false)
                    setStampFlag_sc_Value(false)
                    setMarket_sc_Value("")
                    setCurrency_sc_Value("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNo_sc_Value])

    useEffect(() => {
        (async () => {
            try {
                // get Stock Code List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "StkCodeList"})
                //console.log("StkCodeList: ", res.data.data) 
                setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    const onSubmit = (data: SmEntitInfo) => {


        data.d_StkNo = currentSmentit.d_StkNo;
        data.d_AnnouncementNo = currentSmentit.d_AnnouncementNo;

        //data.d_Bonus_StockNo = StkNo_b_Value;
        if ( typeof(data.d_Bonus_StockNo) === "undefined" ) {
            data.d_Bonus_StockNo = ""
        }
        data.d_Bonus_StockNo = data.d_Bonus_StockNo.toUpperCase();

        //data.d_Bonus_StockNo_lot = Bonus_StockNo_lotValue;

        //data.d_Warrant_1_StockNo = StkNo_w1_Value;
        if ( typeof(data.d_Warrant_1_StockNo) === "undefined" ) {
            data.d_Warrant_1_StockNo = ""
        }
        data.d_Warrant_1_StockNo = data.d_Warrant_1_StockNo.toUpperCase();

        //data.d_Warrant_2_StockNo = StkNo_w2_Value;
        if ( typeof(data.d_Warrant_2_StockNo) === "undefined" ) {
            data.d_Warrant_2_StockNo = ""
        }
        data.d_Warrant_2_StockNo = data.d_Warrant_2_StockNo.toUpperCase();

        //data.d_Rights_StockNo = StkNo_r_Value;
        if ( typeof(data.d_Rights_StockNo) === "undefined" ) {
            data.d_Rights_StockNo = ""
        }
        data.d_Rights_StockNo = data.d_Rights_StockNo.toUpperCase();

        //data.d_Split_combine_StockNo = StkNo_sc_Value;
        if ( typeof(data.d_Split_combine_StockNo) === "undefined" ) {
            data.d_Split_combine_StockNo = ""
        }
        data.d_Split_combine_StockNo = data.d_Split_combine_StockNo.toUpperCase();

       

        (async () => {
            try {
                
                console.log("call CalcWRRtn data", data);

                const res = await gsb.post('/Dividend', {sb_data: data, cPgmId: "StockAnnouncement", cAction: "CalcWRValidate" })
                //console.log("SmEntitInfo res.data.ok", res.data.ok)
                //console.log("SmEntitInfo res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }             

                onCalcWRProcess(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }


    const StkRenderer: ItemRenderer<StkCodeInfo> = (stk, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${stk.d_StkCode}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={stk.d_StkNo}
                key={stk.d_StkNo}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StkPredicate: ItemPredicate<StkCodeInfo> = (query, stk, _index, exactMatch) => {
        const normalizedText = `${stk.d_StkNo} - ${stk.d_StkCode}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StkValueRenderer = (stk: StkCodeInfo) => {
        return stk.d_StkNo
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }


    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Exit</Button>
                <Button className="Col-2-2" icon="calculator" onClick={handleSubmit(onSubmit)}>Calculate / Save</Button> 
             </div>
            <div className="Spacing-V-16" />
            { ! notshow_B_ISSUE && <H6 >Bonus :</H6> }
            { ! notshow_B_ISSUE && <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputBonus_StockNo" >
                    <InputGroup id="inputBonus_StockNo" name="d_Bonus_StockNo" defaultValue="" placeholder="Bonus Stock No. ..." onChange={utils.handleStringChange(s => setStkNo_b_Value(s)) } inputRef={register({ maxLength: 6}) } />
                    {errors.d_Bonus_StockNo && errors.d_Bonus_StockNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_Bonus_StockNo && errors.d_Bonus_StockNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Bonus_StockNo && errors.d_Bonus_StockNo.types && <span id="Err_msg">{errors.d_Bonus_StockNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-1" label="Lot Size" labelFor="inputBonus_StockNo_lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputBonus_StockNo_lot" defaultValue={Bonus_StockNo_lotValue} value={Bonus_StockNo_lotValue} type="number" onChange={utils.handleStringChange(n => setBonus_StockNo_lotValue(n))}  />
                    {errors.d_Bonus_StockNo_lot && errors.d_Bonus_StockNo_lot.types && <span id="Err_msg">{errors.d_Bonus_StockNo_lot.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCode_b_Value}</div>
                    <div id="DispText">{StkName_b_Value}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <Switch readOnly id="inputCcassStk" label="" innerLabelChecked="CCASS" innerLabel="non-CCASS" checked={CCASSStock_b_Value} />
                </FormGroup>
                <FormGroup className="Col-10-2" label="Contract Stamp">
                    <Switch readOnly id="inputStampFlag" label="" innerLabelChecked="Stamp" innerLabel="no Stamp" checked={StampFlag_b_Value} />
                </FormGroup>
                <FormGroup className="Col-12-2" label="Market">
                    <div>
                    <div id="DispText">{Market_b_Value}</div>
                    </div>
                </FormGroup>
            </div> }
            {/* ! notshow_B_ISSUE && <div className="Row">
                <FormGroup className="Col-1-2" label="Lot Size" labelFor="inputBonus_StockNo_lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputBonus_StockNo_lot" defaultValue={Bonus_StockNo_lotValue} value={Bonus_StockNo_lotValue} type="number" onChange={utils.handleStringChange(n => setBonus_StockNo_lotValue(n))}  />
                    {errors.d_Bonus_StockNo_lot && errors.d_Bonus_StockNo_lot.types && <span id="Err_msg">{errors.d_Bonus_StockNo_lot.types.message}</span>}
                </FormGroup>
            </div> */}
            
            { ! notshow_W1_ISSUE && <H6 >1st Warrant :</H6> }
            { ! notshow_W1_ISSUE && <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputWarrant_1_StockNo" >
                    <InputGroup id="inputWarrant_1_StockNo" name="d_Warrant_1_StockNo" defaultValue="" placeholder="1st Warrant Stock No. ..." onChange={utils.handleStringChange(s => setStkNo_w1_Value(s)) } inputRef={register({ maxLength: 6}) } />
                    {errors.d_Warrant_1_StockNo && errors.d_Warrant_1_StockNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_Warrant_1_StockNo && errors.d_Warrant_1_StockNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Warrant_1_StockNo && errors.d_Warrant_1_StockNo.types && <span id="Err_msg">{errors.d_Warrant_1_StockNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-1" label="Lot Size" labelFor="inputWarrant_1_Lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputWarrant_1_Lot" defaultValue={Warrant_1_LotValue} value={Warrant_1_LotValue} type="number" onChange={utils.handleStringChange(n => setWarrant_1_LotValue(n))}  />
                    {errors.d_Warrant_1_Lot && errors.d_Warrant_1_Lot.types && <span id="Err_msg">{errors.d_Warrant_1_Lot.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCode_w1_Value}</div>
                    <div id="DispText">{StkName_w1_Value}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <Switch readOnly id="inputCcassStk" label="" innerLabelChecked="CCASS" innerLabel="non-CCASS" checked={CCASSStock_w1_Value} />
                </FormGroup>
                <FormGroup className="Col-10-2" label="Contract Stamp">
                    <Switch readOnly id="inputStampFlag" label="" innerLabelChecked="Stamp" innerLabel="no Stamp" checked={StampFlag_w1_Value} />
                </FormGroup>
                <FormGroup className="Col-12-2" label="Market">
                    <div>
                    <div id="DispText">{Market_w1_Value}</div>
                    </div>
                </FormGroup>
            </div> }
            {/* ! notshow_W1_ISSUE && <div className="Row">
                <FormGroup className="Col-1-2" label="Lot Size" labelFor="inputWarrant_1_Lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputWarrant_1_Lot" defaultValue={Warrant_1_LotValue} value={Warrant_1_LotValue} type="number" onChange={utils.handleStringChange(n => setWarrant_1_LotValue(n))}  />
                    {errors.d_Warrant_1_Lot && errors.d_Warrant_1_Lot.types && <span id="Err_msg">{errors.d_Warrant_1_Lot.types.message}</span>}
                </FormGroup>
            </div> */}
            
            { ! notshow_W2_ISSUE && <H6 >2nd Warrant :</H6> }
            { ! notshow_W2_ISSUE && <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputWarrant_2_StockNo" >
                    <InputGroup id="inputWarrant_2_StockNo" name="d_Warrant_2_StockNo" defaultValue="" placeholder="2nd Warrant Stock No. ..." onChange={utils.handleStringChange(s => setStkNo_w2_Value(s)) } inputRef={register({ maxLength: 6}) } />
                    {errors.d_Warrant_2_StockNo && errors.d_Warrant_2_StockNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_Warrant_2_StockNo && errors.d_Warrant_2_StockNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Warrant_2_StockNo && errors.d_Warrant_2_StockNo.types && <span id="Err_msg">{errors.d_Warrant_2_StockNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-1" label="Lot Size" labelFor="inputWarrant_2_Lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputWarrant_2_Lot" defaultValue={Warrant_2_LotValue} value={Warrant_2_LotValue} type="number" onChange={utils.handleStringChange(n => setWarrant_2_LotValue(n))}  />
                    {errors.d_Warrant_2_Lot && errors.d_Warrant_2_Lot.types && <span id="Err_msg">{errors.d_Warrant_2_Lot.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCode_w2_Value}</div>
                    <div id="DispText">{StkName_w2_Value}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <Switch readOnly id="inputCcassStk" label="" innerLabelChecked="CCASS" innerLabel="non-CCASS" checked={CCASSStock_w2_Value} />
                </FormGroup>
                <FormGroup className="Col-10-2" label="Contract Stamp">
                    <Switch readOnly id="inputStampFlag" label="" innerLabelChecked="Stamp" innerLabel="no Stamp" checked={StampFlag_w2_Value} />
                </FormGroup>
                <FormGroup className="Col-12-2" label="Market">
                    <div>
                    <div id="DispText">{Market_w2_Value}</div>
                    </div>
                </FormGroup>
            </div> }
            {/* ! notshow_W2_ISSUE && <div className="Row">
                <FormGroup className="Col-1-2" label="Lot Size" labelFor="inputWarrant_2_Lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputWarrant_2_Lot" defaultValue={Warrant_2_LotValue} value={Warrant_2_LotValue} type="number" onChange={utils.handleStringChange(n => setWarrant_2_LotValue(n))}  />
                    {errors.d_Warrant_2_Lot && errors.d_Warrant_2_Lot.types && <span id="Err_msg">{errors.d_Warrant_2_Lot.types.message}</span>}
                </FormGroup>
            </div> */}

            { ! notshow_R_ISSUE && <H6 >Rights :</H6> }
            { ! notshow_R_ISSUE && <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputRights_StockNo" >
                    <InputGroup id="inputRights_StockNo" name="d_Rights_StockNo" defaultValue="" placeholder="Rights Stock No. ..." onChange={utils.handleStringChange(s => setStkNo_r_Value(s)) } inputRef={register({ maxLength: 6}) } />
                    {errors.d_Rights_StockNo && errors.d_Rights_StockNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_Rights_StockNo && errors.d_Rights_StockNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Rights_StockNo && errors.d_Rights_StockNo.types && <span id="Err_msg">{errors.d_Rights_StockNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-1" label="Lot Size" labelFor="inputRights_Lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputRights_Lot" defaultValue={Rights_LotValue} value={Rights_LotValue} type="number" onChange={utils.handleStringChange(n => setRights_LotValue(n))}  />
                    {errors.d_Rights_Lot && errors.d_Rights_Lot.types && <span id="Err_msg">{errors.d_Rights_Lot.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCode_r_Value}</div>
                    <div id="DispText">{StkName_r_Value}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <Switch readOnly id="inputCcassStk" label="" innerLabelChecked="CCASS" innerLabel="non-CCASS" checked={CCASSStock_r_Value} />
                </FormGroup>
                <FormGroup className="Col-10-2" label="Contract Stamp">
                    <Switch readOnly id="inputStampFlag" label="" innerLabelChecked="Stamp" innerLabel="no Stamp" checked={StampFlag_r_Value} />
                </FormGroup>
                <FormGroup className="Col-12-2" label="Market">
                    <div>
                    <div id="DispText">{Market_r_Value}</div>
                    </div>
                </FormGroup>
            </div> }
            {/* ! notshow_R_ISSUE && <div className="Row">
                <FormGroup className="Col-1-2" label="Lot Size" labelFor="inputRights_Lot">
                    <InputGroup readOnly className="inputNumberNoSpin" id="inputRights_Lot" defaultValue={Rights_LotValue} value={Rights_LotValue} type="number" onChange={utils.handleStringChange(n => setRights_LotValue(n))}  />
                    {errors.d_Rights_Lot && errors.d_Rights_Lot.types && <span id="Err_msg">{errors.d_Rights_Lot.types.message}</span>}
                </FormGroup>
            </div> */}

            { ( ! notshow_S_ISSUE || ! notshow_C_ISSUE ) && <H6 >Split / Consolidate :</H6> }
            { ( ! notshow_S_ISSUE || ! notshow_C_ISSUE ) && <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputSplit_combine_StockNo" >
                    <InputGroup id="inputSplit_combine_StockNo" name="d_Split_combine_StockNo" defaultValue="" placeholder="Split / Consolidate Stock No. ..." onChange={utils.handleStringChange(s => setStkNo_sc_Value(s)) } inputRef={register({ maxLength: 6}) } />
                    {errors.d_Split_combine_StockNo && errors.d_Split_combine_StockNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_Split_combine_StockNo && errors.d_Split_combine_StockNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_Split_combine_StockNo && errors.d_Split_combine_StockNo.types && <span id="Err_msg">{errors.d_Split_combine_StockNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCode_sc_Value}</div>
                    <div id="DispText">{StkName_sc_Value}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="CCASS Stock">
                    <Switch readOnly id="inputCcassStk" label="" innerLabelChecked="CCASS" innerLabel="non-CCASS" checked={CCASSStock_sc_Value} />
                </FormGroup>
                <FormGroup className="Col-10-2" label="Contract Stamp">
                    <Switch readOnly id="inputStampFlag" label="" innerLabelChecked="Stamp" innerLabel="no Stamp" checked={StampFlag_sc_Value} />
                </FormGroup>
                <FormGroup className="Col-12-2" label="Market">
                    <div>
                    <div id="DispText">{Market_sc_Value}</div>
                    </div>
                </FormGroup>
            </div> }


            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            {/* <div >Note : </div> */}

        </form>
    )
}



interface WarrantRightsProcess02MainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function WarrantRightsProcess02Main({ gsb }: WarrantRightsProcess02MainProps) {

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    //let Stkno = ""
    let Stkno = utils.getUrlParameter('Stkno', url); 
    let { Stkno: defaultSearchValue = "" }: { Stkno: string } = useParams()
    let dProcess = utils.getUrlParameter('dProcess', url); 


    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />
    const history = useHistory()

    const [Data, setData] = useState([] as PfEntitInfo[])
    const [Data2, setData2] = useState([] as SmEntitInfo[])

    const [searchKey, setSearchKey] = useState("StkNo")

    const [showFilters, setShowFilters] = useState(false)
    const [searchStkNo, setsearchStkNo] = useState("")
    const [showButton, setshowButton] = useState(true)
    const [showCalcButton, setshowCalcButton] = useState(true)
    const [ShowWait, setShowWait] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentPfentit, setcurrentPfentit] = useState({} as PfEntitInfo)
    const [BlankPfentit, setBlankPfentit] = useState({} as PfEntitInfo)
    const [currentSmentit, setcurrentSmentit] = useState({} as SmEntitInfo)

    const [currentMode, setcurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [dateFormat, setdateFormat] = useState("")


    BlankPfentit.d_StkNo = ""
    BlankPfentit.d_AnnouncementNo = ""
    BlankPfentit.d_acCode = ""

    console.log("WarrantRightsProcess02Main dProcess: ", dProcess)
    

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("WarrantRightsProcess02Main disp user_key: ", res.data.Flag.user_key)
                //console.log("WarrantRightsProcess02Main disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("WarrantRightsProcess02Main disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("WarrantRightsProcess02Main disp GsbFlag: ", GsbFlag)
                //console.log("WarrantRightsProcess02Main disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("WarrantRightsProcess02Main disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("WarrantRightsProcess02Main disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("WarrantRightsProcess02Main disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("WarrantRightsProcess02Main disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setdateFormat(GsbFlag.dateFormat);
 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    const refreshPfentitData = async () => {
        if ( qString.trim().length > 7 && selectedTabId == "list" ) {
            setshowButton(true)
            setShowWait(true)
            setData([])
            ;
            try {
                //console.log("SmMastMain onsubmit called", data)
                const res = await gsb.post('/Dividend',
                {   sb_data: {
                        stkNo: qString,
                        nPage: 1,
                        limit: 0,
                    },
                    cPgmId: "WarrantRightsProcess",
                    cAction: "AdjBookClosedList",
                });

                //console.log("PfEntit res.data.data: ", res.data.data, res.data.count) 
                //console.log("SmEntit res.data.data2: ", res.data.data2) 
                setData2(res.data.data2)
                setcurrentSmentit(res.data.data2[0])
                //console.log("currentSmentit: ", currentSmentit) 

                if ( res.data.count > 0 ) {
                    setData(res.data.data)

                    setshowButton(true)
                    setshowCalcButton(true)
                    setcurrentMode("show")

                } else {
                    setshowButton(false)
                    setshowCalcButton(false)
                    setcurrentMode("show")
                }
                setShowWait(false)
            } catch (error) {
                showError(error)
            }
            setShowWait(false)
        }
    }

    useEffect(() => {
        refreshPfentitData()
    }, [gsb, selectedTabId])



    const newOnSave = async (Announcement: PfEntitInfo) => {

    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
    }        


    const onChangeBCQty = (acCode: string, qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeBCQty acCode", acCode, qty);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode === acCode ) {
                    Data[j].d_Book_Closed_Qty = qty
                }
            }
            //setData(newData)
            //console.log("onChangeBCQty Data", Data);
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeLogRegister = (acCode: string, log: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeLogRegister acCode", acCode, log);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Log_to_Register = log
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeBonus_Handling_Fee = (acCode: string, HandlingFee: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeBonus_Handling_Fee acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_Bonus_Handling_Fee = HandlingFee
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeNew_Bonus_Qty = (acCode: string, Qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeNew_Bonus_Qty acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_New_Bonus_Qty = Qty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeNew_Warrant_1_Qty = (acCode: string, Qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeNew_Warrant_1_Qty acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_New_Warrant_1_Qty = Qty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeNew_Warrant_2_Qty = (acCode: string, Qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeNew_Warrant_2_Qty acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_New_Warrant_2_Qty = Qty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeNew_Rights_Qty = (acCode: string, Qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeNew_Rights_Qty acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_New_Rights_Qty = Qty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeNew_Split_Qty = (acCode: string, Qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeNew_Split_Qty acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_New_Split_Qty = Qty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }

    const onChangeNew_Consolidate_Qty = (acCode: string, Qty: string) => {
        if (acCode.length > 0 ) {
            //console.log("onChangeNew_Consolidate_Qty acCode", acCode, DivAmount);

            //let newData = [...Data]

            for (let j = 0; j < Data.length; j++) {
                if ( Data[j].d_acCode == acCode ) {
                    Data[j].d_New_Consolidate_Qty = Qty
                    break
                }
            }
            //setData(newData)
            setshowButton(true)
            setshowCalcButton(false)
        }
    }


    const onWRAdjSaveRec = () => {
        setShowWait(true)
        setshowButton(false)
        setshowCalcButton(false)
        ;

        console.log("onWRAdjSaveRec data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: Data, 
                    cPgmId: "DividendProcess",
                    cAction: "WRAdjSave",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setshowCalcButton(true)
    }

    const onCalcWR = () => {
        setShowWait(true)
        setshowButton(false)
        setshowCalcButton(false)
        ;

        console.log("onCalcWR data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Dividend', {
                    sb_data: Data, 
                    cPgmId: "DividendProcess",
                    cAction: "CalcWR",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
                refreshPfentitData()
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
        setshowButton(true)
        setshowCalcButton(false)
    }
    
    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let SmEntitRec = <>
        {Data2.map((SmEntit, i) =>
            <div >
                <div >
                    <tbody >
                        <tr><td>Stock No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_StkNo} &nbsp; &nbsp; {SmEntit.d_StkCode}</td></tr>
                        <tr><td>Announcement No. :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_AnnouncementNo}</td></tr>
                        <tr><td>Summary :</td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary1}</td></tr>
                        <tr><td></td><td>&nbsp; &nbsp; </td><td> {SmEntit.d_Summary2}</td></tr>
                    </tbody>
                </div>
                <div className="Spacing-V-16" />
                <div >
                    Ex date: &nbsp; {utils.dispDate(SmEntit.d_Ex_date, dateFormat)} &nbsp;
                    Book Close Date: &nbsp; {utils.dispDate(SmEntit.d_BC_date, dateFormat)} &nbsp;
                    Payable date: &nbsp; {utils.dispDate(SmEntit.d_PY_date, dateFormat)} &nbsp;
                </div>
                { ( dProcess === "D" ) && <div >
                    Dividend per share: &nbsp; {SmEntit.d_Dividend_perShare} &nbsp;
                    Payin Currency: &nbsp; {SmEntit.d_Dividend_Currency} &nbsp;
                </div> }
                { ( dProcess === "D" ) && <div >
                    Convert Dividend into Bonus Share at Unit Price: &nbsp; {SmEntit.d_Dividend_Bonus_unitPrice} &nbsp;
                    &nbsp; Stock: &nbsp; {SmEntit.d_Dividend_Bonus_OhterStock} &nbsp;
                </div> }
                { ( dProcess === "WR" ) && <div >
                    <tbody >
                        <tr><td>Bonus :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Bonus_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Bonus_Base}&nbsp;</td><td> Entitled Stock :</td><td> &nbsp;{SmEntit.d_Bonus_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Bonus_StockNo_lot}&nbsp;</td></tr>
                        <tr><td>1st Warrant :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Warrant_1_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Warrant_1_Base}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Warrant_1_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Warrant_1_Lot}&nbsp;</td></tr>
                        <tr><td>2nd Warrant :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Warrant_2_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Warrant_2_Base}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Warrant_2_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Warrant_2_Lot}&nbsp;</td></tr>
                        <tr><td>Rights :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Rights_Unit}&nbsp;</td><td> for shares</td><td> &nbsp;{SmEntit.d_Rights_Base}&nbsp;</td><td> Subscription Price :</td><td> &nbsp;{SmEntit.d_Rights_Price}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Rights_StockNo}&nbsp;</td><td> Lot Size :</td><td> &nbsp;{SmEntit.d_Rights_Lot}&nbsp;</td></tr>
                        <tr><td>Split :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_Split_Old}&nbsp;</td><td> into shares</td><td> &nbsp;{SmEntit.d_Split_New}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Split_combine_StockNo}&nbsp;</td></tr>
                        <tr><td>Consolidate :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_combine_Old}&nbsp;</td><td> into shares</td><td> &nbsp;{SmEntit.d_combine_New}&nbsp;</td><td> Stock :</td><td> &nbsp;{SmEntit.d_Split_combine_StockNo}&nbsp;</td></tr>
                    </tbody>
                </div> }
                <div >
                    { (SmEntit.d_Scrip_indicator === "Y" )
                    ? <tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td><td> &nbsp;{SmEntit.d_Scrip_Fee_Rate}&nbsp;</td></tr>
                    </tbody>
                    : <tbody >
                        <tr><td>Lot Size :</td><td>&nbsp; &nbsp; </td><td> &nbsp;{SmEntit.d_LotSize}&nbsp;</td></tr>
                        <tr><td>Scrip Fee / Lot :</td><td>&nbsp; &nbsp; </td><td>&nbsp;{SmEntit.d_Scrip_indicator}&nbsp;</td></tr>
                    </tbody>
                    }
                </div>
            </div>
        )}
        <div className="Spacing-V-16" />
    </>


    let CalcWR = <CalcWRRtn gsb={gsb} currentSmentit={currentSmentit} onCalcWRProcess={onCalcWR} onCancel={() => { setcurrentMode("show") ; setSelectedTabId("list") } } ></CalcWRRtn>


    let list = <>
        <div className="Row">
            <Button disabled={true} className="Col-1-1" icon="add" onClick={() => { setcurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
            <Button disabled={! showButton} className="Col-2-1" icon="confirm" onClick={() => { onWRAdjSaveRec() } }>Save</Button> 
            <Button disabled={! showCalcButton} className="Col-3-2" icon="calculator" onClick={() => { setSelectedTabId("CalcWR") } }>Calculate WR</Button> 
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-oh_qty">On Hand Qty</th>
                <th className="TCol-Book_Closed_Qty">Book Closed Qty</th>
                <th className="TCol-Log_to_Register">Log to Register</th>
                <th className="TCol-Bonus_Handling_Fee">Bonus Handling Fee</th>
                <th className="TCol-New_Bonus_Qty">Bonus Qty</th>
                <th className="TCol-New_Warrant_1_Qty">1st Warrant Qty</th>
                <th className="TCol-New_Warrant_2_Qty">2nd Warrant Qty</th>
                <th className="TCol-New_Rights_Qty">Rights Qty</th>
                <th className="TCol-New_Split_Qty">Split Qty</th>
                <th className="TCol-New_Consolidate_Qty">Consolidate Qty</th>
            </thead>
            <tbody id="ma_tr">
                {Data.map((PfEntit, i) =>
                    <tr key={i} onDoubleClick={() => { } }>
                        <td className="TCol-ACCode" onClick={() => { } }>{PfEntit.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { } }>{PfEntit.d_acName1}</td>
                        <td className="TCol-oh_qty" onClick={() => { } }>{PfEntit.d_oh_qty}</td>
                        <td className="TCol-Book_Closed_Qty" onClick={() => { } }>{PfEntit.d_Book_Closed_Qty}</td>
                        <td className="TCol-Log_to_Register" onClick={() => { } }>{PfEntit.d_Log_to_Register}</td>
                        <td className="TCol-Bonus_Handling_Fee" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputBonus_Handling_Fee" defaultValue={PfEntit.d_Bonus_Handling_Fee} onChange={utils.handleStringChange(s => onChangeBonus_Handling_Fee(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-New_Bonus_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputNew_Bonus_Qty" defaultValue={PfEntit.d_New_Bonus_Qty} onChange={utils.handleStringChange(s => onChangeNew_Bonus_Qty(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-New_Warrant_1_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputNew_Warrant_1_Qty" defaultValue={PfEntit.d_New_Warrant_1_Qty} onChange={utils.handleStringChange(s => onChangeNew_Warrant_1_Qty(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-New_Warrant_2_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputNew_Warrant_2_Qty" defaultValue={PfEntit.d_New_Warrant_2_Qty} onChange={utils.handleStringChange(s => onChangeNew_Warrant_2_Qty(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-New_Rights_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputNew_Rights_Qty" defaultValue={PfEntit.d_New_Rights_Qty} onChange={utils.handleStringChange(s => onChangeNew_Rights_Qty(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-New_Split_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputNew_Split_Qty" defaultValue={PfEntit.d_New_Split_Qty} onChange={utils.handleStringChange(s => onChangeNew_Split_Qty(PfEntit.d_acCode, s ))} /></td>
                        <td className="TCol-New_Consolidate_Qty" onClick={() => { } }><InputGroup className="inputNumberNoSpin" id="inputNew_Consolidate_Qty" defaultValue={PfEntit.d_New_Consolidate_Qty} onChange={utils.handleStringChange(s => onChangeNew_Consolidate_Qty(PfEntit.d_acCode, s ))} /></td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>95 Bonus, Warrant, Rights, Split & Consolidation Process, 
                        Handling Charges for Bonus Issue &nbsp; &nbsp; &nbsp;</H3>
                        <Button icon="arrow-left" onClick={() => history.goBack()}>Back</Button>
                    </div>
                    <div className="Spacing-V-16" />
                    {SmEntitRec}
                    <div className="Spacing-V-16" />
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="CalcWR" title="Calculate WR" panel={CalcWR} />
                    </Tabs>
                    <p>Notes</p>
                        <div>
                            Calculate WR
                        </div>
                    <div>
                        <div>
                            - The Bonus Handling Fee will NOT be Re-Calculate
                        </div>
                    </div >

                </Card>
            </form>
        </>
    );
}

export default WarrantRightsProcess02Main