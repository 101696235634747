import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MultiSelect2} from "@blueprintjs/select";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";



import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { xGroupPgm } from './Types'
import { SystemHeader } from './CommonUI';


interface PageInfo {
    n: number
}

var GsbFlag: any;


interface GroupAccess02MainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function GroupAccess02Main({ gsb }: GroupAccess02MainProps) {

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    //let Groupid = ""
    let Groupid = utils.getUrlParameter('Groupid', url); 
    let { Groupid: defaultSearchValue = "" }: { Groupid: string } = useParams()
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [Data, setData] = useState([] as xGroupPgm[])
    const [GroupName, setGroupName] = useState("")


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [currentAc, setCurrentAc] = useState({} as xGroupPgm)
    const [BlankAc, setBlankAc] = useState({} as xGroupPgm)

    const [CurrentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

    //gsb default value


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("GroupAccess02Main disp user_key: ", res.data.Flag.user_key)
                //console.log("GroupAccess02Main disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("GroupAccess02Main disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("GroupAccess02Main disp GsbFlag: ", GsbFlag)
                //console.log("GroupAccess02Main disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("GroupAccess02Main disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("GroupAccess02Main disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("GroupAccess02Main disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("GroupAccess02Main disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_GroupId: Groupid,
                        nPage: 1,
                        limit: 0,
                    },
                    cPgmId: "GroupAccess",
                    cAction: "GetGroupAccessInfo",
                });
                console.log("GroupAccess02Main res.data.data", res.data.data);

                if ( res.data.count > 0 ) {
                    setData(res.data.data)
                    setGroupName(res.data.data[0].d_GroupName)

                    setshowButton(true)
                    setCurrentMode("show")

                } else {
                    setshowButton(false)
                    setCurrentMode("show")
                }

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
    }

    const onChangeSelectPgmAccess = (PgmCode: string) => {
        if (PgmCode.length > 0 ) {
            let newData = [...Data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_PgmCode == PgmCode ) {
                    if ( newData[j].d_PgmAccess == "Y" ) {
                        newData[j].d_PgmAccess = "N"
                    } else {
                        newData[j].d_PgmAccess = "Y"
                    }
                    break
                }
            }
            setData(newData);
            setshowButton(true)

        }
    }

    const onChangeSelectAllPgm = () => {

        let newData = [...Data]

        for (let j = 0; j < newData.length; j++) {
            newData[j].d_PgmAccess = "Y"
        }
        setData(newData);
        setshowButton(true)
    }

    const onChangeUnselectAllPgm = () => {

        let newData = [...Data]

        for (let j = 0; j < newData.length; j++) {
            newData[j].d_PgmAccess = "N"
        }
        setData(newData);
        setshowButton(true)
    }

    const onChangeGroupAction = (PgmCode: string, GroupAction: string) => {
        if (PgmCode.length > 0 ) {
            console.log("onChangeGroupAction GroupAction", PgmCode, GroupAction);

            let newData = [...Data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_PgmCode == PgmCode ) {
                    newData[j].d_GroupAction = GroupAction
                    break
                }
            }
            setData(newData);
            setshowButton(true)
        }
    }

    const onGroupActionSaveRec = () => {
        setShowWait(true)
        setshowButton(false)
        ;

        console.log("onGroupActionSaveRec data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Master', {
                    sb_data: Data, 
                    cPgmId: "GroupAccess",
                    cAction: "GroupActionSave",
                })                
                if ( res.data.ok ) {
                    utils.showSuccess(res.data.data)
                } else {
                    utils.showWarn(res.data.data)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
    }


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            <Button disabled={true} className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new") }}>New</Button>
            <Button disabled={! showButton} className="Col-2-1" icon="confirm" onClick={() => { onGroupActionSaveRec() } }>Save</Button> 
        </div>
        <div className="Row">
            <Button className="Col-6-2" icon="confirm" onClick={() => { onChangeSelectAllPgm() }}>Select All</Button>
            <Button className="Col-8-2" onClick={() => { onChangeUnselectAllPgm() } }>Unselect All</Button>
            {/* <div className="Col-7-1" onClick={() => { onChangeSelectAllPgm() }} >Select All</div>
            <div className="Col-8-1" onClick={() => { onChangeUnselectAllPgm() }} >Unselect All</div> */}
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-PgmCode">Pgm Code</th>
                <th className="TCol-PgmName">Pgm Name</th>
                <th className="TCol-PgmAction">Pgm Action</th>
                <th className="TCol-d_PgmAccess">Can Access</th>
                <th className="TCol-GroupAction">Group Action</th>
            </thead>
            <tbody id="ma_tr">
                {Data.map((GroupAction, i) =>
                    <tr key={i} onDoubleClick={() => { } }>
                        <td className="TCol-PgmCode" onClick={() => { } }>{GroupAction.d_PgmCode}</td>
                        <td className="TCol-PgmName" onClick={() => { } }>{GroupAction.d_PgmName}</td>
                        <td className="TCol-PgmAction" onClick={() => { } }>{GroupAction.d_PgmAction}</td>
                        <td className="TCol-d_PgmAccess"><Checkbox disabled={GroupAction.d_PgmCode == "ALL"} checked={GroupAction.d_PgmAccess=="Y"} onChange={() => onChangeSelectPgmAccess(GroupAction.d_PgmCode)} /></td>                        
                        {/* <td className="TCol-GroupAction"><InputGroup className="" id="inputGroupAction" disabled={GroupAction.d_PgmAction.length == 0} defaultValue={GroupAction.d_GroupAction}  onChange={utils.handleStringChange(s => onChangeGroupAction(GroupAction.d_PgmCode, s ))} /></td> */}
                        {GroupAction.d_PgmAction.length == 0 && <td className="TCol-GroupAction">
                            <select  id="inputGroupAction" disabled={GroupAction.d_PgmAction.length == 0} value={GroupAction.d_GroupAction} onChange={utils.handleStringChange(s => onChangeGroupAction(GroupAction.d_PgmCode, s ))} >
                                <option value="F">F: Full Access</option>
                            </select>
                        </td> }
                        {(GroupAction.d_PgmAction.length == 4) && <td className="TCol-GroupAction">
                            <select  id="inputGroupAction" value={GroupAction.d_GroupAction} onChange={utils.handleStringChange(s => onChangeGroupAction(GroupAction.d_PgmCode, s ))} >
                                <option value="F">F: Full Access</option>
                                <option value="C">C: Checker</option>
                                <option value="M">M: Maker</option>
                                <option value="R">R: Read Only</option>
                            </select>
                        </td> }
                        {(GroupAction.d_PgmAction.length == 2) && <td className="TCol-GroupAction">
                            <select  id="inputGroupAction" value={GroupAction.d_GroupAction} onChange={utils.handleStringChange(s => onChangeGroupAction(GroupAction.d_PgmCode, s ))} >
                                <option value="F">F: Full Access</option>
                                <option value="R">R: Read Only</option>
                            </select>
                        </td> }
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>Edit Group Pgm Access</H3>
                    </div>
                    <div className="Spacing-V-16" />
                    Group ID : {Groupid}
                    <div className="Spacing-V-16" />
                    Group Name : {GroupName}
                    <div className="Spacing-V-16" />
                    {list}
                    <div className="Spacing-V-16" />
                    <div >Note : </div>
                    <div >For Group Action : </div>
                    <div >F = Full Access , no Authorization Required</div>
                    <div >C = Checker , Have Authority approve trans , Require another Checker approve</div>
                    <div >M = Maker , Authorization required from checker</div>
                    <div >R = Read Only </div>
                    <p></p>
                    <div >Pgm apply AE / Runnner User Login effect : 754</div>
                    <div >Pgm apply Location / Branch User Login effect : 21, 33, 332, 34, 342, 73, 74 (not 744), 751, 752, AD1, AD2</div>
                </Card>
            </form>
        </>
    );
}

export default GroupAccess02Main