import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MultiSelect2} from "@blueprintjs/select";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip, ProgressBar } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";



import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { ClientTranSettlementInfo } from './Types'
import { SystemHeader, ClientPicker } from './CommonUI';

import i18next from 'i18next';

interface PageInfo {
    n: number
}

var GsbFlag: any;


interface ClientTranSettlementEntryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClientTranSettlementEntryMain({ gsb }: ClientTranSettlementEntryMainProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<ClientTranSettlementInfo>()
    const { t, i18n } = useTranslation();    

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    //let Groupid = ""
    let Groupid = utils.getUrlParameter('Groupid', url); 
    let { Groupid: defaultSearchValue = "" }: { Groupid: string } = useParams()

    let ProgressId2 = ""
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [Data, setData] = useState([] as ClientTranSettlementInfo[])
    const [GroupName, setGroupName] = useState("")


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)

    const [currentAc, setCurrentAc] = useState({} as ClientTranSettlementInfo)
    const [BlankAc, setBlankAc] = useState({} as ClientTranSettlementInfo)

    const [CurrentMode, setCurrentMode] = useState("show")

    const [ShowWait, setShowWait] = useState(false)
    const [ShowRst, setShowRst] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)

    const [s_BsType, sets_BsType] = useState("")
    const [s_ClCode1, sets_ClCode1] = useState("")
    const [s_ClCode2, sets_ClCode2] = useState("")
    const [setClCodeValue, setSetClCodeValue] = useState(() => sets_ClCode1)
    const [isClPickerOpen, setIsClPickerOpen] = useState(false)
    const [s_Date1, sets_Date1] = useState(utils.formatNumber(new Date()))
    const [s_Date2, sets_Date2] = useState(utils.formatNumber(new Date()))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")
    const [PART_SETL, setPART_SETL] = useState(false)

    //gsb default value


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ClientTranSettlementEntryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ClientTranSettlementEntryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClientTranSettlementEntryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ClientTranSettlementEntryMain disp GsbFlag: ", GsbFlag)
                //console.log("ClientTranSettlementEntryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ClientTranSettlementEntryMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("ClientTranSettlementEntryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClientTranSettlementEntryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ClientTranSettlementEntryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
                setPART_SETL(GsbFlag.PART_SETL)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    
    useEffect(() => {
        setShowRst(false)
        setShowNoRecord(false)
        setShowOK(true)
    }, [s_BsType, s_ClCode1, s_ClCode2, s_Date1, s_Date2 ])


    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            //console.log("Progresslist ProgressId: ", ProgressId2) 
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 1*1000)
        return () => {clearInterval(timer)}
    }, [gsb, ProgressId, nPage])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        sets_ClCode1(s_ClCode1.toUpperCase());
        sets_ClCode2(s_ClCode2.toUpperCase());

        setShowOK(false)
        setShowWait(true)
        setShowNoRecord(false)
        setShowRst(false)
        setData([])
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0)

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        (async () => {
            try {
                //console.log("SmMastMain onsubmit called", data)
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            ProgressId: ProgressId2,
                            s_BsType: s_BsType,
                            s_ClCode1: s_ClCode1,
                            s_ClCode2: s_ClCode2,
                            s_Date1: s_Date1,
                            s_Date2: s_Date2,
                        },
                        cPgmId: "ClientTranSettlementEntry",
                        cAction: "GetTranSettlementInfo",
                    });

                //console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRst(true)
                    setData(res.data.data)
            
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRst(false)
                }
            } catch (error) {
                utils.showError(error)
                setShowWait(false)
                setShowRst(false)
        }
        })()
    }



    const onChangeSettleAmount = (Doc_No: string, SettleAmount: string) => {
        if (Doc_No.length > 0 ) {
            console.log("onChangeSettleAmount SettleAmount", Doc_No, SettleAmount);

            let newData = [...Data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_Doc_No == Doc_No ) {
                    newData[j].d_Settlement_amount = SettleAmount
                    break
                }
            }
            setData(newData);
            setshowButton(true)
            //console.log("onChangeSettleAmount data", Data);

        }
    }

    const onChangeSettleFlag = (Doc_No: string, SettleFlag: string) => {
        if (Doc_No.length > 0 ) {
            console.log("onChangeSettleFlag SettleFlag", Doc_No, SettleFlag);

            let newData = [...Data]

            for (let j = 0; j < newData.length; j++) {
                if ( newData[j].d_Doc_No == Doc_No ) {
                    newData[j].d_Settlement_Flag = SettleFlag
                    break
                }
            }
            setData(newData);
            setshowButton(true)
            //console.log("onChangeSettleAmount data", Data);
        }
    }

    const onSettleFlagSaveRec = () => {
        setShowWait(true)
        setshowButton(false)
        setError("d_disp_Error_msg", {message: ""});
        ;

        console.log("onSettleFlagSaveRec data", Data);
        (async () => {
            try {
                const res = await gsb.post('/Transaction', {
                    sb_data: Data, 
                    cPgmId: "ClientTranSettlementEntry",
                    cAction: "TranSettlementValidate",
                })                
                if ( res.data.ok ) {
                    const res2 = await gsb.post('/Transaction', {
                        sb_data: Data, 
                        cPgmId: "ClientTranSettlementEntry",
                        cAction: "TranSettlementSave",
                    })                
                    if ( res2.data.ok ) {
                        utils.showSuccess(res2.data.data)
                    } else {
                        utils.showWarn(res2.data.data)
                    }
                } else {
                    utils.showWarn(res.data.data2)
                    setshowButton(true)
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
        setShowWait(false)
    }


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            <Button disabled={true} className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new") }}>New</Button>
            <Button disabled={! showButton} className="Col-2-1" icon="confirm" onClick={() => { onSettleFlagSaveRec() } }>Save</Button> 
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {errors.d_disp_Error_msg && errors.d_disp_Error_msg.types && <span id="Err_msg">{errors.d_disp_Error_msg.types.message}</span>} 
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-Tran_date">T.Date</th>
                <th className="TCol-Doc_No">Doc No.</th>
                <th className="TCol-acCode">A/C No.</th>
                <th className="TCol-StkNo">Stock No & Code</th>
                <th className="TCol-Desc">Description</th>
                <th className="TCol-Qty">No. of Share</th>
                <th className="TCol-Curr">Curr</th>
                <th className="TCol-Amount">Doc Amount</th>
                <th className="TCol-Amount">Outstanding Amount</th>
                <th className="TCol-Amount">Partial Settle Amount</th>
                <th className="TCol-Settlement_Flag">Fully Settle Ref</th>
            </thead>
            <tbody id="ma_tr">
                {Data.map((SettleFlag, i) =>
                    <tr key={i} onDoubleClick={() => { } }>
                        <td className="TCol-Tran_date" onClick={() => { } }>{utils.dispDate(SettleFlag.d_Tran_date, GsbFlag.dateFormat)}</td>
                        <td className="TCol-Doc_No" onClick={() => { } }>{SettleFlag.d_Doc_No}</td>
                        <td className="TCol-acCode" onClick={() => { } }>{SettleFlag.d_acCode}</td>
                        { "BS".indexOf(SettleFlag.d_Tran_type) >= 0 && <td className="TCol-StkNo" onClick={() => { } }>{SettleFlag.d_StkNo} {SettleFlag.d_StkCode}</td> }
                        { "BS".indexOf(SettleFlag.d_Tran_type) < 0 && <td className="TCol-StkNo" onClick={() => { } }> </td> }
                        { "BS".indexOf(SettleFlag.d_Tran_type) < 0 && <td className="TCol-Desc" onClick={() => { } }>{SettleFlag.d_Desc}</td> }
                        { "BS".indexOf(SettleFlag.d_Tran_type) >= 0 && <td className="TCol-Desc" onClick={() => { } }> </td> }
                        { "BS".indexOf(SettleFlag.d_Tran_type) >= 0 && <td className="TCol-Qty" onClick={() => { } }>{SettleFlag.d_Qty}</td> }
                        { "BS".indexOf(SettleFlag.d_Tran_type) < 0 && <td className="TCol-Qty" onClick={() => { } }> </td> }
                        <td className="TCol-Curr" onClick={() => { } }>{SettleFlag.d_Currency}</td>
                        <td className="TCol-Amount" onClick={() => { } }>{utils.formatNumber2(SettleFlag.d_DocAmount)}</td>
                        <td className="TCol-Amount" onClick={() => { } }>{utils.formatNumber2(SettleFlag.d_Amount)}</td>
                        <td className="TCol-Amount"><InputGroup className="inputNumberNoSpin" id="inputSettleAmount" disabled={! PART_SETL} defaultValue={SettleFlag.d_Settlement_amount} onChange={utils.handleStringChange(s => onChangeSettleAmount(SettleFlag.d_Doc_No, s ))} /></td>
                        <td className="TCol-Settlement_Flag"><InputGroup className="" id="inputSettleFlag" name="d_Settlement_Flag" defaultValue={SettleFlag.d_Settlement_Flag}  onChange={utils.handleStringChange(s => onChangeSettleFlag(SettleFlag.d_Doc_No, s ))} /></td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <ClientPicker gsb={gsb} isOpen={isClPickerOpen} onClose={()=>setIsClPickerOpen(false)} onSelect={(ac)=>{setClCodeValue(ac.d_acCode); setIsClPickerOpen(false)}}/>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <div>
                        <H3>Client Transaction Settlement Entry</H3>
                    </div>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="BS Type"labelFor="inputs_BsType">
                            <select  id="inputTranType"  
                                value={s_BsType}
                                autoFocus
                                onChange={utils.handleStringChange(s => sets_BsType(s)) }
                            >
                                <option value=""> </option>
                                <option value="B">B: Buy</option>
                                <option value="S">S: Sell</option>
                            </select>
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Start Tran Date"labelFor="inputs_Date1">
                            {/* <InputGroup id="inputs_Date1" name="s_Date1" value={s_Date1} type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" /> */}
                            <InputGroup id="inputs_Date1" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={utils.handleStringChange(s => sets_Date1(s)) } />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Tran Date"labelFor="inputs_Date2">
                            <InputGroup id="inputs_Date2" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={utils.handleStringChange(s => sets_Date2(s)) } />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Start Account No." labelFor="inputClCode1" >
                            <InputGroup 
                                className="inputClCode"
                                id="inputClCode1"
                                placeholder=" Account No. ..."
                                value={s_ClCode1}
                                onChange={utils.handleStringChange(s => sets_ClCode1(s))}
                                rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>sets_ClCode1); setIsClPickerOpen(true)}}></Button>}
                            />
                        </FormGroup>
                        <FormGroup className="Col-3-2" label="Account No." labelFor="inputClCode2">
                            <InputGroup
                                className="inputClCode"
                                id="inputClCode2"
                                placeholder=" Account No. ..."
                                value={s_ClCode2}
                                onChange={utils.handleStringChange(s => sets_ClCode2(s))}
                                rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>sets_ClCode2); setIsClPickerOpen(true)}}></Button>}
                            />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <Button disabled={! ShowOK} type="submit">Search</Button>
                    </div>
                    <div className="Spacing-V-16" />
                    {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
                    {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }

                    {ShowNoRecord && <p>
                        <div className="Spacing-V-16" />
                        <div className="Row">
                            <FormGroup className="Col-1-3"  >
                                <span id="Err_msg">No Record found !!!</span>
                            </FormGroup>
                        </div>
                    </p>
                    }
                    {ShowRst && list}
                    <div className="Spacing-V-16" />
                </Card>
            </form>
        </>
    );
}

export default ClientTranSettlementEntryMain