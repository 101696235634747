import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, InputGroup, Button, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { DayStartInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';

var GsbFlag: any;



const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


interface DayStartRtnProps {
    gsb: AxiosInstance,
    currentDayStart?: DayStartInfo,
    onDayStartProcess?: (DayStart: DayStartInfo) => void,
    onCancel?: () => void;
}


function DayStartRtn({ gsb, currentDayStart = {} as DayStartInfo, onDayStartProcess = () => { }, onCancel = () => { } }: DayStartRtnProps) {
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<DayStartInfo>()

    const { t, i18n } = useTranslation();    

    const [Enter2tab, setEnter2tab] = useState("Y")

    const [CanPorcess, setCanPorcess] = useState(true)
    const [CanSkipWarning, setCanSkipWarning] = useState("0")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    const [isShowAlert3, setisShowAlert3] = useState(false)
    const [ShowAlert3Msg, setShowAlert3Msg] = useState([])
    const [isShowAlert4, setisShowAlert4] = useState(false)
    const [ShowAlert4Msg, setShowAlert4Msg] = useState([])

    //const [TodayDateValue, setTodayDateValue] = useState(currentDayStart.d_TodayDate)

    console.log("DayStartRtn  currentDayStart", currentDayStart)
    //console.log("DayStartRtn  ",  currentDayStart.d_TodayDate, currentDayStart.d_SettleDate)

    useEffect(() => {
        // Call reset to get the values of record PfEntitInfo 
        if ("d_TodayDate" in currentDayStart) {
            reset(currentDayStart)
        }
    }, [currentDayStart, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Dividend_Currency" })
    }, [register])


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                setEnter2tab(GsbFlag.Enter2tab)               

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, currentDayStart, GsbFlag])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [gsb, currentDayStart, GsbFlag, Enter2tab])



    
    const onSubmit = (data: DayStartInfo) => {


        ;       

        (async () => {
            try {

                data.d_CanSkipWarning = CanSkipWarning

                console.log("call DayStartRtn data", data);

                
                const res = await gsb.post('/Transaction', {sb_data: data, cPgmId: "DayStartProc" , cAction: "DayStartValidate"})


                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    if ( res.data.Prompt_Msg.isShowMsg ) {
                        setCanPorcess(false)
                        setisShowAlert3(true)
                        setShowAlert3Msg(res.data.Prompt_Msg.MsgShow)
                        //console.log("call DayStartRtn Prompt_Msg.MsgShow", res.data.Prompt_Msg.MsgShow);
                        //console.log("call DayStartRtn ShowAlert3Msg", ShowAlert3Msg);
                    } else if (res.data.Warning_Msg.isShowMsg) {
                        setCanPorcess(false)
                        setisShowAlert4(true)
                        setShowAlert4Msg(res.data.Warning_Msg.MsgShow)
                    }
                    return
                }

                onDayStartProcess(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

    }




    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col-1-2" label="Today's Date"labelFor="inputTodayDate">
                    <InputGroup id="inputTodayDate" name="d_TodayDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={() => setCanPorcess(true)} inputRef={register} />
                    {errors.d_TodayDate && errors.d_TodayDate.types && <span id="Err_msg">{errors.d_TodayDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Next Trade Date"labelFor="inputNextTradeDate">
                    <InputGroup id="inputNextTradeDate" name="d_NextTradeDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={() => setCanPorcess(true)} inputRef={register} /> 
                    {errors.d_NextTradeDate && errors.d_NextTradeDate.types && <span id="Err_msg">{errors.d_NextTradeDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Settlement Date"labelFor="inputSettleDate">
                    <InputGroup id="inputSettleDate" name="d_SettleDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={() => setCanPorcess(true)} inputRef={register} /> 
                    {errors.d_SettleDate && errors.d_SettleDate.types && <span id="Err_msg">{errors.d_SettleDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Client Setl Date"labelFor="inputClientSetlDate">
                    <InputGroup id="inputClientSetlDate" name="d_ClientSetlDate" type="date" placeholder="YYYY-MM-DD" leftIcon="calendar" onChange={() => setCanPorcess(true)} inputRef={register} /> 
                    {errors.d_ClientSetlDate && errors.d_ClientSetlDate.types && <span id="Err_msg">{errors.d_ClientSetlDate.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-8"></div>
            <div className="Row">
                <Button className="Col-1-2" icon={IconNames.LIST} disabled={! CanPorcess} onClick={handleSubmit(onSubmit)}>Start Processing</Button>
            </div>

            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.DANGER}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.DANGER}
                    isOpen={isShowAlert3}
                    onCancel={ () => { setCanPorcess(false); setisShowAlert3(false) } }
                    onConfirm={ () => { setCanPorcess(false); setShowAlert3Msg([]); setisShowAlert3(false) } }
                >
                    <p>
                        {/*ShowAlert3Msg*/}
                        <div className="Spacing-V-16"></div>
                        {ShowAlert3Msg.map((Wmsg, j) => 
                            <div>{ShowAlert3Msg[j]}</div>
                        )}

                    </p>
                </Alert>
                <Alert
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert4}
                    onCancel={ () => { setCanSkipWarning("1"); setCanPorcess(false); setisShowAlert4(false) } }
                    onConfirm={ () => { setCanSkipWarning("2"); setCanPorcess(true); setShowAlert4Msg([]); setisShowAlert4(false) } }
                >
                    <p>
                        {/*ShowAlert4Msg*/}
                        <div className="Spacing-V-16"></div>
                        {ShowAlert4Msg.map((Wmsg, j) => 
                            <div>{ShowAlert4Msg[j]}</div>
                        )}

                    </p>
                </Alert>
                {/*
                <Dialog
                    icon="cross"
                    isOpen={isShowAlert3}
                    onClose = { () => { setCanPorcess(false); setisShowAlert3(false) } }
                >
                    <DialogBody className={Classes.DIALOG_BODY}>
                        {ShowAlert3Msg}
                        <div className="Spacing-V-16"></div>
                        <Button type="submit" onClick={ () => { setCanPorcess(false); setisShowAlert3(false) } }>No</Button>
                        &nbsp; &nbsp; &nbsp;
                        <Button intent={Intent.DANGER} type="submit" onClick={ () => { setCanPorcess(true); setShowAlert3Msg(""); setisShowAlert3(false) } }>Yes</Button>
                    </DialogBody>
                </Dialog> */}
        </form>
    )
}


interface DayStartProcMainProps {
    gsb: AxiosInstance

}



function DayStartProcMain({ gsb  }: DayStartProcMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [DayStartData, setDayStartData] = useState({} as DayStartInfo)
    const [CanPorcess, setCanPorcess] = useState(true)
    const [DayStartPress, setDayStartPress] = useState(false)

    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [DaySTartProcessMsg, setDaySTartProcessMsg] = useState("")

    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    const [isShowAlert3, setisShowAlert3] = useState(false)
    const [ShowAlert3Msg, setShowAlert3Msg] = useState([])
    

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

    const [SING_CURR, setSING_CURR] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
                setSING_CURR(GsbFlag.SING_CURR)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    
    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    useEffect(() => {
        (async () => {
            try {
                //console.log("SmMastMain onsubmit called", data)
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                    },
                    cPgmId: "DayStartProc",
                    cAction: "GetTodayInfo",
                });

                console.log("DayStartProcMain res.data.data: ", res.data.data, res.data.count) 


                if ( res.data.count > 0 ) {
                    setDayStartData(res.data.data[0])
                    setCanPorcess(true)
                    //currentRec.d_TodayDate = res.data.data[0].TRAN_DATE
                    if ( res.data.data[0].d_DayStart_msg.length > 0 ) {
                        setisShowAlert3(true)
                        setShowAlert3Msg(res.data.data[0].d_DayStart_msg)
                        //console.log("GetTodayInfo d_DayStart_msg", res.data.data[0].d_DayStart_msg);
                        //console.log("GetTodayInfo ShowAlert3Msg", ShowAlert3Msg);
                    }

                } else {

                }
            } catch (error) {
                setCanPorcess(false)
                showError(error)
            }
        })()



    }, [gsb, TRAN_DATE, SETL_DATE])


    useEffect(() => {
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await gsb.post('/tasklist', taskList.map((t) => t.id));
                    //console.log("tasklist res.data.data", res.data.data)
                    setTaskList(res.data.data)
                } catch (error) {
                    utils.showError(error)
                }
            }, 2*1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, gsb])

    useEffect(() => {
        (async () => {
            setDaySTartProcessMsg("")
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "xProcess",
                });                
                if ( res.data.count > 0 ) {
                    if ( res.data.data[0].x_Porcessing.substr(2,15) == "DayStartProcess" ) {
                        setDaySTartProcessMsg(res.data.data[0].x_Porcessing)
                        if ( res.data.data[0].x_Porcessing.substr(0,17) == "2,DayStartProcess" && DayStartPress ) {
                            setisShowAlert1(true)
                            setShowAlert1Msg("Day Start Processing Completed. !!!")
                        }
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        })();
    }, [taskList, gsb])


    const onDayStartProcess = (data: DayStartInfo) => {
        console.log("onDayStartProcess data", data);
        (async () => {
            try {
                const res = await gsb.post('/Transaction',
                    {   sb_data: data,
                        cPgmId: "DayStartProc",
                        cAction: "DayStart"
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                setTaskList(res.data.data)
                setShowTasks(true)
                setDayStartPress(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            //toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
            //toaster.show({ message: (err.response.data.osCode > 0 ? "Error:"+err.response.data.subSystem +" - ": "")+err.response.data.description+(err.response.data.osCode > 0 ? ", OS code:"+err.response.data.osCode : ""), intent: Intent.WARNING })
            setisShowAlert2(true)
            setShowAlert2Msg((err.response.data.osCode > 0 ? "Error:"+err.response.data.subSystem +" - ": "")+err.response.data.description+(err.response.data.osCode > 0 ? ", OS code:"+err.response.data.osCode : ""))
        } else if (err.request) {
            //toaster.show({ message: "Request failed", intent: Intent.WARNING })
            setisShowAlert2(true)
            setShowAlert2Msg("Request failed")
        } else if (err.message) {
            //toaster.show({ message: err.message, intent: Intent.WARNING })
            setisShowAlert2(true)
            setShowAlert2Msg(err.message)
        } else {
            //toaster.show({ message: err, intent: Intent.WARNING })
            setisShowAlert2(true)
            setShowAlert2Msg(err)
        }
    
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    let DayStartSection = <DayStartRtn gsb={gsb} currentDayStart={DayStartData}  onDayStartProcess={onDayStartProcess} onCancel={() => {  } } ></DayStartRtn>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <p><H3>16 Day Start</H3></p>
                    <div>
                        <div>Process : {DaySTartProcessMsg}</div>
                    </div >
                    <div className="Spacing-V-8"></div>

                    {CanPorcess && DayStartSection}

                    <div className="Spacing-V-16"></div>
                    <div className="Row">
                        <Button className="Col-1-1" icon={IconNames.LIST} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <Collapse isOpen={showTasks}>
                        <p>Tasks</p>
                        <HTMLTable striped interactive condensed>
                            <thead>
                                <th className="TCol-Ref">File Name</th>
                                <th className="TCol-Progress">Progress</th>
                                <th className="TCol-Message">Message</th>
                                <th className="TCol-Log">Log</th>
                            </thead>
                            <tbody>
                                {taskList.map((t, i) =>
                                    <tr key={i}>
                                        <td className="TCol-Ref">{t.ref}</td>
                                        <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                        <td className="TCol-Message">{t.message}</td>
                                        <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                                    </tr>
                                )}
                            </tbody>
                        </HTMLTable>
                        <div className="Spacing-V-16"></div>
                        {! SING_CURR && <Button className="Col-1-2" icon="document" onClick={() => { window.open(`/#/ExchangeRate`, '_blank') } } >setup Exchange Rate</Button> }
                        <div className="Spacing-V-16"></div>
                        <Button className="Col-1-2" icon="document" onClick={() => { window.open(`/#/BookCloseReminder`, '_blank') } } >print "BOOK CLOSE REMINDER REPORT"</Button>
                        <div className="Spacing-V-16"></div>
                    </Collapse>
                    <br></br>
                    <p>Notes</p>
                    <div>
                        <div>System Day Start</div>

                    </div >
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.DANGER}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="warning-sign"
                        intent={Intent.DANGER}
                        isOpen={isShowAlert3}
                        onCancel={ () => { setCanPorcess(false); setisShowAlert3(false) } }
                        onConfirm={ () => { setCanPorcess(true); setShowAlert3Msg([]); setisShowAlert3(false) } }
                    >
                        <p>
                            {/*ShowAlert3Msg*/}
                            <div className="Spacing-V-16"></div>
                            {ShowAlert3Msg.map((Wmsg, j) => 
                                <div>{ShowAlert3Msg[j]}</div>
                            )}

                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default DayStartProcMain