import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { MktInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "M0013" ;
var isReqAuth2:boolean = false ;


interface MktMastViewProps {
    gsb: AxiosInstance,
    Mkt: MktInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}


function MktMastView({ gsb, Mkt = {} as MktInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: MktMastViewProps) {

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const { t, i18n } = useTranslation();

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("MktMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Mkt])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_Market" in Mkt)) return null



    return (
        <div>
            {/* define add,change,delete button */}
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Market Code" labelFor="inputMktCode" labelInfo="*">
                    <InputGroup readOnly value={Mkt.d_Market} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Market Name" labelFor="inputMktName">
                    <InputGroup readOnly value={Mkt.d_MktName} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Bought Settlement Days for Stock" labelFor="inputBought_Stock_Settle">
                    <InputGroup readOnly value={utils.formatNumber2(Mkt.d_Bought_Stock_Settle)} />
                </FormGroup>
                <FormGroup className="Col-4-3" label="Sold Settlement Days for Stock" labelFor="inputSold_Stock_Settle">
                    <InputGroup readOnly value={utils.formatNumber2(Mkt.d_Sold_Stock_Settle)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Bought Settlement Days for Money" labelFor="inputBought_Money_Settle">
                    <InputGroup readOnly value={utils.formatNumber2(Mkt.d_Bought_Money_Settle)} />
                </FormGroup>
                <FormGroup className="Col-4-3" label="Sold Settlement Days for Money" labelFor="inputSold_Money_Settle">
                    <InputGroup readOnly value={utils.formatNumber2(Mkt.d_Sold_Money_Settle)} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            {(GsbFlag.MULTI_MKT) && 
                <div className="Row">
                    <FormGroup className="Col-1-3"  >
                    </FormGroup>
                    <FormGroup className="Col-4-3"  >
                        <span id="DispText">For SAS , SZMK Market </span>
                    </FormGroup>
                </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-3" label="Stamp Fee Label" labelFor="inputStamp_lbl">
                    <InputGroup readOnly value={Mkt.d_Stamp_lbl} />
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 1 Label" labelFor="inputExpense1_lbl">
                        <InputGroup readOnly value={Mkt.d_Expense1_lbl} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Clearing Fee Label" labelFor="inputClearing_lbl">
                    <InputGroup readOnly value={Mkt.d_Clearing_lbl} />
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 2 Label" labelFor="inputExpense2_lbl">
                        <InputGroup readOnly value={Mkt.d_Expense2_lbl} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="VAT Label" labelFor="inputVAT_lbl">
                    <InputGroup readOnly value={Mkt.d_VAT_lbl} />
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 3 Label" labelFor="inputExpense3_lbl">
                        <InputGroup readOnly value={Mkt.d_Expense3_lbl} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Levy Label" labelFor="inputLevy_lbl">
                    <InputGroup readOnly value={Mkt.d_Levy_lbl} />
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 4 Label" labelFor="inputExpense4_lbl">
                        <InputGroup readOnly value={Mkt.d_Expense4_lbl} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Transfer Fee Label" labelFor="inputTransfer_lbl">
                    <InputGroup readOnly value={Mkt.d_TransferFee_lbl} />
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 5 Label (For Seller)" labelFor="inputExpenseS1_lb">
                        <InputGroup readOnly value={Mkt.d_ExpenseS1_lbl} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="AFRC Levy Label" labelFor="inputFRCLevy_lbl">
                    <InputGroup readOnly value={Mkt.d_FRC_lbl} />
                </FormGroup>
            </div>

        </div>
    )
}


interface MktMastEditProps {
    gsb: AxiosInstance,
    Mkt?: MktInfo,
    isNew?: boolean,
    onSave?: (Mkt: MktInfo) => void,
    onCancel?: () => void;
}

function MktMastEdit({ gsb, Mkt = {} as MktInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: MktMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<MktInfo>()
    const { t, i18n } = useTranslation();


    useEffect(() => {
        // Call reset to get the values of record StkInfo 
        if ("d_Market" in Mkt) {
            reset(Mkt)
        }
    }, [Mkt, reset])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    if (! isNew && ! ("d_Market" in Mkt)) return null


    
    const onSubmit = (data: MktInfo) => {

        //console.log("MktMastEdit onsubmit data: ", data)

        // put value to json/field before write database dbf
        data.d_Market = data.d_Market.toUpperCase();

        (async () => {
            try {
                //console.log("call MktMastEdit data", data)
                //console.log("call MktMastEdit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "Mktmast", cAction: "MktValidate", isNew: isNew})
                //console.log("MktMastEdit res.data.ok", res.data.ok)
                //console.log("MktMastEdit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Market Code" labelFor="inputMktCode" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputMktCode" name="d_Market" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 4 }) } />
                    {errors.d_Market && errors.d_Market.type === "required" && (<span id="Err_msg">Market Code cannot be Blank</span>)}
                    {errors.d_Market && errors.d_Market.type === "maxLength" && <span id="Err_msg">Max length is 4</span>}
                    {errors.d_Market && errors.d_Market.types && <span id="Err_msg">{errors.d_Market.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Market Name" labelFor="inputMktName">
                    <InputGroup id="inputMktName" name="d_MktName" autoFocus={! isNew} defaultValue="" placeholder="Market Name ..." inputRef={register({ maxLength: 20}) } />
                    {errors.d_MktName && errors.d_MktName.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.d_MktName && errors.d_MktName.types && <span id="Err_msg">{errors.d_MktName.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Bought Settlement Days for Stock" labelFor="inputBought_Stock_Settle">
                    <InputGroup className="inputNumberNoSpin" id="inputBought_Stock_Settle" name="d_Bought_Stock_Settle" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Bought_Stock_Settle && errors.d_Bought_Stock_Settle.types && <span id="Err_msg">{errors.d_Bought_Stock_Settle.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="Sold Settlement Days for Stock" labelFor="inputSold_Stock_Settle">
                    <InputGroup className="inputNumberNoSpin" id="inputSold_Stock_Settle" name="d_Sold_Stock_Settle" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Sold_Stock_Settle && errors.d_Sold_Stock_Settle.types && <span id="Err_msg">{errors.d_Sold_Stock_Settle.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Bought Settlement Days for Money" labelFor="inputBought_Money_Settle">
                    <InputGroup className="inputNumberNoSpin" id="inputBought_Money_Settle" name="d_Bought_Money_Settle" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Bought_Money_Settle && errors.d_Bought_Money_Settle.types && <span id="Err_msg">{errors.d_Bought_Money_Settle.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-3" label="Sold Settlement Days for Money" labelFor="inputSold_Money_Settle">
                    <InputGroup className="inputNumberNoSpin" id="inputSold_Money_Settle" name="d_Sold_Money_Settle" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Sold_Money_Settle && errors.d_Sold_Money_Settle.types && <span id="Err_msg">{errors.d_Sold_Money_Settle.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            {(GsbFlag.MULTI_MKT) && 
                <div className="Row">
                    <FormGroup className="Col-1-3"  >
                    </FormGroup>
                    <FormGroup className="Col-4-3"  >
                        <span id="DispText">For SAS , SZMK Market </span>
                    </FormGroup>
                </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-3" label="Stamp Fee Label" labelFor="inputStamp_lbl">
                    <InputGroup id="inputStamp_lbl" name="d_Stamp_lbl" defaultValue="" placeholder="Stamp Fee Label ..." inputRef={register({ maxLength: 15}) } />
                    {errors.d_Stamp_lbl && errors.d_Stamp_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.d_Stamp_lbl && errors.d_Stamp_lbl.types && <span id="Err_msg">{errors.d_Stamp_lbl.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 1 Label" labelFor="inputExpense1_lbl">
                        <InputGroup id="inputExpense1_lbl" name="d_Expense1_lbl" defaultValue="" placeholder="Expense 1 Label ..." inputRef={register({ maxLength: 15}) } />
                        {errors.d_Expense1_lbl && errors.d_Expense1_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                        {errors.d_Expense1_lbl && errors.d_Expense1_lbl.types && <span id="Err_msg">{errors.d_Expense1_lbl.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Clearing Fee Label" labelFor="inputClearing_lbl">
                    <InputGroup id="inputClearing_lbl" name="d_Clearing_lbl" defaultValue="" placeholder="Clearing Fee Label ..." inputRef={register({ maxLength: 15}) } />
                    {errors.d_Clearing_lbl && errors.d_Clearing_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.d_Clearing_lbl && errors.d_Clearing_lbl.types && <span id="Err_msg">{errors.d_Clearing_lbl.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 2 Label" labelFor="inputExpense2_lbl">
                        <InputGroup id="inputExpense2_lbl" name="d_Expense2_lbl" defaultValue="" placeholder="Expense 2 Label ..." inputRef={register({ maxLength: 15}) } />
                        {errors.d_Expense2_lbl && errors.d_Expense2_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                        {errors.d_Expense2_lbl && errors.d_Expense2_lbl.types && <span id="Err_msg">{errors.d_Expense2_lbl.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="VAT Label" labelFor="inputVAT_lbl">
                    <InputGroup id="inputVAT_lbl" name="d_VAT_lbl" defaultValue="" placeholder="VAT Label ..." inputRef={register({ maxLength: 15}) } />
                    {errors.d_VAT_lbl && errors.d_VAT_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.d_VAT_lbl && errors.d_VAT_lbl.types && <span id="Err_msg">{errors.d_VAT_lbl.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 3 Label" labelFor="inputExpense3_lbl">
                        <InputGroup id="inputExpense3_lbl" name="d_Expense3_lbl" defaultValue="" placeholder="Expense 3 Label ..." inputRef={register({ maxLength: 15}) } />
                        {errors.d_Expense3_lbl && errors.d_Expense3_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                        {errors.d_Expense3_lbl && errors.d_Expense3_lbl.types && <span id="Err_msg">{errors.d_Expense3_lbl.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Levy Label" labelFor="inputLevy_lbl">
                    <InputGroup id="inputLevy_lbl" name="d_Levy_lbl" defaultValue="" placeholder="Levy Label ..." inputRef={register({ maxLength: 15}) } />
                    {errors.d_Levy_lbl && errors.d_Levy_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.d_Levy_lbl && errors.d_Levy_lbl.types && <span id="Err_msg">{errors.d_Levy_lbl.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 4 Label" labelFor="inputExpense4_lbl">
                        <InputGroup id="inputExpense4_lbl" name="d_Expense4_lbl" defaultValue="" placeholder="Expense 4 Label ..." inputRef={register({ maxLength: 15}) } />
                        {errors.d_Expense4_lbl && errors.d_Expense4_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                        {errors.d_Expense4_lbl && errors.d_Expense4_lbl.types && <span id="Err_msg">{errors.d_Expense4_lbl.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Transfer Fee Label" labelFor="inputTransfer_lbl">
                    <InputGroup id="inputTransfer_lbl" name="d_TransferFee_lbl" defaultValue="" placeholder="Transfer Fee Label ..." inputRef={register({ maxLength: 15}) } />
                    {errors.d_TransferFee_lbl && errors.d_TransferFee_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.d_TransferFee_lbl && errors.d_TransferFee_lbl.types && <span id="Err_msg">{errors.d_TransferFee_lbl.types.message}</span>}
                </FormGroup>
                {(GsbFlag.MULTI_MKT) && 
                    <FormGroup className="Col-4-3" label="Expense 5 Label (For Seller)" labelFor="inputExpenseS1_lb">
                        <InputGroup id="inputExpenseS1_lb" name="d_ExpenseS1_lbl" defaultValue="" placeholder="Expense 5 Label (For Seller) ..." inputRef={register({ maxLength: 15}) } />
                        {errors.d_ExpenseS1_lbl && errors.d_ExpenseS1_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                        {errors.d_ExpenseS1_lbl && errors.d_ExpenseS1_lbl.types && <span id="Err_msg">{errors.d_ExpenseS1_lbl.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="AFRC Levy Label" labelFor="inputFRCLevy_lbl">
                    <InputGroup id="inputFRCLevy_lbl" name="d_FRC_lbl" defaultValue="" placeholder="AFRC Levy Label ..." inputRef={register({ maxLength: 15}) } />
                    {errors.d_FRC_lbl && errors.d_FRC_lbl.type === "maxLength" && <span id="Err_msg">Max length is 15</span>}
                    {errors.d_FRC_lbl && errors.d_FRC_lbl.types && <span id="Err_msg">{errors.d_FRC_lbl.types.message}</span>}
                </FormGroup>
            </div>

        </form>
    )
}

interface MktMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function MktMastMain({ gsb }: MktMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let { MktCode: defaultSearchValue = "" }: { MktCode: string } = useParams()

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as MktInfo[])

    const [searchKey, setSearchKey] = useState("Market")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentMkt, setCurrentMkt] = useState({} as MktInfo)
    const [BlankMkt, setBlankMkt] = useState({} as MktInfo)

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    
    const [SING_CURR, setSING_CURR] = useState(false)
    const [MarketMaxNo, setMarketMaxNo] = useState(0)
    const [MarketNo, setMarketNo] = useState(0)

    //gsb default value

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setMarketMaxNo(GsbFlag.MarketMaxNo)
                //setMarketMaxNo(4)

                setSING_CURR(GsbFlag.SING_CURR)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        setisReadOnly(false)
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("MktMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master',
                {   sb_data: {
                    },
                    cPgmId: "Mktmast",
                    cAction: "GetMarketNo",
                });

                if (res.data.count > 0) {
                    setMarketNo(res.data.count )
                } else {
                    setMarketMaxNo(res.data.count )
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])

        const PrevRecProcess = async (Mkt: MktInfo) => {
        try {
            //console.log("call PrevRecProcess", Mkt)
            const res = await gsb.post('/Master', {sb_data: Mkt,  cPgmId: "Mktmast",cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", d_MktCode.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market === res.data.data.d_Market) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setCurrentMkt(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (Mkt: MktInfo) => {
        try {
            //console.log("call DeleteProcess", Mkt)
            const res = await gsb.post('/Master', {sb_data: Mkt, cPgmId: "Mktmast", cAction: "DelRec"})
            showSuccess("Market Code " + Mkt.d_Market + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market === res.data.data.d_Market) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentMkt(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Mkt: MktInfo) => {
        try {
            //console.log("call NextRecProcess", Mkt)
            const res = await gsb.post('/Master', {sb_data: Mkt, cPgmId: "Mktmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market === res.data.data.d_Market) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentMkt(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (Mkt: MktInfo) => {
        try {
            //console.log("call newOnSave", Mkt)
            const res = await gsb.post('/Master', {sb_data: Mkt, cPgmId: "Mktmast", cAction: "AddRec"})
            showSuccess("Market Code " + Mkt.d_Market + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market === res.data.data.d_Market) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentMkt(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (Mkt: MktInfo) => {
        try {
            console.log("call editOnSave", Mkt)
            const res = await gsb.post('/Master', {sb_data: Mkt, cPgmId: "Mktmast", cAction: "UpdateRec"})
            showSuccess("Market Code " + Mkt.d_Market + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market === res.data.data.d_Market) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("editOnSave stk", Mkt)
            //console.log("editOnSave res.data.data", res.data.data)
            setCurrentMkt(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( SING_CURR ) {
            toaster.show({message: 'Your SB Version have not Multi-Currency Module !  Please contact POP (Tel: 23918816) for more detail.', intent: Intent.WARNING })
            return
        }

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        // get SmMast list if StkNo have value
        try {
            //console.log("SmMastMain onsubmit called", data)
            const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                        }, 
                    },
                    cPgmId: "Mktmast",
                    cAction: "ListRec",
                });

            if ( m_limit > 1 ) {
                setData(res.data.data)
            }
            if (res.data.data.length > 0) {
                setCurrentMkt(res.data.data[0])
            }

            if ( m_limit > 1 ) {
                if ( res.data.count > 0 ) {
                const aPageNumber1 = []
                if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                    }
                        setaPageNumber(aPageNumber1)
                    }
                }
            }
            setShowWait(false)
        } catch (error) {
            utils.showError(error)
        }
    }

    // get one SmMast record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                //console.log("SmMastMain useEffect length > 0  called", data)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "Market",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "Mktmast",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentMkt(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                            }, 
                        },
                        cPgmId: "Mktmast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentMkt(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <MktMastView gsb={gsb} Mkt={currentMkt} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentMkt)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentMkt)} onNextRec={() => NextRecProcess(currentMkt)} ></MktMastView>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            if ( MarketNo+1 > MarketMaxNo && MarketMaxNo > 0) {
                console.log("currentMode = new , reach Max No.")
                setCurrentMode("show")
                setisShowAlert2(true)
                setShowAlert2Msg("Warning: Reach Maximum No. Of Market. Please Contact POP (Tel: 23918816) ! ")
            } else {
                console.log("currentMode = new , detail 1")
                detail = <MktMastEdit gsb={gsb} Mkt={BlankMkt} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></MktMastEdit>
            }
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <MktMastEdit gsb={gsb} Mkt={currentMkt} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></MktMastEdit>
            break
        }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            { (! SING_CURR ) &&
                <Button className="Col-1-1"  disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
            }
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-MarketCode">Market Code</th>
                <th className="TCol-MktName">Market Name</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((Mkt, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-MarketCode" onClick={() => { setCurrentMkt(Mkt) ; MoblieDev && setSelectedTabId("detail") }}>{Mkt.d_Market}</td>
                        <td className="TCol-MktName" onClick={() => { setCurrentMkt(Mkt) ; MoblieDev && setSelectedTabId("detail") }}>{Mkt.d_MktName}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    { ( SING_CURR ) && 
                        <div>
                        <div id="DispText">Your SB Version have not Multi-Currency Module !</div>
                        <div id="DispText">Please contact POP (Tel: 23918816) for more detail.</div>
                        </div>
                    }
                    {/* define search key */}
                    <H3><div style={{justifyContent: 'flex-end'}}>Market Master</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="Market">Market Code</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "Market"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "Market"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            {/* <FormGroup className="Col-5-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default MktMastMain