 
import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem, Utils } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation,Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { FiniInfo, StkCodeInfo, CurrencyInfo,AcCodeInfo } from './Types'
import { SystemHeader,ClientPicker } from './CommonUI';

import i18next from 'i18next';
import { Util } from 'konva/lib/Util';


interface PageInfo {
    n: number
}

 
var GsbFlag: any;

var UserID: string;
var PgmCode = "H0002" ;
var isReqAuth2:boolean = false ;



interface IPOEntryViewProps {
    gsb: AxiosInstance,
    Fini: FiniInfo,
    Fini0: FiniInfo,
    CurrencyList?: CurrencyInfo[],
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
    PgmMode: any,    
}


function IPOEntryView({ gsb, Fini = {} as FiniInfo,Fini0 = {} as FiniInfo, CurrencyList =[] as CurrencyInfo[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 ,PgmMode}: IPOEntryViewProps) {

    const { t, i18n } = useTranslation();   
    
    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)
    const [isCanDelete, setisCanDelete] = useState(true)
    const [isDeletePress2, setisDeletePress2] = useState(false)

 

    const [isOpenDialog, setisOpenDialog] = useState(false)
    const [isOPenAlert, setisOPenAlert] = useState(false)
    const [cAction, setcAction] = useState("")
    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")
    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")

    //Listed cannnot ammmed
    const [FiniListStatusValue,setFiniListStatisValue] = useState(false)

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestBalValue, setLatestBalValue] = useState(0)
    const [UnClrChqValue, setUnClrChqValue] = useState(0)

   const [d_RebateRate,setd_RebateRate ] =useState("") //useState ((utils.ParseFloat(Fini.d_RebateRate,4)).toString())

  
     
    
    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkidrMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Fini])


    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: Fini.d_acCode})
                let d_acCode = (typeof Fini.d_acCode === 'string')? Fini.d_acCode.trim() : ''
                
                if ( d_acCode.trim().length > 0 && res.data.count > 0 )
                {
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    //setAcStatusValue(res.data.data[0].d_status)
                    console.log("d_RebateRate=",Fini.d_RebateRate)
                    setd_RebateRate(((utils.ParseFloat(Fini.d_RebateRate,4)).toString()))
                    console.log("d_RebateRate=",d_RebateRate)

                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    //setAcStatusValue("")
                }
            
            } catch (error) {
                showErrorBox(error)
            }
        })()

    }, [gsb, Fini.d_acCode])



/*
    const onReqAuthDelRec = () => {
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: AuthUser,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )
                if (res2.data.ok) {
                    setAuthUser("")
                    setPassword("")

                    const res = await gsb.post('/Master', {
                        sb_data: {
                            userId: AuthUser,
                            password: password,
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "ReqUserGroupAccessReqAuth"
                    })
                    console.log("onReqAuthDelRec res.data.data", res.data.data);
                    if ( res.data.ok && res.data.data[0].d_Can_DeleteMaster == "Y") {
                        onDelete();
                        
                    } else {
                        utils.showWarn("Authorization failed")
                        setisShowAlert2(true)
                        setShowAlert2Msg("Authorization failed")
                    }

                } else {
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }
*/

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

   { if (! ("d_acCode" in Fini)) return null}

    
    return (
        <div>
            {/* define add,change,delete button */}
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
          
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => setisDeletePress(true)} >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                {/* <Button className="Col-7-2" icon="document" onClick={() => { window.open(`/#/BookClosedQty?Stkno=${Fini.d_StkNo}?IPONo=${Fini.d_IPONo}`, '_blank') } } >Book Closed Qty</Button> */}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No.">
                    <InputGroup readOnly value={utils.formatNumber2(Fini.d_Seq)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini.d_Seq.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Fini0.d_Seq)} </div> }
                </FormGroup>

                <FormGroup className="Col-2-1" label="PrePaid Aoumt" labelFor="inputPrePaidAmtoSB" >
                    {/*<InputGroup className="inputPrePaidAmtoSB" disabled={false} id="inputPrePaidAmtoSB" name="d_OAPOST" autoFocus={isNew}  inputRef={register} /> */}
                    {Fini.d_OAPOST==="Y"
                        ?<Button icon="small-tick" intent="success" text="-> SB" />
                        :<Button icon="small-cross"  intent= "warning" text="to SB" />
                    }
                   
                </FormGroup> 
          
                <FormGroup className="Col-3-1" label="Qty posted" labelFor="inputQtyToSB">
                    {/*<InputGroup className="inputQtyToSB" disabled={true} id="inputQtyToSB" name="d_OIPOST" autoFocus={isNew} inputRef={register} />*/}
                    {Fini.d_OIPOST==="Y"
                        ?<Button icon="small-tick" intent="success" text="-> SB" />
                        :<Button icon="small-cross"  intent= "warning" text="to SB" />
                    }
                                       
                </FormGroup>
              
         
                <FormGroup className="Col-4-1" label="Amount posted" labelFor="inputAmtPostedToSb">
                   {/*<InputGroup className="inputAmtPostedToSb" disabled={false} id="inputAmtPostedToSb" name="d_OIAMTPOST" autoFocus={isNew}  inputRef={register} /> */}
                   {Fini.d_OIAMTPOST==="Y"
                        ?<Button icon="small-tick" intent="success" text="-> SB" />
                        :<Button icon="small-cross"  intent= "warning" text="to SB" />
                    }                   
                </FormGroup> 

 
                <FormGroup className="Col-5-1" label="Input User" labelFor="inputUser">
                    <InputGroup readOnly value={Fini.d_Input_User} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_Input_User.toString() == "") && 
                    <div id="DispText2" > {Fini0.d_Input_User} </div> }
                </FormGroup>               
                <FormGroup className="Col-1-1" label="Cheque No:">
                    <InputGroup readOnly value={Fini.d_OCHQ } />
                </FormGroup> 

            </div>
            <div className="Spacing-V-16" />
            <div className="Row">     
                {/*
                    <FormGroup className="Col-1-2" label="Ex date"labelFor="inputEx_date">
                    <InputGroup readOnly value={utils.dispDate(Announcement.d_Ex_date, GsbFlag.dateFormat)} />
                </FormGroup>
                */}       
                <FormGroup className="Col-1-2" label="Appy Date.">
                    <InputGroup readOnly value={utils.dispDate(Fini.d_ApplyDate,GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_ApplyDate.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(Fini.d_ApplyDate, GsbFlag.dateFormat)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="List Date.">
                    <InputGroup readOnly value={utils.dispDate(Fini.d_ListingDate, GsbFlag.dateFormat)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_ListingDate.toString() == "") && 
                    <div id="DispText2" > {utils.dispDate(Fini0.d_ListingDate, GsbFlag.dateFormat)} </div> }
                </FormGroup>
                <FormGroup className="Col-5-1" label="EIPO">
                    <InputGroup readOnly value={(Fini.d_OGENEPIO)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_OGENEPIO.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_OGENEPIO)} </div> }
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No.">
                    <InputGroup readOnly value={(Fini.d_acCode)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_acCode.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_acCode)} </div> }
                </FormGroup>
                {/*Client Name*/}
                <FormGroup className="Col-2-3" label="Client Name">
                    <div id="DispText2" > {(Fini.d_acName1)} </div>
                    <div id="DispText2" > {(Fini.d_acName2)} </div>       
                </FormGroup>
                <FormGroup className="Col-5-1" label="Runner.">
                    <div id="DispText2" > {(Fini.d_aeCode)} </div>            
                </FormGroup>

            </div>

            <div className="Row">
               <FormGroup className="Col-1-2" label="Stock Code 1" labelFor="inputStk">
                    <InputGroup readOnly value={Fini.d_OSTK} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_OSTK.toString() == "") && 
                    <div id="DispText2" > {Fini0.d_OSTK} </div> }
                </FormGroup>   
                <FormGroup className="Col-3-2" label="Stock No" labelFor="inputStkNo">
                <InputGroup readOnly value={Fini.d_StkNo} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_StkNo.toString() == "") && 
                    <div id="DispText2" > {Fini0.d_StkNo} </div> }
                </FormGroup>
                <FormGroup className="Col-1-2" label="Stock Name" labelFor="inputStkName">
                    <InputGroup readOnly value={Fini.d_StkName} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_StkName.toString() == "") && 
                    <div id="DispText2" > {Fini0.d_StkName} </div> }
                </FormGroup>   
            </div>

            <div className="Row">
                <FormGroup className="Col-1-1" label="Comm Rate.">
                    <InputGroup readOnly value={(utils.ParseFloat(Fini.d_CommRate,4)).toString()} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_CommRate.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_CommRate)} </div> }
                </FormGroup >

                <FormGroup className="Col-2-2" label="Consideration">
                    <InputGroup readOnly value={ (Fini.d_Turnover)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_Turnover.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_Turnover)} </div> }
                </FormGroup >


                <FormGroup className="Col-4-2" label="Receive Pre-Pay Date">
                    <InputGroup readOnly value={(Fini.d_OPPDATE)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_OPPDATE.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_OPPDATE)} </div> }
                </FormGroup >
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Levy Rate.">
                    <InputGroup readOnly value={(Fini.d_LevyRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_LevyRate.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_LevyRate)} </div> }
                </FormGroup >

                <FormGroup className="Col-2-2" label="Commission Amount.">
                    <InputGroup readOnly value={(Fini.d_EstCommAmt)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_EstCommAmt.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_EstCommAmt)} </div> }
                </FormGroup >
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Trading Fee Rate(%)">
                    <InputGroup readOnly value={(Fini.d_TradingFeeRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_TradingFeeRate.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_TradingFeeRate)} </div> }
                </FormGroup >
                <FormGroup className="Col-2-2" label="Lst.Prc & Qty Levy.">
                    <InputGroup readOnly value={(Fini.d_EstTransLevy)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_EstTransLevy.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_EstTransLevy)} </div> }
                </FormGroup >
                <FormGroup className="Col-4-2" label="AFRC.">
                    <InputGroup readOnly value={(Fini.d_OFLEVY)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_OFLEVY.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_OFLEVY)} </div> }
                </FormGroup >
            </div>

            <div className="Row">
                <FormGroup className="Col-1-1" label="Apply Price.">
                    <InputGroup readOnly value={utils.formatNumber(Fini.d_ApplyPrice)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_ApplyPrice.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_ApplyPrice)} </div> }
                </FormGroup >
                <FormGroup className="Col-2-1" label="currency">
                    <InputGroup readOnly value={utils.formatNumber(Fini.d_Currency)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_Currency.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_Currency)} </div> }
                </FormGroup >


                <FormGroup className="Col-4-2" label="Trading Fee">
                    <InputGroup readOnly value={utils.formatNumber(Fini.d_EstTradingFee)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_EstTradingFee.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_EstTradingFee)} </div> }
                </FormGroup >


                <FormGroup className="Col-1-1" label="Apply Qty.">
                    <InputGroup readOnly value={utils.formatNumber(Fini.d_ApplyQty)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_ApplyQty.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_ApplyQty)} </div> }
                </FormGroup >

                {/*Total Amount*/}
                <FormGroup className="Col-4-2" label="Total Amount">
                    <InputGroup readOnly value={utils.ParseFloat(Fini.d_ApplyAmount,2)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_ApplyAmount.toString() == "") && 
                    <div id="DispText2" > {utils.ParseFloat(Fini0.d_ApplyAmount,2)} </div> }
                </FormGroup >

                <FormGroup className="Col-1-1" label="Apply Type(W/Y)">
                    <InputGroup readOnly value={(Fini.d_ApplyType)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_ApplyType.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_ApplyType)} </div> }
                </FormGroup >
                <FormGroup className="Col-4-2" label="Handing Fee">
                    <InputGroup readOnly value={(utils.ParseFloat((Fini.d_HandlingFee),2)).toString()} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_HandlingFee.toString() == "") && 
                    <div id="DispText2" > {(utils.ParseFloat(Fini0.d_HandlingFee,2)).toString()} </div> }
                </FormGroup >
            </div>
            <div className="Spacing-V-16" />

 
            <div className="Row">
               <FormGroup className="Col-1-1" label="Pre-Paid">
                    <InputGroup readOnly value={utils.formatNumber2(Fini.d_OPAYPERC)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_OPAYPERC.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_OPAYPERC)} </div> }
                </FormGroup >
                <FormGroup className="Col-4-2" label="Pre-Paid Acount">
                    <InputGroup readOnly value={utils.formatNumber2(Fini.d_OPREPAID)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_OPREPAID.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_OPREPAID)} </div> }
                </FormGroup >



                <FormGroup className="Col-1-2" label="Interest Rate(%)">
                    <InputGroup readOnly value={(Fini.d_OIRATE)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_OIRATE.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_OIRATE)} </div> }
                </FormGroup >
                <FormGroup className="Col-3-1" label="Loan Day">
                    <InputGroup readOnly value={utils.formatNumber(Fini.d_LoanDay)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_LoanDay.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_LoanDay)} </div> }
                </FormGroup >
                <FormGroup className="Col-4-2" label="Loan Amount">
                    <InputGroup readOnly value={utils.formatNumber3(utils.StringToNum(Fini.d_LoanAmt),2)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_LoanAmt.toString() == "") && 
                    <div id="DispText2" > {(Fini0.d_LoanAmt)} </div> }
                </FormGroup >
                <FormGroup className="Col-1-1" label="Rebate Rate(%)">
                    <InputGroup readOnly   value={ utils.formatNumber3(utils.StringToNum(Fini.d_RebateRate))} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (utils.formatNumber3(utils.StringToNum(Fini0.d_RebateRate)) == "") && 
                    <div id="DispText2" > {utils.formatNumber3(utils.StringToNum(Fini0.d_RebateRate))} </div> }
                </FormGroup >
                <FormGroup className="Col-4-2" label="Rebate Amount">
                    <InputGroup readOnly value={utils.formatNumber3(utils.StringToNum(Fini0.d_EstRebateAmt),2)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Fini0.d_EstRebateAmt.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber3(utils.StringToNum(Fini0.d_EstRebateAmt),2)} </div> }
                </FormGroup >
            </div>
            <p>Notes</p>
   
        </div>
    )
}



interface IPOEntryEditProps {
    gsb: AxiosInstance,
    Fini?: FiniInfo,
    CurrencyList?: CurrencyInfo[],
    isNew?: boolean,
    onSave?: (stk: FiniInfo) => void,
    onCancel?: () => void,
    PgmMode: any,    
}

function IPOEntryEdit({ gsb, Fini = {} as FiniInfo, CurrencyList=[] as CurrencyInfo[], isNew = false, onSave = () => { }, onCancel = () => { } ,PgmMode}: IPOEntryEditProps) {

    const [isReqAuth, setisReqAuth] = useState(false)
    
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<FiniInfo>()

    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    
   
    const [StkCodeList, setStkCodeList] = useState([] as StkCodeInfo[])
    const [CCASSStockValue, setCCASSStockValue] = useState(false)

    const [AcCodeValue, setAcCodeValue] = useState(Fini.d_acCode)
    const [setClCodeValue, setSetClCodeValue] = useState(() => setAcCodeValue)
    const [isClPickerOpen, setIsClPickerOpen] = useState(false)   
    const [AcCodeList, setAcCodeList] = useState([] as AcCodeInfo[])
    const [searchStk,setsearchStk] = useState("")    
    const [cStk,setcStk] =useState(Fini.d_OSTK)

    const [CurrencyValue,setCurrencyValue] = useState("")
    const [StkValue ,setStkValue] = useState ("")
    const [StkNoValue,setStkNoValue] = useState("")     
    const [StkNameValue,setStkNameValue] = useState("")

    const [AcName1Value, setAcName1Value] = useState(Fini.d_acName1)    
    const [AcName2Value, setAcName2Value] = useState(Fini.d_acName2)
  
    const [AcStatusValue, setAcStatusValue] = useState("")

    //const [CommCodeValue,setCommCodeValue] = useState(Fini.d_cp)
   
    const [AeCodeValue, setAeCodeValue] = useState(Fini.d_aeCode)


    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [showChannel, setshowChannel] = useState(false)
    const [showMULTI_PRCE, setshowMULTI_PRCE] = useState(false)

    
  //  const [d_CommAmt, setd_CommAmt] = useState(Fini.d_EstCommAmt)       // Estimate Commission Amount

    const [d_OREBATE, setd_OREBATE] = useState(Fini.d_EstCommAmt)      // Estimate Rebate Amount    // Estimate Rebate Amount
    //const [d_HandlingFee, setd_HandlingFee] = useState(Fini.d_HandlingFee)    
    const [d_HandlingFee, setd_HandlingFee] = useState("100.00")

    const [d_LoanAmt, setd_OLOANAMT] = useState(Fini.d_LoanAmt)    
    const [d_d_ApplyAmount, setd_ApplyAmount] = useState(Fini.d_ApplyAmount)        // Apply Amount 
    const[d_OPREPAID,setd_OPREPAID] = useState(Fini.d_OPREPAID) // Pre-Paid Amount
   // const[d_OAPOST,setd_OAPOST] = useState(Fini.d_OAPOST) // Pre-Paid Amount    
    const[d_OCMAMT,setd_OCMAMT] = useState(Fini.d_OCMAMT)       // Final Commission Amount
    const[d_ORFAMT,setd_ORFAMT] = useState(Fini.d_ORFAMT)       // Refund Amount
    const[d_OINAMT,setd_OINAMT] = useState(Fini.d_OINAMT)       // Interest Amount

   // const[d_CommRate,setd_CommRate] = useState(Fini.d_CommRate)       //Commission Rate 1%  -> cofig

 //  {utils.numToString(utils.StringToNum4(Fini.d_CommRate))}

    const[d_CommRate,setd_CommRate] = useState((Fini.d_CommRate))
   // const[d_AFRCLevyRate,setd_AFRCLevyRate] = useState(Fini.d_AFRCLevyRate)       //AFRC rate 0.00015  
    const[d_AFRCLevyRate,setd_AFRCLevyRate] = useState("0.00015")       //AFRC rate 0.00015  
  
    //  const[d_OLVRATE,setd_LevyRate] = useState(Fini.d_LevyRate)       //Levy rate 0.0005
   // const[d_OSLRATE,setd_OSLRATE] = useState(Fini.d_OSLRATE)       //Tradeing rate 0.00005

    const[d_LevyRate,setd_LevyRate] = useState("0.00270")       //Levy rate 0.0005
   // const[d_OSLRATE,setd_OSLRATE] = useState( 0.00005)       //Tradeing rate 0.00005 <// load config
    const[d_TradingFeeRate,setd_TradingFeeRate] = useState("0.00565")       //Tradeing rate 0.00005 <// load config
    

    const[d_ApplyType,setd_ApplyType] = useState("Y")      	//    Francis Add Default Type = "Yellow Form" For EIPO
   
    const[d_LoanDay,setd_LoanDay] = useState(7)      	//    Francis Add Default Type = "Yellow Form" For EIPO    
    //const[d_OIRATE,setd_OIRATE] = useState(10)

    
    const [d_OIRATE ,setd_OIRATE ] = useState((Fini.d_OIRATE))
    const [d_RebateRate ,setd_RebateRate ] = useState((Fini.d_RebateRate))
    const [d_EstRebateAmt ,setd_EstRebateAmt ] = useState((Fini.d_EstRebateAmt))
 

 
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
 
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }

                setshowChannel( GsbFlag.MULTI_CHNL ) ;
                setshowMULTI_PRCE( GsbFlag.MULTI_PRCE ) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])  

    let o_acCode = Fini.d_acCode
    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    setAcStatusValue(res.data.data[0].d_status)
                    setAeCodeValue (res.data.data[0].d_aeCode)

                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                    setAeCodeValue("")
                }
            
            } catch (error) {
                showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])

    
 
    useEffect(() => {
        // Call reset to get the values of record BrokerTranInfo 
        if ("d_Seq" in Fini) {
            reset(Fini)
        }
    }, [Fini, reset])




    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_Currency" })   
        //register({ name: "d_status" })
    
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    

    useEffect(() => {
        (async () => {
            try { 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})  
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    //setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    //setLatestQtyValue(res.data.data[0].acbal_LatestBal) 
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    console.log("ae.",res.data.data[0].d_aeCode)
                    setAeCodeValue(res.data.data[0].d_aeCode)
                   //setAcName2Value(res.data.data[0].d_commCode)
                  
                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAeCodeValue("")
              
                }
            
            } catch (error) {
               showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])

    if (! isNew && ! ("d_Seq" in Fini)) return null

 
 
/*
    let DescLength = 45
    if ( GsbFlag.LONG_DESC ) {
        DescLength = 60
    }    
*/       




    const onSubmit = (data: FiniInfo) => {
  
        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        };

        data.d_acCode = data.d_acCode.toUpperCase();
        data.d_acName1 = AcName1Value
        data.d_acName2 = AcName2Value
        data.d_aeCode = AeCodeValue
        data.d_Currency = CurrencyValue
        if ( typeof(data.d_Currency) === "undefined" ) {
            data.d_Currency = ""
        }
        data.d_Currency = data.d_Currency.toUpperCase();

        data.d_Seq = data.d_Seq ;
 


        data.d_AFRCLevyRate = d_AFRCLevyRate;
        
        if ( typeof(data.d_OCHQ) === "undefined" ) {
            data.d_OCHQ = ""
        }
        data.d_OCHQ = data.d_OCHQ ;

        if ( typeof(data.d_OIPOST) === "undefined" ) {
            data.d_OIPOST = "N"
        }
        data.d_OIPOST = data.d_OIPOST ;

      
        if ( typeof(data.d_OIAMTPOST) === "undefined" ) {
            data.d_OIAMTPOST = "N"
        }
        data.d_OIAMTPOST = data.d_OIAMTPOST ;

        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }

        (async () => {
            try {
                console.log("call IPOEntryEdit data", data)
                console.log("call IPOEntryEdit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "IPOEntry", cAction: "IPOEntryValidate", isNew: isNew})
                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }
                else
                
                onSave(data)
            } catch (error) {
              ///  showErrorBox(error)

                utils.showError(error)
            }
        })()
      
    }
    
    const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (CurrencyList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CurrencyList.d_CcyDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CurrencyList.d_Currency}
                key={CurrencyList.d_Currency}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (query, CurrencyList, _index, exactMatch) => {
        const normalizedText = `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
        return CurrencyList.d_Currency
    }



    const StkRenderer: ItemRenderer<StkCodeInfo> = (stk, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${stk.d_StkCode}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={stk.d_StkNo}
                key={stk.d_StkNo}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StkPredicate: ItemPredicate<StkCodeInfo> = (query, stk, _index, exactMatch) => {
        const normalizedText = `${stk.d_StkNo} - ${stk.d_StkCode}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StkValueRenderer = (stk: StkCodeInfo) => {
        return stk.d_StkNo
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    const handleViewInfoClick = (event: any): void => {
        event.preventDefault()    
       
      } 

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <ClientPicker gsb={gsb} isOpen={isClPickerOpen} onClose={()=>setIsClPickerOpen(false)} onSelect={(ac)=>{setClCodeValue(ac.d_acCode); setIsClPickerOpen(false)}}/>
      
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>

            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No." labelFor="inputSeqNo"  >
                    <InputGroup className="inputNumberNoSpin" disabled={isNew} id="inputSeqNo" name="d_Seq" autoFocus={isNew} defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Seq && errors.d_Seq.type === "required" && (<span id="Err_msg">Seq No. cannot be Blank</span>)}
                    {errors.d_Seq && errors.d_Seq.types && <span id="Err_msg">{errors.d_Seq.types.message}</span>}
                </FormGroup>

                <FormGroup className="Col-3-1" label="Pre-Paid Amount" labelFor="inputPrePaidAmtoSB"  >
                    {/*<InputGroup className="inputPrePaidAmtoSB" disabled={false} id="inputPrePaidAmtoSB" name="d_OAPOST" autoFocus={isNew}  inputRef={register} /> */}
                    {Fini.d_OAPOST==="Y"
                        ?<Button icon="small-tick" intent="success" text="To SB" />
                        :<Button icon="small-cross"  intent= "warning" text="no to SB" />
                    }
                   
                </FormGroup> 
          
                <FormGroup className="Col-3-1" label="Qty posted" labelFor="inputQtyToSB" >
                    {/*<InputGroup className="inputQtyToSB" disabled={true} id="inputQtyToSB" name="d_OIPOST" autoFocus={isNew} inputRef={register} />*/}
                    {Fini.d_OIPOST==="Y"
                        ?<Button icon="small-tick" intent="success" text="To SB" />
                        :<Button icon="small-cross"  intent= "warning" text="no to SB" />
                    }
                                       
                </FormGroup>
              
         
                <FormGroup className="Col-4-1" label="Amount posted" labelFor="inputAmtPostedToSb">
                   {/*<InputGroup className="inputAmtPostedToSb" disabled={false} id="inputAmtPostedToSb" name="d_OIAMTPOST" autoFocus={isNew}  inputRef={register} /> */}
                   {Fini.d_OIAMTPOST==="Y"
                        ?<Button icon="small-tick" intent="success" text="To SB" />
                        :<Button icon="small-cross"  intent= "warning" text="no to SB" />
                    }                   
                </FormGroup>    

                <FormGroup className="Col-5-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup disabled={true} id="inputInputUser" name="d_Input_User" defaultValue="" placeholder="Input User ..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_Input_User && errors.d_Input_User.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_Input_User && errors.d_Input_User.types && <span id="Err_msg">{errors.d_Input_User.types.message}</span>}
                </FormGroup>
               
            
                <FormGroup className="Col-1-1" label="cheque No." labelFor="inputChequeNo" labelInfo="*">
                    <InputGroup className="inputChequeNo" disabled={isNew} id="inputChequeNo" name="d_OCHQ" autoFocus={isNew}   defaultValue="" inputRef={register} />
                    {errors.d_OCHQ && errors.d_OCHQ.type === "required" && (<span id="Err_msg">Seq No. cannot be Blank</span>)}
                    {errors.d_OCHQ && errors.d_OCHQ.types && <span id="Err_msg">{errors.d_OCHQ.types.message}</span>}
                </FormGroup>

                <FormGroup className="Col-1-2" label="cheque No." labelFor="inputFiniStatus" labelInfo="*">
                    <InputGroup className="inputFiniStatus" disabled={isNew} id="inputFiniStatus" name="d_Status" autoFocus={isNew}   defaultValue="" inputRef={register} />
                    {errors.d_Status && errors.d_Status.type === "required" && (<span id="Err_msg">Seq No. cannot be Blank</span>)}
                    {errors.d_Status && errors.d_Status.types && <span id="Err_msg">{errors.d_Status.types.message}</span>}
                </FormGroup>

            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                 <FormGroup className="Col-1-2" label="Apply date" labelFor="inputOADATE">
                    <InputGroup id="inputOADATE" name="d_ApplyDate" type="date" autoFocus={! isNew} placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_ApplyDate && errors.d_ApplyDate.types && <span id="Err_msg">{errors.d_ApplyDate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="List date" labelFor="inputOLDATE">
                    <InputGroup id="inputOLDATE" name="d_OLDATE" type="date" autoFocus={! isNew} placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} />
                    {errors.d_ListingDate && errors.d_ListingDate.types && <span id="Err_msg">{errors.d_ListingDate.types.message}</span>}
                </FormGroup>                
                <FormGroup className="Col-5-1" label="EIPO">
                    <InputGroup readOnly value={(Fini.d_OGENEPIO)} />                
                </FormGroup>

            </div>
            <div className="Spacing-V-16"/>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputacCode" labelInfo="*">
                    <InputGroup id="inputacCode" value={AcCodeValue} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s)) } inputRef={register({ required: true , maxLength: 8}) } 
                        rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>setAcCodeValue); setIsClPickerOpen(true)}}></Button>}
                    />
                    {errors.d_acCode && errors.d_acCode.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-2" label="Client Name" labelFor="inputAcName1">
 
                    <div>
                    <div id="DispText">{AcName1Value}</div>
                    <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
            </div>
     
          
            <div className="Row">
                <FormGroup className="Col-1-2" label="Stock No." labelFor="inputStk" labelInfo="*">
                    <InputGroup id="inputStk" name ="d_OSTK"  defaultValue="" placeholder="Stock Code..." onChange={utils.handleStringChange(s => setStkValue(s)) } inputRef={register({ required: false }) } />
                    {errors.d_OSTK && errors.d_OSTK.type === "required" && <span id="Err_msg">Stock Code cannot be Blank</span>}              
                    {errors.d_OSTK && errors.d_OSTK.types && <span id="Err_msg">{errors.d_OSTK.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                    <InputGroup id="inputStkNo" name ="d_StkNo"  defaultValue="" placeholder="Stock No. ..." onChange={utils.handleStringChange(s => setStkNoValue(s)) } inputRef={register({ required: false , maxLength: 6}) } />
                    {errors.d_StkNo && errors.d_StkNo.type === "required" && <span id="Err_msg">Stock No. cannot be Blank</span>}
                    {errors.d_StkNo && errors.d_StkNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                    {errors.d_StkNo && errors.d_StkNo.types && <span id="Err_msg">{errors.d_StkNo.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-1-2" label="Stock Name." labelFor="inputStkName" labelInfo="*">
                    <InputGroup id="inputStkName" name ="d_StkName"  defaultValue="" placeholder="Stock Name. ..." onChange={utils.handleStringChange(s => setStkNameValue(s)) } inputRef={register({ required: false }) } />
                    {errors.d_StkName && errors.d_StkName.type === "required" && <span id="Err_msg">Stock Name. cannot be Blank</span>}   
                    {errors.d_StkName && errors.d_StkName.types && <span id="Err_msg">{errors.d_StkName.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-2" label="Comm Rate." labelFor="inputCommRate" >

                {/*<InputGroup id="inputCommRate" name="d_CommRate" type="number" defaultValue={utils.formatNumber4(d_LevyRate)} value={utils.formatNumber4(d_LevyRate)}  onChange={utils.handleNumberChange(n => setd_LevyRate(n))}  autoFocus={! isNew} inputRef={register({ required: true }) } />*/}
                <InputGroup id="inputCommRate" name ="d_CommRate" type="text" defaultValue={(d_CommRate)} value={d_CommRate} onChange={utils.handleStringChange(n => setd_CommRate(n))}  inputRef={register({ required: false }) } />
                    {/*<InputGroup id="inputCommRate" name ="d_CommRate" type="number"  defaultValue=""  inputRef={register({ required: false }) } />*/}
                    {errors.d_CommRate && errors.d_CommRate.types && <span id="Err_msg">{errors.d_CommRate.types.message}</span>}
                </FormGroup >

                {! isNew && <FormGroup className="Col-3-2" label="Consideration" labelFor="inputConsiderartion" >
                    <InputGroup id="inputConsiderartion" name = "d_Turnover"  readOnly={true} defaultValue="" inputRef={register({ required: false }) } />
                    {errors.d_Turnover && errors.d_Turnover.types && <span id="Err_msg">{errors.d_Turnover.types.message}</span>}
                </FormGroup >
                }
                <FormGroup className="Col-5-2" label="Receive Pre-Pay Date" labelFor="inputRecPrePayDate" >
                    <InputGroup id="inputRecPrePayDate" name="d_OPPDATE" type="date" autoFocus={! isNew} placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register({ required: false }) } />                  
                    {errors.d_OPPDATE && errors.d_OPPDATE.types && <span id="Err_msg">{errors.d_OPPDATE.types.message}</span>}
                </FormGroup >
            </div>
            <div className="Row">
                {/*efaultValue={Qty3Value} value={Qty3Value} onChange={utils.handleStringChange(n => setQty3Value(n))}  /> */}
               <FormGroup className="Col-1-2" label="Transaction Levy (%)" labelFor="inputLevyRate" >
                    <InputGroup id="inputLevyRate" name="d_LevyRate" type="text"  defaultValue={(d_LevyRate)} value={(d_LevyRate)}  onChange={utils.handleStringChange(n => setd_LevyRate(n))}  autoFocus={! isNew} inputRef={register({ required: true }) } />
                    {/*<InputGroup id="inputLevyRate" name="d_LevyRate" type="number"  autoFocus={! isNew} inputRef={register({ required: false }) } />   */}
                                  
                    {errors.d_LevyRate && errors.d_LevyRate.types && <span id="Err_msg">{errors.d_LevyRate.types.message}</span>}
                </FormGroup >

                {isNew &&  <FormGroup className="Col-3-2" label="AFRC Levy Rate(%)" labelFor="inputAFRCLevyRate" >
                    {/*<InputGroup id="inputTradeRate" name="d_TradingFeeRate" type="number" autoFocus={! isNew} inputRef={register({ required: false }) } />*/}
                    <InputGroup id="inputAFRCLevyRate" name="d_AFRCLevyRate" type="text"  defaultValue={(d_AFRCLevyRate)} value={(d_AFRCLevyRate)}  onChange={utils.handleStringChange(n => setd_AFRCLevyRate(n))}  autoFocus={! isNew} inputRef={register({ required: true }) } />
                </FormGroup >
                }

                {isNew && <FormGroup className="Col-5-2" label="Trading Fee (%)" labelFor="inputTradeRate" >
                    {/*<InputGroup id="inputTradeRate" name="d_TradingFeeRate" type="number" autoFocus={! isNew} inputRef={register({ required: false }) } />*/}
                    <InputGroup id="inputLevyRate" name="d_TradingFeeRate" type="text"  defaultValue={(d_TradingFeeRate)} value={(d_TradingFeeRate)}  onChange={utils.handleStringChange(n => setd_TradingFeeRate(n))}  autoFocus={! isNew} inputRef={register({ required: true }) } />
                </FormGroup >
                }

                {! isNew && <FormGroup className="Col-3-2" label="Commission Amount." labelFor="inputCommissionAmount" >
                    <InputGroup id="inputCommissionAmount" name="d_EstCommAmt" type="text" readOnly={true} onWheel={ event => event.currentTarget.blur() } onKeyDown={(event) => {if (event.keyCode==38 ||event.keyCode==40 ){handleViewInfoClick(event)}}} autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_EstCommAmt && errors.d_EstCommAmt.types && <span id="Err_msg">{errors.d_EstCommAmt.types.message}</span>}
                </FormGroup >
                }
            </div>
            <div className="Row">


                {! isNew && <FormGroup className="Col-1-2" label="Trading Fee(%)" labelFor="inputTradeRate" >
                    {/*<InputGroup id="inputTradeRate" name="d_TradingFeeRate" type="number" autoFocus={! isNew} inputRef={register({ required: false }) } />*/}
                    <InputGroup id="inputLevyRate" name="d_TradingFeeRate" type="text"  defaultValue={(d_TradingFeeRate)} value={(d_TradingFeeRate)}  onChange={utils.handleStringChange(n => setd_TradingFeeRate(n))}  autoFocus={! isNew} inputRef={register({ required: true }) } />
                </FormGroup >
                }

                {! isNew && <FormGroup className="Col-3-2" label="Lst.Prc & Qty Levy." labelFor="inputLstPrQtyLevy" >
                    <InputGroup id="inputLstPrQtyLevy" name="d_EstTransLevy" type="number" autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_EstTransLevy && errors.d_EstTransLevy.types && <span id="Err_msg">{errors.d_EstTransLevy.types.message}</span>}
                </FormGroup >
                }

               {! isNew && <FormGroup className="Col-5-2" label="AFRC." labelFor="inputAFRC" >
                    <InputGroup id="inputAFRC" name="d_OFLEVY" type="text"  autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_OFLEVY && errors.d_OFLEVY.types && <span id="Err_msg">{errors.d_OFLEVY.types.message}</span>}
                </FormGroup >                
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Apply Price." labelFor="inputApplyPrice" >
                    <InputGroup id="inputTradeRate" name="d_ApplyPrice" type="number"  autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_ApplyPrice && errors.d_ApplyPrice.types && <span id="Err_msg">{errors.d_ApplyPrice.types.message}</span>}
                </FormGroup >
{/*
                <FormGroup className="Col-2-1" label="currency" labelFor="inputCurcy" >
                    <InputGroup id="inputCurcy" name="d_OCURCY" type="number" autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>}
                </FormGroup >
*/}
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                    <FormGroup className="Col-3-2" label="Currency" labelFor="inputCurrency" labelInfo="*">
                        <Suggest2
                                inputProps={{ placeholder: "Currency ..." }}
                                //activeItem={CurrencyList.find((Ccy) => Ccy.d_Currency === CurrencyValue)}
                                //selectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === CurrencyValue)}
                                
                                defaultSelectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === Fini.d_Currency)}
                                items={CurrencyList}
                                itemRenderer={CurrencyRenderer}
                                itemPredicate={CurrencyPredicate}
                                inputValueRenderer={CurrencyValueRenderer}
                                onItemSelect={(IPO) => { setCurrencyValue(Fini.d_Currency) }}
                            />
                        {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>}
                    </FormGroup>
                }
                <FormGroup className="Col-5-2" label="Trading Fee" labelFor="inputTradingFee" >
                    <InputGroup id="inputTradingFee" name="d_EstTradingFee" type="number" autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_EstTradingFee && errors.d_EstTradingFee.types && <span id="Err_msg">{errors.d_EstTradingFee.types.message}</span>}
                </FormGroup >
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Apply Qty." labelFor="inputApplyQty" >
                    <InputGroup id="inputTradingFee" name="d_ApplyQty" type="number" autoFocus={! isNew} inputRef={register({ required: false }) } />        
                    {errors.d_ApplyQty && errors.d_ApplyQty.types && <span id="Err_msg">{errors.d_ApplyQty.types.message}</span>}                    
                </FormGroup >

                {/*Total Amount*/}
                <FormGroup className="Col-3-2" label="Total Amount">                  
                    <InputGroup id="inputTradingFee" name="d_ApplyAmount" readOnly={true} type="text"  defaultValue={utils.formatNumber3(Fini.d_ApplyAmount,2)} value={utils.formatNumber3(Fini.d_ApplyAmount,2)}      autoFocus={! isNew} inputRef={register({ required: false }) } /> 
                    {errors.d_ApplyAmount && errors.d_ApplyAmount.types && <span id="Err_msg">{errors.d_ApplyAmount.types.message}</span>}
                </FormGroup >
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Apply Type(W/Y)" labelFor="inputApplyType" >
                    <InputGroup id="inputApplyType" name="d_ApplyType" type="text"  defaultValue={(d_ApplyType)} value={(d_ApplyType)}  onChange={utils.handleStringChange(n => setd_ApplyType(n))}   autoFocus={! isNew} inputRef={register({ required: false }) } />    
                    {errors.d_ApplyType && errors.d_ApplyType.types && <span id="Err_msg">{errors.d_ApplyType.types.message}</span>}  
                </FormGroup >
                <FormGroup className="Col-3-2" label="Handing Fee"  labelFor="inputHandingFee" >
                      <InputGroup id="inputHandingFee" name="d_HandlingFee" type="text" defaultValue={(d_HandlingFee)} value={(d_HandlingFee)} onChange={utils.handleStringChange(n => setd_HandlingFee(n))} autoFocus={! isNew} inputRef={register({ required: false }) } />                    
                    {errors.d_HandlingFee && errors.d_HandlingFee.types && <span id="Err_msg">{errors.d_HandlingFee.types.message}</span>}  
                </FormGroup >
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Pre-Paid" labelFor="inputPrePaid" >
                    <InputGroup id="inputPrePaid" name="d_OPAYPERC" type="number"   defaultValue={utils.formatNumber(0)}  autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_OPAYPERC && errors.d_OPAYPERC.types && <span id="Err_msg">{errors.d_OPAYPERC.types.message}</span>}
                </FormGroup >

                <FormGroup className="Col-3-2" label="Pre-Paid Amount" labelFor="inputPrePaidAmount" >
                    <InputGroup id="inputPrePaidAmount" name="d_OPREPAID" type="number" defaultValue={utils.formatNumber(0)} autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_OPREPAID && errors.d_OPREPAID.types && <span id="Err_msg">{errors.d_OPREPAID.types.message}</span>}
                </FormGroup >

                <FormGroup className="Col-1-2" label="Interest Rate(%)" labelFor="inputInterestRate" >
                    <InputGroup id="inputInterestRate" name="d_OIRATE" type="number" onWheel={ event => event.currentTarget.blur() }   defaultValue={utils.formatNumber(0)} autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_OIRATE && errors.d_OIRATE.types && <span id="Err_msg">{errors.d_OIRATE.types.message}</span>}
                </FormGroup >

                <FormGroup className="Col-3-2" label="Loan Day" labelFor="inputLoanDay" >
                    <InputGroup id="inputLoanDay" name="d_LoanDay" type="number" onWheel={ event => event.currentTarget.blur() }   defaultValue={utils.formatNumber(d_LoanDay)} value={utils.formatNumber(d_LoanDay)}  onChange={utils.handleNumberChange(n => setd_LoanDay(n))}  autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_LoanDay && errors.d_LoanDay.types && <span id="Err_msg">{errors.d_LoanDay.types.message}</span>}
                </FormGroup >
                <FormGroup className="Col-5-2" label="Loan Amount" labelFor="inputLoanAmount" >
                    <InputGroup id="inputLoanAmount" name="d_LoanAmt" readOnly={true} type="text" autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_LoanAmt && errors.d_LoanAmt.types && <span id="Err_msg">{errors.d_LoanAmt.types.message}</span>}
                </FormGroup >

                <FormGroup className="Col-1-2" label="Rebate Rate(%)" labelFor="inputRebateRate" >
                    <InputGroup id="inputRebateRate" name="d_RebateRate" type="number"  defaultValue={utils.formatNumber(0)}   autoFocus={! isNew} inputRef={register({ required: false }) } />                  
                    {errors.d_RebateRate && errors.d_RebateRate.types && <span id="Err_msg">{errors.d_RebateRate.types.message}</span>}
                </FormGroup >
 
                <FormGroup className="Col-5-2" label="rebate Amount" labelFor="inputRebateAmount" >
                    <InputGroup id="inputRebateAmount" name="d_EstRebateAmt" type="text" autoFocus={! isNew}  inputRef={register({ required: false }) } />                  
                    {errors.d_EstRebateAmt && errors.d_EstRebateAmt.types && <span id="Err_msg">{errors.d_EstRebateAmt.types.message}</span>}
                </FormGroup >
            </div>

 


            
        </form>
    )
}

interface IPOEntryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function IPOEntryMain({ gsb }: IPOEntryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))
    
    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 


    const [data, setData] = useState([] as FiniInfo[])
    const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])


    const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])


    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)


    

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")


    const [ViewMode, setViewMode] = useState("V")    
    
    //const [currentAc, setCurrentAc] = useState({} as FiniInfo)
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [BlankIPO, setBlankIPO] = useState({} as FiniInfo)
    const [currentIPO, setcurrentIPO] = useState({} as FiniInfo)    
    const [CurrentMode, setCurrentMode] = useState("show")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [searchKey, setSearchKey] = useState("StkNo")
    const [searchStkName, setsearchStkName] = useState("")
    const [searchApplyDate, setsearchApplyDate] = useState("")

    const [searchAcName, setsearchAcName] = useState("")
    const [searchStkNo, setsearchStkNo] = useState("")        
    const [searchStk, setsearchStk] = useState("")
    const [MULTI_MKT, setMULTI_MKT] = useState(false)
 
    

  
    BlankIPO.d_acCode = ""
    BlankIPO.d_Currency="HKD"
   // BlankIPO.d_StkNo =""
    BlankIPO.d_aeCode ="C01"
    BlankIPO.d_CommRate = "1.0000"
    BlankIPO.d_AFRCLevyRate ="0.00015"   //AFRC rate
    BlankIPO.d_LevyRate ="0.0005"     //Levy rate
    BlankIPO.d_TradingFeeRate = "0.00565"    //Tradeing rate   A Trading Fee of 0.00565%
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("IPOEntryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("IPOEntryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("IPOEntryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("IPOEntryMain disp GsbFlag: ", GsbFlag)
                //console.log("IPOEntryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("IPOEntryMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("IPOEntryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("IPOEntryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("IPOEntryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) 
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                // get Market List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYList"})

                //console.log("MarketList: ", res.data.data) 
                CurrencyList2.push({d_Currency: "", d_CcyDesc: "as Stock Currency"})
                CurrencyList2.push({d_Currency: "HKD", d_CcyDesc: "HKD"})
                for (let j = 0; j < res.data.data.length; j++) {
                    CurrencyList2.push({d_Currency: res.data.data[j][0], d_CcyDesc: res.data.data[j][1]})
                }
                setCurrencyList(CurrencyList2)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [])



    const PrevRecProcess = async (Fini: FiniInfo) => {
        try {
            console.log("call PrevRecProcess", Fini)
            const res = await gsb.post('/Master', {sb_data: Fini, cPgmId: "IPOEntry", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_StkNo)
            console.log("PrevRecProcess data : ", data)
            console.log("PrevRecProcess res.data : ", res.data.data)
                //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentIPO(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (Fini: FiniInfo) => {
        try {
            Fini.d_AuthTran = ""
            if (isReqAuth2) {
                Fini.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Master', {sb_data: Fini, cPgmId: "IPOEntry", cAction: "DelRec"})
            if (res.data.ok) {
                if (isReqAuth2) {
                    showSuccess("Account No. " + Fini.d_acCode + "[" + Fini.d_StkNo +"] record deleting, waiting for authorization")
                } else {
                    showSuccess("Account No. " + Fini.d_acCode + "[" + Fini.d_StkNo +"] record deleted")
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].d_Seq === res.data.data.d_Seq) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    }
                }
                setcurrentIPO(res.data.data)
               
                setCurrentMode("show")
                if (isReqAuth2) {
                    alert("Account No. " + Fini.d_acCode + "[" + Fini.d_StkNo +"]  record deleting, waiting for authorization")
                }
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Fini: FiniInfo) => {
        try {
            console.log("call NextRecProcess", Fini)
            const res = await gsb.post('/Master', {sb_data: Fini, cPgmId: "IPOEntry", cAction: "NextRec"})
            //console.log("NextRecProcess show 1: ", stk.d_StkNo)
            console.log("NextRecProcess data : ", data)
            console.log("NextRecProcess res.data : ", res.data.data)
                //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentIPO(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (Fini: FiniInfo) => {
        try {
            //console.log("call newOnSave", Fini)
            const res1 = await gsb.post('/GsbFlag');
            //console.log("MenuBar disp user_key: ", res.data.Flag.user_key)
            GsbFlag = res1.data.Flag;
            
            //console.log("MenuBar disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
            const res = await gsb.post('/Master', {sb_data: Fini, cPgmId: "IPOEntry", cAction: "AddRec"})
            if (isReqAuth2) {
                showSuccess("Account No."+ Fini.d_acCode +  " Stock No. [" + Fini.d_StkNo + "] record adding, waiting for authorization")
            } else {
                showSuccess("Account No."+ Fini.d_acCode +  " Stock No. [" + Fini.d_StkNo + "] record added")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setcurrentIPO(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                alert("Account No."+ Fini.d_acCode +  " Stock No. [" + Fini.d_StkNo + "] record adding, waiting for authorization")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }


    const editOnSave = async (Fini: FiniInfo) => {
        try {
            console.log("call editOnSave", Fini)
             //console.log("call newOnSave", Fini)
            const res1 = await gsb.post('/GsbFlag');
             //console.log("MenuBar disp user_key: ", res.data.Flag.user_key)
            GsbFlag = res1.data.Flag;
             
            const res = await gsb.post('/Master', {sb_data: Fini, cPgmId: "IPOEntry", cAction: "UpdateRec"})
            if (isReqAuth2) {
                console.log("Account No. " + Fini.d_acCode + " record saving, waiting for authorization")
               showSuccess("Account No. " + Fini.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        setcurrentIPO(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + Bcan.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Fini.d_acCode + " record saving, waiting for authorization")
                console.log("Account No. " + Fini.d_acCode + " record saving, waiting for authorization")
            } else {
           
                console.log("Account Code " +Fini.d_acCode + " record saved")
               showSuccess("Account Code " +Fini.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setcurrentIPO(res.data.data)
                setCurrentMode("show")
                console.log("call editOnSaved", Fini)
                
            }
        } 
        catch (error) {
            utils.showError(error)
        }
        
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
       // setnTotalPage(0)
        //setPageReachEnd(false)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get IPOEntry list if StkNo have value
        try {
            //console.log("IPOEntryMain onsubmit called", data)
            if ( m_limit > 1 ) {
                console.log("IPOEntryMain onsubmit Search","by=", searchKey,"start=",searchValue)
                console.log("IPOEntryMain onsubmit Search", searchStkNo,searchStkName)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: 1,
                            limit: m_limit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                Stk: searchStk,
                                StkName: searchStkName,
                                AppyDate:searchApplyDate,
                            },
                        },
                        cPgmId: "IPOEntry",
                        cAction: "ListRec",
                    });

                console.log("onSubmit IPOEntry res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentIPO(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_StkNo: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "IPOEntry",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    console.log("GetRec123 res.data.data", res.data.data)
                    setcurrentIPO(res.data.data)
                    setCurrentMode("show")
                } else {
                    alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                Stk: searchStk,
                                StkName: searchStkName,
                                AppyDate:searchApplyDate,
                            },
                            },
                        cPgmId: "IPOEntry",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentIPO(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }
 

    let detail = <IPOEntryView gsb={gsb} Fini={currentIPO} Fini0={currentIPO}  CurrencyList={CurrencyList} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentIPO)} onNew={() => setCurrentMode("new")}  onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentIPO)} onNextRec={() => NextRecProcess(currentIPO)} ViewMode={ViewMode} logKey2={logKey2.trim()} PgmMode="IPOEMTRY" ></IPOEntryView>
    switch (CurrentMode) {
        case "edit":
          
            detail = <IPOEntryEdit gsb={gsb} Fini={currentIPO} CurrencyList={CurrencyList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="IPOEntry" ></IPOEntryEdit>
            break
        case "new":
            //detail = <BrokerTranEntryEdit gsb={gsb} BrokerTran={BlankBrokerTran} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></BrokerTranEntryEdit>

            console.error("new" )
            detail = <IPOEntryEdit gsb={gsb} Fini={BlankIPO} CurrencyList={CurrencyList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } PgmMode="IPOEntry" ></IPOEntryEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>
    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-100" >Seq No.</th> 
                <th className="TCol-StkNo">Account No.</th>            
                <th className="TCol-StkCode">Stock NO</th>
                <th className="TCol-StkNo">Stock Code(OSTK)</th>
                <th className="TCol-StkCode">Stock Name</th>
                <th className="TCol-IPO">Apply Price</th>
                <th className="TCol-Ex_date">Qty</th>
                <th className="TCol-BC_date">Apply TypeWY</th>
                <th className="TCol-BC_date">Total Amount</th>
                <th className="TCol-PY_date">EIPO</th> 
                <th className="TCol-PY_date">Apply Date</th>  
                <th className="TCol-PY_date">Pre-Paid Amt to SB</th>  
                <th className="TCol-PY_date"> Qty posted to SB</th>   
                <th className="TCol-PY_date"> Amount posted to SB</th>  
            </thead>
            <tbody id="ma_tr">
                {data.map((FINI, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_Seq}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_acCode}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_StkNo}</td>                        
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_OSTK}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_StkName}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_ApplyPrice}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_ApplyQty}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_ApplyDate}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{utils.formatNumber2(FINI.d_ApplyAmount)}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_OGENEPIO==='G' ? <Icon  icon="tick"/> : ''}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>{FINI.d_ApplyDate.toUpperCase()}</td>

                        {/*              <td className="TCol-ACName" onClick={() => { setCurrentAc(Bcan) ; MoblieDev && setSelectedTabId("detail") }}>
                            {(Bcan.d_EcpRecordStatus) === "N" && <Button icon="small-tick" intent="success" text="To ECP" />
                            || (Bcan.d_EcpRecordStatus) === "S" &&  <Button icon="small-cross"  intent= "warning" text="Cancel" />
                            }</td>   
                
                        */}
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>
                            {(FINI.d_OAPOST.toUpperCase()==="Y")?<Button icon="small-tick" intent="success" text="To SB" /> :""}
                            </td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>
                            {(FINI.d_OIPOST.toUpperCase()==="Y")?<Button icon="small-tick" intent="success" text="To SB" /> :""}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentIPO(FINI) ; MoblieDev && setSelectedTabId("detail") }}>
                            {(FINI.d_OIAMTPOST.toUpperCase()==="Y")?<Button icon="small-tick" intent="success" text="To SB" /> :""}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                 
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>IPO Entry</H3>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Account Name</option>
                                    <option value="StkNo">StockNo</option>
                                    <option value="StkName">StockName</option>
                                    {/* <option value="StkCode">Stock Code</option> */}
                                    {/* <option value="IPOkNo">IPO No</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "StkNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-2" label="Appy Date" labelFor="searchApplyDate">
                               <InputGroup id="searchApplyDate"   value={searchApplyDate} type ="date"  placeholder="YYYY-MM-DD" onChange={utils.handleStringChange(s => setsearchApplyDate(s))} />
                            </FormGroup>
                            <FormGroup className="Col-1-2" label="Stcok Code" labelFor="searchStk">
                                <InputGroup id="searchStk" value={searchStk} onChange={utils.handleStringChange(s => setsearchStk(s))} />
                            </FormGroup>
                            <FormGroup className="Col-3-1" label="Stock Name." labelFor="searchStkName">
                                <InputGroup id="searchStkNo" value={searchStkName} onChange={utils.handleStringChange(s => setsearchStkName(s))} />
                            </FormGroup>
                            
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default IPOEntryMain