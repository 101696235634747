import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { MultiSelect2} from "@blueprintjs/select";
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";



import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { UserPgmAccess, xGroupAccess, PgmAccessList } from './Types'
import { SystemHeader } from './CommonUI';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "S0008" ;
var isReqAuth2:boolean = false ;


interface UserPgmAccessViewProps {
    gsb: AxiosInstance,
    UserPgmAccess: UserPgmAccess,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function UserPgmAccessView({ gsb, UserPgmAccess = {} as UserPgmAccess, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: UserPgmAccessViewProps) {

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

 
	const [item1, setitem1] = useState('');
	const [items1, setitems1] = useState<any[]>([]);
	const [items1a, setitems1a] = useState<any[]>([]);
	const [item2, setitem2] = useState('');
	const [items2, setitems2] = useState<any[]>([]);
	const [items2a, setitems2a] = useState<any[]>([]);

    const [PgmLists1, setPgmLists1] = useState([] as PgmAccessList[])
    const [PgmLists2, setPgmLists2] = useState([] as PgmAccessList[])

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("UserPgmAccessView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, UserPgmAccess])


    useEffect(() => {
        (async () => {
            try {
                if ( UserPgmAccess.d_Userid.trim().length > 0 ) {
                    console.log("call UserPgmAccessView UserPgmAccess", UserPgmAccess)
                    const res = await gsb.post('/Master', {sb_data: UserPgmAccess, cPgmId: "UserPgmAccess", cAction: "GetUserPgmAccessInfo"})
                    //console.log("UserPgmAccessView res.data.ok", res.data.ok)
                    console.log("UserPgmAccessView res.data.data4", res.data.data4)
                    console.log("UserPgmAccessView res.data.data", res.data.data)
                    console.log("UserPgmAccessView res.data.data2", res.data.data2)
                    console.log("UserPgmAccessView res.data.data3", res.data.data3)

                    if (res.data.ok) {
                        setPgmLists1(res.data.data4)
                        console.log("UserPgmAccessView PgmLists1", PgmLists1)

                        setPgmLists2(res.data.data)
                        console.log("UserPgmAccessView PgmLists2", PgmLists2)

                        setitems1a([])
                        for (let j = 0; j < res.data.data2.length; j++) {
                            items1a.push(res.data.data2[j].d_PgmCode)
                        }
                        setitems1(items1a)

                        setitems2a([])
                        for (let j = 0; j < res.data.data3.length; j++) {
                            items2a.push(res.data.data3[j].d_PgmCode)
                        }
                        setitems2(items2a)
                        console.log("UserPgmAccessView items2a", items2a)
                        console.log("UserPgmAccessView items2", items2)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()

    }, [gsb, UserPgmAccess.d_Userid])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    if (! ("d_Userid" in UserPgmAccess)) return null


    return (
        <div>
            <div className="Row">
            <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="User ID">
                    <InputGroup readOnly value={UserPgmAccess.d_Userid} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 1" labelFor="inputGroupId1">
                    <InputGroup readOnly value={UserPgmAccess.d_GroupId1} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 2" labelFor="inputGroupId2">
                    <InputGroup readOnly value={UserPgmAccess.d_GroupId2} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 3" labelFor="inputGroupId3">
                    <InputGroup readOnly value={UserPgmAccess.d_GroupId3} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 4" labelFor="inputGroupId4">
                    <InputGroup readOnly value={UserPgmAccess.d_GroupId4} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 5" labelFor="inputGroupId5">
                    <InputGroup readOnly value={UserPgmAccess.d_GroupId5} />
                </FormGroup>
            </div>

            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col3-1-5" label="Programm Add" labelFor="inputPgmAdd">
                    <MultiSelect2
                        disabled
                        placeholder="Please Choose"
                        activeItem={PgmLists1.find((pgm1) => pgm1.d_PgmCode === item1)}
                        items={PgmLists1}
                        selectedItems={items1}
                        itemRenderer={(PgmList, itemProps) => {
                            if (! itemProps.modifiers.matchesPredicate) {
                                return null;
                            }
                            const text = `${PgmList.d_PgmName}`;
                            return (
                                <MenuItem
                                    disabled={itemProps.modifiers.disabled}
                                    label={PgmList.d_PgmCode}
                                    key={PgmList.d_PgmCode}
                                    onClick={(elm ) => {
                                        console.log("PgmList.d_PgmCode", PgmList.d_PgmCode)
                                        setitem1(PgmList.d_PgmCode)
                    
                                        setitems1((items) => {
                                            if ( items1.find((pgm1) => pgm1 === PgmList.d_PgmCode) ) {
                                                return [...items1]
                                            }
                                            return [...items1, PgmList.d_PgmCode]
                                        })
                                        console.log("items1", items1)
                                
                    
                                    }}
                                    active={itemProps.modifiers.active}
                                    //text={highlightText(text, itemProps.query)}
                                />
                            )
                        }}
                        onItemSelect={() => { }}
                        tagRenderer={(item1) => item1}
                        onRemove={(item1) => {
                            setitems1(item => items1.filter(elm => elm !== item1))
                            console.log("onRemove item ", item1)
                            console.log("onRemove items1 ", items1)
                        }}
                        onClear={() => setitems1([])}
                    />
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col3-1-5" label="Programm Deduction" labelFor="inputPgmDeduction">
                    <MultiSelect2
                        disabled
                        placeholder="Please Choose"
                        activeItem={PgmLists2.find((pgm1) => pgm1.d_PgmCode === item2)}
                        items={PgmLists2}
                        selectedItems={items2}
                        itemRenderer={(PgmList, itemProps) => {
                            if (! itemProps.modifiers.matchesPredicate) {
                                return null;
                            }
                            const text = `${PgmList.d_PgmName}`;
                            return (
                                <MenuItem
                                    disabled={itemProps.modifiers.disabled}
                                    label={PgmList.d_PgmCode}
                                    key={PgmList.d_PgmCode}
                                    onClick={(elm ) => {
                                        console.log("PgmList.d_PgmCode", PgmList.d_PgmCode)
                                        setitem2(PgmList.d_PgmCode)
                    
                                        setitems2((items) => {
                                            if ( items2.find((pgm1) => pgm1 === PgmList.d_PgmCode) ) {
                                                return [...items2]
                                            }
                                            return [...items2, PgmList.d_PgmCode]
                                        })
                                        console.log("items2", items2)
                                
                    
                                    }}
                                    active={itemProps.modifiers.active}
                                    //text={highlightText(text, itemProps.query)}
                                />
                            )
                        }}
                        onItemSelect={() => { }}
                        tagRenderer={(item2) => item2}
                        onRemove={(item2) => {
                            setitems2(item => items2.filter(elm => elm !== item2))
                            console.log("onRemove item ", item2)
                            console.log("onRemove items2 ", items2)
                        }}
                        onClear={() => setitems2([])}
                    />
                </FormGroup>
            </div>

        </div>
    )
}

interface UserPgmAccessEditProps {
    gsb: AxiosInstance,
    UserPgmAccess?: UserPgmAccess,
    GroupIdList?: xGroupAccess[],
    isNew?: boolean,
    onSave?: (UserPgmAccess: UserPgmAccess, items1: any[] , items2: any[] ) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function UserPgmAccessEdit({ gsb, UserPgmAccess = {} as UserPgmAccess, GroupIdList=[] as xGroupAccess[], isNew = false, onSave = () => { }, onCancel = () => { } }: UserPgmAccessEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<UserPgmAccess>()

    //const [def_d_User_Group1, setdef_d_User_Group1] = useState(UserPgmAccess.d_User_Group1==="Y")


	const [item1, setitem1] = useState('');
    const [items1, setitems1] = useState<any[]>([]);
    const [items1a, setitems1a] = useState<any[]>([]);
    const [item2, setitem2] = useState('');
    const [items2, setitems2] = useState<any[]>([]);
    const [items2a, setitems2a] = useState<any[]>([]);
   

    const [PgmLists1, setPgmLists1] = useState([] as PgmAccessList[])
    const [PgmLists2, setPgmLists2] = useState([] as PgmAccessList[])

  
   
   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_Userid" in UserPgmAccess) {
            reset(UserPgmAccess)
        }
    }, [UserPgmAccess, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_GroupId1" })
        register({ name: "d_GroupId2" })
        register({ name: "d_GroupId3" })
        register({ name: "d_GroupId4" })
        register({ name: "d_GroupId5" })
    }, [register])

    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    useEffect(() => {

        (async () => {
            try {
                if ( UserPgmAccess.d_Userid.trim().length > 0 ) {
                    //console.log("call UserPgmAccessEdit data", UserPgmAccess)
                    const res = await gsb.post('/Master', {sb_data: UserPgmAccess, cPgmId: "UserPgmAccess", cAction: "GetUserPgmAccessInfo"})
                    //console.log("UserPgmAccessEdit res.data.ok", res.data.ok)
                    console.log("UserPgmAccessEdit res.data.data", res.data.data)

                    if (res.data.ok) {
                        setPgmLists1(res.data.data4)
                        console.log("UserPgmAccessView PgmLists1", PgmLists1)

                        setPgmLists2(res.data.data)
                        console.log("UserPgmAccessView PgmLists2", PgmLists2)

                        setitems1a([])
                        for (let j = 0; j < res.data.data2.length; j++) {
                            items1a.push(res.data.data2[j].d_PgmCode)
                        }
                        setitems1(items1a)

                        setitems2a([])
                        for (let j = 0; j < res.data.data3.length; j++) {
                            items2a.push(res.data.data3[j].d_PgmCode)
                        }
                        setitems2(items2a)

                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            


    }, [])


    if (! isNew && !("d_Userid" in UserPgmAccess)) return null

    
    const onSubmit = (data: UserPgmAccess) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_Userid = data.d_Userid.toUpperCase();

        if ( typeof(data.d_GroupId1) === "undefined" ) {
            data.d_GroupId1 = ""
        }
        if ( typeof(data.d_GroupId2) === "undefined" ) {
            data.d_GroupId2 = ""
        }
        if ( typeof(data.d_GroupId3) === "undefined" ) {
            data.d_GroupId3 = ""
        }
        if ( typeof(data.d_GroupId4) === "undefined" ) {
            data.d_GroupId4 = ""
        }


        (async () => {
            try {
                console.log("call UserPgmAccessedit data", data)
                console.log("call UserPgmAccessedit isNew", isNew)
                console.log("call UserPgmAccessedit items1", items1)
                console.log("call UserPgmAccessedit items2", items2)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "UserPgmAccess", cAction: "UserPgmAccessValidate", isNew: isNew,
                                            d_PgmAdd: items1, d_PgmDeduction: items2})
                //console.log("SysConfigedit res.data.ok", res.data.ok)
                //console.log("SysConfigedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data, items1, items2)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    const GroupIdRenderer: ItemRenderer<xGroupAccess> = (GroupIdList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${GroupIdList.d_GroupName}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={GroupIdList.d_GroupId}
                key={GroupIdList.d_GroupId}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const GroupIdPredicate: ItemPredicate<xGroupAccess> = (query, GroupIdList, _index, exactMatch) => {
        const normalizedText = `${GroupIdList.d_GroupId} - ${GroupIdList.d_GroupName}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const GroupIdValueRenderer = (GroupIdList: xGroupAccess) => {
        return GroupIdList.d_GroupId
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                {/*<Button className="Col-2-1" icon="undo" onClick={() => reset(ac)}>Reset</Button>*/}
                {/*<Button className="Col-3-1" icon="tick" type="submit">Save</Button>*/}
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="User ID" labelFor="inputd_Userid">
                    <InputGroup disabled={! isNew} id="inputd_Userid" name="d_Userid" autoFocus={isNew} defaultValue="" placeholder="User ID ..." inputRef={register({ required: true , maxLength: 20}) } />
                    {errors.d_Userid && errors.d_Userid.types && <span id="Err_msg">{errors.d_Userid.types.message}</span>}
                    {errors.d_Userid && errors.d_Userid.type === "required" && (<span id="Err_msg">User ID cannot be blank</span>)}
                    {errors.d_Userid && errors.d_Userid.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 1" labelFor="inputGroupId1">
                    <Suggest2
                            inputProps={{ placeholder: "Group ID ..." }}
                            activeItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId1)}
                            defaultSelectedItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId1)}
                            items={GroupIdList}
                            itemRenderer={GroupIdRenderer}
                            itemPredicate={GroupIdPredicate}
                            inputValueRenderer={GroupIdValueRenderer}
                            onItemSelect={(grp2) => { setValue("d_GroupId1", grp2.d_GroupId) }}
                        />
                    {errors.d_GroupId1 && errors.d_GroupId1.types && <span id="Err_msg">{errors.d_GroupId1.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 2" labelFor="inputGroupId2">
                    <Suggest2
                            inputProps={{ placeholder: "Group ID ..." }}
                            activeItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId2)}
                            defaultSelectedItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId2)}
                            items={GroupIdList}
                            itemRenderer={GroupIdRenderer}
                            itemPredicate={GroupIdPredicate}
                            inputValueRenderer={GroupIdValueRenderer}
                            onItemSelect={(grp2) => { setValue("d_GroupId2", grp2.d_GroupId) }}
                        />
                    {errors.d_GroupId2 && errors.d_GroupId2.types && <span id="Err_msg">{errors.d_GroupId2.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 3" labelFor="inputGroupId3">
                    <Suggest2
                            inputProps={{ placeholder: "Group ID ..." }}
                            activeItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId3)}
                            defaultSelectedItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId3)}
                            items={GroupIdList}
                            itemRenderer={GroupIdRenderer}
                            itemPredicate={GroupIdPredicate}
                            inputValueRenderer={GroupIdValueRenderer}
                            onItemSelect={(grp2) => { setValue("d_GroupId3", grp2.d_GroupId) }}
                        />
                    {errors.d_GroupId3 && errors.d_GroupId3.types && <span id="Err_msg">{errors.d_GroupId3.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 4" labelFor="inputGroupId4">
                    <Suggest2
                            inputProps={{ placeholder: "Group ID ..." }}
                            activeItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId4)}
                            defaultSelectedItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId4)}
                            items={GroupIdList}
                            itemRenderer={GroupIdRenderer}
                            itemPredicate={GroupIdPredicate}
                            inputValueRenderer={GroupIdValueRenderer}
                            onItemSelect={(grp2) => { setValue("d_GroupId4", grp2.d_GroupId) }}
                        />
                    {errors.d_GroupId4 && errors.d_GroupId4.types && <span id="Err_msg">{errors.d_GroupId4.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col3-1-2" label="Group ID 5" labelFor="inputGroupId5">
                    <Suggest2
                            inputProps={{ placeholder: "Group ID ..." }}
                            activeItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId5)}
                            defaultSelectedItem={GroupIdList.find((grp1) => grp1.d_GroupId === UserPgmAccess.d_GroupId5)}
                            items={GroupIdList}
                            itemRenderer={GroupIdRenderer}
                            itemPredicate={GroupIdPredicate}
                            inputValueRenderer={GroupIdValueRenderer}
                            onItemSelect={(grp2) => { setValue("d_GroupId5", grp2.d_GroupId) }}
                        />
                    {errors.d_GroupId5 && errors.d_GroupId5.types && <span id="Err_msg">{errors.d_GroupId5.types.message}</span>}
                </FormGroup>
            </div>

            <div className="Spacing-V-16" />

            <div className="Row">
                <FormGroup className="Col3-1-5" label="Programm Add" labelFor="inputPgmAdd">
                    <MultiSelect2
                        placeholder="Please Choose"
                        activeItem={PgmLists1.find((pgm1) => pgm1.d_PgmCode === item1)}
                        items={PgmLists1}
                        selectedItems={items1}
                        itemRenderer={(PgmList, itemProps) => {
                            if (! itemProps.modifiers.matchesPredicate) {
                                return null;
                            }
                            const text = `${PgmList.d_PgmName}`;
                            return (
                                <MenuItem
                                    disabled={itemProps.modifiers.disabled}
                                    label={PgmList.d_PgmCode}
                                    key={PgmList.d_PgmCode}
                                    onClick={(elm ) => {
                                        //console.log("PgmList.d_PgmCode", PgmList.d_PgmCode)
                                        setitem1(PgmList.d_PgmCode)
                    
                                        setitems1((items) => {
                                            if ( items1.find((pgm1) => pgm1 === PgmList.d_PgmCode) ) {
                                                return [...items1]
                                            }
                                            return [...items1, PgmList.d_PgmCode]
                                        })
                                        console.log("items1", items1)
                                
                    
                                    }}
                                    active={itemProps.modifiers.active}
                                    text={highlightText(text, itemProps.query)}
                                />
                            )
                        }}
                        onItemSelect={() => { }}
                        tagRenderer={(item1) => item1}
                        onRemove={(item1) => {
                            setitems1(item => items1.filter(elm => elm !== item1))
                            console.log("onRemove item ", item1)
                            console.log("onRemove items1 ", items1)
                        }}
                        onClear={() => setitems1([])}
                    />
                    {/* {errors.d_PgmAdd && errors.d_PgmAdd.types && <span id="Err_msg">{errors.d_PgmAdd.types.message}</span>} */}
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col3-1-5" label="Programm Deduction" labelFor="inputPgmDeduction">
                    <MultiSelect2
                        placeholder="Please Choose"
                        activeItem={PgmLists2.find((pgm1) => pgm1.d_PgmCode === item2)}
                        items={PgmLists2}
                        selectedItems={items2}
                        itemRenderer={(PgmList, itemProps) => {
                            if (! itemProps.modifiers.matchesPredicate) {
                                return null;
                            }
                            const text = `${PgmList.d_PgmName}`;
                            return (
                                <MenuItem
                                    disabled={itemProps.modifiers.disabled}
                                    label={PgmList.d_PgmCode}
                                    key={PgmList.d_PgmCode}
                                    onClick={(elm ) => {
                                        //console.log("PgmList.d_PgmCode", PgmList.d_PgmCode)
                                        setitem2(PgmList.d_PgmCode)
                    
                                        setitems2((items) => {
                                            if ( items2.find((pgm1) => pgm1 === PgmList.d_PgmCode) ) {
                                                return [...items2]
                                            }
                                            return [...items2, PgmList.d_PgmCode]
                                        })
                                        console.log("items2", items2)
                                
                    
                                    }}
                                    active={itemProps.modifiers.active}
                                    text={highlightText(text, itemProps.query)}
                                />
                            )
                        }}
                        onItemSelect={() => { }}
                        tagRenderer={(item2) => item2}
                        onRemove={(item2) => {
                            setitems2(item => items2.filter(elm => elm !== item2))
                            console.log("onRemove item ", item2)
                            console.log("onRemove items2 ", items2)
                        }}
                        onClear={() => setitems2([])}
                    />
                    {/* {errors.d_PgmDeduction && errors.d_PgmDeduction.types && <span id="Err_msg">{errors.d_PgmDeduction.types.message}</span>} */}
                </FormGroup>
            </div>


        </form>
    )
}

interface UserPgmAccessMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function UserPgmAccessMain({ gsb }: UserPgmAccessMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as UserPgmAccess[])
    const [GroupIdList, setGroupIdList] = useState([] as xGroupAccess[])
    const [GroupIdList2, setGroupIdList2] = useState([] as xGroupAccess[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as UserPgmAccess)
    const [BlankAc, setBlankAc] = useState({} as UserPgmAccess)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankAc.d_Userid = ""
    BlankAc.d_GroupId1 = ""
    BlankAc.d_GroupId2 = ""
    BlankAc.d_GroupId3 = ""
    BlankAc.d_GroupId4 = ""
    BlankAc.d_GroupId5 = ""


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("UserPgmAccessMain disp user_key: ", res.data.Flag.user_key)
                //console.log("UserPgmAccessMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("UserPgmAccessMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("UserPgmAccessMain disp GsbFlag: ", GsbFlag)
                //console.log("UserPgmAccessMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("UserPgmAccessMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("UserPgmAccessMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("UserPgmAccessMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("UserPgmAccessMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "GroupAccess", cAction: "getGroupId"})

                console.log("Main getGroupId data: ", res.data.data) 
                setGroupIdList2([])
                GroupIdList2.push({d_GroupId: "", d_GroupName: "NIL", d_Auth_Value_Limit_tran: "", d_Can_DeleteMaster: "", d_Can_ViewClientDoc: "", d_Can_ManageClientDoc: ""})
                for (let j = 0; j < res.data.data.length; j++) {
                    GroupIdList2.push(res.data.data[j])
                }
                setGroupIdList(GroupIdList2)
                console.log("Main GroupIdList: ", GroupIdList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    const PrevRecProcess = async (UserPgmAccess: UserPgmAccess) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: UserPgmAccess, cPgmId: "UserPgmAccess", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (UserPgmAccess: UserPgmAccess) => {
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: UserPgmAccess, cPgmId: "UserPgmAccess", cAction: "DelRec"})
            showSuccess("User ID " + UserPgmAccess.d_Userid + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (UserPgmAccess: UserPgmAccess) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: UserPgmAccess, cPgmId: "UserPgmAccess", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (UserPgmAccess: UserPgmAccess, items1: any[] , items2: any[]) => {
        try {
            const res = await gsb.post('/Master', {sb_data: UserPgmAccess, cPgmId: "UserPgmAccess", cAction: "AddRec",
                                        d_PgmAdd: items1, d_PgmDeduction: items2})
            showSuccess("User ID " + UserPgmAccess.d_Userid + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (UserPgmAccess: UserPgmAccess, items1: any[] , items2: any[]) => {
        try {
            console.log("call editOnSave", UserPgmAccess)
            const res = await gsb.post('/Master', {sb_data: UserPgmAccess, cPgmId: "UserPgmAccess", cAction: "UpdateRec",
                                        d_PgmAdd: items1, d_PgmDeduction: items2})
            showSuccess("User ID " + UserPgmAccess.d_Userid + " record saved")
            
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Userid === res.data.data.d_Userid) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                        },
                    },
                    cPgmId: "UserPgmAccess",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.count", res.data.count)
                console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.data[3].d_PgmDeduction", res.data.data[3].d_PgmDeduction)
                //console.log("ListRec res.data.data[3].d_PgmDeduction[0]", res.data.data[3].d_PgmDeduction[0])
                //console.log("ListRec res.data.data[3].d_PgmDeduction[0].d_PgmCode", res.data.data[3].d_PgmDeduction[0].d_PgmCode)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_Userid: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "UserPgmAccess",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    alert(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }


    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                            },
                            },
                        cPgmId: "UserPgmAccess",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <UserPgmAccessView gsb={gsb} UserPgmAccess={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></UserPgmAccessView>
    switch (currentMode) {
        case "edit":
            detail = <UserPgmAccessEdit gsb={gsb} UserPgmAccess={currentAc} GroupIdList={GroupIdList} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></UserPgmAccessEdit>
            break
        case "new":
            detail = <UserPgmAccessEdit gsb={gsb} UserPgmAccess={BlankAc} GroupIdList={GroupIdList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></UserPgmAccessEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page &nbsp;
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(-1)}> &laquo; &nbsp; </span>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <span id="RPageNo2" onClick={() => setnPage(1)}> {1} </span>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage >= 1) && <span > {nPage} </span> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <span id="RPageNo2" onClick={() => setnPage(no.n)}> {no.n} </span>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <span id="RPageNo2" onClick={() => setnPage(nTotalPage)}> &nbsp; {nTotalPage} </span>
                }
                { (nTotalPage > 1) &&
                    <span id="RPageNo2" onClick={() =>PageNo(+1)}> &nbsp; &raquo; </span>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-UserId">User ID</th>
                <th className="TCol-GroupId">Group ID 1</th>
                <th className="TCol-GroupId">Group ID 2</th>
                <th className="TCol-GroupId">Group ID 3</th>
                <th className="TCol-GroupId">Group ID 4</th>
                <th className="TCol-GroupId">Group ID 5</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((UserPgmAccess, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-UserId" onClick={() => { setCurrentAc(UserPgmAccess) ; MoblieDev && setSelectedTabId("detail") }}>{UserPgmAccess.d_Userid}</td>
                        <td className="TCol-GroupId" onClick={() => { setCurrentAc(UserPgmAccess) ; MoblieDev && setSelectedTabId("detail") }}>{UserPgmAccess.d_GroupId1}</td>
                        <td className="TCol-GroupId" onClick={() => { setCurrentAc(UserPgmAccess) ; MoblieDev && setSelectedTabId("detail") }}>{UserPgmAccess.d_GroupId2}</td>
                        <td className="TCol-GroupId" onClick={() => { setCurrentAc(UserPgmAccess) ; MoblieDev && setSelectedTabId("detail") }}>{UserPgmAccess.d_GroupId3}</td>
                        <td className="TCol-GroupId" onClick={() => { setCurrentAc(UserPgmAccess) ; MoblieDev && setSelectedTabId("detail") }}>{UserPgmAccess.d_GroupId4}</td>
                        <td className="TCol-GroupId" onClick={() => { setCurrentAc(UserPgmAccess) ; MoblieDev && setSelectedTabId("detail") }}>{UserPgmAccess.d_GroupId5}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>


    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>User Pgm Access</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="Userid">User ID</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-2 Button-LabeledFormGroup" icon="search" type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-8-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        {/*
                        <div className="Row">
                            <FormGroup className="Col3-1-2" label="Verified Email" labelFor="searchVerifiedEmail">
                                <select  id="searchVerifiedEmail" value={searchVerifiedEmail} onChange={utils.handleStringChange(s => setsearchVerifiedEmail(s))}>
                                    <option value=""> </option>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </FormGroup>
                        </div>
                        */}
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    <div className="Spacing-V-16" />
                    <div >Note : </div>
                    <div >If progamme code are duplicate , use first one </div>
                </Card>
            </form>
        </>
    );
}

export default UserPgmAccessMain