import React, { useState, FormEvent, useEffect, WheelEventHandler } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Callout, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
//import { DateInput3 } from "@blueprintjs/datetime2";
import { IconNames } from "@blueprintjs/icons";
import { AxiosInstance } from 'axios';
import * as utils from "./utils";
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import svgWait2 from './svg/wait2.gif'
import { Account, CRSDataInfo,RegionCodeInfo,CCEPINFO} from './Types'
import { SystemHeader, ClientPicker} from './CommonUI';
import i18next from 'i18next';
import { access } from 'fs';

interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "H0002" ;
var isReqAuth2:boolean = false ;

interface HkCrsDataMastViewProps {
    gsb: AxiosInstance,
    Crs: CRSDataInfo,
    Crs0: CRSDataInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onReplicate?: () => void,  
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
    PgmMode:any,
    SuspendAC:boolean,
}


function HkCrsDataMastView({ gsb, Crs = {} as CRSDataInfo, Crs0 = {} as CRSDataInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onReplicate = () => { },  onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 , PgmMode,SuspendAC}: HkCrsDataMastViewProps) {
    const [isReplicate, setisReplicate] = useState(false)
    const { t, i18n } = useTranslation();
    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 


    const [showSIMore, setshowSIMore] = useState(true)

    //const [BlankCRSD, setBlankCRSD] = useState({} as CRSDataInfo)   

    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")



    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkCrsDataMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Crs])

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: Crs.d_acCode})
                
                if ( Crs.d_acCode.trim().length > 0 && res.data.count > 0 )
                {
                    console.log(" Crs.d_acCode=", Crs.d_acCode,res.data.count)
                    //console.log(res.data.data[0].d_acName1)
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)

                    //console.log(res.data.data[0].d_status)
                    setAcStatusValue(res.data.data[0].d_status)
                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_acCode" in Crs)) return null

    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>


    return (
        <div>
        { ViewMode === "V" && <div className="Row No-Print">
            <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
            {<Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew() } >New</Button>}
 
            <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
            <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="Delete"
                    canEscapeKeyCancel={true}
                    icon="trash"
                    intent={Intent.DANGER}
                    isOpen={isDeletePress}
                    onCancel={ () => setisDeletePress(false) }
                    onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                >
                    <p>
                        Are you sure to Delete ?
                    </p>
                </Alert>
            <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>

            { isReqAuth && 
                <Callout className="Col-7-2"
                    icon="warning-sign"
                    intent={Intent.WARNING}
                >
                    Require Authority
                </Callout>
            }

        </div> }
        
        { ViewMode === "L" && <div>
            Account No. : {Crs.d_acCode}
            { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
            { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
            { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
            <div className="Spacing-V-16" />
        </div> }

        <div className="Spacing-V-16" />        
       
     
        <div className="Row">
 
             <FormGroup className="Col-1-2" label="Account No." labelInfo="*">
                <InputGroup readOnly value={Crs.d_acCode} />
            </FormGroup>

            <FormGroup className="Col-1-3" label="Account Balance">
                <InputGroup readOnly value={utils.formatNumber2(Crs.d_CRSBal)} />
            </FormGroup>
            <FormGroup className="Col-1-3" label="CRS501(Dividends)">
                <InputGroup readOnly value={utils.formatNumber2(Crs.d_CRS501)} />
            </FormGroup>

            <FormGroup className="Col-1-3" label="CRS502(Interest)">
                <InputGroup readOnly value={utils.formatNumber2(Crs.d_CRS502)} />
            </FormGroup>
            <FormGroup className="Col-1-3" label="CRS503(Gross Proceeds / Redemptions)">
                <InputGroup readOnly value={utils.formatNumber2(Crs.d_CRS503)} />
            </FormGroup>
            <FormGroup className="Col-1-3" label="CRS504">
                <InputGroup readOnly value={utils.formatNumber2(Crs.d_CRS504)} />
            </FormGroup>

            <FormGroup className="Col-1-3" label="ReportYear">
                <InputGroup readOnly value={(Crs.d_ReportYear)} />
            </FormGroup>
        </div>
 
            <div className="Spacing-V-16" />
        </div> 
    
    )
}

interface HkCrsDataMastEditProps {
    gsb: AxiosInstance,
    Crs?: CRSDataInfo,
    CrsData?:CRSDataInfo,  
    isNew?: boolean,
    onSave?: (Crs: CRSDataInfo) => void,
    onCancel?: () => void;
    PgmMode: any, 
    SuspendAC:boolean,
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function HkCrsDataMastEdit({ gsb, Crs = {} as CRSDataInfo, isNew = false, onSave = () => { }, onCancel = () => { } , PgmMode,SuspendAC}: HkCrsDataMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<CRSDataInfo>()
    const { t, i18n } = useTranslation();    
    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Crs])

    const [AcCodeValue, setAcCodeValue] = useState(Crs.d_acCode)
    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")

    const [showSIMore, setshowSIMore] = useState(true)
    // const [search, setse] = useState(Crs.d_acCode)
    const [isOpened, setIsOpened] = useState(false);
 
     //const [def_d_NetAsset, setdef_d_NetAsset] = useState(ac.d_NetAsset)
 
    // const [SendemailValue, setSendemailValue] = useState(Crs.d_sendemail==="Y")
 
     const [def_d_JurisdictionofResidence3, setdef_d_JurisdictionofResidence3] = useState("")
  
     const [searchKey, setSearchKey] = useState("acCode")
 
    const [s_CRS501, sets_CRS501] = useState(utils.numToString(Crs.d_CRS501)) 
    const [s_CRS502, sets_CRS502] = useState(utils.numToString(Crs.d_CRS502))
    const [s_CRS503, sets_CRS503] = useState(utils.numToString(Crs.d_CRS503))
    const [s_CRS504, sets_CRS504] = useState(utils.numToString(Crs.d_CRS504))
    const [s_CRSBal, sets_d_CRSBal] = useState(utils.numToString(Crs.d_CRSBal))  
    const [s_ReportYear, sets_ReportYear] = useState(Crs.d_ReportYear)

 
    const [setClCodeValue, setSetClCodeValue] = useState(() => setAcCodeValue)
    const [isClPickerOpen, setIsClPickerOpen] = useState(false)
    const AcNOToolTip = 
     <div>
         <div>Account No. </div>
         <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
         <div>Margin A/C : MARGIN=Mxxxx </div> 
         <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
    </div>
 
    
    useEffect(() => {
         // Call reset to set default values if ac is passed
         if ("d_acCode" in Crs) {
             reset(Crs)
         }
     }, [Crs, reset])

     useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected      
        
       // (Crs) => { setValue("d_ExeCCEP_FirmID", Crs.ParticipantID);  setValue("d_ExeCCEP_FirmName", Crs.ParticipantName)}}

    }, [register])

    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }    
   
        (async () => {
            try {
                // Get Last Report Year
                const res = await gsb.post('/Master',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "HkCrsDataMast",
                    cAction: "GetLastReportYear",

                });                
                sets_ReportYear(res.data.data)                
            } catch (error) {
                utils.showError(error)
            }
        }) ()
       
        
    }, [])

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    setAcStatusValue(res.data.data[0].d_status)

                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                }
            
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])

    if (!isNew && !("d_acCode" in Crs)) return null
    const onSubmit = (data: CRSDataInfo) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = AcCodeValue;
        data.d_CRS501 = utils.StringToNum(s_CRS501);
        data.d_CRS502 = utils.StringToNum(s_CRS502);
        data.d_CRS503 = utils.StringToNum(s_CRS503);
        data.d_CRS504 = utils.StringToNum(s_CRS504);
        data.d_CRSBal = utils.StringToNum(s_CRSBal);
        data.d_ReportYear = Crs.d_ReportYear;   
        if ( typeof(data.d_ReportYear) === "undefined" ) {
            data.d_ReportYear = s_ReportYear
        }
   
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        data.d_acCode = data.d_acCode.toUpperCase();     
        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = ""
        }
        (async () => {
            try {
                //console.log("call HkCrsDataMastedit data", data)
                //console.log("call HkCrsDataMastedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "HkCrsDataMast", cAction: "HkCrsDataValidate", isNew: isNew})
                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }
                onSave(data)
            } catch (error) {
                showErrorBox(error)
            }
        })()
    }

    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    let acNoLength = 8
    if ( GsbFlag.user_key === "DJSEC" ) {
        acNoLength = 10
    }


   /*<form onSubmit={handleSubmit(onSubmit)}>*/
   return (
    <form onKeyDown={handleHotkey}>
         <ClientPicker gsb={gsb} isOpen={isClPickerOpen} onClose={()=>setIsClPickerOpen(false)} onSelect={(ac)=>{setClCodeValue(ac.d_acCode); setIsClPickerOpen(false)}}/>
        <div className="Row">
            <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
            <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            { isReqAuth && 
                <Callout className="Col-6-2"
                    icon="warning-sign"
                    intent={Intent.WARNING}
                >
                    Require Authority
                </Callout>
            }
        </div>
        <div className="Spacing-V-16" />
        <div className="Row">
         	{!isNew &&
            <FormGroup className="Col-1-2" label="Account No." labelInfo="*">
                <InputGroup readOnly value={Crs.d_acCode} />
            </FormGroup>        
            }

         	{isNew &&

            <FormGroup className="Col-1-2" label="Account No." labelFor="inputacCode" labelInfo="*">
                <InputGroup id="inputacCode" value={AcCodeValue} disabled={false} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s.toUpperCase())) } inputRef={register({ required: true , maxLength: acNoLength}) } 
                    rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>setAcCodeValue); setIsClPickerOpen(true)}}></Button>}
                />
                {errors.d_acCode && errors.d_acCode.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is {acNoLength}</span>}
                {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
            </FormGroup>
			}
            <FormGroup className="Col-1-3" label="Account Balance" labelFor="inputCRSBal">
                 <InputGroup id="inputCRSBal" defaultValue={s_CRSBal} value={s_CRSBal} onChange={utils.handleStringChange(n => sets_d_CRSBal(n))} />
                {errors.d_CRSBal && errors.d_CRSBal.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                {errors.d_CRSBal && errors.d_CRSBal.types && <span id="Err_msg">{errors.d_CRSBal.types.message}</span>}
            </FormGroup>            
            <FormGroup className="Col-1-3" label="CRS501(Dividends)" labelFor="inputCRS501">
                <InputGroup id="inputCRS501" defaultValue={s_CRS501} value={s_CRS501} onChange={utils.handleStringChange(n => sets_CRS501(n))} />
                {errors.d_CRS501 && errors.d_CRS501.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                {errors.d_CRS501 && errors.d_CRS501.types && <span id="Err_msg">{errors.d_CRS501.types.message}</span>}
            </FormGroup>
            <FormGroup className="Col-1-3" label="CRS502(Interest)" labelFor="inputCRS502">
                <InputGroup id="inputCRS502" defaultValue={s_CRS502} value={s_CRS502} onChange={utils.handleStringChange(n => sets_CRS502(n))} />
                {errors.d_CRS502 && errors.d_CRS502.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                {errors.d_CRS502 && errors.d_CRS502.types && <span id="Err_msg">{errors.d_CRS502.types.message}</span>}
            </FormGroup>
            <FormGroup className="Col-1-3" label="CRS503(Gross Proceeds / Redemptions)" labelFor="inputCRS503">
                <InputGroup id="inputCRS503" defaultValue={s_CRS503} value={s_CRS503} onChange={utils.handleStringChange(n => sets_CRS503(n))} />
                {errors.d_CRS503 && errors.d_CRS503.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                {errors.d_CRS503 && errors.d_CRS503.types && <span id="Err_msg">{errors.d_CRS503.types.message}</span>}
            </FormGroup>
            <FormGroup className="Col-1-3" label="CRS504(Other income generated with respect to the assets held in the account)" labelFor="inputCRS504">
                 <InputGroup id="inputCRS504" defaultValue={s_CRS504} value={s_CRS504} onChange={utils.handleStringChange(n => sets_CRS504(n))} />
                {errors.d_CRS504 && errors.d_CRS504.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                {errors.d_CRS504 && errors.d_CRS504.types && <span id="Err_msg">{errors.d_CRS504.types.message}</span>}
            </FormGroup> 
         
            {!isNew &&
            <FormGroup className="Col-1-3" label="Report Year" labelFor="inputReportYear">
                 <InputGroup id="inputReportYear" defaultValue={s_ReportYear} disabled={false} value={s_ReportYear} onChange={utils.handleStringChange(n => sets_ReportYear(n))} />
                {errors.d_ReportYear && errors.d_ReportYear.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                {errors.d_ReportYear && errors.d_ReportYear.types && <span id="Err_msg">{errors.d_ReportYear.types.message}</span>}
            </FormGroup>
            }

            {isNew &&
            <FormGroup className="Col-1-3" label="Report Year" labelFor="inputReportYear">
                 <InputGroup id="inputReportYear" defaultValue={s_ReportYear} disabled={true} value={s_ReportYear}  />
                {errors.d_ReportYear && errors.d_ReportYear.type === "min" && <span id="Err_msg">Price sholud be greater than 0 </span>}
                {errors.d_ReportYear && errors.d_ReportYear.types && <span id="Err_msg">{errors.d_ReportYear.types.message}</span>}
            </FormGroup>
            }

        </div>
    </form>
    )
}
interface HkCrsDataMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function HkCrsDataMastMain({ gsb }: HkCrsDataMastMainProps) {
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()
    const [s_AcStatus, sets_AcStatus] = useState("")

    const [s_ReportYear, sets_ReportYear] = useState("")

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [data, setData] = useState([] as CRSDataInfo[])


    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchCrsStatus,setsearchCrsStatus] = useState("")
    const [searchHolderType,setsearchHolderType] = useState("")
    const [searchName, setSearchName] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as CRSDataInfo)
    const [BlankAc, setBlankAc] = useState({} as CRSDataInfo)
    const [CurrentLog1, setCurrentLog1] = useState({} as CRSDataInfo)
    const [CurrentLog0, setCurrentLog0] = useState({} as CRSDataInfo)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
    


    const { t, i18n } = useTranslation();
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');        
                GsbFlag = res.data.Flag;
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
             
                UserID = res.data.Flag.UserID  
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setSUSPEND_AC(GsbFlag.SUSPEND_AC);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("HkCrsDataMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }
        
        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "HkCrsDataMast", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("HkCrsDataMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])




    const PrevRecProcess = async (Crs: CRSDataInfo) => {
        try {
            //console.log("call PrevRecProcess", crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsDataMast", cAction: "PrevRec"})
            console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode ) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const ReplicateProcess = async (Crs: CRSDataInfo) => {
        try {
            //console.log("call ReplicateProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsDataMast", cAction: "ReplicateRec"})
            showSuccess("Account No. " + Crs.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            setisShowAlert1(true)
            setShowAlert1Msg("Account No. " + Crs.d_acCode + "["+ res.data.data.d_acHolderNo +"] record Holder No. Replicated")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    } 
  
    const DeleteProcess = async (Crs: CRSDataInfo) => {
        try {
            Crs.d_AuthTran = ""
            if (isReqAuth2) {
                Crs.d_AuthTran = "Y"
            }
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsDataMast", cAction: "DelRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode + " record deleting, wait for Authority")
            } else {
                showSuccess("Account No. " + Crs.d_acCode + " is record deleted")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Crs.d_acCode + " ["+ Crs.d_acHolderNo + " record deleting, wait for Authority")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + "  record deleting, wait for Authority")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Crs: CRSDataInfo) => {
        try {
            //console.log("call NextRecProcess", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsDataMast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode ) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const newOnSave = async (Crs: CRSDataInfo) => {
        try {
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsDataMast", cAction: "AddRec"})

        
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode +  " record adding, wait for Authority")
            } else {
                showSuccess("Account Code " + Crs.d_acCode +  " record added")

                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }    

            setCurrentAc(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                //alert("Account No. " + Crs.d_acCode + " record adding, wait for Authority")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " record adding, wait for Authority")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const editOnSave = async (Crs: CRSDataInfo) => {
        try {
            console.log("call editOnSave", Crs)
            const res = await gsb.post('/Master', {sb_data: Crs, cPgmId: "HkCrsDataMast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + Crs.d_acCode + " record saving, wait for Authority")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + Crs.d_acCode + " record saving, wait for Authority")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + Crs.d_acCode + " record saving, wait for Authority")
            } else {
                showSuccess("Account Code " +Crs.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            utils.showError(error)
        }
    }


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        PageShowInfo: aPageShowInfo,
                        refreshPageShow: true,
                        backward: searchBackward,
                        SortByName: false,   
                        filters: {
                            AcStatus:s_AcStatus,                   
                            Name: searchName,                       
                            Holdertype : searchHolderType,                          
                            CrsStatus:searchCrsStatus,
                            ReportYear:s_ReportYear,
                        },                                             
                    },
                    cPgmId: "HkCrsDataMast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                setData(res.data.data)
                setaPageShowInfo(res.data.PageShowInfo)

                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    {/*
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                    */}
                    const aPageNumber1 = []
                    let k = 0
                    for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                        if ( res.data.PageShowInfo[j-1].pageShow ) {
                            k++
                            aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                            if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                setPageReachEnd(true)
                            }
                        }
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
                    //console.log("ListRec k", k, aPageNumber1)
                } else {
                    setnPage(0)
                    setnTotalPage(0)
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "HkCrsDataMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            SortByName: false,
                        },
                        cPgmId: "HkCrsDataMast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && selectedTabId == "list" ) {    
            setShowWait(true)
            setPageReachEnd(false)
            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: searchBackward,
                            SortByName: false, 
                            },
                        cPgmId: "HkCrsDataMast",
                        cAction: "ListRec",
                    });
                    //console.log(res.data.data)
                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)

                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }

                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }

                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }
  // currentMode = show
  let detail = <HkCrsDataMastView gsb={gsb} Crs={currentAc} Crs0={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} PgmMode="HKCRS"  SuspendAC={SUSPEND_AC} ></HkCrsDataMastView>
  switch (currentMode) {
      case "edit":
          detail = <HkCrsDataMastEdit gsb={gsb} Crs={currentAc}  onSave={editOnSave} onCancel={() => setCurrentMode("show")} PgmMode="HKCRS" SuspendAC={SUSPEND_AC} ></HkCrsDataMastEdit>
          break
      case "new":
          detail = <HkCrsDataMastEdit gsb={gsb} Crs={BlankAc}  isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } PgmMode="HKCRS" SuspendAC={SUSPEND_AC} ></HkCrsDataMastEdit>
          break
  }

  let logKeyViewRec = <HkCrsDataMastView gsb={gsb} Crs={CurrentLog1} Crs0={CurrentLog0} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")}  onReplicate={() => ReplicateProcess(currentAc)} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} PgmMode="HKCRS" SuspendAC={SUSPEND_AC}  ></HkCrsDataMastView>


  function PageNo(n: number) {
      if (n > 0 ) {
          if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
              setnPage(nPage+n)
          }
      } else
      {
          if ( nPage+n > 0 ) {
              setnPage(nPage+n)
          }
      }
      return null;
  }

  let Pagination = <>
      <div className="Row">
          <FormGroup className="Col-1-12" label="">
              Page
              { (nPage > 0) &&
                  <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
              }
               {aPageNumber.map((no, j) => 
                  (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
              )}
              { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
              {aPageNumber.map((no, j) => 
                  (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
              )}
              { (nPage > 0) &&
                  <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
              }
              { (nPage > 0) && ! ( PageReachEnd ) &&
                  <Button minimal >...</Button>
              }
          </FormGroup>
      </div>
   </>
   let list = <>      
   <div className="Row">  
       {/* <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
   </div>
   <div className="Spacing-V-16" />
   {ShowWait && <div>{imgWait}</div> }
   {nPage > 0 && Pagination}
        
   <HTMLTable striped interactive condensed>
       <thead>
           <th className="TCol-ACCode">Account</th>
           <th className="TCol-150"><div className="Align-R">Account Balace</div></th> 
           <th className="TCol-150"><div className="Align-R">CRS501</div></th>
           <th className="TCol-150"><div className="Align-R">CRS502</div></th>
           <th className="TCol-150"><div className="Align-R">CRS503</div></th>
           <th className="TCol-150"><div className="Align-R">CRS504</div></th>
           <th className="TCol-150"><div className="Align-R">Report Year</div></th>         
          {/* <th className="TCol-ACName">CNConsent</th> 
           <th className="TCol-ACName">CNConsent DATE</th> */}
       </thead>
       <tbody id="ma_tr">
           {data.map((Crs, i) =>
               <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                   <td className="TCol-ACCode" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}><div className="Align-R">{Crs.d_acCode}</div></td>
                   <td className="TCol-150" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}><div className="Align-R">{Crs.d_CRSBal}</div></td> 
                   <td className="TCol-150" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}><div className="Align-R">{Crs.d_CRS501}</div></td>
                   <td className="TCol-150" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}><div className="Align-R">{Crs.d_CRS502}</div></td> 
                   <td className="TCol-150" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}><div className="Align-R">{Crs.d_CRS503}</div></td> 
                   <td className="TCol-150" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}><div className="Align-R">{Crs.d_CRS504}</div></td> 
                   <td className="TCol-150" onClick={() => { setCurrentAc(Crs) ; MoblieDev && setSelectedTabId("detail") }}><div className="Align-R">{Crs.d_ReportYear}</div></td>
                </tr>
           )}
       </tbody>
   </HTMLTable>
   {ShowWait && nPage > 0 && <div>{imgWait}</div> }
   {nPage > 0 && Pagination}   
    </>
    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H4><div style={{justifyContent: 'flex-end'}}>CRS Data Master</div></H4>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Name</option>                                    
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        {/*<Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                    </div>
                    <Collapse isOpen={showFilters}>



                    </Collapse>
                  
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>HKCRS Data Log View</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}
export default HkCrsDataMastMain

