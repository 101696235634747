import React, { useState, FormEvent, useEffect } from 'react';

import { FormGroup, InputGroup, AnchorButton, Button, FileInput, HTMLTable, Position, Toaster, Intent, ProgressBar, Collapse, Checkbox  } from "@blueprintjs/core";
import { Alert, Dialog, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";

import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';

import svgWait2 from './svg/wait2.gif'

import { SystemHeader } from './CommonUI';

var GsbFlag: any;


interface SBBackupMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}

interface BackupInfo {
    backupName: string,
    size: number,
    date: string,
    time: string
}


function SBBackupMain({ gsb }: SBBackupMainProps) {
    
    
    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [isRestore, setisRestore] = useState(false)
    const [BackupNamesuffix, setBackupNamesuffix] = useState("_Manually")
    const [RestoreName, setRestoreName] = useState("")

    const [file1Label, setFile1Label] = useState("Choose Restore file...")
    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [backupInfoList, setBackupInfoList] = useState([] as BackupInfo[])
    const [backupMsg, setbackupMsg] = useState("")
    const [restorepMsg, setrestorepMsg] = useState("")
    const [startRestoreProcess, setstartRestoreProcess] = useState(false)


    const [ShowWait, setShowWait] = useState(true)
    const [ShowRpt, setShowRpt] = useState(false)
    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")

 

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab) ;
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    useEffect(() => {
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await gsb.post('/tasklist', taskList.map((t) => t.id));
                    //console.log("tasklist res.data.data", res.data.data)
                    setTaskList(res.data.data)
                } catch (error) {
                    utils.showError(error)
                }
            }, 2*1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, gsb])

    useEffect(() => {
        (async () => {
            setbackupMsg("")
            setrestorepMsg("")
            setisShowAlert1(false)
            setShowAlert1Msg("")
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "xProcess",
                });
                if ( res.data.count > 0 ) {
                    if ( res.data.data[0].x_Porcessing.substr(2,6) == "Backup" ) {
                        setbackupMsg(res.data.data[0].x_Porcessing)
                    }
                    if ( res.data.data[0].x_Porcessing.substr(2,6) == "Restor" ) {
                        setrestorepMsg(res.data.data[0].x_Porcessing)
                    }
                    if ( res.data.data[0].x_Porcessing.substr(0,10) == "2,Restored" ) {
                        if ( startRestoreProcess ) {
                            setstartRestoreProcess(false)
                            setisShowAlert1(true)
                            setShowAlert1Msg("Restore Completed !!!")
                        }
                    }
                }
            } catch (error) {
                utils.showError(error)
            }
        })();
    }, [taskList, gsb])


    const refreshBackupList = () => {
        (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        //
                    },
                    cPgmId: "backuplist",
                });                
                setBackupInfoList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    const onSBBackup1 = () => {
        //console.log("onSBBackup fileInfoList", fileInfoList);
        setShowOK(false)
        ;

        (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        BackupNamesuffix: BackupNamesuffix,
                    },
                    cPgmId: "SBBackup1",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }


    const onSBBackup2 = () => {
        //console.log("onSBBackup fileInfoList", fileInfoList);
        setShowOK(false)
        ;

        (async () => {
            try {
                const res = await gsb.post('/SysMaint',
                    {   sb_data: {
                        BackupNamesuffix: BackupNamesuffix,
                    },
                    cPgmId: "SBBackup2",
                });                
                //console.log("SysMaint res.data.data", res.data.data)
                setTaskList(res.data.data)
                setShowTasks(true)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }


    const onSBRetore = (RestoreName: string) => {
        //console.log("onSBRetore backupName", backupName);
        (async () => {
            if ( RestoreName.trim().length > 0 )
            {
                try {
                    const res = await gsb.post('/SysMaint',
                        {   sb_data: {
                            RestoreName: RestoreName,
                        },
                        cPgmId: "SBRestore",
                    });                
                    //console.log("SysMaint res.data.data", res.data.data)
                    setTaskList(res.data.data)
                    setShowTasks(true)
                    setstartRestoreProcess(true)
                } catch (error) {
                    utils.showError(error)
                }
            }
        })()
    }



    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }



    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <p><H3>Backup Processing</H3></p>
                    <div>
                        <div>Process : {backupMsg}</div>
                    </div >
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <FormGroup className="Col-1-2" label="Backup File Name Suffix" labelFor="inputNameSuffix">
                            <InputGroup
                                id="inputNameSuffix"
                                autoFocus
                                placeholder="Backup File Name Suffix ..."
                                value={BackupNamesuffix}
                                onChange={utils.handleStringChange(s => setBackupNamesuffix(s))}
                            />
                        </FormGroup>
                    </div>
                    <div className="Spacing-V-8"></div>
                    <div className="Spacing-V-8"></div>
                    <div className="Row">
                        <Button disabled={! ShowOK} className="Col-1-2" icon={IconNames.LIST} onClick={onSBBackup1}>Backup 3B1 (Call SBBACKUP.BAT)</Button>
                        <Button disabled={! ShowOK} className="Col-3-2" icon={IconNames.LIST} onClick={onSBBackup2}>Backup 3B2 (Call SBZIP.BAT)</Button>
                        <Button className="Col-5-1" icon={IconNames.LIST} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <Collapse isOpen={showTasks}>
                        <p>Tasks</p>
                        <HTMLTable striped interactive condensed>
                            <thead>
                                <th className="TCol-Ref">File Name</th>
                                <th className="TCol-Progress">Progress</th>
                                <th className="TCol-Message">Message</th>
                                <th className="TCol-Log">Log</th>
                            </thead>
                            <tbody>
                                {taskList.map((t, i) =>
                                    <tr key={i}>
                                        <td className="TCol-Ref">{t.ref}</td>
                                        <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                        <td className="TCol-Message">{t.message}</td>
                                        <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                                    </tr>
                                )}
                            </tbody>
                        </HTMLTable>
                        <div className="Spacing-V-16"></div>
                        <Button className="Col-1-2" onClick={() => window.location.reload()}>Refresh Status</Button>
                        <div className="Spacing-V-16"></div>
                    </Collapse>
                    <br></br>
                    <p>Notes</p>
                    <div>
                        <div>Backup 3B1 (Call SBBACKUP.BAT)</div>
                        <div>Backup 3B2 after System End (Call SBZIP.BAT)</div>
                    </div >
                    <br></br>
                    <p><H3>Restore Processing</H3></p>
                    <div>
                        <div>Process : {restorepMsg}</div>
                    </div >
                    <div className="Spacing-V-8"></div>
                    {/*
                    <div className="Row">
                        <FileInput inputProps={{ name: "file1" }} text={file1Label} onInputChange={utils.handleStringChange((s) => setFile1Label(utils.fileBase(s)))} />
                    </div>
                    */}
                    <Button className="Col-1-1" icon={IconNames.REFRESH} onClick={() => refreshBackupList()}>Refresh Restore File List</Button>
                    <HTMLTable striped interactive condensed>
                        <thead>
                            <tr>
                                <th className="TCol-FileName">Backup File Name</th>
                                <th className="TCol-DateTime">Time</th>
                                <th className="TCol-Records"><div className="Align-R">Size</div></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {backupInfoList.map((f, i) =>
                                <tr key={i}>
                                    <td className="TCol-FileName">{f.backupName}</td>
                                    <td className="TCol-DateTime">{f.date+' '+f.time}</td>
                                    <td className="TCol-Records"><div className="Align-R">{utils.fileSize(f.size)}</div></td>
                                    <td><AnchorButton icon={IconNames.DOWNLOAD} href={`${gsb.defaults.baseURL}/backupdownload?backupName=${f.backupName}`}>Download</AnchorButton></td>
                                    <td><Button icon={IconNames.UNARCHIVE} disabled={false} onClick={() => { setRestoreName(f.backupName); setisRestore(true) }}>Restore</Button></td>
                                </tr>
                            )}
                        </tbody>
                    </HTMLTable>
                    <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="Restore"
                        cancelButtonText="No"
                        icon = "warning-sign"
                        intent={Intent.PRIMARY}
                        isOpen = {isRestore}
                        onCancel = { () => setisRestore(false) }
                        onConfirm = { () =>{ onSBRetore(RestoreName); setisRestore(false) } }
                    >
                        <p >
                            <div>Restore the backup {RestoreName}</div>
                            <div>Are you sure to Restore ? </div>
                            <div className="Spacing-V-16"></div>
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                </Card>
            </form>

        </>
    );
}

export default SBBackupMain