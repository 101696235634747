import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { MktFeeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "M0015" ;
var isReqAuth2:boolean = false ;


interface MktFeeMastViewProps {
    gsb: AxiosInstance,
    MktFee: MktFeeInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}


function MktFeeMastView({ gsb, MktFee = {} as MktFeeInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: MktFeeMastViewProps) {

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const { t, i18n } = useTranslation();    

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("MktFeeMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, MktFee])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_Market" in MktFee)) return null


    return (
        <div>
            {/* define add,change,delete button */}
            <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Market Code" labelFor="inputMktCode" labelInfo="*">
                    <InputGroup readOnly value={MktFee.d_Market} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fee Code" labelFor="inputMktName" labelInfo="*">
                    <InputGroup readOnly value={MktFee.d_FeeCode} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Convert to Local Currency" labelFor="inputLocal_Curr">
                    <InputGroup readOnly value={MktFee.d_Local_Curr} />
                </FormGroup>
                    {/* 2010/10/05 Option to use Indirect Fee Method (eg. Y , Wing Hang) or Direct Fee Method (eg. N, Cheerful) BUG#2527 */}
                <FormGroup className="Col-4-2" label="InDirect" labelFor="inputInDirect">
                    <RadioGroup
                        name="d_InDirect"
                        disabled={true}
                        inline={true}
                        selectedValue={MktFee.d_InDirect}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Stamp Rate (%)" labelFor="inputStamp_Rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Stamp_Rate)} />
                </FormGroup>
                <FormGroup className="Col-3-1" label="Min Stamp" labelFor="inputMinStamp">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Min_Stamp)} />
                </FormGroup>
                <FormGroup className="Col-4-1" label="Max Stamp" labelFor="inputMaxStamp">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Max_Stamp)} />
                </FormGroup>
                <FormGroup className="Col-5-4" label="Combined Amount Of Diff Price To Calc Stamp" labelFor="inputCombine_Stamp">
                    <RadioGroup
                        name="d_Stamp"
                        disabled={true}
                        inline={true}
                        selectedValue={MktFee.d_Combine_Stamp}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fixed Comm Amount For I Account" labelFor="inputBkr_I_Amount">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Bkr_I_Amount)} />
                </FormGroup>
                <FormGroup className="Col-4-4" label="Comm Amount Reserved Before Rebate To Runner" labelFor="inputBase_Comm_Amount">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Base_Comm_Amount)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Clearing Rate (%)" labelFor="inputClearing_Rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Clearing_Rate)} />
                </FormGroup>
                <FormGroup className="Col-3-1" label="Min Clearing" labelFor="inputMin_Clearing">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Min_Clearing)} />
                </FormGroup>
                <FormGroup className="Col-4-1" label="Max Clearing" labelFor="inputMax_Clearing">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Max_Clearing)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Transfer Fee/TD (Sold)" labelFor="inputTransfer_Fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Transfer_Fee)} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="VAT/Trading Fee Rate (%)" labelFor="inputTrading_Fee_Rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Trading_Fee_Rate)} />
                </FormGroup>
                { GsbFlag.US_FEE && <FormGroup className="Col-3-2" label="Calculate By Shares Only" labelFor="inputCalc_by_Share">
                    <RadioGroup
                        name="d_Calc_by_Share"
                        disabled={true}
                        inline={true}
                        selectedValue={MktFee.d_Calc_by_Share}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup> }
                { GsbFlag.US_FEE && <FormGroup className="Col-5-2" label="Min VAT/Trading Fee" labelFor="inputMin_Trading_Fee">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Min_Trading_Fee)} />
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Levy Rate (%)" labelFor="inputLevy_Rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Levy_Rate)} />
                </FormGroup>
                { GsbFlag.US_FEE && <FormGroup className="Col-3-2" label="Levy Sell only" labelFor="inputLevy_Sell_only">
                    <RadioGroup
                        name="d_Levy_Sell_only"
                        disabled={true}
                        inline={true}
                        selectedValue={MktFee.d_Levy_Sell_only}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup> }
                { GsbFlag.US_FEE && <FormGroup className="Col-5-2" label="Min Levy" labelFor="inputMin_Levy">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Min_Levy)} />
                </FormGroup> }
                { GsbFlag.US_FEE && <FormGroup className="Col-7-2" label="Round Up To The 2 D.P." labelFor="inputRoundUp_Levy">
                    <RadioGroup
                        name="d_RoundUp_Levy"
                        disabled={true}
                        inline={true}
                        selectedValue={MktFee.d_RoundUp_Levy}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="AFRC Levy Rate (%)" labelFor="inputFRC_Levy_Rate">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_FRC_Levy_Rate)} />
                </FormGroup>
            </div>
            {
                 (GsbFlag.WDSI_CCY) && 
                 <div className="Row">
                 <FormGroup className="Col-1-4"  >
                     <span id="DispText">Stock Withdrawal Fee</span>
                 </FormGroup>
                 </div>
            }
            {
                (GsbFlag.WDSI_CCY) && 
                <div className="Row">
                <FormGroup className="Col-1-2" label="Physical Charge/Lot" labelFor="inputWD_PCHARGE">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_WD_PCHARGE)} />
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Physical Charge" labelFor="inputWD_PMIN">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_WD_PMIN)} />               
                </FormGroup>
            </div>
            }
            {
                (GsbFlag.WDSI_CCY) && 
                <div className="Row">
                <FormGroup className="Col-1-2" label="SI Fee" labelFor="inputWD_SIFEE">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_WD_SIFEE)} />
                </FormGroup>
            </div>
            }
            {
                (GsbFlag.WDSI_CCY) && 
                <div className="Row">
                <FormGroup className="Col-1-2" label="SI Rate (%)" labelFor="inputWD_SCHARGE">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_WD_SCHARGE)} />
                </FormGroup>
                <FormGroup className="Col-3-1" label="Min SI Fee" labelFor="inputWD_SIMIN">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_WD_SIMIN)} />
                </FormGroup>
                <FormGroup className="Col-4-1" label="Max SI Fee" labelFor="inputWD_SIMAX">
                    <InputGroup readOnly value={utils.formatNumber2(MktFee.d_WD_SIMAX)} />
                </FormGroup>
            </div>
            }
            { ( GsbFlag.SAS_MKT ) &&
                <div className="Row">
                    <FormGroup className="Col-1-4"  >
                        <span id="DispText">For SAS , SZMK Market </span>
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.SAS_MKT ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Handling Fee" labelFor="inputExp1_rate">
                        <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Exp1_rate)} />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Management Fee" labelFor="inputExp2_rate">
                        <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Exp2_rate)} />
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.SAS_MKT ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Transfer Fee" labelFor="inputExp3_rate">
                        <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Exp3_rate)} />
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Transfer Fee HK" labelFor="inputExp4_rate">
                        <InputGroup readOnly value={utils.formatNumber2(MktFee.d_Exp4_rate)} />
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Stamp Duty (For Seller)" labelFor="inputExpS1_rate">
                        <InputGroup readOnly value={utils.formatNumber2(MktFee.d_ExpS1_rate)} />
                    </FormGroup>
                </div>
            }

        </div>
    )
}


interface MktFeeMastEditProps {
    gsb: AxiosInstance,
    MktFee?: MktFeeInfo,
    isNew?: boolean,
    onSave?: (MktFee: MktFeeInfo) => void,
    onCancel?: () => void;
}

function MktFeeMastEdit({ gsb, MktFee = {} as MktFeeInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: MktFeeMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<MktFeeInfo>()
    const { t, i18n } = useTranslation();    

    const [InDirectValue, setInDirectValue] = useState(MktFee.d_InDirect)
    const [CombineStampValue, setCombineStampValue] = useState(MktFee.d_Combine_Stamp)

    const [CalcByShareValue, setCalcByShareValue] = useState(MktFee.d_Calc_by_Share)
    const [Levy_Sell_onlyValue, setLevy_Sell_onlyValue] = useState(MktFee.d_Levy_Sell_only)
    const [RoundUp_LevyValue, setRoundUp_LevyValue] = useState(MktFee.d_RoundUp_Levy)
    
    useEffect(() => {
        // Call reset to get the values of record StkInfo 
        if ("d_Market" in MktFee) {
            reset(MktFee)
        }
    }, [MktFee, reset])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
    if (! isNew && ! ("d_Market" in MktFee)) return null


    
    const onSubmit = (data: MktFeeInfo) => {

        //console.log("MktFeeMastEdit onsubmit data: ", data)

        // put value to json/field before write database dbf
        data.d_Market = data.d_Market.toUpperCase();
        data.d_FeeCode = data.d_FeeCode.toUpperCase();

        data.d_InDirect = InDirectValue

        data.d_Combine_Stamp = CombineStampValue

        data.d_Calc_by_Share = CalcByShareValue
        data.d_Levy_Sell_only = Levy_Sell_onlyValue
        data.d_RoundUp_Levy = RoundUp_LevyValue

        ;
        (async () => {
            try {
                //console.log("call MktFeeMastEdit data", data)
                //console.log("call MktFeeMastEdit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "MktFeeMast", cAction: "MktFeeValidate", isNew: isNew})
                //console.log("MktFeeMastEdit res.data.ok", res.data.ok)
                //console.log("MktFeeMastEdit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const WDSI_ToolTip = 
    <div>
        <div>D/W Handling Fee (SI) </div>
        <div>D/W Handling Fee = SI Fee + Min/Max Value of [SI Rate x Market Value] </div> 
   </div>

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Market Code" labelFor="inputMktCode" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputMktCode" name="d_Market" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 4 }) } />
                    {errors.d_Market && errors.d_Market.type === "required" && (<span id="Err_msg">Market Code cannot be Blank</span>)}
                    {errors.d_Market && errors.d_Market.type === "maxLength" && <span id="Err_msg">Max length is 4</span>}
                    {errors.d_Market && errors.d_Market.types && <span id="Err_msg">{errors.d_Market.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fee Code" labelFor="inputFeeCode" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputFeeCode" name="d_FeeCode" autoFocus={! isNew} defaultValue="" placeholder="Fee Code ..." inputRef={register({ required: true , maxLength: 2}) } />
                    {errors.d_FeeCode && errors.d_FeeCode.type === "required" && (<span id="Err_msg">Fee Code cannot be Blank</span>)}
                    {errors.d_FeeCode && errors.d_FeeCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                    {errors.d_FeeCode && errors.d_FeeCode.types && <span id="Err_msg">{errors.d_FeeCode.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Convert to Local Currency" labelFor="inputLocal_Curr">
                    <InputGroup id="inputLocal_Curr" name="d_Local_Curr" defaultValue="" placeholder="Convert to Local Currency ..." inputRef={register({ maxLength: 3}) } />
                    {errors.d_Local_Curr && errors.d_Local_Curr.type === "maxLength" && <span id="Err_msg">Max length is 3</span>}
                    {errors.d_Local_Curr && errors.d_Local_Curr.types && <span id="Err_msg">{errors.d_Local_Curr.types.message}</span>}
                </FormGroup>
                {/* 2010/10/05 Option to use Indirect Fee Method (eg. Y , Wing Hang) or Direct Fee Method (eg. N, Cheerful) BUG#2527 */}
                <FormGroup className="Col-4-2" label="InDirect" labelFor="inputInDirect">
                    <RadioGroup
                        name="d_InDirect"
                        inline={true}
                        selectedValue={InDirectValue}
                        onChange={utils.handleStringChange(s => setInDirectValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_InDirect && errors.d_InDirect.types && <span id="Err_msg">{errors.d_InDirect.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Stamp Rate (%)" labelFor="inputStamp_Rate">
                    <InputGroup className="inputNumberNoSpin" id="inputStamp_Rate" name="d_Stamp_Rate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Stamp_Rate && errors.d_Stamp_Rate.types && <span id="Err_msg">{errors.d_Stamp_Rate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-1" label="Min Stamp" labelFor="inputMinStamp">
                    <InputGroup className="inputNumberNoSpin" id="inputMinStamp" name="d_Min_Stamp" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Min_Stamp && errors.d_Min_Stamp.types && <span id="Err_msg">{errors.d_Min_Stamp.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-1" label="Max Stamp" labelFor="inputMaxStamp">
                    <InputGroup className="inputNumberNoSpin" id="inputMaxStamp" name="d_Max_Stamp" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Max_Stamp && errors.d_Max_Stamp.types && <span id="Err_msg">{errors.d_Max_Stamp.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-4" label="Combined Amount Of Diff Price To Calc Stamp" labelFor="inputCombine_Stamp">
                    <RadioGroup
                        name="d_Stamp"
                        inline={true}
                        selectedValue={CombineStampValue}
                        onChange={utils.handleStringChange(s => setCombineStampValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    {errors.d_Combine_Stamp && errors.d_Combine_Stamp.types && <span id="Err_msg">{errors.d_Combine_Stamp.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label="Fixed Comm Amount For I Account" labelFor="inputBkr_I_Amount">
                    <InputGroup className="inputNumberNoSpin" id="inputBkr_I_Amount" name="d_Bkr_I_Amount" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Bkr_I_Amount && errors.d_Bkr_I_Amount.types && <span id="Err_msg">{errors.d_Bkr_I_Amount.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-4" label="Comm Amount Reserved Before Rebate To Runner" labelFor="inputBase_Comm_Amount">
                    <InputGroup className="inputNumberNoSpin" id="inputBase_Comm_Amount" name="d_Base_Comm_Amount" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Base_Comm_Amount && errors.d_Base_Comm_Amount.types && <span id="Err_msg">{errors.d_Base_Comm_Amount.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Clearing Rate (%)" labelFor="inputClearing_Rate">
                    <InputGroup className="inputNumberNoSpin" id="inputClearing_Rate" name="d_Clearing_Rate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Clearing_Rate && errors.d_Clearing_Rate.types && <span id="Err_msg">{errors.d_Clearing_Rate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-1" label="Min Clearing" labelFor="inputMin_Clearing">
                    <InputGroup className="inputNumberNoSpin" id="inputMin_Clearing" name="d_Min_Clearing" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Min_Clearing && errors.d_Min_Clearing.types && <span id="Err_msg">{errors.d_Min_Clearing.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-1" label="Max Clearing" labelFor="inputMax_Clearing">
                    <InputGroup className="inputNumberNoSpin" id="inputMax_Clearing" name="d_Max_Clearing" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Max_Clearing && errors.d_Max_Clearing.types && <span id="Err_msg">{errors.d_Max_Clearing.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Transfer Fee/TD (Sold)" labelFor="inputTransfer_Fee">
                    <InputGroup className="inputNumberNoSpin" id="inputTransfer_Fee" name="d_Transfer_Fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Transfer_Fee && errors.d_Transfer_Fee.types && <span id="Err_msg">{errors.d_Transfer_Fee.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="VAT/Trading Fee Rate (%)" labelFor="inputTrading_Fee_Rate">
                    <InputGroup className="inputNumberNoSpin" id="inputTrading_Fee_Rate" name="d_Trading_Fee_Rate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Trading_Fee_Rate && errors.d_Trading_Fee_Rate.types && <span id="Err_msg">{errors.d_Trading_Fee_Rate.types.message}</span>}
                </FormGroup>
                { GsbFlag.US_FEE && <FormGroup className="Col-3-2" label="Calculate By Shares Only" labelFor="inputCalc_by_Share">
                    <RadioGroup
                        name="d_Calc_by_Share"
                        inline={true}
                        selectedValue={CalcByShareValue}
                        onChange={utils.handleStringChange(s => setCalcByShareValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup> }
                { GsbFlag.US_FEE && <FormGroup className="Col-5-2" label="Min VAT/Trading Fee" labelFor="inputMin_Trading_Fee">
                    <InputGroup className="inputNumberNoSpin" id="inputMin_Trading_Fee" name="d_Min_Trading_Fee" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Min_Trading_Fee && errors.d_Min_Trading_Fee.types && <span id="Err_msg">{errors.d_Min_Trading_Fee.types.message}</span>}
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Levy Rate (%)" labelFor="inputLevy_Rate">
                    <InputGroup className="inputNumberNoSpin" id="inputLevy_Rate" name="d_Levy_Rate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Levy_Rate && errors.d_Levy_Rate.types && <span id="Err_msg">{errors.d_Levy_Rate.types.message}</span>}
                </FormGroup>
                { GsbFlag.US_FEE && <FormGroup className="Col-3-2" label="Levy Sell only" labelFor="inputLevy_Sell_only">
                    <RadioGroup
                        name="d_Levy_Sell_only"
                        inline={true}
                        selectedValue={Levy_Sell_onlyValue}
                        onChange={utils.handleStringChange(s => setLevy_Sell_onlyValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup> }
                { GsbFlag.US_FEE && <FormGroup className="Col-5-2" label="Min Levy" labelFor="inputMin_Levy">
                    <InputGroup className="inputNumberNoSpin" id="inputMin_Levy" name="d_Min_Levy" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Min_Levy && errors.d_Min_Levy.types && <span id="Err_msg">{errors.d_Min_Levy.types.message}</span>}
                </FormGroup> }
                { GsbFlag.US_FEE && <FormGroup className="Col-7-2" label="Round Up To The 2 D.P." labelFor="inputRoundUp_Levy">
                    <RadioGroup
                        name="d_RoundUp_Levy"
                        inline={true}
                        selectedValue={RoundUp_LevyValue}
                        onChange={utils.handleStringChange(s => setRoundUp_LevyValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="AFRC Levy Rate (%)" labelFor="inputFRC_Levy_Rate">
                    <InputGroup className="inputNumberNoSpin" id="inputFRC_Levy_Rate" name="d_FRC_Levy_Rate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_FRC_Levy_Rate && errors.d_FRC_Levy_Rate.types && <span id="Err_msg">{errors.d_FRC_Levy_Rate.types.message}</span>}
                </FormGroup>
            </div>
            {
                 (GsbFlag.WDSI_CCY) && 
                 <div className="Row">
                 <FormGroup className="Col-1-4"  >
                     <span id="DispText">Stock Withdrawal Fee</span>
                 </FormGroup>
                 </div>
            }
            {
                (GsbFlag.WDSI_CCY) && 
                <div className="Row">
                <FormGroup className="Col-1-2" label="Physical Charge/Lot" labelFor="inputWD_PMIN">
                    <InputGroup className="inputNumberNoSpin" id="inputWD_PMIN" name="d_WD_PCHARGE" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_WD_PCHARGE && errors.d_WD_PCHARGE.types && <span id="Err_msg">{errors.d_WD_PCHARGE.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Physical Charge" labelFor="inputWD_PMIN">
                    <InputGroup className="inputNumberNoSpin" id="inputWD_PMIN" name="d_WD_PMIN" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_WD_PMIN && errors.d_WD_PMIN.types && <span id="Err_msg">{errors.d_WD_PMIN.types.message}</span>}
                </FormGroup>
            </div>
            }
            {
                (GsbFlag.WDSI_CCY) && 
                <div className="Row">
                <FormGroup className="Col-1-2" label="SI Fee" labelFor="inputWD_SIFEE">
                    <Tooltip content={WDSI_ToolTip} position={Position.TOP}>
                    <InputGroup className="inputNumberNoSpin" id="inputWD_SIFEE" name="d_WD_SIFEE" defaultValue="0" type="number" inputRef={register} />
                    </Tooltip>    
                    {errors.d_WD_SIFEE && errors.d_WD_SIFEE.types && <span id="Err_msg">{errors.d_WD_SIFEE.types.message}</span>}
                </FormGroup>
                
            </div>
            }
            {
                (GsbFlag.WDSI_CCY) && 
                <div className="Row">
                <FormGroup className="Col-1-2" label="SI Rate (%)" labelFor="inputWD_SCHARGE">
                    <InputGroup className="inputNumberNoSpin" id="inputWD_SCHARGE" name="d_WD_SCHARGE" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_WD_SCHARGE && errors.d_WD_SCHARGE.types && <span id="Err_msg">{errors.d_WD_SCHARGE.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-1" label="Min SI Fee" labelFor="inputWD_SIMIN">
                    <InputGroup className="inputNumberNoSpin" id="inputWD_SIMIN" name="d_WD_SIMIN" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_WD_SIMIN && errors.d_WD_SIMIN.types && <span id="Err_msg">{errors.d_WD_SIMIN.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-4-1" label="Max SI Fee" labelFor="inputWD_SIMAX">
                    <InputGroup className="inputNumberNoSpin" id="inputMaxStamp" name="d_WD_SIMAX" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_WD_SIMAX && errors.d_WD_SIMAX.types && <span id="Err_msg">{errors.d_WD_SIMAX.types.message}</span>}
                </FormGroup>
            </div>
            }
            { ( GsbFlag.SAS_MKT ) &&
                <div className="Row">
                    <FormGroup className="Col-1-4"  >
                        <span id="DispText">For SAS , SZMK Market </span>
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.SAS_MKT ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Handling Fee" labelFor="inputExp1_rate">
                            <InputGroup className="inputNumberNoSpin" id="inputExp1_rate" name="d_Exp1_rate" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_Exp1_rate && errors.d_Exp1_rate.type === "min" && <span id="Err_msg">Handling Fee sholud be greater than 0 </span>}
                            {errors.d_Exp1_rate && errors.d_Exp1_rate.types && <span id="Err_msg">{errors.d_Exp1_rate.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Management Fee" labelFor="inputExp2_rate">
                            <InputGroup className="inputNumberNoSpin" id="inputExp2_rate" name="d_Exp2_rate" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_Exp2_rate && errors.d_Exp2_rate.type === "min" && <span id="Err_msg">Management Fee sholud be greater than 0 </span>}
                            {errors.d_Exp2_rate && errors.d_Exp2_rate.types && <span id="Err_msg">{errors.d_Exp2_rate.types.message}</span>}
                    </FormGroup>
                </div>
            }
            { ( GsbFlag.SAS_MKT ) &&
                <div className="Row">
                    <FormGroup className="Col-1-2" label="Transfer Fee" labelFor="inputExp3_rate">
                            <InputGroup className="inputNumberNoSpin" id="inputExp3_rate" name="d_Exp3_rate" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_Exp3_rate && errors.d_Exp3_rate.type === "min" && <span id="Err_msg">Transfer Fee sholud be greater than 0 </span>}
                            {errors.d_Exp3_rate && errors.d_Exp3_rate.types && <span id="Err_msg">{errors.d_Exp3_rate.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Transfer Fee HK" labelFor="inputExp4_rate">
                            <InputGroup className="inputNumberNoSpin" id="inputExp4_rate" name="d_Exp4_rate" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_Exp4_rate && errors.d_Exp4_rate.type === "min" && <span id="Err_msg">Transfer Fee HK sholud be greater than 0 </span>}
                            {errors.d_Exp4_rate && errors.d_Exp4_rate.types && <span id="Err_msg">{errors.d_Exp4_rate.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="Stamp Duty (For Seller)" labelFor="inputExpS1_rate">
                            <InputGroup className="inputNumberNoSpin" id="inputExpS1_rate" name="d_ExpS1_rate" defaultValue="0" type="number" inputRef={register({min: 0})} />
                            {errors.d_ExpS1_rate && errors.d_ExpS1_rate.type === "min" && <span id="Err_msg">Stamp Duty (For Seller) sholud be greater than 0 </span>}
                            {errors.d_ExpS1_rate && errors.d_ExpS1_rate.types && <span id="Err_msg">{errors.d_ExpS1_rate.types.message}</span>}
                    </FormGroup>
                </div>
            }

        </form>
    )
}

interface MktFeeMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function MktFeeMastMain({ gsb }: MktFeeMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let { MktFeeCode: defaultSearchValue = "" }: { MktFeeCode: string } = useParams()

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as MktFeeInfo[])

    const [searchKey, setSearchKey] = useState("Market")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentMktFee, setcurrentMktFee] = useState({} as MktFeeInfo)
    const [BlankMktFee, setBlankMktFee] = useState({} as MktFeeInfo)

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    
    const [SING_CURR, setSING_CURR] = useState(false)

    //gsb default value
    BlankMktFee.d_Market = ""
    BlankMktFee.d_FeeCode = ""
    BlankMktFee.d_Plan_no = ""
    BlankMktFee.d_InDirect = "Y"
    BlankMktFee.d_Combine_Stamp = "Y"
    BlankMktFee.d_Calc_by_Share = "N"
    BlankMktFee.d_Levy_Sell_only = "N"
    BlankMktFee.d_RoundUp_Levy = "Y"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }

                setSING_CURR(GsbFlag.SING_CURR)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        setisReadOnly(false)
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("MktFeeMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])

    const PrevRecProcess = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call PrevRecProcess", MktFee)
            const res = await gsb.post('/Master', {sb_data: MktFee,  cPgmId: "MktFeeMast",cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", d_MktateCode.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentMktFee(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call DeleteProcess", Mkt)
            const res = await gsb.post('/Master', {sb_data: MktFee, cPgmId: "MktFeeMast", cAction: "DelRec"})
            showSuccess("Market Fee Code " + MktFee.d_Market+MktFee.d_FeeCode + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market === res.data.data.d_Market) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call NextRecProcess", MktFee)
            const res = await gsb.post('/Master', {sb_data: MktFee, cPgmId: "MktFeeMast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (MktFee: MktFeeInfo) => {
        try {
            //console.log("call newOnSave", MktFee)
            const res = await gsb.post('/Master', {sb_data: MktFee, cPgmId: "MktFeeMast", cAction: "AddRec"})
            showSuccess("Market Fee Code " + MktFee.d_Market+MktFee.d_FeeCode + " record added")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (MktFee: MktFeeInfo) => {
        try {
            console.log("call editOnSave", MktFee)
            const res = await gsb.post('/Master', {sb_data: MktFee, cPgmId: "MktFeeMast", cAction: "UpdateRec"})
            showSuccess("Market Fee Code " + MktFee.d_Market+MktFee.d_FeeCode + " record saved")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Market+data[i].d_FeeCode === res.data.data.d_Market+res.data.data.d_FeeCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("editOnSave stk", MktFee)
            //console.log("editOnSave res.data.data", res.data.data)
            setcurrentMktFee(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if ( SING_CURR ) {
            toaster.show({message: 'Your SB Version have not Multi-Currency Module !  Please contact POP (Tel: 23918816) for more detail.', intent: Intent.WARNING })
            return
        }
 
        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        // get SmMast list if StkNo have value
        try {
            //console.log("SmMastMain onsubmit called", data)
            const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                        }, 
                    },
                    cPgmId: "MktFeeMast",
                    cAction: "ListRec",
                });

            if ( m_limit > 1 ) {
                setData(res.data.data)
            }
            if (res.data.data.length > 0) {
                setcurrentMktFee(res.data.data[0])
            }

            if ( m_limit > 1 ) {
                if ( res.data.count > 0 ) {
                const aPageNumber1 = []
                if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                    }
                        setaPageNumber(aPageNumber1)
                    }
                }
            }
            setShowWait(false)
        } catch (error) {
            utils.showError(error)
        }
    }

    // get one SmMast record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                //console.log("SmMastMain useEffect length > 0  called", data)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "Market",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "MktFeeMast",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentMktFee(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                            }, 
                        },
                        cPgmId: "MktFeeMast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentMktFee(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <MktFeeMastView gsb={gsb} MktFee={currentMktFee} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentMktFee)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentMktFee)} onNextRec={() => NextRecProcess(currentMktFee)} ></MktFeeMastView>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            detail = <MktFeeMastEdit gsb={gsb} MktFee={BlankMktFee} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></MktFeeMastEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <MktFeeMastEdit gsb={gsb} MktFee={currentMktFee} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></MktFeeMastEdit>
            break
        }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            { (! SING_CURR ) &&
                <Button className="Col-1-1"  disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
            }
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-MarketCode">Market Code</th>
                <th className="TCol-MktFee">Fee Code</th>
                <th className="TCol-MktFee">Stamp Rate</th>
                <th className="TCol-MktFee">Clearing Rate </th>
                <th className="TCol-MktFee">VAT/Trading Fee Rate</th>
                <th className="TCol-MktFee">Levy Rate</th>
                <th className="TCol-MktFee">Handling Fee</th>
                <th className="TCol-MktFee">Management Fee</th>
                <th className="TCol-MktFee">Transfer Fee</th>
                <th className="TCol-MktFee">Transfer Fee HK</th>
                <th className="TCol-MktFee">Stamp Duty (For Seller)</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((MktFee, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-MarketCode" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Market}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_FeeCode}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Stamp_Rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Clearing_Rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Trading_Fee_Rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Levy_Rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Exp1_rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Exp2_rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Exp3_rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_Exp4_rate}</td>
                        <td className="TCol-MktFee" onClick={() => { setcurrentMktFee(MktFee) ; MoblieDev && setSelectedTabId("detail") }}>{MktFee.d_ExpS1_rate}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    { ( SING_CURR ) && 
                        <div>
                        <div id="DispText">Your SB Version have not Multi-Currency Module !</div>
                        <div id="DispText">Please contact POP (Tel: 23918816) for more detail.</div>
                        </div>
                    }
                    {/* define search key */}
                    <H3><div style={{justifyContent: 'flex-end'}}>Market Fee Master</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="Market">Market Code</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "Market"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "Market"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            {/* <FormGroup className="Col-5-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card>

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default MktFeeMastMain