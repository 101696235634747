import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import { CommInfo, ChnlInfo, CurrencyInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "M0011" ;
var isReqAuth2:boolean = false ;


interface CommMastViewProps {
    gsb: AxiosInstance,
    Comm: CommInfo,
    Comm0: CommInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}


function CommMastView({ gsb, Comm = {} as CommInfo, Comm0 = {} as CommInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: CommMastViewProps) {

    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)


    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 
    
    const [Currency, setCurrency] = useState(Comm.d_Currency)

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Comm])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    if (! ("d_CommCode" in Comm)) return null


    return (
        <div>
            {/* define add,change,delete button */}
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div> }
            { ViewMode === "L" && <div>
                Commission Code : {Comm.d_CommCode}
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Code" labelFor="inputCommCode" labelInfo="*">
                    <InputGroup readOnly value={Comm.d_CommCode} />
                </FormGroup>
                { ( GsbFlag.MULTI_CHNL ) &&  
                    <FormGroup className="Col3-3-1" label="Channel" labelFor="inputChannel">
                        <InputGroup readOnly value={Comm.d_Channel} />
                    </FormGroup>
                }
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                    <FormGroup className="Col3-4-1" label="Currency" labelFor="inputCurrency">
                        <InputGroup readOnly value={Comm.d_Currency} />
                    </FormGroup>
                }
                { ( GsbFlag.COMM_MKT ) &&  
                    <FormGroup className="Col-5-1" label="Market" labelFor="inputMarket">
                        <InputGroup readOnly value={Comm.d_Market} />
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_CommRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_CommRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_CommRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Commission Rate (Bonds)" labelFor="inputCommRateBond">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_CommRateBond)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_CommRateBond.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_CommRateBond)} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_RebateRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_RebateRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_RebateRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate (Bonds)" labelFor="inputRebateRateBond">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_RebateRateBond)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_RebateRateBond.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_RebateRateBond)} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Min Commission" labelFor="inputMinComm">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_MinComm)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_MinComm.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_MinComm)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Commission (Bonds)" labelFor="inputMinCommBond">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_MinCommBond)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_MinCommBond.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_MinCommBond)} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Min Rebate" labelFor="inputMinRebate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_MinRebate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_MinRebate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_MinRebate)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Rebate (Bonds)" labelFor="inputMinRebateBond">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_MinRebateBond)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_MinRebateBond.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_MinRebateBond)} </div> }
                </FormGroup>
            </div>
            {(GsbFlag.US_FEE) && <div className="Row">
                <div className="Spacing-V-16" />
                <FormGroup className="Col-1-2" label="Calculate by Shares Only" labelFor="inputCalc_by_Share">
                    <RadioGroup
                        name="d_Calc_by_Share"
                        disabled={true}
                        inline={true}
                        selectedValue={Comm.d_Calc_by_Share}
                        onChange={(s) => { }}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Calc_by_Share.toString() == "") && 
                    <div id="DispText2" > {Comm0.d_Calc_by_Share} </div> }
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <div className="Spacing-V-16" />
                <FormGroup className="Col-1-4"  >
                    <span id="DispText">Privilege Rate Settings :- </span>
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv1_CommRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv1_CommRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv1_CommRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv1_RebateRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv1_RebateRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv1_RebateRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="For Trade Above 1" labelFor="inputTradeAbove">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv1_Trade_Above)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv1_Trade_Above.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv1_Trade_Above)} </div> }
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv2_CommRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv2_CommRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv2_CommRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv2_RebateRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv2_RebateRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv2_RebateRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="For Trade Above 2" labelFor="inputTradeAbove">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv2_Trade_Above)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv2_Trade_Above.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv2_Trade_Above)} </div> }
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv3_CommRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv3_CommRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv3_CommRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv3_RebateRate)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv3_RebateRate.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv3_RebateRate)} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="For Trade Above 3" labelFor="inputTradeAbove">
                    <InputGroup readOnly value={utils.formatNumber2(Comm.d_Priv3_Trade_Above)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (Comm0.d_Priv3_Trade_Above.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(Comm0.d_Priv3_Trade_Above)} </div> }
                </FormGroup>
            </div> }
        </div>
    )
}


interface CommMastEditProps {
    gsb: AxiosInstance,
    Comm?: CommInfo,
    ChnlList?: ChnlInfo[],
    CurrencyList?: CurrencyInfo[],
    isNew?: boolean,
    onSave?: (Comm: CommInfo) => void,
    onCancel?: () => void;
}

function CommMastEdit({ gsb, Comm = {} as CommInfo, ChnlList=[] as ChnlInfo[], CurrencyList=[] as CurrencyInfo[], isNew = false, onSave = () => { }, onCancel = () => { } }: CommMastEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<CommInfo>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    const [CalcByShareValue, setCalcByShareValue] = useState(Comm.d_Calc_by_Share)
    const [CaneditCalcByShareValue, setCaneditCalcByShareValue] = useState(false)
    

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, Comm])


    //const [Currency, setCurrency] = useState(Comm.d_Currency)


    useEffect(() => {
        // Call reset to get the values of record StkInfo 
        if ("d_CommCode" in Comm) {
            reset(Comm)
        }
    }, [Comm, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Channel) manually and call setValue when item is selected
        register({ name: "d_Channel" })
        register({ name: "d_Currency" })
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])


    useEffect(() => {
        if ( Comm.d_Currency.trim().length > 0 ) {
            setCaneditCalcByShareValue(true)
        }
    }, [Comm, Comm.d_Currency])



    if (! isNew && ! ("d_CommCode" in Comm)) return null

    const GetCCYParam = async (i_CCY: string) => {
/*        
        (async () => {
            try {
                // get CCYParam
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYParam", CCY: i_CCY})
                //console.log("GetCCYParam: ", res.data.data) 
                //setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                showErrorBox(error)
            }
        })()
*/
        return true;
    }



    
    const onSubmit = (data: CommInfo) => {

        //console.log("CommMastEdit onsubmit data: ", data)
        //console.log("CommMastEdit onsubmit StkPrice: ", StkPrice)

        // put value to json/field before write database dbf
        data.d_CommCode = data.d_CommCode.toUpperCase();
        //data.d_Currency = data.d_Currency.toUpperCase();

        //data.d_Currency = Currency;
        if ( typeof(data.d_Currency) === "undefined" ) {
            data.d_Currency = ""
        }
        data.d_Currency = data.d_Currency.toUpperCase();
        if  ( GsbFlag.MULTI_CHNL ) {
            if ( typeof(data.d_Channel) === "undefined" ) {
                data.d_Channel = ""
            }
                data.d_Channel = data.d_Channel.toUpperCase();
        }

        data.d_Calc_by_Share = CalcByShareValue


        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }

        (async () => {
            try {
                //console.log("call CommMastEdit data", data)
                //console.log("call CommMastEdit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "Commmast", cAction: "CommValidate", isNew: isNew})
                //console.log("CommMastEdit res.data.ok", res.data.ok)
                //console.log("CommMastEdit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }


    const ChnlRenderer: ItemRenderer<ChnlInfo> = (ChnlList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${ChnlList.d_ChnlDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ChnlList.d_ChnlCode}
                key={ChnlList.d_ChnlCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const ChnlPredicate: ItemPredicate<ChnlInfo> = (query, ChnlList, _index, exactMatch) => {
        const normalizedText = `${ChnlList.d_ChnlCode} - ${ChnlList.d_ChnlDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const ChnlValueRenderer = (ChnlList: ChnlInfo) => {
        return ChnlList.d_ChnlCode
    }


    const CurrencyRenderer: ItemRenderer<CurrencyInfo> = (CurrencyList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${CurrencyList.d_CcyDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={CurrencyList.d_Currency}
                key={CurrencyList.d_Currency}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const CurrencyPredicate: ItemPredicate<CurrencyInfo> = (query, CurrencyList, _index, exactMatch) => {
        const normalizedText = `${CurrencyList.d_Currency} - ${CurrencyList.d_CcyDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const CurrencyValueRenderer = (CurrencyList: CurrencyInfo) => {
        return CurrencyList.d_Currency
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Code" labelFor="inputCommCode" labelInfo="*">
                    <InputGroup disabled={! isNew} id="inputCommCode" name="d_CommCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 2 }) } />
                    {errors.d_CommCode && errors.d_CommCode.type === "required" && (<span id="Err_msg">Commission Code cannot be Blank</span>)}
                    {errors.d_CommCode && errors.d_CommCode.type === "maxLength" && <span id="Err_msg">Max length is 2</span>}
                    {errors.d_CommCode && errors.d_CommCode.types && <span id="Err_msg">{errors.d_CommCode.types.message}</span>}
                </FormGroup>
                { ( GsbFlag.MULTI_CHNL ) &&  
                    <FormGroup className="Col3-3-1" label="Channel" labelFor="inputChannel">
                        {/* <InputGroup id="inputChannel" name="d_Channel"  defaultValue="" placeholder="Channel ..."  inputRef={register({ maxLength: 1 }) } />
                        {errors.d_Channel && errors.d_Channel.type === "maxLength" && <span id="Err_msg">Max length is 1</span>} */}
                        <Suggest
                            disabled={! isNew}
                            inputProps={{ placeholder: "Channel ..." }}
                            defaultSelectedItem={ChnlList.find((Chnl) => Chnl.d_ChnlCode === Comm.d_Channel)}
                            items={ChnlList}
                            itemRenderer={ChnlRenderer}
                            itemPredicate={ChnlPredicate}
                            inputValueRenderer={ChnlValueRenderer}
                            onItemSelect={(Comm) => { setValue("d_Channel", Comm.d_ChnlCode) }}
                        />
                        {errors.d_Channel && errors.d_Channel.types && <span id="Err_msg">{errors.d_Channel.types.message}</span>}
                    </FormGroup>
                }
                {(GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) &&
                    <FormGroup className="Col3-4-1" label="Currency" labelFor="inputCurrency">
                        {/* <InputGroup id="inputCurrency" value={Currency} defaultValue="" placeholder="Currency ..." onChange={utils.handleStringChange(s => setCurrency(s)) } inputRef={register({ maxLength: 3 }) } />
                        {errors.d_Currency && errors.d_Currency.type === "maxLength" && <span id="Err_msg">Max length is 3</span>} */}
                        <Suggest
                                disabled={! isNew}
                                inputProps={{ placeholder: "Currency ..." }}
                                defaultSelectedItem={CurrencyList.find((Ccy) => Ccy.d_Currency === Comm.d_Currency)}
                                items={CurrencyList}
                                itemRenderer={CurrencyRenderer}
                                itemPredicate={CurrencyPredicate}
                                inputValueRenderer={CurrencyValueRenderer}
                                onItemSelect={(CurrencyList) => { setValue("d_Currency", CurrencyList.d_Currency) }}
                            />
                        {errors.d_Currency && errors.d_Currency.types && <span id="Err_msg">{errors.d_Currency.types.message}</span>}
                    </FormGroup>
                }
                { ( GsbFlag.COMM_MKT ) &&  
                    <FormGroup className="Col-5-1" label="Market Code" labelFor="inputMktCode" labelInfo="*">
                        <InputGroup disabled={! isNew} id="inputMktCode" name="d_Market" defaultValue="" inputRef={register({ maxLength: 4 }) } />
                        {errors.d_Market && errors.d_Market.type === "maxLength" && <span id="Err_msg">Max length is 4</span>}
                        {errors.d_Market && errors.d_Market.types && <span id="Err_msg">{errors.d_Market.types.message}</span>}
                    </FormGroup>
                }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup className="inputNumberNoSpin" id="inputCommRate" name="d_CommRate" autoFocus={! isNew} defaultValue="0" type="number" inputRef={register} />
                    {errors.d_CommRate && errors.d_CommRate.types && <span id="Err_msg">{errors.d_CommRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Commission Rate (Bonds)" labelFor="inputCommRateBond">
                    <InputGroup className="inputNumberNoSpin" id="inputCommRateBond" name="d_CommRateBond" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_CommRateBond && errors.d_CommRateBond.types && <span id="Err_msg">{errors.d_CommRateBond.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup className="inputNumberNoSpin" id="inputRebateRate" name="d_RebateRate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_RebateRate && errors.d_RebateRate.types && <span id="Err_msg">{errors.d_RebateRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate (Bonds)" labelFor="inputRebateRateBond">
                    <InputGroup className="inputNumberNoSpin" id="inputRebateRateBond" name="d_RebateRateBond" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_RebateRateBond && errors.d_RebateRateBond.types && <span id="Err_msg">{errors.d_RebateRateBond.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Min Commission" labelFor="inputMinComm">
                    <InputGroup className="inputNumberNoSpin" id="inputMinComm" name="d_MinComm" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_MinComm && errors.d_MinComm.types && <span id="Err_msg">{errors.d_MinComm.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Commission (Bonds)" labelFor="inputMinCommBond">
                    <InputGroup className="inputNumberNoSpin" id="inputMinCommBond" name="d_MinCommBond" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_MinCommBond && errors.d_MinCommBond.types && <span id="Err_msg">{errors.d_MinCommBond.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-2" label="Min Rebate" labelFor="inputMinRebate">
                    <InputGroup className="inputNumberNoSpin" id="inputMinRebate" name="d_MinRebate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_MinRebate && errors.d_MinRebate.types && <span id="Err_msg">{errors.d_MinRebate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Min Rebate (Bonds)" labelFor="inputMinRebateBond">
                    <InputGroup className="inputNumberNoSpin" id="inputMinRebateBond" name="d_MinRebateBond" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_MinRebateBond && errors.d_MinRebateBond.types && <span id="Err_msg">{errors.d_MinRebateBond.types.message}</span>}
                </FormGroup>
            </div>
            { GsbFlag.US_FEE  && <div className="Row">
                <FormGroup className="Col-1-2" label="Calculate by Shares Only" labelFor="inputCalc_by_Share">
                    <RadioGroup
                        name="d_Calc_by_Share"
                        disabled={! CaneditCalcByShareValue}
                        inline={true}
                        selectedValue={CalcByShareValue}
                        onChange={utils.handleStringChange(s => setCalcByShareValue(s)) }
                        ref={register}
                    >
                        <Radio label="Yes" value="Y" />
                        <Radio label="No"  value="N" />
                    </RadioGroup>
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <div className="Spacing-V-16" />
                <FormGroup className="Col-1-4"  >
                    <span id="DispText">Privilege Rate Settings :- </span>
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup className="inputNumberNoSpin" id="inputCommRate" name="d_Priv1_CommRate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv1_CommRate && errors.d_Priv1_CommRate.types && <span id="Err_msg">{errors.d_Priv1_CommRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup className="inputNumberNoSpin" id="inputRebateRate" name="d_Priv1_RebateRate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv1_RebateRate && errors.d_Priv1_RebateRate.types && <span id="Err_msg">{errors.d_Priv1_RebateRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="For Trade Above 1" labelFor="inputTradeAbove">
                    <InputGroup className="inputNumberNoSpin" id="inputTradeAbove" name="d_Priv1_Trade_Above" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv1_Trade_Above && errors.d_Priv1_Trade_Above.types && <span id="Err_msg">{errors.d_Priv1_Trade_Above.types.message}</span>}
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup className="inputNumberNoSpin" id="inputCommRate" name="d_Priv2_CommRate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv2_CommRate && errors.d_Priv2_CommRate.types && <span id="Err_msg">{errors.d_Priv2_CommRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup className="inputNumberNoSpin" id="inputRebateRate" name="d_Priv2_RebateRate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv2_RebateRate && errors.d_Priv2_RebateRate.types && <span id="Err_msg">{errors.d_Priv2_RebateRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="For Trade Above 2" labelFor="inputTradeAbove">
                    <InputGroup className="inputNumberNoSpin" id="inputTradeAbove" name="d_Priv2_Trade_Above" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv2_Trade_Above && errors.d_Priv2_Trade_Above.types && <span id="Err_msg">{errors.d_Priv2_Trade_Above.types.message}</span>}
                </FormGroup>
            </div> }
            {(GsbFlag.MULTI_RATE) && <div className="Row">
                <FormGroup className="Col-1-2" label="Commission Rate" labelFor="inputCommRate">
                    <InputGroup className="inputNumberNoSpin" id="inputCommRate" name="d_Priv3_CommRate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv3_CommRate && errors.d_Priv3_CommRate.types && <span id="Err_msg">{errors.d_Priv3_CommRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-2" label="Rebate Rate" labelFor="inputRebateRate">
                    <InputGroup className="inputNumberNoSpin" id="inputRebateRate" name="d_Priv3_RebateRate" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv3_RebateRate && errors.d_Priv3_RebateRate.types && <span id="Err_msg">{errors.d_Priv3_RebateRate.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-5-2" label="For Trade Above 3" labelFor="inputTradeAbove">
                    <InputGroup className="inputNumberNoSpin" id="inputTradeAbove" name="d_Priv3_Trade_Above" defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Priv3_Trade_Above && errors.d_Priv3_Trade_Above.types && <span id="Err_msg">{errors.d_Priv3_Trade_Above.types.message}</span>}
                </FormGroup>
            </div> }
        </form>
    )
}

interface CommMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function CommMastMain({ gsb }: CommMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { CommCode: defaultSearchValue = "" }: { CommCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as CommInfo[])
    const [ChnlList, setChnlList] = useState([] as ChnlInfo[])
    const [ChnlList2, setChnlList2] = useState([] as ChnlInfo[])
    const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])
    const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])

    const [searchKey, setSearchKey] = useState("CommmissionCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchCurrency, setsearchCurrency] = useState("")
    const [searchChannel, setsearchChannel] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentComm, setCurrentComm] = useState({} as CommInfo)
    const [BlankComm, setBlankComm] = useState({} as CommInfo)
    const [CurrentLog1, setCurrentLog1] = useState({} as CommInfo)
    const [CurrentLog0, setCurrentLog0] = useState({} as CommInfo)
    const [ViewMode, setViewMode] = useState("V")
    
    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [showChannel, setshowChannel] = useState(false)
    const [showCurrency, setshowCurrency] = useState(false)
    const [showMarket, setshowMarket] = useState(false)
    const [showUS_FEE, setshowUS_FEE] = useState(false)

    //gsb default value
    BlankComm.d_CommCode = ""
    BlankComm.d_Currency = ""
    BlankComm.d_Channel = ""
    BlankComm.d_Market = ""
    BlankComm.d_Calc_by_Share = "N"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("SmMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("SmMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("SmMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("SmMastMain disp GsbFlag: ", GsbFlag)
                //console.log("SmMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("SmMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("SmMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("SmMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("SmMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setshowChannel( GsbFlag.MULTI_CHNL ) ;
                
                ChnlList2.push({d_ChnlCode: "", d_ChnlDesc: "Normal"})
                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.CHN_STATUS.substr(i, 1).trim().length > 0 ) {
                        let tmpChnlName = "Normal"
                        tmpChnlName = GsbFlag.CHN_DESC[i]

                        ChnlList2.push({d_ChnlCode: GsbFlag.CHN_STATUS.substr(i, 1), d_ChnlDesc: tmpChnlName})
                    }
                }
                setChnlList(ChnlList2)
                //console.log("set ChnlList: ", ChnlList)
                setshowCurrency( (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) );
                setshowMarket( GsbFlag.COMM_MKT ) ;
                setshowUS_FEE( GsbFlag.US_FEE ) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("CommMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {

        if (logviewonly === "Y") {
            setViewMode("L")
        }
        //console.log("ClMastMain ViewMode: ", ViewMode)

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "Commmast", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("CommMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])

    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "ExchangeRate", cAction: "GetCCYList"})

                //console.log("CommMast: ", res.data.data) 
                CurrencyList2.push({d_Currency: "", d_CcyDesc: "HKD"})
                for (let j = 0; j < res.data.data.length; j++) {
                    CurrencyList2.push({d_Currency: res.data.data[j][0], d_CcyDesc: res.data.data[j][1]})
                }
                setCurrencyList(CurrencyList2)
                //console.log("set CurrencyList: ", CurrencyList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    const PrevRecProcess = async (Comm: CommInfo) => {
        try {
            //console.log("call PrevRecProcess", Comm)
            const res = await gsb.post('/Master', {sb_data: Comm, cPgmId: "Commmast", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", d_CommCode.d_StkNo)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if ( showChannel ) {
                    if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Channel === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Channel) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                } else if ( showMarket ) { 
                    if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Market === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Market) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                } else {
                    if (data[i].d_CommCode+data[i].d_Currency === res.data.data.d_CommCode+res.data.data.d_Currency) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentComm(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (Comm: CommInfo) => {

        try {
            Comm.d_AuthTran = ""
            if (isReqAuth2) {
                Comm.d_AuthTran = "Y"
            }
            //console.log("call DeleteProcess", Comm)
            const res = await gsb.post('/Master', {sb_data: Comm, cPgmId: "Commmast", cAction: "DelRec"})
            if (isReqAuth2) {
                if ( showChannel ) {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record deleting, waiting for authorization")
                } else if ( showMarket ) { 
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record deleting, waiting for authorization")
                } else {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record deleting, waiting for authorization")
                }
            } else {
                if ( showChannel ) {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record deleted")
                } else if ( showMarket ) { 
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record deleted")
                } else {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record deleted")
                }
                for (let i = 0; i < data.length; i++) {
                    if ( showChannel ) {
                        if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Channel === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Channel) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    } else {
                        if (data[i].d_CommCode+data[i].d_Currency === res.data.data.d_CommCode+res.data.data.d_Currency) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    }
                }
            }
            setCurrentComm(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                if ( showChannel ) {
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record deleting, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record deleting, waiting for authorization")
                } else if ( showMarket ) { 
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record deleting, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record deleting, waiting for authorization")
                } else {
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record deleting, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record deleting, waiting for authorization")
                }
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (Comm: CommInfo) => {
        try {
            //console.log("call NextRecProcess", Comm)
            const res = await gsb.post('/Master', {sb_data: Comm, cPgmId: "Commmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if ( showChannel ) {
                    if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Channel === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Channel) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                } else if ( showMarket ) { 
                    if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Market === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Market) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                } else {
                    if (data[i].d_CommCode+data[i].d_Currency === res.data.data.d_CommCode+res.data.data.d_Currency) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setCurrentComm(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (Comm: CommInfo) => {
        try {
            //console.log("call newOnSave", Comm)
            const res = await gsb.post('/Master', {sb_data: Comm, cPgmId: "Commmast", cAction: "AddRec"})
            if (isReqAuth2) {
                if ( showChannel ) {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record adding, waiting for authorization")
                } else if ( showMarket ) { 
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record adding, waiting for authorization")
                } else {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record adding, waiting for authorization")
                }
            } else {
                if ( showChannel ) {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record added")
                } else if ( showMarket ) { 
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record added")
                } else {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record added")
                }
                for (let i = 0; i < data.length; i++) {
                    if ( showChannel ) {
                        if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Channel === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Channel) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    } else {
                        if (data[i].d_CommCode+data[i].d_Currency === res.data.data.d_CommCode+res.data.data.d_Currency) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    }
                }
            }
            setCurrentComm(res.data.data)
            setCurrentMode("show")
            if (isReqAuth2) {
                if ( showChannel ) {
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record adding, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record adding, waiting for authorization")
                } else if ( showMarket ) { 
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record adding, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record adding, waiting for authorization")
                } else {
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record adding, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record adding, waiting for authorization")
                }
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (Comm: CommInfo) => {
        try {
            //console.log("call editOnSave", Comm)
            const res = await gsb.post('/Master', {sb_data: Comm, cPgmId: "Commmast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                if ( showChannel ) {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record saving, waiting for authorization")
                } else if ( showMarket ) { 
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record saving, waiting for authorization")
                } else {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record saving, waiting for authorization")
                }
                for (let i = 0; i < data.length; i++) {
                    if ( showChannel ) {
                        if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Channel === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Channel) {
                            data[i] = { ...data[i], ...res.data.data }
                            setCurrentComm(data[i])
                            break
                        }
                    } else {
                        if (data[i].d_CommCode+data[i].d_Currency === res.data.data.d_CommCode+res.data.data.d_Currency) {
                            data[i] = { ...data[i], ...res.data.data }
                            setCurrentComm(data[i])
                            break
                        }
                    }
                }
                setCurrentMode("show")
                if ( showChannel ) {
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record saving, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record saving, waiting for authorization")
                } else if ( showMarket ) { 
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record saving, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record saving, waiting for authorization")
                } else {
                    //alert("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record saving, waiting for authorization")
                    setisShowAlert1(true)
                    setShowAlert1Msg("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record saving, waiting for authorization")
                }
            } else {
                if ( showChannel ) {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Channel +","+ Comm.d_Currency + " record saved")
                } else if ( showMarket ) { 
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency +","+ Comm.d_Market + " record saved")
                } else {
                    showSuccess("Commission Code " + Comm.d_CommCode +","+ Comm.d_Currency + " record saved")
                }
                for (let i = 0; i < data.length; i++) {
                    if ( showChannel ) {
                        if (data[i].d_CommCode+data[i].d_Currency+data[i].d_Channel === res.data.data.d_CommCode+res.data.data.d_Currency+res.data.data.d_Channel) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    } else {
                        if (data[i].d_CommCode+data[i].d_Currency === res.data.data.d_CommCode+res.data.data.d_Currency) {
                            data[i] = { ...data[i], ...res.data.data }
                            setData(data)
                            break
                        }
                    }
                }
                setCurrentComm(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        // get SmMast list if StkNo have value
        try {
            //console.log("SmMastMain onsubmit called", data)
            const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            Curency: searchCurrency,
                            Channel: searchChannel,
                        }, 
                    },
                    cPgmId: "Commmast",
                    cAction: "ListRec",
                });

            if ( m_limit > 1 ) {
                setData(res.data.data)
            }
            if (res.data.data.length > 0) {
                setCurrentComm(res.data.data[0])
            }

            if ( m_limit > 1 ) {
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                            setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
            }
            setShowWait(false)
        } catch (error) {
            utils.showError(error)
        }
    }

    // get one SmMast record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                //console.log("SmMastMain useEffect length > 0  called", data)
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "CommmissionCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "Commmast",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentComm(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                Curency: searchCurrency,
                                Channel: searchChannel,
                            }, 
                            },
                        cPgmId: "Commmast",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentComm(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <CommMastView gsb={gsb} Comm={currentComm} Comm0={currentComm} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentComm)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentComm)} onNextRec={() => NextRecProcess(currentComm)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></CommMastView>
    switch (currentMode) {
        case "new":
            //console.log("currentMode = new")
            detail = <CommMastEdit gsb={gsb} Comm={BlankComm} ChnlList={ChnlList} CurrencyList={CurrencyList} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></CommMastEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <CommMastEdit gsb={gsb} Comm={currentComm} ChnlList={ChnlList} CurrencyList={CurrencyList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></CommMastEdit>
            break
    }
        
    let logKeyViewRec = <CommMastView gsb={gsb} Comm={CurrentLog1} Comm0={CurrentLog0} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentComm)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentComm)} onNextRec={() => NextRecProcess(currentComm)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></CommMastView>

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>

    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-CommissionCode">Commission Code</th>
                {( showChannel ) &&
                    <th className="TCol-Channel">Channel</th>
                }
                { showCurrency &&
                    <th className="TCol-Currency">Currency</th>
                }
                { showMarket &&
                    <th className="TCol-Market">Market</th>
                }
                <th className="TCol-CommRate">CommRate</th>
                <th className="TCol-RebateRate">Rebate Rate</th>
                <th className="TCol-MinComm">Min Comm</th>
                <th className="TCol-MinRebate">Min Rebate</th>
                {showUS_FEE && <th className="TCol-byShares">Calculate by Shares Only</th> }
            </thead>
            <tbody id="ma_tr">
                {data.map((Comm, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-CommissionCode" onClick={() => { setCurrentComm(Comm) ; MoblieDev && setSelectedTabId("detail") }}>{Comm.d_CommCode}</td>
                        {( showChannel ) &&
                            <td className="TCol-Channel" onClick={() => { setCurrentComm(Comm) ; MoblieDev && setSelectedTabId("detail") }}>{Comm.d_Channel}</td>
                        }
                        { showCurrency &&
                            <td className="TCol-Currency" onClick={() => { setCurrentComm(Comm) ; MoblieDev && setSelectedTabId("detail") }}>{Comm.d_Currency}</td>
                        }
                        {( showMarket ) &&
                            <td className="TCol-Market" onClick={() => { setCurrentComm(Comm) ; MoblieDev && setSelectedTabId("detail") }}>{Comm.d_Market}</td>
                        }
                        <td className="TCol-CommRate" onClick={() => { setCurrentComm(Comm) }}>{Comm.d_CommRate}</td>
                        <td className="TCol-RebateRate" onClick={() => { setCurrentComm(Comm) }}>{Comm.d_RebateRate}</td>
                        <td className="TCol-MinComm" onClick={() => { setCurrentComm(Comm) }}>{Comm.d_MinComm}</td>
                        <td className="TCol-MinRebate" onClick={() => { setCurrentComm(Comm) }}>{Comm.d_MinRebate}</td>
                        {showUS_FEE && <td className="TCol-byShares" onClick={() => { setCurrentComm(Comm) }}>{Comm.d_Calc_by_Share}</td> }
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    {/* define search key */}
                    <H3><div style={{justifyContent: 'flex-end'}}>Commission Master</div></H3>
                    <div className="Row">
                        <FormGroup className="Col-1-5" label="Search for " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="CommmissionCode">Commission Code</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "CommmissionCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "CommmissionCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-2" label="Currency" labelFor="searchCurrency">
                                <InputGroup id="searchCurrency" value={searchCurrency} onChange={utils.handleStringChange(s => setsearchCurrency(s))} />
                            </FormGroup>
                            {( showChannel ) &&
                                <FormGroup className="Col-3-2" label="Channel" labelFor="searchChannel">
                                    <InputGroup id="searchChannel" value={searchChannel} onChange={utils.handleStringChange(s => setsearchChannel(s))} />
                                </FormGroup>
                            }
                            {/* <FormGroup className="Col-5-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Commission Master Log View</H3>
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default CommMastMain