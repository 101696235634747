import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { UserMast } from './Types'
import { SystemHeader } from './CommonUI';


interface PageInfo {
    n: number
}

var GsbFlag: any;



interface UserchgPasswdMainProps {
    gsb: AxiosInstance,
    currentRec?: UserMast
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function UserchgPasswdMain({ gsb , currentRec = {} as UserMast }: UserchgPasswdMainProps) {
    //const history = useHistory()
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<UserMast>()

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as UserMast[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as UserMast)
    const [BlankAc, setBlankAc] = useState({} as UserMast)

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [Enter2tab, setEnter2tab] = useState("Y")
    const [UserId, setUserId] = useState("")


    const [def_d_Userid, setdef_d_Userid] = useState("")
    const [def_d_User_Password1, setdef_d_User_Password1] = useState("")
    const [def_d_User_Password2, setdef_d_User_Password2] = useState("")
    const [def_d_User_Password3, setdef_d_User_Password3] = useState("")




    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("UserchgPasswdMain disp user_key: ", res.data.Flag.user_key)
                //console.log("UserchgPasswdMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("UserchgPasswdMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("UserchgPasswdMain disp GsbFlag: ", GsbFlag)
                //console.log("UserchgPasswdMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("UserchgPasswdMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("UserchgPasswdMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("UserchgPasswdMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("UserchgPasswdMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                setEnter2tab(GsbFlag.Enter2tab);
                setUserId(GsbFlag.UserID) ;
                setdef_d_Userid(GsbFlag.UserID) ;

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        if ( Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    },  [GsbFlag, Enter2tab])


    const onSubmit = (data: UserMast) => {
        //console.log("onSubmit called", data)

        data.d_Userid = data.d_Userid.toUpperCase();

        currentRec.d_Userid = data.d_Userid
        currentRec.d_User_Password1 = data.d_User_Password1
        currentRec.d_User_Password2 = data.d_User_Password2
        currentRec.d_User_Password3 = data.d_User_Password3

        ;

        (async () => {
            try {
                console.log("call onSubmit currentRec", currentRec)
                const res = await gsb.post('/Master', {sb_data: currentRec, cPgmId: "UserMast", cAction: "ChgPasswdValidate" })
                console.log("onSubmit res.data.ok", res.data.ok)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(currentRec);

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }


    const onSave = async (UserMast: UserMast) => {
        try {
            console.log("call OnSave", UserMast)
            const res = await gsb.post('/Master', {sb_data: UserMast, cPgmId: "UserMast", cAction: "ChgPasswdUpdate" })
            if ( res.data.ok) {
                showSuccess("User ID " + UserMast.d_Userid + " password saved")
                alert("User ID " + UserMast.d_Userid + " password saved")
                window.open(`/#/`, '_self')
            }
            

        } catch (error) {
            utils.showError(error)
        }
    }


    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            //handleSubmit(onValidate1)()
        }
    }


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


    
    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    
    return (
        <>
            <div className="Spacing-V-Menu"></div>
            <div className="Row">
                <FormGroup className="Col-1-12"  >
                    {CoHead}
                </FormGroup>
            </div>
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                <H3>Change User Password</H3>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-2" label="User ID" labelFor="inputd_Userid">
                        <InputGroup disabled={true} id="inputd_Userid" name="d_Userid" defaultValue={def_d_Userid} placeholder="User ID ..." inputRef={register({ required: true , maxLength: 20}) } />
                        {errors.d_Userid && errors.d_Userid.types && <span id="Err_msg">{errors.d_Userid.types.message}</span>}
                        {errors.d_Userid && errors.d_Userid.type === "required" && (<span id="Err_msg">User ID cannot be blank</span>)}
                        {errors.d_Userid && errors.d_Userid.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Old Password" labelFor="inpud_User_Password">
                        <InputGroup id="inpud_User_Password" name="d_User_Password1" type="password" autoFocus={true} defaultValue="" placeholder="Old Password ..." inputRef={ register({ required: true , maxLength: 20}) } />
                        {errors.d_User_Password1 && errors.d_User_Password1.types && <span id="Err_msg">{errors.d_User_Password1.types.message}</span>}
                        {errors.d_User_Password1 && errors.d_User_Password1.type === "required" && (<span id="Err_msg">User Password cannot be blank</span>)}
                        {errors.d_User_Password1 && errors.d_User_Password1.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-3" label="New Password" labelFor="inpud_User_Password">
                    <InputGroup id="inpud_User_Password" name="d_User_Password2" type="password"  defaultValue="" placeholder="New Password ..." inputRef={ register({ required: true , maxLength: 20}) } />
                        {errors.d_User_Password2 && errors.d_User_Password2.types && <span id="Err_msg">{errors.d_User_Password2.types.message}</span>}
                        {errors.d_User_Password2 && errors.d_User_Password2.type === "required" && (<span id="Err_msg">User Password cannot be blank</span>)}
                        {errors.d_User_Password2 && errors.d_User_Password2.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-1-3" label="Re-enter New Password" labelFor="inpud_User_Password">
                    <InputGroup id="inpud_User_Password" name="d_User_Password3" type="password"  defaultValue="" placeholder="Re-enter New Password ..." inputRef={ register({ required: true , maxLength: 20}) } />
                        {errors.d_User_Password3 && errors.d_User_Password3.types && <span id="Err_msg">{errors.d_User_Password3.types.message}</span>}
                        {errors.d_User_Password3 && errors.d_User_Password3.type === "required" && (<span id="Err_msg">User Password cannot be blank</span>)}
                        {errors.d_User_Password3 && errors.d_User_Password3.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <div className="Spacing-V-16" />
                <div className="Row">
                    <Button className="Col-1-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                </div>
                <div className="Spacing-V-16" />
                <br></br>
                <p>Notes</p>
                <div>
                    <div>The password is case-sensitive</div>
                    <div>After change user password, this user may be CANNOT login old GSB</div>
                    <div>If you want to login old GSB, Plase use All UPPER case letter(s)</div>
                </div >
            </Card>
        </>
    );
}

export default UserchgPasswdMain