import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'


import { CategoryInfo } from './Types'
import { SystemHeader } from './CommonUI';

import { transpileModule } from 'typescript';
import { stringify } from 'querystring';
import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

const RESR_Code = "ACSR*#"

interface CategoryViewProps {
    gsb: AxiosInstance,
    Category: CategoryInfo,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function CategoryView({ gsb, Category = {} as CategoryInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: CategoryViewProps) {

    const [isDeletePress, setisDeletePress] = useState(false)
    const [isParametPress, setisParametPress] = useState(false)


    //console.log("CategoryView: " , Category)            

    //if (! ("d_CURCY" in Category[0])) return null


    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
               
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Code">
                    <InputGroup readOnly value={Category.d_CategoryCode1} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="Category">
                    <InputGroup readOnly value={Category.x_CAT_DESC1} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode2} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC2} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode3} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC3} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode4} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC4} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode5} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC5} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode6} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC6} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode7} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC7} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode8} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC8} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode9} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC9} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode10} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC10} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode11} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC11} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode12} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC12} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode13} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC13} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode14} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC14} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode15} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC15} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode16} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC16} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode17} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC17} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode18} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC18} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode19} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC19} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup readOnly value={Category.d_CategoryCode20} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup readOnly value={Category.x_CAT_DESC20} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Staff & Related Category">
                    <InputGroup readOnly value={Category.x_CAT_STAFF} />
                </FormGroup>
            </div>

        </div>
    )
}

interface CategoryEditProps {
    gsb: AxiosInstance,
    Category?: CategoryInfo,
    isNew?: boolean,
    onSave?: (Category: CategoryInfo) => void,
    onCancel?: () => void;
}

function CategoryEdit({ gsb, Category = {} as CategoryInfo, isNew = false, onSave = () => { }, onCancel = () => { } }: CategoryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<CategoryInfo>()
  
/*
    useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_CURCY" in Category) {
            reset(Category)
        }
    }, [Category, reset])
*/
    

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "x_Status" })
    }, [register])

    
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    


    //if (! isNew && !("d_tr_date" in Category)) return null




    const onSubmit = (data: CategoryInfo) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        //data.d_CE_No = data.d_CE_No.toUpperCase();


        (async () => {
            try {
                //console.log("call Categoryedit data", data)
                //console.log("call Categoryedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "Category", cAction: "CategoryValidate", isNew: isNew})
                //console.log("Categoryedit res.data.ok", res.data.ok)
                //console.log("Categoryedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            //handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>

            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Code">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode1" defaultValue={Category.d_CategoryCode1} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode1 && errors.d_CategoryCode1.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode1 && errors.d_CategoryCode1.types && <span id="Err_msg">{errors.d_CategoryCode1.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="Category">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC1" defaultValue={Category.x_CAT_DESC1} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC1 && errors.x_CAT_DESC1.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC1 && errors.x_CAT_DESC1.types && <span id="Err_msg">{errors.x_CAT_DESC1.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode2" defaultValue={Category.d_CategoryCode2} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode2 && errors.d_CategoryCode2.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode2 && errors.d_CategoryCode2.types && <span id="Err_msg">{errors.d_CategoryCode2.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC2" defaultValue={Category.x_CAT_DESC2} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC2 && errors.x_CAT_DESC2.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC2 && errors.x_CAT_DESC2.types && <span id="Err_msg">{errors.x_CAT_DESC2.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode3" defaultValue={Category.d_CategoryCode3} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode3 && errors.d_CategoryCode3.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode3 && errors.d_CategoryCode3.types && <span id="Err_msg">{errors.d_CategoryCode3.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC3" defaultValue={Category.x_CAT_DESC3} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC3 && errors.x_CAT_DESC3.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC3 && errors.x_CAT_DESC3.types && <span id="Err_msg">{errors.x_CAT_DESC3.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode4" defaultValue={Category.d_CategoryCode4} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode4 && errors.d_CategoryCode4.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode4 && errors.d_CategoryCode4.types && <span id="Err_msg">{errors.d_CategoryCode4.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC4" defaultValue={Category.x_CAT_DESC4} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC4 && errors.x_CAT_DESC4.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC4 && errors.x_CAT_DESC4.types && <span id="Err_msg">{errors.x_CAT_DESC4.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode5" defaultValue={Category.d_CategoryCode5} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode5 && errors.d_CategoryCode5.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode5 && errors.d_CategoryCode5.types && <span id="Err_msg">{errors.d_CategoryCode5.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC5" defaultValue={Category.x_CAT_DESC5} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC5 && errors.x_CAT_DESC5.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC5 && errors.x_CAT_DESC5.types && <span id="Err_msg">{errors.x_CAT_DESC5.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode6" defaultValue={Category.d_CategoryCode6} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode6 && errors.d_CategoryCode6.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode6 && errors.d_CategoryCode6.types && <span id="Err_msg">{errors.d_CategoryCode6.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC6" defaultValue={Category.x_CAT_DESC6} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC6 && errors.x_CAT_DESC6.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC6 && errors.x_CAT_DESC6.types && <span id="Err_msg">{errors.x_CAT_DESC6.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode7" defaultValue={Category.d_CategoryCode7} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode7 && errors.d_CategoryCode7.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode7 && errors.d_CategoryCode7.types && <span id="Err_msg">{errors.d_CategoryCode7.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC7" defaultValue={Category.x_CAT_DESC7} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC7 && errors.x_CAT_DESC7.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC7 && errors.x_CAT_DESC7.types && <span id="Err_msg">{errors.x_CAT_DESC7.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode8" defaultValue={Category.d_CategoryCode8} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode8 && errors.d_CategoryCode8.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode8 && errors.d_CategoryCode8.types && <span id="Err_msg">{errors.d_CategoryCode8.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC8" defaultValue={Category.x_CAT_DESC8} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC8 && errors.x_CAT_DESC8.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC8 && errors.x_CAT_DESC8.types && <span id="Err_msg">{errors.x_CAT_DESC8.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode9" defaultValue={Category.d_CategoryCode9} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode9 && errors.d_CategoryCode9.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode9 && errors.d_CategoryCode9.types && <span id="Err_msg">{errors.d_CategoryCode9.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC9" defaultValue={Category.x_CAT_DESC9} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC9 && errors.x_CAT_DESC9.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC9 && errors.x_CAT_DESC9.types && <span id="Err_msg">{errors.x_CAT_DESC9.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode10" defaultValue={Category.d_CategoryCode10} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode10 && errors.d_CategoryCode10.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode10 && errors.d_CategoryCode10.types && <span id="Err_msg">{errors.d_CategoryCode10.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC10" defaultValue={Category.x_CAT_DESC10} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC10 && errors.x_CAT_DESC10.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC10 && errors.x_CAT_DESC10.types && <span id="Err_msg">{errors.x_CAT_DESC10.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode11" defaultValue={Category.d_CategoryCode11} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode11 && errors.d_CategoryCode11.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode11 && errors.d_CategoryCode11.types && <span id="Err_msg">{errors.d_CategoryCode11.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC11" defaultValue={Category.x_CAT_DESC11} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC11 && errors.x_CAT_DESC11.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC11 && errors.x_CAT_DESC11.types && <span id="Err_msg">{errors.x_CAT_DESC11.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode12" defaultValue={Category.d_CategoryCode12} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode12 && errors.d_CategoryCode12.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode12 && errors.d_CategoryCode12.types && <span id="Err_msg">{errors.d_CategoryCode12.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC12" defaultValue={Category.x_CAT_DESC12} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC12 && errors.x_CAT_DESC12.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC12 && errors.x_CAT_DESC12.types && <span id="Err_msg">{errors.x_CAT_DESC12.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode13" defaultValue={Category.d_CategoryCode13} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode13 && errors.d_CategoryCode13.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode13 && errors.d_CategoryCode13.types && <span id="Err_msg">{errors.d_CategoryCode13.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC13" defaultValue={Category.x_CAT_DESC13} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC13 && errors.x_CAT_DESC13.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC13 && errors.x_CAT_DESC13.types && <span id="Err_msg">{errors.x_CAT_DESC13.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode14" defaultValue={Category.d_CategoryCode14} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode14 && errors.d_CategoryCode14.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode14 && errors.d_CategoryCode14.types && <span id="Err_msg">{errors.d_CategoryCode14.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC14" defaultValue={Category.x_CAT_DESC14} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC14 && errors.x_CAT_DESC14.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC14 && errors.x_CAT_DESC14.types && <span id="Err_msg">{errors.x_CAT_DESC14.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode15" defaultValue={Category.d_CategoryCode15} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode15 && errors.d_CategoryCode15.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode15 && errors.d_CategoryCode15.types && <span id="Err_msg">{errors.d_CategoryCode15.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC15" defaultValue={Category.x_CAT_DESC15} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC15 && errors.x_CAT_DESC15.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC15 && errors.x_CAT_DESC15.types && <span id="Err_msg">{errors.x_CAT_DESC15.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode16" defaultValue={Category.d_CategoryCode16} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode16 && errors.d_CategoryCode16.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode16 && errors.d_CategoryCode16.types && <span id="Err_msg">{errors.d_CategoryCode16.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC16" defaultValue={Category.x_CAT_DESC16} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC16 && errors.x_CAT_DESC16.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC16 && errors.x_CAT_DESC16.types && <span id="Err_msg">{errors.x_CAT_DESC16.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode17" defaultValue={Category.d_CategoryCode17} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode17 && errors.d_CategoryCode17.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode17 && errors.d_CategoryCode17.types && <span id="Err_msg">{errors.d_CategoryCode17.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC17" defaultValue={Category.x_CAT_DESC17} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC17 && errors.x_CAT_DESC17.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC17 && errors.x_CAT_DESC17.types && <span id="Err_msg">{errors.x_CAT_DESC17.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode18" defaultValue={Category.d_CategoryCode18} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode18 && errors.d_CategoryCode18.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode18 && errors.d_CategoryCode18.types && <span id="Err_msg">{errors.d_CategoryCode18.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC18" defaultValue={Category.x_CAT_DESC18} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC18 && errors.x_CAT_DESC18.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC18 && errors.x_CAT_DESC18.types && <span id="Err_msg">{errors.x_CAT_DESC18.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode19" defaultValue={Category.d_CategoryCode19} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode19 && errors.d_CategoryCode19.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode19 && errors.d_CategoryCode19.types && <span id="Err_msg">{errors.d_CategoryCode19.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC19" defaultValue={Category.x_CAT_DESC19} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC19 && errors.x_CAT_DESC19.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC19 && errors.x_CAT_DESC19.types && <span id="Err_msg">{errors.x_CAT_DESC19.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="">
                    <InputGroup id="inputStatusCode" name="d_CategoryCode20" defaultValue={Category.d_CategoryCode20} placeholder="Category Code ..." inputRef={register({ maxLength: 1}) } />
                    {errors.d_CategoryCode20 && errors.d_CategoryCode20.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_CategoryCode20 && errors.d_CategoryCode20.types && <span id="Err_msg">{errors.d_CategoryCode20.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="">
                    <InputGroup id="inputStatusDesc" name="x_CAT_DESC20" defaultValue={Category.x_CAT_DESC20} placeholder="Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_DESC20 && errors.x_CAT_DESC20.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_DESC20 && errors.x_CAT_DESC20.types && <span id="Err_msg">{errors.x_CAT_DESC20.types.message}</span>}
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label="Staff & Related Category">
                    <InputGroup id="inputCAT_Staff" name="x_CAT_STAFF" defaultValue={Category.x_CAT_STAFF} placeholder="Staff & Related Category ..." inputRef={register({ maxLength: 20}) } />
                    {errors.x_CAT_STAFF && errors.x_CAT_STAFF.type === "maxLength" && <span id="Err_msg">Max length is 20</span>}
                    {errors.x_CAT_STAFF && errors.x_CAT_STAFF.types && <span id="Err_msg">{errors.x_CAT_STAFF.types.message}</span>}
                </FormGroup>
            </div>
        
        </form>
    )
}



interface CategoryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function CategoryMain({ gsb }: CategoryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as CategoryInfo[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentCategory, setcurrentCategory] = useState({} as CategoryInfo)
    const [BlankCategory, setBlankCategory] = useState({} as CategoryInfo)


    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    

    
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    BlankCategory.d_CategoryCode1 = "C"
    BlankCategory.x_CAT_DESC1 = "CORPORATE"
    BlankCategory.d_CategoryCode2 = " "
    BlankCategory.x_CAT_DESC2 = "INDIVIDUAL"
    BlankCategory.d_CategoryCode3 = "S"
    BlankCategory.x_CAT_DESC3 = "STAFF A/C"
    BlankCategory.d_CategoryCode4 = "R"
    BlankCategory.x_CAT_DESC4 = "STAFF RELATED"


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("CategoryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("CategoryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("CategoryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("CategoryMain disp GsbFlag: ", GsbFlag)
                //console.log("CategoryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("CategoryMain disp dateFormat: ", dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("CategoryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("CategoryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("CategoryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;

                //console.log("CategoryMain isProduction: ", GsbFlag.isProduction)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])


    useEffect(() => {

        console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, GsbFlag, cardOpen01, currentMode])


    useEffect(() => {

        setnPage(1)
        ;

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "Category",
                        cAction: "ListRec",
                    });
                console.log("CategoryMain CategoryInfo ", res.data.data) 
                setData(res.data.data[0])
                if (res.data.data.length > 0) {
                    setcurrentCategory(res.data.data[0])
                }

                console.log("main currentCategory", currentCategory)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb ])



    const PrevRecProcess = async (Category: CategoryInfo) => {
/*        
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Category", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentCategory(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const DeleteProcess = async (Category: CategoryInfo) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Category", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentCategory(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const NextRecProcess = async (Category: CategoryInfo) => {
/*        
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Category", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentCategory(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }


    const newOnSave = async (Category: CategoryInfo) => {
/*        
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Category", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentCategory(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const editOnSave = async (Category: CategoryInfo) => {

        try {
            console.log("call editOnSave Category", Category)

            const res = await gsb.post('/Master', {sb_data: Category, cPgmId: "Category", cAction: "UpdateRec"})

            console.log("call editOnSave res.data.data", res.data.data)
            
            if ( res.data.ok ) {
                setcurrentCategory(res.data.data[0])
                showSuccess("Account Category Code Record Saved")
                //setisShowAlert1(true)
                //setShowAlert1Msg(res.data.data_Msg)
                setCurrentMode("show")
            } else {
                setisShowAlert2(true)
                setShowAlert2Msg(res.data.err_Msg)
                setCurrentMode("show")
            }

        } catch (error) {
            utils.showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <CategoryView gsb={gsb} Category={currentCategory} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentCategory)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentCategory)} onNextRec={() => NextRecProcess(currentCategory)} ></CategoryView>
    switch (currentMode) {
        case "edit":
            detail = <CategoryEdit gsb={gsb} Category={currentCategory} onSave={editOnSave} onCancel={() => { setCurrentMode("show") } }></CategoryEdit>
            break
        case "new":
            detail = <CategoryEdit gsb={gsb} Category={currentCategory} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></CategoryEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    

    return (
        <>
            <form >
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Account Category Code Setup</H3>
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}

                    {detail}
                    <p>Notes</p>
                    <div>
                        <div>List out trades in "(44) Daily Transaction Register" for control purpose:- </div>
                        <div>{currentCategory.x_CAT_STAFF}</div>
                    </div >
                </Card>
                <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>
            </form>
        </>
    );
}

export default CategoryMain