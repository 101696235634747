import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'

import svgWait2 from './svg/wait2.gif'


import { GLConfig } from './Types'
import { SystemHeader } from './CommonUI';

import { transpileModule } from 'typescript';
import { stringify } from 'querystring';

var showPurchaseSection:boolean = false ;
var showSellSection:boolean = false ;
var showTBOB_UserSection:boolean = false ;
var showChequeSection:boolean = false ;
var showPaymentReceivedSection:boolean = false ;
var showInterestSection:boolean = false ;


interface PageInfo {
    n: number
}

var GsbFlag: any;


interface GLConfigViewProps {
    gsb: AxiosInstance,
    GLConfig: GLConfig,
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
}



function GLConfigView({ gsb, GLConfig = {} as GLConfig, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { } }: GLConfigViewProps) {

    const [isDeletePress, setisDeletePress] = useState(false)

    const [showPurchaseSectionMore, setshowPurchaseSectionMore] = useState(showPurchaseSection)
    const [showSellSectionMore, setshowSellSectionMore] = useState(showSellSection)
    const [showTBOB_UserSectionMore, setshowTBOB_UserSectionMore] = useState(showTBOB_UserSection)
    const [showChequeSectionMore, setshowChequeSectionMore] = useState(showChequeSection)
    const [showPaymentReceivedSectionMore, setshowPaymentReceivedSectionMore] = useState(showPaymentReceivedSection)
    const [showInterestSectionMore, setshowInterestSectionMore] = useState(showInterestSection)

    const [dateFormat, setdateFormat] = useState("1")

    const [GSBx_USER, setGSBx_USER] = useState(false)


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;

                setdateFormat(GsbFlag.dateFormat)
                setGSBx_USER(GsbFlag.GSBx_USER) ;
            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, GLConfig ])



    if (! ("x_MB_CLA" in GLConfig)) return null



    return (
        <div>
            <div className="Row">
                <Button className="Col-1-1" disabled={true} icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                <Button className="Col-2-1" disabled={true} icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" disabled={true} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>

                <Button className="Col-5-1" disabled={true} icon="direction-right" onClick={() => onNextRec()} >Next</Button>
             
            </div>
            <div className="Spacing-V-16" />
            { ! showPurchaseSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPurchaseSectionMore(! showPurchaseSectionMore) ; showPurchaseSection = ! showPurchaseSection }} >+ Purchase Section </div> }
            { showPurchaseSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPurchaseSectionMore(! showPurchaseSectionMore) ; showPurchaseSection = ! showPurchaseSection }} >- Purchase Section </div> }
            { showPurchaseSectionMore && <div>
                <H6>Purchase (Bought) Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLA} />
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Bought">
                        <InputGroup readOnly value={GLConfig.x_MB_BKR} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLC} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Contract Stamp">
                        <InputGroup readOnly value={GLConfig.x_MB_STAMP} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Internal)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLI} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Transaction Levy">
                        <InputGroup readOnly value={GLConfig.x_MB_LEVY} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Margin)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLM} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Trading Fee">
                        <InputGroup readOnly value={GLConfig.x_MB_SLEVY} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Priority)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLP} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="AFRC Levy">
                        <InputGroup readOnly value={GLConfig.x_MB_FLEVY} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Finance)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLF} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Interest Accrued">
                        <InputGroup readOnly value={GLConfig.x_MB_INTACR} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Brokerage">
                        <InputGroup readOnly value={GLConfig.x_MB_COMM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Fee">
                        <InputGroup readOnly value={GLConfig.x_MB_CCASS} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Handling Fee">
                        <InputGroup readOnly value={GLConfig.x_MB_EXP1} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Management Fee">
                        <InputGroup readOnly value={GLConfig.x_MB_EXP2} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transfer Fee">
                        <InputGroup readOnly value={GLConfig.x_MB_EXP3} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Trf Fee">
                        <InputGroup readOnly value={GLConfig.x_MB_EXP3A} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Stamp Duty">
                        <InputGroup readOnly value={GLConfig.x_MB_EXP4} />
                    </FormGroup>
                    </div>
            </div> }
            <div className="Spacing-V-16" />
            { ! showSellSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSellSectionMore(! showSellSectionMore) ; showSellSection = ! showSellSection }} >+ Sell Section </div> }
            { showSellSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSellSectionMore(! showSellSectionMore) ; showSellSection = ! showSellSection }} >- Sell Section </div> }
            { showSellSectionMore && <div>
                <H6>Sell (Sold) Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Broker -- Sold">
                        <InputGroup readOnly value={GLConfig.x_MS_BKR} />
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Client (Account)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLA} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Interest Accrued">
                        <InputGroup readOnly value={GLConfig.x_MS_INTACR} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Client (Cash)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLC} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Internal)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLI} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Margin)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Priority)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLP} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Finance)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLF} />
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Contract Stamp">
                        <InputGroup readOnly value={GLConfig.x_MS_STAMP} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transaction Levy">
                        <InputGroup readOnly value={GLConfig.x_MS_LEVY} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Trading Fee">
                        <InputGroup readOnly value={GLConfig.x_MS_SLEVY} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="AFRC Levy">
                        <InputGroup readOnly value={GLConfig.x_MS_FLEVY} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transfer Deed">
                        <InputGroup readOnly value={GLConfig.x_MS_TD} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Brokerage">
                        <InputGroup readOnly value={GLConfig.x_MS_COMM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Fee">
                        <InputGroup readOnly value={GLConfig.x_MS_CCASS} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Handling Fee">
                        <InputGroup readOnly value={GLConfig.x_MS_EXP1} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Management Fee">
                        <InputGroup readOnly value={GLConfig.x_MS_EXP2} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transfer Fee">
                        <InputGroup readOnly value={GLConfig.x_MS_EXP3} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Trf Fee">
                        <InputGroup readOnly value={GLConfig.x_MS_EXP3A} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Stamp Duty">
                        <InputGroup readOnly value={GLConfig.x_MS_EXP4} />
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
            { GsbFlag.TBOB_USER && <div>
                { ! showTBOB_UserSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowTBOB_UserSectionMore(! showTBOB_UserSectionMore) ; showTBOB_UserSection = ! showTBOB_UserSection }} >+ Trade by Other Brokers Section </div> }
                { showTBOB_UserSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowTBOB_UserSectionMore(! showTBOB_UserSectionMore) ; showTBOB_UserSection = ! showTBOB_UserSection }} >- Trade by Other Brokers Section </div> }
                { showTBOB_UserSectionMore && <div>
                    <H6>Trade by Other Brokers : </H6>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-1" label="Debit">
                        </FormGroup>
                        <FormGroup className="Col-2-1" label="Sold To Broker">
                            <InputGroup readOnly value={GLConfig.x_MS_CLB} />
                        </FormGroup>
                        <FormGroup className="Col-5-1" label="Credit:">
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Bought From Broker">
                            <InputGroup readOnly value={GLConfig.x_MB_CLB} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-2-1" label="Brokerage">
                            <InputGroup readOnly value={GLConfig.x_MS_COMM_S} />
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Brokerage">
                            <InputGroup readOnly value={GLConfig.x_MS_COMM_B} />
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-2-1" label="Price Income">
                            <InputGroup readOnly value={GLConfig.x_MS_PRICEIN} />
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Price Income">
                            <InputGroup readOnly value={GLConfig.x_MB_PRICEIN} />
                        </FormGroup>
                    </div>
                </div> }
            </div> }
            <div className="Spacing-V-16" />
            { ! showChequeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChequeSectionMore(! showChequeSectionMore) ; showChequeSection = ! showChequeSection }} >+ Cheque Section </div> }
            { showChequeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChequeSectionMore(! showChequeSectionMore) ; showChequeSection = ! showChequeSection }} >- Cheque Section </div> }
            { showChequeSectionMore && <div>
                <H6>Broker Cheque Issued Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Broker -- Bought">
                        <InputGroup readOnly value={GLConfig.x_BQ_BKR} />
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Bank Currenct A/C">
                        <InputGroup readOnly value={GLConfig.x_BQ_BANK} />
                    </FormGroup>
                </div>
                <H6>Broker Direct Trade Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Broker -- Bought">
                        <InputGroup readOnly value={GLConfig.x_DB_BKR} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Sold">
                        <InputGroup readOnly value={GLConfig.x_DS_BKR} />
                    </FormGroup>
                </div>
                <H6>Broker CCASS Trade Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Broker -- Bought">
                        <InputGroup readOnly value={GLConfig.x_MB_BKR} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="CCASS Payable">
                        <InputGroup readOnly value={GLConfig.x_CB_BKR} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="CCASS Receivable">
                        <InputGroup readOnly value={GLConfig.x_CS_BKR} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Sold">
                        <InputGroup readOnly value={GLConfig.x_MS_BKR} />
                    </FormGroup>
                </div>
                <H6>Client Cheque Issued Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup readOnly value={GLConfig.x_CQ_CLA} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Bank Currenct A/C">
                        <InputGroup readOnly value={GLConfig.x_CQ_BANK} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup readOnly value={GLConfig.x_CQ_CLC} />
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
            { ! showPaymentReceivedSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPaymentReceivedSectionMore(! showPaymentReceivedSectionMore) ; showPaymentReceivedSection = ! showPaymentReceivedSection }} >+ Payment / Received Section </div> }
            { showPaymentReceivedSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPaymentReceivedSectionMore(! showPaymentReceivedSectionMore) ; showPaymentReceivedSection = ! showPaymentReceivedSection }} >- Payment / Received Section </div> }
            { showPaymentReceivedSectionMore && <div>
                <H6>Received From Broker Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Bank Currenct A/C">
                        <InputGroup readOnly value={GLConfig.x_RB_BANK} />
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Sold">
                        <InputGroup readOnly value={GLConfig.x_RB_BKR} />
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <H6>Received From Client Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Bank Currenct A/C">
                        { GsbFlag.MULTI_BANK && <InputGroup id="inputRB_BANK" readOnly value="(Multi Bank)" /> }
                        { ! GsbFlag.MULTI_BANK && <InputGroup readOnly value={GLConfig.x_RC_BANK} /> }
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Client (Account)">
                        <InputGroup readOnly value={GLConfig.x_RC_CLA} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Cash)">
                        <InputGroup readOnly value={GLConfig.x_RC_CLC} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Internal)">
                        <InputGroup readOnly value={GLConfig.x_RC_CLI} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Margin)">
                        <InputGroup readOnly value={GLConfig.x_RC_CLM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Priority)">
                        <InputGroup readOnly value={GLConfig.x_RC_CLP} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Finance)">
                        <InputGroup readOnly value={GLConfig.x_RC_CLF} />
                    </FormGroup>
                </div>
                { GsbFlag.TBOB_USER && <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Broker)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLB} />
                    </FormGroup>
                </div> }
                <H6>Payment To Client Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup readOnly value={GLConfig.x_PC_CLA} />
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Bank Currenct A/C">
                        { GsbFlag.MULTI_BANK && <InputGroup id="inputRB_BANK" readOnly value="(Multi Bank)" /> }
                        { ! GsbFlag.MULTI_BANK && <InputGroup readOnly value={GLConfig.x_PC_BANK} /> }
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup readOnly value={GLConfig.x_PC_CLC} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Internal)">
                        <InputGroup readOnly value={GLConfig.x_PC_CLI} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Margin)">
                        <InputGroup readOnly value={GLConfig.x_PC_CLM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Priority)">
                        <InputGroup readOnly value={GLConfig.x_PC_CLP} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Finance)">
                        <InputGroup readOnly value={GLConfig.x_PC_CLF} />
                    </FormGroup>
                </div>
                { GsbFlag.TBOB_USER && <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Broker)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLB} />
                    </FormGroup>
                </div> }
            </div> }
            <div className="Spacing-V-16" />
            { ! showInterestSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowInterestSectionMore(! showInterestSectionMore) ; showInterestSection = ! showInterestSection }} >+ Interest Section </div> }
            { showInterestSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowInterestSectionMore(! showInterestSectionMore) ; showInterestSection = ! showInterestSection }} >- Interest Section </div> }
            { showInterestSectionMore && <div>
                <H6>Monthly Interest Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup readOnly value={GLConfig.x_INT_CLA} />
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Interest Received">
                        <InputGroup readOnly value={GLConfig.x_INT_CR} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup readOnly value={GLConfig.x_INT_CLC} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Internal)">
                        <InputGroup readOnly value={GLConfig.x_INT_CLI} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Margin)">
                        <InputGroup readOnly value={GLConfig.x_INT_CLM} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Priority)">
                        <InputGroup readOnly value={GLConfig.x_INT_CLP} />
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Finance)">
                        <InputGroup readOnly value={GLConfig.x_INT_CLF} />
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
        </div>
    )
}

interface GLConfigEditProps {
    gsb: AxiosInstance,
    GLConfig?: GLConfig,
    isNew?: boolean,
    onSave?: (GLConfig: GLConfig) => void,
    onCancel?: () => void;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function GLConfigEdit({ gsb, GLConfig = {} as GLConfig, isNew = false, onSave = () => { }, onCancel = () => { } }: GLConfigEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<GLConfig>()

    const [showPurchaseSectionMore, setshowPurchaseSectionMore] = useState(showPurchaseSection)
    const [showSellSectionMore, setshowSellSectionMore] = useState(showSellSection)
    const [showTBOB_UserSectionMore, setshowTBOB_UserSectionMore] = useState(showTBOB_UserSection)
    const [showChequeSectionMore, setshowChequeSectionMore] = useState(showChequeSection)
    const [showPaymentReceivedSectionMore, setshowPaymentReceivedSectionMore] = useState(showPaymentReceivedSection)
    const [showInterestSectionMore, setshowInterestSectionMore] = useState(showInterestSection)

    const [dateFormat, setdateFormat] = useState("1")

    const [MB_CLAValue, setMB_CLAValue] = useState(GLConfig.x_MB_CLA)
    const [MB_CLCValue, setMB_CLCValue] = useState(GLConfig.x_MB_CLC)
    const [MB_CLIValue, setMB_CLIValue] = useState(GLConfig.x_MB_CLI)
    const [MB_CLMValue, setMB_CLMValue] = useState(GLConfig.x_MB_CLM)
    const [MB_CLPValue, setMB_CLPValue] = useState(GLConfig.x_MB_CLP)
    const [MB_CLFValue, setMB_CLFValue] = useState(GLConfig.x_MB_CLF)

    const [MB_BKRValue, setMB_BKRValue] = useState(GLConfig.x_MB_BKR)
    const [MB_STAMPValue, setMB_STAMPValue] = useState(GLConfig.x_MB_STAMP)
    const [MB_LEVYValue, setMB_LEVYValue] = useState(GLConfig.x_MB_LEVY)
    const [MB_SLEVYValue, setMB_SLEVYValue] = useState(GLConfig.x_MB_SLEVY)
    const [MB_FLEVYValue, setMB_FLEVYValue] = useState(GLConfig.x_MB_FLEVY)
    const [MB_INTACRValue, setMB_INTACRValue] = useState(GLConfig.x_MB_INTACR)
    const [MB_COMMValue, setMB_COMMValue] = useState(GLConfig.x_MB_COMM)
    const [MB_CCASSValue, setMB_CCASSValue] = useState(GLConfig.x_MB_CCASS)
    const [MB_EXP1Value, setMB_EXP1Value] = useState(GLConfig.x_MB_EXP1)
    const [MB_EXP2Value, setMB_EXP2Value] = useState(GLConfig.x_MB_EXP2)
    const [MB_EXP3Value, setMB_EXP3Value] = useState(GLConfig.x_MB_EXP3)
    const [MB_EXP3AValue, setMB_EXP3AValue] = useState(GLConfig.x_MB_EXP3A)
    const [MB_EXP4Value, setMB_EXP4Value] = useState(GLConfig.x_MB_EXP4)

    const [MS_BKRValue, setMS_BKRValue] = useState(GLConfig.x_MS_BKR)
    const [MS_INTACRValue, setMS_INTACRValue] = useState(GLConfig.x_MS_INTACR)

    const [MS_CLAValue, setMS_CLAValue] = useState(GLConfig.x_MS_CLA)
    const [MS_CLCValue, setMS_CLCValue] = useState(GLConfig.x_MS_CLC)
    const [MS_CLIValue, setMS_CLIValue] = useState(GLConfig.x_MS_CLI)
    const [MS_CLMValue, setMS_CLMValue] = useState(GLConfig.x_MS_CLM)
    const [MS_CLPValue, setMS_CLPValue] = useState(GLConfig.x_MS_CLP)
    const [MS_CLFValue, setMS_CLFValue] = useState(GLConfig.x_MS_CLF)

    const [MS_STAMPValue, setMS_STAMPValue] = useState(GLConfig.x_MS_STAMP)
    const [MS_LEVYValue, setMS_LEVYValue] = useState(GLConfig.x_MS_LEVY)
    const [MS_SLEVYValue, setMS_SLEVYValue] = useState(GLConfig.x_MS_SLEVY)
    const [MS_FLEVYValue, setMS_FLEVYValue] = useState(GLConfig.x_MS_FLEVY)
    const [MS_TDValue, setMS_TDValue] = useState(GLConfig.x_MS_TD)
    const [MS_COMMValue, setMS_COMMValue] = useState(GLConfig.x_MS_COMM)
    const [MS_CCASSValue, setMS_CCASSValue] = useState(GLConfig.x_MS_CCASS)
    const [MS_EXP1Value, setMS_EXP1Value] = useState(GLConfig.x_MS_EXP1)
    const [MS_EXP2Value, setMS_EXP2Value] = useState(GLConfig.x_MS_EXP2)
    const [MS_EXP3Value, setMS_EXP3Value] = useState(GLConfig.x_MS_EXP3)
    const [MS_EXP3AValue, setMS_EXP3AValue] = useState(GLConfig.x_MS_EXP3A)
    const [MS_EXP4Value, setMS_EXP4Value] = useState(GLConfig.x_MS_EXP4)

    const [MS_CLBValue, setMS_CLBValue] = useState(GLConfig.x_MS_CLB)
    const [MS_COMM_SValue, setMS_COMM_SValue] = useState(GLConfig.x_MS_COMM_S)
    const [MS_PRICEINValue, setMS_PRICEINValue] = useState(GLConfig.x_MS_PRICEIN)
    const [MB_CLBValue, setMB_CLBValue] = useState(GLConfig.x_MB_CLB)
    const [MS_COMM_BValue, setMS_COMM_BValue] = useState(GLConfig.x_MS_COMM_B)
    const [MB_PRICEINValue, setMB_PRICEINValue] = useState(GLConfig.x_MB_PRICEIN)

    const [BQ_BKRValue, setBQ_BKRValue] = useState(GLConfig.x_BQ_BKR)
    const [BQ_BANKValue, setBQ_BANKValue] = useState(GLConfig.x_BQ_BANK)
    const [DB_BKRValue, setDB_BKRValue] = useState(GLConfig.x_DB_BKR)
    const [DS_BKRValue, setDS_BKRValue] = useState(GLConfig.x_DS_BKR)
    const [CS_BKRValue, setCS_BKRValue] = useState(GLConfig.x_CS_BKR)
    const [CB_BKRValue, setCB_BKRValue] = useState(GLConfig.x_CB_BKR)
    const [CQ_CLAValue, setCQ_CLAValue] = useState(GLConfig.x_CQ_CLA)
    const [CQ_CLCValue, setCQ_CLCValue] = useState(GLConfig.x_CQ_CLC)
    const [CQ_BANKValue, setCQ_BANKValue] = useState(GLConfig.x_CQ_BANK)

    const [RB_BANKValue, setRB_BANKValue] = useState(GLConfig.x_RB_BANK)
    const [RB_BKRValue, setRB_BKRValue] = useState(GLConfig.x_RB_BKR)

    const [RC_BANKValue, setRC_BANKValue] = useState(GLConfig.x_RC_BANK)
    const [RC_CLAValue, setRC_CLAValue] = useState(GLConfig.x_RC_CLA)
    const [RC_CLCValue, setRC_CLCValue] = useState(GLConfig.x_RC_CLC)
    const [RC_CLIValue, setRC_CLIValue] = useState(GLConfig.x_RC_CLI)
    const [RC_CLMValue, setRC_CLMValue] = useState(GLConfig.x_RC_CLM)
    const [RC_CLPValue, setRC_CLPValue] = useState(GLConfig.x_RC_CLP)
    const [RC_CLFValue, setRC_CLFValue] = useState(GLConfig.x_RC_CLF)

    const [PC_BANKValue, setPC_BANKValue] = useState(GLConfig.x_PC_BANK)
    const [PC_CLAValue, setPC_CLAValue] = useState(GLConfig.x_PC_CLA)
    const [PC_CLCValue, setPC_CLCValue] = useState(GLConfig.x_PC_CLC)
    const [PC_CLIValue, setPC_CLIValue] = useState(GLConfig.x_PC_CLI)
    const [PC_CLMValue, setPC_CLMValue] = useState(GLConfig.x_PC_CLM)
    const [PC_CLPValue, setPC_CLPValue] = useState(GLConfig.x_PC_CLP)
    const [PC_CLFValue, setPC_CLFValue] = useState(GLConfig.x_PC_CLF)

    const [INT_CLAValue, setINT_CLAValue] = useState(GLConfig.x_INT_CLA)
    const [INT_CLCValue, setINT_CLCValue] = useState(GLConfig.x_INT_CLC)
    const [INT_CLIValue, setINT_CLIValue] = useState(GLConfig.x_INT_CLI)
    const [INT_CLMValue, setINT_CLMValue] = useState(GLConfig.x_INT_CLM)
    const [INT_CLPValue, setINT_CLPValue] = useState(GLConfig.x_INT_CLP)
    const [INT_CLFValue, setINT_CLFValue] = useState(GLConfig.x_INT_CLF)
    const [INT_CRValue, setINT_CRValue] = useState(GLConfig.x_INT_CR)


    const [GSBx_USER, setGSBx_USER] = useState(false)
  

    useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("x_MB_CLA" in GLConfig) {
            reset(GLConfig)
        }
    }, [GLConfig, reset])

    
    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;

                setGSBx_USER(GsbFlag.GSBx_USER) ;
                setdateFormat(GsbFlag.dateFormat)

            } catch (error) {
                //utils.showError(error)
            }
        })()
    }, [gsb, GLConfig])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "d_status" })
    }, [register])

    
    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])
    

    if (! isNew && !("x_MB_CLA" in GLConfig)) return null


    const onSubmit = (data: GLConfig) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        //data.d_CE_No = data.d_CE_No.toUpperCase();

        data.x_MB_CLA = MB_CLAValue;
        data.x_MB_CLC = MB_CLCValue;
        data.x_MB_CLI = MB_CLIValue;
        data.x_MB_CLM = MB_CLMValue;
        data.x_MB_CLP = MB_CLPValue;
        data.x_MB_CLF = MB_CLFValue;

        data.x_MB_BKR = MB_BKRValue;
        data.x_MB_STAMP = MB_STAMPValue;
        data.x_MB_LEVY = MB_LEVYValue;
        data.x_MB_SLEVY = MB_SLEVYValue;
        data.x_MB_FLEVY = MB_FLEVYValue;
        data.x_MB_INTACR = MB_INTACRValue;
        data.x_MB_COMM = MB_COMMValue;
        data.x_MB_CCASS = MB_CCASSValue;
        data.x_MB_EXP1 = MB_EXP1Value;
        data.x_MB_EXP2 = MB_EXP2Value;
        data.x_MB_EXP3 = MB_EXP3Value;
        data.x_MB_EXP3A = MB_EXP3AValue;
        data.x_MB_EXP4 = MB_EXP4Value;

        data.x_MS_BKR = MS_BKRValue;
        data.x_MS_INTACR = MS_INTACRValue;

        data.x_MS_CLA = MS_CLAValue;
        data.x_MS_CLC = MS_CLCValue;
        data.x_MS_CLI = MS_CLIValue;
        data.x_MS_CLM = MS_CLMValue;
        data.x_MS_CLP = MS_CLPValue;
        data.x_MS_CLF = MS_CLFValue;

        data.x_MS_STAMP = MS_STAMPValue;
        data.x_MS_LEVY = MS_LEVYValue;
        data.x_MS_SLEVY = MS_SLEVYValue;
        data.x_MS_FLEVY = MS_FLEVYValue;
        data.x_MS_TD = MS_TDValue;
        data.x_MS_COMM = MS_COMMValue;
        data.x_MS_CCASS = MS_CCASSValue;
        data.x_MS_EXP1 = MS_EXP1Value;
        data.x_MS_EXP2 = MS_EXP2Value;
        data.x_MS_EXP3 = MS_EXP3Value;
        data.x_MS_EXP3A = MS_EXP3AValue;
        data.x_MS_EXP4 = MS_EXP4Value;

        data.x_MS_CLB = MS_CLBValue;
        data.x_MS_COMM_S = MS_COMM_SValue;
        data.x_MS_PRICEIN = MS_PRICEINValue;
        data.x_MB_CLB = MB_CLBValue;
        data.x_MS_COMM_B = MS_COMM_BValue;
        data.x_MB_PRICEIN = MB_PRICEINValue;

        data.x_BQ_BKR = BQ_BKRValue;
        data.x_BQ_BANK = BQ_BANKValue;
        data.x_DB_BKR = DB_BKRValue;
        data.x_DS_BKR = DS_BKRValue;
        data.x_CS_BKR = CS_BKRValue;
        data.x_CB_BKR = CB_BKRValue;
        data.x_CQ_CLA = CQ_CLAValue;
        data.x_CQ_CLC = CQ_CLCValue;
        data.x_CQ_BANK = CQ_BANKValue;

        data.x_RB_BANK = RB_BANKValue;
        data.x_RB_BKR = RB_BKRValue;

        data.x_RC_BANK = RC_BANKValue;
        data.x_RC_CLA = RC_CLAValue;
        data.x_RC_CLC = RC_CLCValue;
        data.x_RC_CLI = RC_CLIValue;
        data.x_RC_CLM = RC_CLMValue;
        data.x_RC_CLF = RC_CLPValue;
        data.x_RC_CLF = RC_CLFValue;

        data.x_PC_BANK = PC_BANKValue;
        data.x_PC_CLA = PC_CLAValue;
        data.x_PC_CLC = PC_CLCValue;
        data.x_PC_CLI = PC_CLIValue;
        data.x_PC_CLM = PC_CLMValue;
        data.x_PC_CLP = PC_CLPValue;
        data.x_PC_CLF = PC_CLFValue;

        data.x_INT_CLA = INT_CLAValue;
        data.x_INT_CLC = INT_CLCValue;
        data.x_INT_CLI = INT_CLIValue;
        data.x_INT_CLM = INT_CLMValue;
        data.x_INT_CLP = INT_CLPValue;
        data.x_INT_CLF = INT_CLFValue;
        data.x_INT_CR = INT_CRValue;

        ;
        (async () => {
            try {
                //console.log("call GLConfigedit data", data)
                //console.log("call GLConfigedit isNew", isNew)
                const res = await gsb.post('/Master', {sb_data: data, cPgmId: "GLConfig", cAction: "GLConfigValidate", isNew: isNew})
                //console.log("GLConfigedit res.data.ok", res.data.ok)
                //console.log("GLConfigedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }
                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()


    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
            </div>
            <div className="Spacing-V-16" />
            { ! showPurchaseSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPurchaseSectionMore(! showPurchaseSectionMore) ; showPurchaseSection = ! showPurchaseSection }} >+ Purchase Section </div> }
            { showPurchaseSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPurchaseSectionMore(! showPurchaseSectionMore) ; showPurchaseSection = ! showPurchaseSection }} >- Purchase Section </div> }
            { showPurchaseSectionMore && <div>
                <H6>Purchase (Bought) Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup id="inputMB_CLA" defaultValue={MB_CLAValue} value={MB_CLAValue} onChange={utils.handleStringChange(n => setMB_CLAValue(n))} />
                        {errors.x_MB_CLA && errors.x_MB_CLA.types && <span id="Err_msg">{errors.x_MB_CLA.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Bought">
                        <InputGroup id="inputMB_BKR" defaultValue={MB_BKRValue} value={MB_BKRValue} onChange={utils.handleStringChange(n => setMB_BKRValue(n))} />
                        {errors.x_MB_BKR && errors.x_MB_BKR.types && <span id="Err_msg">{errors.x_MB_BKR.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup id="inputMB_CLC" defaultValue={MB_CLCValue} value={MB_CLCValue} onChange={utils.handleStringChange(n => setMB_CLCValue(n))} />
                        {errors.x_MB_CLC && errors.x_MB_CLC.types && <span id="Err_msg">{errors.x_MB_CLC.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Contract Stamp">
                        <InputGroup id="inputMB_STAMP" defaultValue={MB_STAMPValue} value={MB_STAMPValue} onChange={utils.handleStringChange(n => setMB_STAMPValue(n))} />
                        {errors.x_MB_STAMP && errors.x_MB_STAMP.types && <span id="Err_msg">{errors.x_MB_STAMP.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Internal)">
                        <InputGroup id="inputMB_CLI" defaultValue={MB_CLIValue} value={MB_CLIValue} onChange={utils.handleStringChange(n => setMB_CLIValue(n))} />
                        {errors.x_MB_CLI && errors.x_MB_CLI.types && <span id="Err_msg">{errors.x_MB_CLI.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Transaction Levy">
                        <InputGroup id="inputMB_LEVY" defaultValue={MB_LEVYValue} value={MB_LEVYValue} onChange={utils.handleStringChange(n => setMB_LEVYValue(n))} />
                        {errors.x_MB_LEVY && errors.x_MB_LEVY.types && <span id="Err_msg">{errors.x_MB_LEVY.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Margin)">
                        <InputGroup id="inputMB_CLM" defaultValue={MB_CLMValue} value={MB_CLMValue} onChange={utils.handleStringChange(n => setMB_CLMValue(n))} />
                        {errors.x_MB_CLM && errors.x_MB_CLM.types && <span id="Err_msg">{errors.x_MB_CLM.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Trading Fee">
                        <InputGroup id="inputMB_SLEVY" defaultValue={MB_SLEVYValue} value={MB_SLEVYValue} onChange={utils.handleStringChange(n => setMB_SLEVYValue(n))} />
                        {errors.x_MB_SLEVY && errors.x_MB_SLEVY.types && <span id="Err_msg">{errors.x_MB_SLEVY.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Priority)">
                        <InputGroup id="inputMB_CLP" defaultValue={MB_CLPValue} value={MB_CLPValue} onChange={utils.handleStringChange(n => setMB_CLPValue(n))} />
                        {errors.x_MB_CLP && errors.x_MB_CLP.types && <span id="Err_msg">{errors.x_MB_CLP.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="AFRC Levy">
                        <InputGroup id="inputMB_FLEVY" defaultValue={MB_FLEVYValue} value={MB_FLEVYValue} onChange={utils.handleStringChange(n => setMB_FLEVYValue(n))} />
                        {errors.x_MB_FLEVY && errors.x_MB_FLEVY.types && <span id="Err_msg">{errors.x_MB_FLEVY.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Finance)">
                        <InputGroup id="inputMB_CLF" defaultValue={MB_CLFValue} value={MB_CLFValue} onChange={utils.handleStringChange(n => setMB_CLFValue(n))} />
                        {errors.x_MB_CLF && errors.x_MB_CLF.types && <span id="Err_msg">{errors.x_MB_CLF.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Interest Accrued">
                        <InputGroup id="inputMB_INTACR" defaultValue={MB_INTACRValue} value={MB_INTACRValue} onChange={utils.handleStringChange(n => setMB_INTACRValue(n))} />
                        {errors.x_MB_INTACR && errors.x_MB_INTACR.types && <span id="Err_msg">{errors.x_MB_INTACR.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Brokerage">
                        <InputGroup id="inputMB_COMM" defaultValue={MB_COMMValue} value={MB_COMMValue} onChange={utils.handleStringChange(n => setMB_COMMValue(n))} />
                        {errors.x_MB_COMM && errors.x_MB_COMM.types && <span id="Err_msg">{errors.x_MB_COMM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Fee">
                        <InputGroup id="inputMMB_CCASS" defaultValue={MB_CCASSValue} value={MB_CCASSValue} onChange={utils.handleStringChange(n => setMB_CCASSValue(n))} />
                        {errors.x_MB_CCASS && errors.x_MB_CCASS.types && <span id="Err_msg">{errors.x_MB_CCASS.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Handling Fee">
                        <InputGroup id="inputMB_EXP1" defaultValue={MB_EXP1Value} value={MB_EXP1Value} onChange={utils.handleStringChange(n => setMB_EXP1Value(n))} />
                        {errors.x_MB_EXP1 && errors.x_MB_EXP1.types && <span id="Err_msg">{errors.x_MB_EXP1.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Management Fee">
                        <InputGroup id="inputMB_EXP2" defaultValue={MB_EXP2Value} value={MB_EXP2Value} onChange={utils.handleStringChange(n => setMB_EXP2Value(n))} />
                        {errors.x_MB_EXP2 && errors.x_MB_EXP2.types && <span id="Err_msg">{errors.x_MB_EXP2.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transfer Fee">
                        <InputGroup id="inputMB_EXP3" defaultValue={MB_EXP3Value} value={MB_EXP3Value} onChange={utils.handleStringChange(n => setMB_EXP3Value(n))} />
                        {errors.x_MB_EXP3 && errors.x_MB_EXP3.types && <span id="Err_msg">{errors.x_MB_EXP3.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Trf Fee">
                        <InputGroup id="inputMB_EXP3A" defaultValue={MB_EXP3AValue} value={MB_EXP3AValue} onChange={utils.handleStringChange(n => setMB_EXP3AValue(n))} />
                        {errors.x_MB_EXP3A && errors.x_MB_EXP3A.types && <span id="Err_msg">{errors.x_MB_EXP3A.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Stamp Duty">
                        <InputGroup id="inputMB_EXP4" defaultValue={MB_EXP4Value} value={MB_EXP4Value} onChange={utils.handleStringChange(n => setMB_EXP4Value(n))} />
                        {errors.x_MB_EXP4 && errors.x_MB_EXP4.types && <span id="Err_msg">{errors.x_MB_EXP4.types.message}</span>}
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
            { ! showSellSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSellSectionMore(! showSellSectionMore) ; showSellSection = ! showSellSection }} >+ Sell Section </div> }
            { showSellSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowSellSectionMore(! showSellSectionMore) ; showSellSection = ! showSellSection }} >- Sell Section </div> }
            { showSellSectionMore && <div>
                <H6>Sell (Sold) Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Broker -- Sold">
                        <InputGroup id="inputMS_BKR" defaultValue={MS_BKRValue} value={MS_BKRValue} onChange={utils.handleStringChange(n => setMS_BKRValue(n))} />
                        {errors.x_MS_BKR && errors.x_MS_BKR.types && <span id="Err_msg">{errors.x_MS_BKR.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Client (Account)">
                        <InputGroup id="inputMMS_CLA" defaultValue={MS_CLAValue} value={MS_CLAValue} onChange={utils.handleStringChange(n => setMS_CLAValue(n))} />
                        {errors.x_MS_CLA && errors.x_MS_CLA.types && <span id="Err_msg">{errors.x_MS_CLA.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Interest Accrued">
                        <InputGroup id="inputMMS_INTACR" defaultValue={MS_INTACRValue} value={MS_INTACRValue} onChange={utils.handleStringChange(n => setMS_INTACRValue(n))} />
                        {errors.x_MS_INTACR && errors.x_MS_INTACR.types && <span id="Err_msg">{errors.x_MS_INTACR.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Client (Cash)">
                        <InputGroup id="inputMS_CLC" defaultValue={MS_CLCValue} value={MS_CLCValue} onChange={utils.handleStringChange(n => setMS_CLCValue(n))} />
                        {errors.x_MS_CLC && errors.x_MS_CLC.types && <span id="Err_msg">{errors.x_MS_CLC.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Internal)">
                        <InputGroup id="inputMS_CLI" defaultValue={MS_CLIValue} value={MS_CLIValue} onChange={utils.handleStringChange(n => setMS_CLIValue(n))} />
                        {errors.x_MS_CLI && errors.x_MS_CLI.types && <span id="Err_msg">{errors.x_MS_CLI.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Margin)">
                        <InputGroup id="inputMS_CLM" defaultValue={MS_CLMValue} value={MS_CLMValue} onChange={utils.handleStringChange(n => setMS_CLMValue(n))} />
                        {errors.x_MS_CLM && errors.x_MS_CLM.types && <span id="Err_msg">{errors.x_MS_CLM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Priority)">
                        <InputGroup id="inputMS_CLP" defaultValue={MS_CLPValue} value={MS_CLPValue} onChange={utils.handleStringChange(n => setMS_CLPValue(n))} />
                        {errors.x_MS_CLP && errors.x_MS_CLP.types && <span id="Err_msg">{errors.x_MS_CLP.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Finance)">
                        <InputGroup id="inputMS_CLF" defaultValue={MS_CLFValue} value={MS_CLFValue} onChange={utils.handleStringChange(n => setMS_CLFValue(n))} />
                        {errors.x_MS_CLF && errors.x_MS_CLF.types && <span id="Err_msg">{errors.x_MS_CLF.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Contract Stamp">
                        <InputGroup id="inputx_MS_STAMP" defaultValue={MS_STAMPValue} value={MS_STAMPValue} onChange={utils.handleStringChange(n => setMS_STAMPValue(n))} />
                        {errors.x_MS_STAMP && errors.x_MS_STAMP.types && <span id="Err_msg">{errors.x_MS_STAMP.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transaction Levy">
                        <InputGroup id="inputMMS_LEVY" defaultValue={MS_LEVYValue} value={MS_LEVYValue} onChange={utils.handleStringChange(n => setMS_LEVYValue(n))} />
                        {errors.x_MS_LEVY && errors.x_MS_LEVY.types && <span id="Err_msg">{errors.x_MS_LEVY.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Trading Fee">
                    <InputGroup id="inputMMS_SLEVY" defaultValue={MS_SLEVYValue} value={MS_SLEVYValue} onChange={utils.handleStringChange(n => setMS_SLEVYValue(n))} />
                        {errors.x_MS_SLEVY && errors.x_MS_SLEVY.types && <span id="Err_msg">{errors.x_MS_SLEVY.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="AFRC Levy">
                    <InputGroup id="inputMMS_FLEVY" defaultValue={MS_FLEVYValue} value={MS_FLEVYValue} onChange={utils.handleStringChange(n => setMS_FLEVYValue(n))} />
                        {errors.x_MS_FLEVY && errors.x_MS_FLEVY.types && <span id="Err_msg">{errors.x_MS_FLEVY.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transfer Deed">
                        <InputGroup id="inputMMS_TD" defaultValue={MS_TDValue} value={MS_TDValue} onChange={utils.handleStringChange(n => setMS_TDValue(n))} />
                        {errors.x_MS_TD && errors.x_MS_TD.types && <span id="Err_msg">{errors.x_MS_TD.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Brokerage">
                        <InputGroup id="inputMS_COMM" defaultValue={MS_COMMValue} value={MS_COMMValue} onChange={utils.handleStringChange(n => setMS_COMMValue(n))} />
                        {errors.x_MS_COMM && errors.x_MS_COMM.types && <span id="Err_msg">{errors.x_MS_COMM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Fee">
                        <InputGroup id="inputMS_CCASS" defaultValue={MS_CCASSValue} value={MS_CCASSValue} onChange={utils.handleStringChange(n => setMS_CCASSValue(n))} />
                        {errors.x_MS_CCASS && errors.x_MS_CCASS.types && <span id="Err_msg">{errors.x_MS_CCASS.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Handling Fee">
                        <InputGroup id="inputMS_EXP1" defaultValue={MS_EXP1Value} value={MS_EXP1Value} onChange={utils.handleStringChange(n => setMS_EXP1Value(n))} />
                        {errors.x_MS_EXP1 && errors.x_MS_EXP1.types && <span id="Err_msg">{errors.x_MS_EXP1.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Management Fee">
                        <InputGroup id="inputMS_EXP2" defaultValue={MS_EXP2Value} value={MS_EXP2Value} onChange={utils.handleStringChange(n => setMS_EXP2Value(n))} />
                        {errors.x_MS_EXP2 && errors.x_MS_EXP2.types && <span id="Err_msg">{errors.x_MS_EXP2.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Transfer Fee">
                        <InputGroup id="inputMS_EXP3" defaultValue={MS_EXP3Value} value={MS_EXP3Value} onChange={utils.handleStringChange(n => setMS_EXP3Value(n))} />
                        {errors.x_MS_EXP3 && errors.x_MS_EXP3.types && <span id="Err_msg">{errors.x_MS_EXP3.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="CCASS Trf Fee">
                        <InputGroup id="inputMS_EXP3A" defaultValue={MS_EXP3AValue} value={MS_EXP3AValue} onChange={utils.handleStringChange(n => setMS_EXP3AValue(n))} />
                        {errors.x_MS_EXP3A && errors.x_MS_EXP3A.types && <span id="Err_msg">{errors.x_MS_EXP3A.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Stamp Duty">
                        <InputGroup id="inputMS_EXP4" defaultValue={MS_EXP4Value} value={MS_EXP4Value} onChange={utils.handleStringChange(n => setMS_EXP4Value(n))} />
                        {errors.x_MS_EXP4 && errors.x_MS_EXP4.types && <span id="Err_msg">{errors.x_MS_EXP4.types.message}</span>}
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
            { GsbFlag.TBOB_USER && <div>
                { ! showTBOB_UserSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowTBOB_UserSectionMore(! showTBOB_UserSectionMore) ; showTBOB_UserSection = ! showTBOB_UserSection }} >+ Trade by Other Brokers Section </div> }
                { showTBOB_UserSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowTBOB_UserSectionMore(! showTBOB_UserSectionMore) ; showTBOB_UserSection = ! showTBOB_UserSection }} >- Trade by Other Brokers Section </div> }
                { showTBOB_UserSectionMore && <div>
                    <H6>Trade by Other Brokers : </H6>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        <FormGroup className="Col-1-1" label="Debit">
                        </FormGroup>
                        <FormGroup className="Col-2-1" label="Sold To Broker">
                            <InputGroup id="inputMS_CLB" defaultValue={MS_CLBValue} value={MS_CLBValue} onChange={utils.handleStringChange(n => setMS_CLBValue(n))} />
                            {errors.x_MS_CLB && errors.x_MS_CLB.types && <span id="Err_msg">{errors.x_MS_CLB.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-5-1" label="Credit:">
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Bought From Broker">
                            <InputGroup id="inputMB_CLB" defaultValue={MB_CLBValue} value={MB_CLBValue} onChange={utils.handleStringChange(n => setMB_CLBValue(n))} />
                            {errors.x_MB_CLB && errors.x_MB_CLB.types && <span id="Err_msg">{errors.x_MB_CLB.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-2-1" label="Brokerage">
                            <InputGroup id="inputMS_COMM_S" defaultValue={MS_COMM_SValue} value={MS_COMM_SValue} onChange={utils.handleStringChange(n => setMS_COMM_SValue(n))} />
                            {errors.x_MS_COMM_S && errors.x_MS_COMM_S.types && <span id="Err_msg">{errors.x_MS_COMM_S.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Brokerage">
                            <InputGroup id="inputMS_COMM_B" defaultValue={MS_COMM_BValue} value={MS_COMM_BValue} onChange={utils.handleStringChange(n => setMS_COMM_BValue(n))} />
                            {errors.x_MS_COMM_B && errors.x_MS_COMM_B.types && <span id="Err_msg">{errors.x_MS_COMM_B.types.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="Row">
                        <FormGroup className="Col-2-1" label="Price Income">
                            <InputGroup id="inputMS_PRICEIN" defaultValue={MS_PRICEINValue} value={MS_PRICEINValue} onChange={utils.handleStringChange(n => setMS_PRICEINValue(n))} />
                            {errors.x_MS_PRICEIN && errors.x_MS_PRICEIN.types && <span id="Err_msg">{errors.x_MS_PRICEIN.types.message}</span>}
                        </FormGroup>
                        <FormGroup className="Col-6-1" label="Price Income">
                            <InputGroup id="inputMB_PRICEIN" defaultValue={MB_PRICEINValue} value={MB_PRICEINValue} onChange={utils.handleStringChange(n => setMB_PRICEINValue(n))} />
                            {errors.x_MB_PRICEIN && errors.x_MB_PRICEIN.types && <span id="Err_msg">{errors.x_MB_PRICEIN.types.message}</span>}
                        </FormGroup>
                    </div>
                </div> }
            </div> }
            <div className="Spacing-V-16" />
            { ! showChequeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChequeSectionMore(! showChequeSectionMore) ; showChequeSection = ! showChequeSection }} >+ Cheque Section </div> }
            { showChequeSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowChequeSectionMore(! showChequeSectionMore) ; showChequeSection = ! showChequeSection }} >- Cheque Section </div> }
            { showChequeSectionMore && <div>
                <H6>Broker Cheque Issued Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Broker -- Bought">
                        <InputGroup id="inputBQ_BKR" defaultValue={BQ_BKRValue} value={BQ_BKRValue} onChange={utils.handleStringChange(n => setBQ_BKRValue(n))} />
                        {errors.x_BQ_BKR && errors.x_BQ_BKR.types && <span id="Err_msg">{errors.x_BQ_BKR.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Bank Currenct A/C">
                        <InputGroup id="inputBQ_BANK" defaultValue={BQ_BANKValue} value={BQ_BANKValue} onChange={utils.handleStringChange(n => setBQ_BANKValue(n))} />
                        {errors.x_BQ_BANK && errors.x_BQ_BANK.types && <span id="Err_msg">{errors.x_BQ_BANK.types.message}</span>}
                    </FormGroup>
                </div>
                <H6>Broker Direct Trade Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Broker -- Bought">
                        <InputGroup id="inputDB_BKR" defaultValue={DB_BKRValue} value={DB_BKRValue} onChange={utils.handleStringChange(n => setDB_BKRValue(n))} />
                        {errors.x_DB_BKR && errors.x_DB_BKR.types && <span id="Err_msg">{errors.x_DB_BKR.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Sold">
                        <InputGroup id="inputDS_BKR" defaultValue={DS_BKRValue} value={DS_BKRValue} onChange={utils.handleStringChange(n => setDS_BKRValue(n))} />
                        {errors.x_DS_BKR && errors.x_DS_BKR.types && <span id="Err_msg">{errors.x_DS_BKR.types.message}</span>}
                    </FormGroup>
                </div>
                <H6>Broker CCASS Trade Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Broker -- Bought">
                        <InputGroup readOnly value={GLConfig.x_MB_BKR} />
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="CCASS Payable">
                        <InputGroup id="inputCB_BKRR" defaultValue={CB_BKRValue} value={CB_BKRValue} onChange={utils.handleStringChange(n => setCB_BKRValue(n))} />
                        {errors.x_CB_BKR && errors.x_CB_BKR.types && <span id="Err_msg">{errors.x_CB_BKR.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="CCASS Receivable">
                        <InputGroup id="inputCS_BKR" defaultValue={CS_BKRValue} value={CS_BKRValue} onChange={utils.handleStringChange(n => setCS_BKRValue(n))} />
                        {errors.x_CS_BKR && errors.x_CS_BKR.types && <span id="Err_msg">{errors.x_CS_BKR.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Sold">
                        <InputGroup readOnly value={GLConfig.x_MS_BKR} />
                    </FormGroup>
                </div>
                <H6>Client Cheque Issued Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup id="inputCQ_CLA" defaultValue={CQ_CLAValue} value={CQ_CLAValue} onChange={utils.handleStringChange(n => setCQ_CLAValue(n))} />
                        {errors.x_CQ_CLA && errors.x_CQ_CLA.types && <span id="Err_msg">{errors.x_CQ_CLA.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Bank Currenct A/C">
                        <InputGroup id="inputCQ_BANK" defaultValue={CQ_BANKValue} value={CQ_BANKValue} onChange={utils.handleStringChange(n => setCQ_BANKValue(n))} />
                        {errors.x_CQ_BANK && errors.x_CQ_BANK.types && <span id="Err_msg">{errors.x_CQ_BANK.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup id="inputCQ_CLC" defaultValue={CQ_CLCValue} value={CQ_CLCValue} onChange={utils.handleStringChange(n => setCQ_CLCValue(n))} />
                        {errors.x_CQ_CLC && errors.x_CQ_CLC.types && <span id="Err_msg">{errors.x_CQ_CLC.types.message}</span>}
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
            { ! showPaymentReceivedSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPaymentReceivedSectionMore(! showPaymentReceivedSectionMore) ; showPaymentReceivedSection = ! showPaymentReceivedSection }} >+ Payment / Received Section </div> }
            { showPaymentReceivedSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowPaymentReceivedSectionMore(! showPaymentReceivedSectionMore) ; showPaymentReceivedSection = ! showPaymentReceivedSection }} >- Payment / Received Section </div> }
            { showPaymentReceivedSectionMore && <div>
                <H6>Received From Broker Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Bank Currenct A/C">
                        <InputGroup id="inputRB_BANK" defaultValue={RB_BANKValue} value={RB_BANKValue} onChange={utils.handleStringChange(n => setRB_BANKValue(n))} />
                        {errors.x_RB_BANK && errors.x_RB_BANK.types && <span id="Err_msg">{errors.x_RB_BANK.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Broker -- Sold">
                        <InputGroup id="inputRB_BKR" defaultValue={RB_BKRValue} value={RB_BKRValue} onChange={utils.handleStringChange(n => setRB_BKRValue(n))} />
                        {errors.x_RB_BKR && errors.x_RB_BKR.types && <span id="Err_msg">{errors.x_RB_BKR.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Spacing-V-16" />
                <H6>Received From Client Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Bank Currenct A/C">
                        { GsbFlag.MULTI_BANK && <InputGroup id="inputRB_BANK" readOnly value="(Multi Bank)" /> }
                        { ! GsbFlag.MULTI_BANK && <InputGroup id="inputRC_BANK" defaultValue={RC_BANKValue} value={RC_BANKValue} onChange={utils.handleStringChange(n => setRC_BANKValue(n))} /> }
                        {errors.x_RC_BANK && errors.x_RC_BANK.types && <span id="Err_msg">{errors.x_RC_BANK.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Client (Account)">
                        <InputGroup id="inputRC_CLA" defaultValue={RC_CLAValue} value={RC_CLAValue} onChange={utils.handleStringChange(n => setRC_CLAValue(n))} />
                        {errors.x_RC_CLA && errors.x_RC_CLA.types && <span id="Err_msg">{errors.x_RC_CLA.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Cash)">
                        <InputGroup id="inputRC_CLC" defaultValue={RC_CLCValue} value={RC_CLCValue} onChange={utils.handleStringChange(n => setRC_CLCValue(n))} />
                        {errors.x_RC_CLC && errors.x_RC_CLC.types && <span id="Err_msg">{errors.x_RC_CLC.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Internal)">
                        <InputGroup id="inputRC_CLI" defaultValue={RC_CLIValue} value={RC_CLIValue} onChange={utils.handleStringChange(n => setRC_CLIValue(n))} />
                        {errors.x_RC_CLI && errors.x_RC_CLI.types && <span id="Err_msg">{errors.x_RC_CLI.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Margin)">
                        <InputGroup id="inputRC_CLM" defaultValue={RC_CLMValue} value={RC_CLMValue} onChange={utils.handleStringChange(n => setRC_CLMValue(n))} />
                        {errors.x_RC_CLM && errors.x_RC_CLM.types && <span id="Err_msg">{errors.x_RC_CLM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Priority)">
                        <InputGroup id="inputRC_CLP" defaultValue={RC_CLPValue} value={RC_CLPValue} onChange={utils.handleStringChange(n => setRC_CLPValue(n))} />
                        {errors.x_RC_CLP && errors.x_RC_CLP.types && <span id="Err_msg">{errors.x_RC_CLP.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Finance)">
                        <InputGroup id="inputRC_CLF" defaultValue={RC_CLFValue} value={RC_CLFValue} onChange={utils.handleStringChange(n => setRC_CLFValue(n))} />
                        {errors.x_RC_CLF && errors.x_RC_CLF.types && <span id="Err_msg">{errors.x_RC_CLF.types.message}</span>}
                    </FormGroup>
                </div>
                { GsbFlag.TBOB_USER && <div className="Row">
                    <FormGroup className="Col-6-1" label="Client (Broker)">
                        <InputGroup readOnly value={GLConfig.x_MS_CLB} />
                    </FormGroup>
                </div> }
                <H6>Payment To Client Journal : </H6>
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup id="inputPC_CLA" defaultValue={PC_CLAValue} value={PC_CLAValue} onChange={utils.handleStringChange(n => setPC_CLAValue(n))} />
                        {errors.x_PC_CLA && errors.x_PC_CLA.types && <span id="Err_msg">{errors.x_PC_CLA.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Bank Currenct A/C">
                        { GsbFlag.MULTI_BANK && <InputGroup id="inputRB_BANK" readOnly value="(Multi Bank)" /> }
                        { ! GsbFlag.MULTI_BANK && <InputGroup id="inputPC_BANK" defaultValue={PC_BANKValue} value={PC_BANKValue} onChange={utils.handleStringChange(n => setPC_BANKValue(n))} /> }
                        {errors.x_PC_BANK && errors.x_PC_BANK.types && <span id="Err_msg">{errors.x_PC_BANK.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup id="inputPC_CLC" defaultValue={PC_CLCValue} value={PC_CLCValue} onChange={utils.handleStringChange(n => setPC_CLCValue(n))} />
                        {errors.x_PC_CLC && errors.x_PC_CLC.types && <span id="Err_msg">{errors.x_PC_CLC.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Internal)">
                        <InputGroup id="inputPC_CLI" defaultValue={PC_CLIValue} value={PC_CLIValue} onChange={utils.handleStringChange(n => setPC_CLIValue(n))} />
                        {errors.x_PC_CLI && errors.x_PC_CLI.types && <span id="Err_msg">{errors.x_PC_CLI.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Margin)">
                        <InputGroup id="inputPC_CLM" defaultValue={PC_CLMValue} value={PC_CLMValue} onChange={utils.handleStringChange(n => setPC_CLMValue(n))} />
                        {errors.x_PC_CLM && errors.x_PC_CLM.types && <span id="Err_msg">{errors.x_PC_CLM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Priority)">
                        <InputGroup id="inputPC_CLP" defaultValue={PC_CLPValue} value={PC_CLPValue} onChange={utils.handleStringChange(n => setPC_CLPValue(n))} />
                        {errors.x_PC_CLP && errors.x_PC_CLP.types && <span id="Err_msg">{errors.x_PC_CLP.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Finance)">
                        <InputGroup id="inputPC_CLF" defaultValue={PC_CLFValue} value={PC_CLFValue} onChange={utils.handleStringChange(n => setPC_CLFValue(n))} />
                        {errors.x_PC_CLF && errors.x_PC_CLF.types && <span id="Err_msg">{errors.x_PC_CLF.types.message}</span>}
                    </FormGroup>
                </div>
                { GsbFlag.TBOB_USER && <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Broker)">
                        <InputGroup readOnly value={GLConfig.x_MB_CLB} />
                    </FormGroup>
                </div> }
            </div> }
            <div className="Spacing-V-16" />
            { ! showInterestSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowInterestSectionMore(! showInterestSectionMore) ; showInterestSection = ! showInterestSection }} >+ Interest Section </div> }
            { showInterestSectionMore && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowInterestSectionMore(! showInterestSectionMore) ; showInterestSection = ! showInterestSection }} >- Interest Section </div> }
            { showInterestSectionMore && <div>
                <H6>Monthly Interest Journal : </H6>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-1" label="Debit">
                    </FormGroup>
                    <FormGroup className="Col-2-1" label="Client (Account)">
                        <InputGroup id="inputINT_CLA" defaultValue={INT_CLAValue} value={INT_CLAValue} onChange={utils.handleStringChange(n => setINT_CLAValue(n))} />
                        {errors.x_INT_CLA && errors.x_INT_CLA.types && <span id="Err_msg">{errors.x_INT_CLA.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-5-1" label="Credit:">
                    </FormGroup>
                    <FormGroup className="Col-6-1" label="Interest Received">
                        <InputGroup id="inputINT_CR" defaultValue={INT_CRValue} value={INT_CRValue} onChange={utils.handleStringChange(n => setINT_CRValue(n))} />
                        {errors.x_INT_CR && errors.x_INT_CR.types && <span id="Err_msg">{errors.x_INT_CR.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Cash)">
                        <InputGroup id="inputINT_CLC" defaultValue={INT_CLCValue} value={INT_CLCValue} onChange={utils.handleStringChange(n => setINT_CLCValue(n))} />
                        {errors.x_INT_CLC && errors.x_INT_CLC.types && <span id="Err_msg">{errors.x_INT_CLC.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Internal)">
                        <InputGroup id="inputINT_CLI" defaultValue={INT_CLIValue} value={INT_CLIValue} onChange={utils.handleStringChange(n => setINT_CLIValue(n))} />
                        {errors.x_INT_CLI && errors.x_INT_CLI.types && <span id="Err_msg">{errors.x_INT_CLI.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Margin)">
                        <InputGroup id="inputINT_CLM" defaultValue={INT_CLMValue} value={INT_CLMValue} onChange={utils.handleStringChange(n => setINT_CLMValue(n))} />
                        {errors.x_INT_CLM && errors.x_INT_CLM.types && <span id="Err_msg">{errors.x_INT_CLM.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Priority)">
                        <InputGroup id="inputINT_CLP" defaultValue={INT_CLPValue} value={INT_CLPValue} onChange={utils.handleStringChange(n => setINT_CLPValue(n))} />
                        {errors.x_INT_CLP && errors.x_INT_CLP.types && <span id="Err_msg">{errors.x_INT_CLP.types.message}</span>}
                    </FormGroup>
                </div>
                <div className="Row">
                    <FormGroup className="Col-2-1" label="Client (Finance)">
                        <InputGroup id="inputINT_CLF" defaultValue={INT_CLFValue} value={INT_CLFValue} onChange={utils.handleStringChange(n => setINT_CLFValue(n))} />
                        {errors.x_INT_CLF && errors.x_INT_CLF.types && <span id="Err_msg">{errors.x_INT_CLF.types.message}</span>}
                    </FormGroup>
                </div>
            </div> }
            <div className="Spacing-V-16" />
        </form>
    )
}

interface GLConfigMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function GLConfigMain({ gsb }: GLConfigMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    const [data, setData] = useState([] as GLConfig[])


    const [searchKey, setSearchKey] = useState("OpenDate")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(false)
    const [showButton, setshowButton] = useState(false)


    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("Detail")
    const [currentAc, setCurrentAc] = useState({} as GLConfig)
    const [BlankAc, setBlankAc] = useState({} as GLConfig)


    const [currentMode, setCurrentMode] = useState("show")

    const [isDark, setIsDark] = useState(document.getElementById("body")?.classList.contains("bp4-dark"))

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    

    
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [userKey, setuserKey] = useState("")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    //gsb default value
    //BlankAc.d_p_Pref = "P"



    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("GLConfigMain disp user_key: ", res.data.Flag.user_key)
                //console.log("GLConfigMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("GLConfigMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("GLConfigMain disp GsbFlag: ", GsbFlag)
                //console.log("GLConfigMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("GLConfigMain disp dateFormat: ", dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                //console.log("GLConfigMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("GLConfigMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("GLConfigMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                setuserKey(GsbFlag.user_key) ;

                //console.log("GLConfigMain isProduction: ", GsbFlag.isProduction)
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentMode])


    useEffect(() => {

        console.log("MenuBar cardOpen01", cardOpen01)        
        if ( cardOpen01.substr(0,17) === "cardOpen01-Theme8" ) {
            document.getElementById("body")?.classList.add("bp4-dark")
            setIsDark(true)
        } else {
            document.getElementById("body")?.classList.remove("bp4-dark")
            setIsDark(false)
        }


    }, [gsb, GsbFlag, cardOpen01, currentMode])


    useEffect(() => {

        setnPage(1)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "",
                            start: "",
                            nPage: 1,
                            limit: 0,
                            backward: false,
                            SortByName: false,
                                    filters: {
                            }
                        },
                        cPgmId: "GLConfig",
                        cAction: "ListRec",
                    });
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                console.log("main res.data", res.data.data)
                console.log("main currentAc", currentAc)
                setCurrentMode("show")

                setshowButton(false)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])



    const PrevRecProcess = async (GLConfig: GLConfig) => {
/*        
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GLConfig", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const DeleteProcess = async (GLConfig: GLConfig) => {
/*        
        try {
            //console.log("call DeleteProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GLConfig", cAction: "DelRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const NextRecProcess = async (GLConfig: GLConfig) => {
/*        
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GLConfig", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }


    const newOnSave = async (GLConfig: GLConfig) => {
/*        
        try {
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "GLConfig", cAction: "AddRec"})
            showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record added")

            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
*/        
    }

    const editOnSave = async (GLConfig: GLConfig) => {
        try {
            console.log("call editOnSave", GLConfig)
            const res = await gsb.post('/Master', {sb_data: GLConfig, cPgmId: "GLConfig", cAction: "UpdateRec"})
            //showSuccess("Input Date & Time " + ac.d_inpputDate +" "+ ac.d_inpputTime + " record saved")
/*            
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
*/            
            //setCurrentAc(res.data.data)
            showSuccess("GL Configuration Record Saved")
            setCurrentAc(res.data.data[0])
            setCurrentMode("show")

        } catch (error) {
            utils.showError(error)
        }
    }



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <GLConfigView gsb={gsb} GLConfig={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentAc)} onNextRec={() => NextRecProcess(currentAc)} ></GLConfigView>
    switch (currentMode) {
        case "edit":
            detail = <GLConfigEdit gsb={gsb} GLConfig={currentAc} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></GLConfigEdit>
            break
        case "new":
            detail = <GLConfigEdit gsb={gsb} GLConfig={BlankAc} isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } }></GLConfigEdit>
            break
    }

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>



    let list = <>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>    

    return (
        <>
            <form >
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>GL Configuration - General Ledger Interface Table </H3>
                    {/*
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                    */}

                    {detail}
                </Card>
            </form>
        </>
    );
}

export default GLConfigMain