import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, Suggest2, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem, Icon } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, DialogBody, Callout, Checkbox, Tooltip } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

//import { Account, cbMast, Document, StatusInfo, CurrencyInfo, HkidrInfo, CommInfo, IntRInfo } from './Types'
import { Account,  Document, StatusInfo,  HkidrInfo,   } from './Types'
//import { LocationInfo, BranchEmailInfo, ExtraFeeInfo, MktFeeInfo, RegionCodeInfo } from './Types'
import { LocationInfo, BranchEmailInfo, ExtraFeeInfo, MktFeeInfo, RegionCodeInfo } from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';
import { Label } from 'react-konva';
 

interface PageInfo {
    n: number
}

interface PageInfo2 {
    pageno: number,
    pageShow: boolean,
    pageStartno: number,
    pageEndno: number,
    pageLast: boolean,
}


var GsbFlag: any;

var UserID: string;
var PgmCode = "M0001" ;
var isReqAuth2:boolean = false ;


interface ClDocSetViewProps {
    gsb: AxiosInstance,
    ac: Account,
    ac0: Account,
    docList?: Document[],
    StatusList?: StatusInfo[],   
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}

const getDocIcon = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "id-number"
        case "signature":
            return "draw"
    }
    // contract, others
    return "document"
}

const getDocText = (docType: string) => {
    switch (docType.toLowerCase()) {
        case "id":
            return "ID"
        case "signature":
            return "Signature"
        case "contract":
            return "Contract"
        case "others":
            return "Others"
    }
    // contract, others
    return "Unknown"
}



function ClDocSetView({ gsb, ac = {} as Account, ac0 = {} as Account, docList = [] as Document[], StatusList=[] as StatusInfo[], baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: ClDocSetViewProps) {


    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)

    const [isViewClientDoc, setisViewClientDoc] = useState(false)
    const [isViewClientDocPress, setisViewClientDocPress] = useState(false)
    const [showClientDoc, setshowClientDoc] = useState(true)

    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [isForeignCurrencyPress, setisForeignCurrencyPress] = useState(false)
    //const [BlankCB, setBlankCB] = useState({} as cbMast)

    const [showSIMore, setshowSIMore] = useState(true)

    const [RegionCodeList, setRegionCodeList] = useState([] as RegionCodeInfo[])

    const [HKIDR_Info2, setHKIDR_Info2] = useState({} as HkidrInfo)
    const [Blank_HKIDR_Info2, setBlank_HKIDR_Info2] = useState({} as HkidrInfo)


    const [StatusDesc, setStatusDesc] = useState("")
    const [ExtraFeeDesc, setExtraFeeDesc] = useState("")
    const [CountryDesc, setCountryDesc] = useState("")


    const [userKey, setuserKey] = useState("DEMO")
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [showPenaltyInt, setshowPenaltyInt] = useState(false)
    const [Batch1Value, setBatch1Value] = useState(false)
 
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 




    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                GsbFlag = res.data.Flag;
                setuserKey(GsbFlag.user_key)
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }

                setshowPenaltyInt( (GsbFlag.OVRMGN_INT) || ( GsbFlag.OVRLMT_INT) );
                //setshowCurrency( (GsbFlag.MULTI_MKT) && ( ! GsbFlag.SING_CURR) );

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                        setisViewClientDoc(false)
                        setshowClientDoc(false)
                        if ( res.data.data[0].d_Can_ViewClientDoc == "Y" ) {
                            setisViewClientDoc(true)
                            setshowClientDoc(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])

    const onReqViewClientDoc = () => {
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: AuthUser,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )
                if (res2.data.ok) {
                    setAuthUser("")
                    setPassword("")

                    const res = await gsb.post('/Master', {
                        sb_data: {
                            userId: AuthUser,
                            password: password,
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "ReqUserGroupAccessReqAuth"
                    })
                    console.log("onReqAuthDelRec res.data.data", res.data.data);
                    if ( res.data.ok && res.data.data[0].d_Can_ViewClientDoc == "Y") {
                        setshowClientDoc(true);
                        
                    } else {
                        utils.showWarn("Authorization failed")
                        setisShowAlert2(true)
                        setShowAlert2Msg("Authorization failed")
                    }

                } else {
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }



    console.log("ClMastView ac.d_docset1: ", ac.d_docset1) 


    useEffect(() => {
        setCountryDesc("")
        let RegionItem3 = RegionCodeList.findIndex((RegionCode: RegionCodeInfo) => RegionCode.d_RegionCode === ac.d_RegionCode)
        //console.log("ClMastView RegionCodeList.find RegionItem3 : ", RegionItem3) 
        //console.log("ClMastView RegionCodeList.find RegionItem3.d_Country : ", RegionCodeList[RegionItem3]) 
        if ( RegionItem3 > 0 ) {
            //console.log("ClMastView RegionCodeList.find RegionItem3.d_Country2 : ", RegionCodeList[RegionItem3].d_Country) 
            setCountryDesc(RegionCodeList[RegionItem3].d_Country)
        }

        //console.log("ClMastView RegionCodeList.find CountryDesc : ", CountryDesc) 
    }, [ac, ac.d_RegionCode])


    useEffect(() => {
        //console.log("set StatusList: ", StatusList)
        setStatusDesc("")
        let StatusItem3 = StatusList.findIndex((StatusCode: StatusInfo) => StatusCode.d_StatusCode === ac.d_status)
        //console.log("ClMastView StatusList.find StatusItem3 : ", StatusItem3) 
        //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc : ", StatusList[StatusItem3]) 
        if ( StatusItem3 > 0 ) {
            //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc2 : ", StatusList[StatusItem3].d_StatusDesc) 
            setStatusDesc(StatusList[StatusItem3].d_StatusDesc)
        }

        //console.log("ClMastView StatusList.find StatusDes : ", StatusDes) 
    }, [ac, ac.d_status])



    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }


    if (! ("d_acCode" in ac)) return null



    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT=Axxxx, CUSTODY=Pxxxx </div> 
        <div>Margin A/C : MARGIN=Mxxxx </div> 
        <div>RUNNER=Cxxxx, INTERNAL=Ixxxx </div> 
   </div>

    const DocSet1ToolTip = 
    <div>Cash Client Agreement (Securities Client)</div>


    return (
        <div>
            { ViewMode === "V" && <div className="Row No-Print">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec() } >Prev</Button>
                <Button className="Col-2-1" disabled={ true } icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={ isReadOnly } icon="edit" onClick={() => onEdit() } >Edit</Button>
                <Button className="Col-4-1" disabled={ true } icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button>
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
			
 
	 
            </div> }
            { ViewMode === "L" && <div>
                Account No. : {ac.d_acCode} 
            
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }
            
            <div className="Spacing-V-16" />
            <div className="Row">
            
                <FormGroup className="Col-1-2" label={t('Doc Date On')} labelFor="inputDocDate">
                    {/*dispDate
                     //d_type = "0"
                       YYYY-MM-DD => DD-MM-YYYY
                     //d_type = "1"
                    // YYYY-MM-DD => DD-MM-YYYY
                    */}
                    <InputGroup readOnly value= {utils.dispDate(ac.d_DocDate,'0')} />   
                </FormGroup>
            </div>  
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelInfo="*">
                    <InputGroup readOnly value={ac.d_acCode} />
                </FormGroup>
                <FormGroup className="Col-2-3" label="Client Name">
                    <InputGroup readOnly value={ac.d_acName1} />
                    <InputGroup readOnly value={ac.d_acName2} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_acName1.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acName1} </div> }
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_acName2.toString() == "") && 
                    <div id="DispText2" > {ac0.d_acName2} </div> }
                </FormGroup>
                <FormGroup className="Col-5-2" label="Runner">
                    <InputGroup readOnly value={ac.d_aeCode} />
                    
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_aeCode.toString() == "") && 
                    <div id="DispText2" > {ac0.d_aeCode} </div> }
                </FormGroup>
                <FormGroup className="Col-7-1" label="Status">
                    <InputGroup readOnly value= {ac.d_status} />
                    <div>
                    <div id="DispText">{StatusDesc}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (ac0.d_status.toString() == "") && 
                    <div id="DispText2" > {ac0.d_status} </div> }
                </FormGroup>

            </div>
 
            <div className="Row">
                <FormGroup className="Col-1-6">
                    <Checkbox label="1. Cash Client Agreement (Securities Client)" disabled={false} checked={utils.stringToBoolean(ac.d_docset1)} />                            
                    <Checkbox label="2. General Commercial Agreement & Memorandum of Deposit (Finance Client)" disabled={false} checked={utils.stringToBoolean(ac.d_docset2)}/> 
                    <Checkbox label="3. Authority to Transfer Securities" disabled={false} checked={utils.stringToBoolean(ac.d_docset3)}/>
                    <Checkbox label="4. Authority to Third Party to operate my/our account" disabled={false}  checked={utils.stringToBoolean(ac.d_docset4)}/>
                    <Checkbox label="5. Authority to Fund Transfer" disabled={false} checked={utils.stringToBoolean(ac.d_docset5)}/>
                    <Checkbox label="6. Signature Cards" disabled={false} checked={utils.stringToBoolean(ac.d_docset6)}/>
                    <Checkbox label="7. Fax Indemnity" disabled={false} checked={utils.stringToBoolean(ac.d_docset7)}/>
                    <Checkbox label="8. Personal Guarantee company director(s), sole proprietor, partner(s)," disabled={false}  checked={utils.stringToBoolean(ac.d_docset8)}/>
                    <Checkbox label="9. Business Registration and/or authorized person(s)" disabled={false} checked={utils.stringToBoolean(ac.d_docset9)}/>
                    <Checkbox label="10. Certificate of Incorporation" disabled={false} checked={utils.stringToBoolean(ac.d_docset10)}/>
                    <Checkbox label="11. Memorandum & Article of Association" disabled={false} checked={utils.stringToBoolean(ac.d_docset11)} />
                    <Checkbox label="12. Company Resolution" disabled={false} checked={utils.stringToBoolean(ac.d_docset12)}/>
                    <Checkbox label="13. Financial information of director(s) and/or authorized person(s)" readOnly checked={utils.stringToBoolean(ac.d_docset13)}/>
                    <Checkbox label="14. I.D. Card copy" disabled={false} checked={utils.stringToBoolean(ac.d_docset14)} />
                    <Checkbox label="15. Proof of address" disabled={false} checked={utils.stringToBoolean(ac.d_docset15)}/>
                    <Checkbox label="16. Power Attorney" disabled={false}  checked = {utils.stringToBoolean(ac.d_docset16)}  />  
                </FormGroup>
            </div>

              
            { ViewMode === "V" && <div>
            { isViewClientDoc && ! showClientDoc && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setshowClientDoc(! showClientDoc) }} >Show Documents</div> }
            { ! isViewClientDoc && ! showClientDoc && <div style={{justifyContent: 'flex-end', color:"blue"}} onClick={() => { setisViewClientDocPress(true) }} >Show Documents</div> }
            <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isViewClientDocPress}
                    onCancel={ () => setisViewClientDocPress(false) }
                    onConfirm={ () => { onReqViewClientDoc(); setisViewClientDocPress(false) } }
                >
                    <div>
                        <div>Authorization Required to View Document</div>
                        <div>Please Input User & Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="User" labelFor="inputAuthUser">
                        <InputGroup id="inputAuthUser" autoFocus value={AuthUser} onChange={utils.handleStringChange(s => setAuthUser(s))} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                </Alert>
    
            </div> }
            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>

        </div>
    )
}


interface ClDocSetEditProps {
    gsb: AxiosInstance,
    ac?: Account,
    aeList?: Account[],
    StatusList?: StatusInfo[],
    isNew?: boolean,
    onSave?: (ac: Account) => void,
    onCancel?: () => void;
    logKey2: any;
}

// onChange={utils.handleStringChange(s => setAc(prevState => Object.assign(prevState, {acName1: s})))}
function ClDocSetEdit({ gsb, ac = {} as Account, aeList = [] as Account[], StatusList=[] as StatusInfo[], isNew = false, onSave = () => { }, onCancel = () => { },logKey2 }: ClDocSetEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<Account>()
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)

    const [showSIMore, setshowSIMore] = useState(true)
    const [StatusDesc, setStatusDesc] = useState("")

    useEffect(() => {
        //console.log("set StatusList: ", StatusList)
        setStatusDesc("")
        let StatusItem3 = StatusList.findIndex((StatusCode: StatusInfo) => StatusCode.d_StatusCode === ac.d_status)
        //console.log("ClMastView StatusList.find StatusItem3 : ", StatusItem3) 
        //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc : ", StatusList[StatusItem3]) 
        if ( StatusItem3 > 0 ) {
            //console.log("ClMastView StatusList.find StatusItem3.d_StatusDesc2 : ", StatusList[StatusItem3].d_StatusDesc) 
            setStatusDesc(StatusList[StatusItem3].d_StatusDesc)
        }

        //console.log("ClMastView StatusList.find StatusDes : ", StatusDes) 
    }, [ac, ac.d_status])



    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClMastEdit UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            setisReqAuth(true)
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, ac])

    const [LocationList, setLocationList] = useState([] as LocationInfo[])
    const [BranchEmailList, setBranchEmailList] = useState([] as BranchEmailInfo[])
    const [ExtraFeeList, setExtraFeeList] = useState([] as ExtraFeeInfo[])
    const [PlanNoList, setPlanNoList] = useState([] as MktFeeInfo[])
    const [RegionCodeList, setRegionCodeList] = useState([] as RegionCodeInfo[])

    
    const [SendemailValue, setSendemailValue] = useState(ac.d_sendemail)
   // const [showEmail,setshowEamil] = useState(stringToBoolean(ac.d_sendemail.toUpperCase()))
    const [HardCopyStmtValue, setHardCopyStmtValue] = useState(ac.d_HardCopyStmt)
    const [HardCopyMthStmtValue, setHardCopyMthStmtValue] = useState(ac.d_HardCopyMthStmt)
    const [PrintChqValue, setPrintChqValue] = useState(ac.d_PrintChq)
    const [PrintCBBCChqValue, setPrintCBBCChqValue] = useState(ac.d_PrintCBBCChq)
    const [PrintDivChqValue, setPrintDivChqValue] = useState(ac.d_PrintDivChq)
    const [HighRiskValue, setHighRiskValue] = useState(ac.d_HighRisk)
    const [AutoCalcAvgPriceValue, setAutoCalcAvgPriceValue] = useState(ac.d_AutoCalcAvgPrice)
    const [PrnDetailTranValue, setPrnDetailTranValue] = useState(ac.d_PrnDetailTran)
    const [Charge_CCASS_FeeValue, setCharge_CCASS_FeeValue] = useState(ac.d_Charge_CCASS_Fee)
    const [Custom_TermValue, setCustom_TermValue] = useState(ac.d_Custom_Term)
    const [LocationValue, setLocationValue] = useState(ac.d_Location)
    const [BranchEmailValue, setBranchEmailValue] = useState(ac.d_Branch_code)
    const [ExtraFeeValue, setExtraFeeValue] = useState(ac.d_Extra_Fee)
    const [PlanNoValue, setPlanNoValue] = useState(ac.d_Plan_no)
	const [RegionCodeValue, setRegionCodeValue] = useState(ac.d_RegionCode)
    const [AcStatusValue, setAcStatusValue] = useState(ac.d_status)
    const [AeCodeValue, setAeCodeValue] = useState(ac.d_aeCode)

    const [ShowAdd4, setShowAdd4] = useState(false)
    let today = new Date();

    const [DocDate, setDocDate] = useState(today.getFullYear() + "-"+ (today.getMonth()+1)+ "-" + today.getDate()  )
    const [docset1, setdocset1] = useState(utils.stringToBoolean(ac.d_docset1)) 
    const [docset2, setdocset2] = useState(utils.stringToBoolean(ac.d_docset2)) 
    const [docset3, setdocset3] = useState(utils.stringToBoolean(ac.d_docset3))   
    const [docset4, setdocset4] = useState(utils.stringToBoolean(ac.d_docset4)) 
    const [docset5, setdocset5] = useState(utils.stringToBoolean(ac.d_docset5)) 
    const [docset6, setdocset6] = useState(utils.stringToBoolean(ac.d_docset6)) 
    const [docset7, setdocset7] = useState(utils.stringToBoolean(ac.d_docset7)) 
    const [docset8, setdocset8] = useState(utils.stringToBoolean(ac.d_docset8))         
    const [docset9, setdocset9] = useState(utils.stringToBoolean(ac.d_docset9))  
    const [docset10, setdocset10] = useState(utils.stringToBoolean(ac.d_docset10)) 
    const [docset11, setdocset11] = useState(utils.stringToBoolean(ac.d_docset11)) 
    const [docset12, setdocset12] = useState(utils.stringToBoolean(ac.d_docset12)) 
    const [docset13, setdocset13] = useState(utils.stringToBoolean(ac.d_docset13))        
    const [docset14, setdocset14] = useState(utils.stringToBoolean(ac.d_docset14))  
    const [docset15, setdocset15] = useState(utils.stringToBoolean(ac.d_docset15)) 
    const [docset16, setdocset16] = useState(utils.stringToBoolean(ac.d_docset16)) 
    const AcNOToolTip = 
    <div>
        <div>Account No. </div>
        <div>Cash A/C : ACCOUNT = Axxxx</div> 
        { GsbFlag.TBOB_USER && <div>Broker A/C : Bxxxx </div> }
        { GsbFlag.CTDY_USER && <div>Custodian A/C : Pxxxx </div> }
        <div>Margin A/C : MARGIN = Mxxxx </div> 
        <div>RUNNER = Cxxxx, INTERNAL = Ixxxx </div> 
   </div>
   

   useEffect(() => {
        // Call reset to set default values if ac is passed
        if ("d_acCode" in ac) {
            reset(ac)
        }
    }, [ac, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        register({ name: "d_aeCode" })
        register({ name: "d_status" }) 
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    useEffect(() => {
        if (GsbFlag.user_key === "CHEERFUL") {
            setShowAdd4(true)
        }
    }, [])

 
 


    if (!isNew && !("d_acCode" in ac)) return null
 

 

    const onSubmit = (data: Account) => {
        //console.log("onsubmit called", data)

        // put value to json/field before write database dbf
        data.d_acCode = data.d_acCode.toUpperCase();

        data.d_aeCode = AeCodeValue
        data.d_docset1 =utils.BooleanTostring( docset1)       
        data.d_docset2 =utils.BooleanTostring( docset2)
        data.d_docset3 =utils.BooleanTostring( docset3)
        data.d_docset4 =utils.BooleanTostring( docset4)
        data.d_docset5 =utils.BooleanTostring( docset5)
        data.d_docset6 =utils.BooleanTostring( docset6)
        data.d_docset7 =utils.BooleanTostring( docset7)
        data.d_docset8 =utils.BooleanTostring( docset8)
        data.d_docset9 =utils.BooleanTostring( docset9)
        data.d_docset10 =utils.BooleanTostring( docset10)
        data.d_docset11 =utils.BooleanTostring( docset11)
        data.d_docset12 =utils.BooleanTostring( docset12)
        data.d_docset13 =utils.BooleanTostring( docset13)
        data.d_docset14 =utils.BooleanTostring( docset14)
        data.d_docset15 =utils.BooleanTostring( docset15)
        data.d_docset16 =utils.BooleanTostring( docset16)    
        data.d_DocDate = DocDate       //yyyy-mm-dd to d_DocDate today.getFullYear() + "-"+ (today.getMonth()+1) + "-" +today.getDate()
     
        data.d_AuthTran = ""
        if (isReqAuth) {
            data.d_AuthTran = "Y"
        }
      
        (async () => {
            try {
             
                //if ( typeof(data.d_DocDate) === "undefined" ||  data.d_DocDate.trim() === "") {
                //    data.d_DocDate = today.getFullYear() + "-"+ (today.getMonth()+1) + "-" +today.getDate()
                //} 
                onSave(data)
            } catch (error) {
                showErrorBox(error)
            }
        })()
    }

    const acRenderer: ItemRenderer<Account> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<Account> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: Account) => {
        return ac.d_acCode
    }

    const StatusRenderer: ItemRenderer<StatusInfo> = (StatusList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${StatusList.d_StatusDesc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={StatusList.d_StatusCode}
                key={StatusList.d_StatusCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StatusPredicate: ItemPredicate<StatusInfo> = (query, StatusList, _index, exactMatch) => {
        const normalizedText = `${StatusList.d_StatusCode} - ${StatusList.d_StatusDesc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StatusValueRenderer = (StatusList: StatusInfo) => {
        return StatusList.d_StatusCode
    }

    const LocationRenderer: ItemRenderer<LocationInfo> = (LocationList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${LocationList.d_Branch_name}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={LocationList.d_Location}
                key={LocationList.d_Location}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const LocationPredicate: ItemPredicate<LocationInfo> = (query, LocationList, _index, exactMatch) => {
        const normalizedText = `${LocationList.d_Location} - ${LocationList.d_Branch_name}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const LocationValueRenderer = (LocationList: LocationInfo) => {
        return LocationList.d_Location
    }

    const BranchEmailRenderer: ItemRenderer<BranchEmailInfo> = (BranchEmailList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${BranchEmailList.d_Location}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={BranchEmailList.d_Location}
                key={BranchEmailList.d_Location}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const BranchEmailPredicate: ItemPredicate<BranchEmailInfo> = (query, BranchEmailList, _index, exactMatch) => {
        const normalizedText = `${BranchEmailList.d_Location} - ${BranchEmailList.d_Location}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const BranchEmailValueRenderer = (BranchEmailList: BranchEmailInfo) => {
        return BranchEmailList.d_Location
    }

    const ExtraFeeRenderer: ItemRenderer<ExtraFeeInfo> = (ExtraFeeList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${ExtraFeeList.d_ExtraFee_Rate}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ExtraFeeList.d_ExtraFeeCode}
                key={ExtraFeeList.d_ExtraFeeCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const ExtraFeePredicate: ItemPredicate<ExtraFeeInfo> = (query, ExtraFeeList, _index, exactMatch) => {
        const normalizedText = `${ExtraFeeList.d_ExtraFeeCode} - ${ExtraFeeList.d_ExtraFee_Rate}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const ExtraFeeValueRenderer = (ExtraFeeList: ExtraFeeInfo) => {
        return ExtraFeeList.d_ExtraFeeCode
    }

    const PlanNoRenderer: ItemRenderer<MktFeeInfo> = (PlanNoList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${PlanNoList.d_Plan_no}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={PlanNoList.d_Plan_no}
                key={PlanNoList.d_Plan_no}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const PlanNoPredicate: ItemPredicate<MktFeeInfo> = (query, PlanNoList, _index, exactMatch) => {
        const normalizedText = `${PlanNoList.d_Plan_no} - ${PlanNoList.d_Plan_no}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const PlanNoValueRenderer = (PlanNoList: MktFeeInfo) => {
        return PlanNoList.d_Plan_no
    }

    const RegionCodeRenderer: ItemRenderer<RegionCodeInfo> = (RegionCodeList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${RegionCodeList.d_Country}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={RegionCodeList.d_RegionCode}
                key={RegionCodeList.d_RegionCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const RegionCodePredicate: ItemPredicate<RegionCodeInfo> = (query, RegionCodeList, _index, exactMatch) => {
        const normalizedText = `${RegionCodeList.d_RegionCode} - ${RegionCodeList.d_Country}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const RegionCodeValueRenderer = (RegionCodeList: RegionCodeInfo) => {
        return RegionCodeList.d_RegionCode
    }


    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }
    
    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-2" label={t('Doc Date On')} labelFor="inputDocDate">    

                   {/* <InputGroup     id="inputDoB" name="d_DocDate" type="date"    placeholder="YYYY-MM-DD" leftIcon="calendar" inputRef={register} required /> */}
                  {/* <InputGroup   name="d_DocDate" type="date" value= {DocDate} />   */}
                 <InputGroup readOnly value={DocDate}   placeholder="YYYY-MM-DD"   intent="success" /> * current
              </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputAcCode" labelInfo="*">
                    <Tooltip content={AcNOToolTip} position={Position.TOP}>
                        <InputGroup disabled={! isNew} id="inputAcCode" name="d_acCode" autoFocus={isNew} defaultValue="" inputRef={register({ required: true , maxLength: 8 }) } />
                    </Tooltip>    
                    {errors.d_acCode && errors.d_acCode.type === "required" && (<span id="Err_msg">Account No. cannot be Blank</span>)}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-2-3" label="Runner" labelFor="inputAcName1">
                   <InputGroup readOnly value={ac.d_acName1} />
                    <InputGroup readOnly value={ac.d_acName2} />     
                </FormGroup>
                <FormGroup className="Col-5-2" label="Runner" labelFor="inputAeCode">
                    <InputGroup id="inputAeCode" name="d_aeCode" readOnly defaultValue={ac.d_aeCode} />
                 </FormGroup>
                
                 <FormGroup className="Col-7-1" label="Status">
                    <InputGroup readOnly value= {ac.d_status} />
                    <div>
                    <div id="DispText">{StatusDesc}</div>
                    </div>
                    { (logKey2.indexOf("*A1") > 0 ) && ! (ac.d_status.toString() == "") && 
                    <div id="DispText2" > {ac.d_status} </div> }
                </FormGroup>
            </div>

            <div className="Row">
                <FormGroup className="Col-1-6">
                    <Checkbox label="1. Cash Client Agreement (Securities Client)"  checked={(docset1)} onChange={utils.handleBooleanChange(v => setdocset1(v))}  />
                    <Checkbox label="2. General Commercial Agreement & Memorandum of Deposit (Finance Client)"  checked={docset2}  onChange={utils.handleBooleanChange(v => setdocset2(v))} /> 
                    <Checkbox label="3. Authority to Transfer Securities"   checked={(docset3)}  onChange={utils.handleBooleanChange(v => setdocset3(v))} />
                    <Checkbox label="4. Authority to Third Party to operate my/our account"   checked={(docset4)}  onChange={utils.handleBooleanChange(v => setdocset4(v))} />
                    <Checkbox label="5. Authority to Fund Transfer"  checked={(docset5)}  onChange={utils.handleBooleanChange(v => setdocset5(v))} />
                    <Checkbox label="6. Signature Cards"  checked={(docset6)}  onChange={utils.handleBooleanChange(v => setdocset6(v))}/>
                    <Checkbox label="7. Fax Indemnity"  checked={(docset7)}  onChange={utils.handleBooleanChange(v => setdocset7(v))} />
                    <Checkbox label="8. Personal Guarantee company director(s), sole proprietor, partner(s),"   checked={(docset8)}  onChange={utils.handleBooleanChange(v => setdocset8(v))}/>
                    <Checkbox label="9. Business Registration and/or authorized person(s)"  checked={(docset9)}  onChange={utils.handleBooleanChange(v => setdocset9(v))} /> 
                    <Checkbox label="10. Certificate of Incorporation"  checked={(docset10)}  onChange={utils.handleBooleanChange(v => setdocset10(v))} />
                    <Checkbox label="11. Memorandum & Article of Association"   checked={(docset11)}  onChange={utils.handleBooleanChange(v => setdocset11(v))} />
                    <Checkbox label="12. Company Resolution"   checked={(docset12)} onChange={utils.handleBooleanChange(v => setdocset12(v))}/>
                    <Checkbox label="13. Financial information of director(s) and/or authorized person(s)"   checked={(docset13)}  onChange={utils.handleBooleanChange(v => setdocset13(v))}/>
                    <Checkbox label="14. I.D. Card copy"   checked={(docset14)} onChange={utils.handleBooleanChange(v => setdocset14(v))} />
                    <Checkbox label="15. Proof of address"   checked={(docset15)} onChange={utils.handleBooleanChange(v => setdocset15(v))} />
                    <Checkbox label="16. Power Attorney"  checked={(docset16)}  onChange={utils.handleBooleanChange(v => setdocset16(v))} />
                </FormGroup>
            </div>
        </form>
    )
}

interface ClDocSetMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function ClDocSetMain({ gsb }: ClDocSetMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 
    
    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as Account[])
    const [StatusList, setStatusList] = useState([] as StatusInfo[])
    const [StatusList2, setStatusList2] = useState([] as StatusInfo[])

    //const [CurrencyList, setCurrencyList] = useState([] as CurrencyInfo[])
    //const [CurrencyList2, setCurrencyList2] = useState([] as CurrencyInfo[])

    const [aeList, setAeList] = useState([] as Account[])
    const [docList, setDocList] = useState([] as Document[])

    const [searchKey, setSearchKey] = useState("acCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchIdNo, setSearchIdNo] = useState("")
    const [searchTel, setSearchTel] = useState("")
    const [searchStatus, setSearchStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [aPageShowInfo, setaPageShowInfo] = useState([] as PageInfo2[])
    const [PageReachEnd, setPageReachEnd] = useState(false)
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as Account)
    const [BlankAc, setBlankAc] = useState({} as Account)
    const [CurrentLog1, setCurrentLog1] = useState({} as Account)
    const [CurrentLog0, setCurrentLog0] = useState({} as Account)
    //const [CbCurrentLog1, setCbCurrentLog1] = useState({} as cbMast)
    //const [CbCurrentLog0, setCbCurrentLog0] = useState({} as cbMast)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("ClDocSetMain disp user_key: ", res.data.Flag.user_key)
                //console.log("ClDocSetMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("ClDocSetMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("ClDocSetMain disp GsbFlag: ", GsbFlag)
                //console.log("ClDocSetMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("ClDocSetMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("ClDocSetMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("ClDocSetMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("ClDocSetMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;

                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;

                if ( GsbFlag.isProduction == "Y" ) {
                    if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                } 

                for (let i = 0; i <= 20-1; i++) {
                    if ( GsbFlag.AC_STATUS.substr(i, 1).trim().length > 0 ) {
                        let tmpStatusName = "Active"
                        tmpStatusName = GsbFlag.AC_SDESC[i]

                        StatusList2.push({d_StatusCode: GsbFlag.AC_STATUS.substr(i, 1), d_StatusDesc: tmpStatusName})
                    }
                }
                setStatusList(StatusList2)
                //console.log("set StatusList: ", StatusList)

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("ClDocSetMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {

        if (logviewonly === "Y") {
            setViewMode("L")
        }
        if (logviewonly === "Y_CB") {
            setViewMode("L_CB")
        }
        //console.log("ClDocSetMain ViewMode: ", ViewMode)

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 && logviewonly === "Y" ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "ClDocSet", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("ClDocSetMain ClDocSet ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
                /*
                if ( logKey.trim().length > 0 && logviewonly === "Y_CB" ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "Cbmast", 
                        cAction: "ViewLogRec"
                    })
                    console.log("ClDocSetMain Cbmast ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCbCurrentLog0(res.data.data[0])
                        setCbCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
                */
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])


    useEffect(() => {

        setnPage(0)
        if ( nPage > 0 ) {
            //setcPrintMode("PS")
        } 

        (async () => {
            try {
                // usually A/E code is prefixed with C, but some user may use others e.g. Upbest uses A for A/E
                // TODO: Read A/E prefix from config
                // fixed can read true A/E
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: "C",
                            nPage: 1,
                            limit: 0,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            backward: false,
                            SortByName: false,
                                    filters: {
                                aeList: "Y",
                            }
                        },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                    });
                    //"aeList ", res.data.data, res.data.count)
                    setAeList(res.data.data)
                    //setAeList(utils.sortByColumn(res.data.data, "d_acName1"))

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                const res = await gsb.post('/documentlist',
                    {
                        acCode: currentAc.d_acCode,
                        idNo: currentAc.d_idNo
                    });
                setDocList(res.data.data)
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb, currentAc])


    
    const PrevRecProcess = async (ac: Account) => {
        try {
            //console.log("call PrevRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "PrevRec"})
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }
    let small_tick = "small-tick"

    const NextRecProcess = async (ac: Account) => {
        try {
            //console.log("call NextRecProcess", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_acCode === res.data.data.d_acCode) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentAc(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }


    const editOnSave = async (ac: Account) => {
        try {
            //console.log("call editOnSave", ac)
            const res = await gsb.post('/Master', {sb_data: ac, cPgmId: "Clmast", cAction: "UpdateRec"})
            if (isReqAuth2) {
                showSuccess("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        setCurrentAc(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Account No. " + ac.d_acCode + " record saving, waiting for authorization")
            } else {
                showSuccess("Account No. " +ac.d_acCode + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_acCode === res.data.data.d_acCode) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
                setCurrentAc(res.data.data)
                setCurrentMode("show")
                //setisShowAlert1(true)
                //setShowAlert1Msg("Account No. " +ac.d_acCode + " record saved")
            }
        } catch (error) {
            utils.showError(error)
        }
    }

    
    const DocSet1ToolTip = 
    <div>Cash Client Agreement (Securities Client)</div>


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(0)
        setnTotalPage(0)
        setPageReachEnd(false)

        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        PageShowInfo: aPageShowInfo,
                        refreshPageShow: true,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            idNo: searchIdNo,
                            tel: searchTel,
                            status: searchStatus
                        },
                    },
                    cPgmId: "Clmast",
                    cAction: "ListRec",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                //console.log("ListRec res.data.PageShowInfo", res.data.PageShowInfo)
                setData(res.data.data)
                setaPageShowInfo(res.data.PageShowInfo)

                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    {/*
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                    */}
                    const aPageNumber1 = []
                    let k = 0
                    for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                        if ( res.data.PageShowInfo[j-1].pageShow ) {
                            k++
                            aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                            if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                setPageReachEnd(true)
                            }
                        }
                    }
                    setaPageNumber(aPageNumber1)
                    setnPage(1)
                    //console.log("ListRec k", k, aPageNumber1)
                } else {
                    setnPage(0)
                    setnTotalPage(0)
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "Clmast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // lookup account if acCode is passed
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)

            ;

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: "acCode",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: true,
                            SortByName: false,
                        },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && selectedTabId == "list" ) {
    
            setShowWait(true)
            setPageReachEnd(false)

            ;
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            PageShowInfo: aPageShowInfo,
                            refreshPageShow: false,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                idNo: searchIdNo,
                                tel: searchTel,
                                status: searchStatus
                            },
                            },
                        cPgmId: "Clmast",
                        cAction: "ListRec",
                    });

                    //console.log("ListRec Press  res.data.PageShowInfo", res.data.PageShowInfo)
                    setData(res.data.data)
                    setaPageShowInfo(res.data.PageShowInfo)

                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }

                    if ( res.data.count > 0 ) {
                        const aPageNumber1 = []
                        let k = 0
                        for ( let j = 1; j <= res.data.PageShowInfo.length; j++ ) {
                            if ( res.data.PageShowInfo[j-1].pageShow ) {
                                k++
                                aPageNumber1.push({n: res.data.PageShowInfo[j-1].pageno});
                                if ( res.data.PageShowInfo[j-1].pageLast ) { 
                                    setnTotalPage(res.data.PageShowInfo[j-1].pageno)
                                    setPageReachEnd(true)
                                }
                            }
                        }
                        setaPageNumber(aPageNumber1)
                        //console.log("ListRec Press k", k, aPageNumber1)
                    }
    
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
    let detail = <ClDocSetView gsb={gsb} ac={currentAc} ac0={currentAc} docList={docList} StatusList={StatusList}   baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)}  onEdit={() => setCurrentMode("edit")} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></ClDocSetView>
    switch (currentMode) {
        case "edit":
            detail = <ClDocSetEdit gsb={gsb} ac={currentAc} aeList={aeList} StatusList={StatusList} onSave={editOnSave} onCancel={() => setCurrentMode("show")} logKey2={logKey2.trim()} ></ClDocSetEdit>
            break
    }

    let logKeyViewRec = <ClDocSetView gsb={gsb} ac={CurrentLog1} ac0={CurrentLog0} docList={docList} StatusList={StatusList}   baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentAc)}  onEdit={() => setCurrentMode("edit")} onNextRec={() => NextRecProcess(currentAc)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></ClDocSetView>

    function PageNo(n: number) {
        if (n > 0 ) {
            if (  nPage+n <= nTotalPage || nTotalPage == 0 ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                {aPageNumber.map((no, j) => 
                    (no.n < nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (no.n > nPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nPage > 0) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
                { (nPage > 0) && ! ( PageReachEnd ) &&
                    <Button minimal >...</Button>
                }
            </FormGroup>
        </div>
    </>


    // old, backup 
    let Pagination2 = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    let list = <>
        {/*
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        */}
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Account</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-AECode">Runner</th>
                <th className="TCol-Tel">DocDate</th>
                <th className="TCol-TICK">1</th>
                <th className="TCol-TICK">2</th>
                <th className="TCol-TICK">3</th>
                <th className="TCol-TICK">4</th>
                <th className="TCol-TICK">5</th>
                <th className="TCol-TICK">6</th>
                <th className="TCol-TICK">7</th>
                <th className="TCol-TICK">8</th>
                <th className="TCol-TICK">9</th>
                <th className="TCol-TICK">10</th>
                <th className="TCol-TICK">11</th>
                <th className="TCol-TICK">12</th>
                <th className="TCol-TICK">13</th>
                <th className="TCol-TICK">14</th>
                <th className="TCol-TICK">15</th>
                <th className="TCol-TICK">16</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((ac, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setCurrentAc(ac) ; MoblieDev && setSelectedTabId("detail") }}>{ac.d_acName1}</td>              
                        <td className="TCol-AECode" onClick={() => { setCurrentAc(ac) }}>{ac.d_aeCode}</td>
                        <td className="TCol-AECode" onClick={() => { setCurrentAc(ac) }}>{ac.d_DocDate}</td>           
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset1)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset2)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset3)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset4)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset5)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset6)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset7)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset8)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset9)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset10)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset11)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset12)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset13)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset14)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset15)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                        <td className="TCol-TICK" onClick={() => { setCurrentAc(ac) }}>{utils.stringToBoolean(ac.d_docset16)? <Icon  icon="tick-circle" color='#2d72d2'/>:''} </td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && <><div className="Spacing-V-16" />{Pagination}</>}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly.substr(0,1) === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>Client Control Document Set Setup</div></H3>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="acCode">Account</option>
                                    <option value="acName">Name</option>
                                    <option value="aeCode">Runner</option>
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "acCode"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        {/*<Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                    </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            <FormGroup className="Col-1-1" label="ID" labelFor="searchIdNo">
                                <InputGroup id="searchIdNo" value={searchIdNo} onChange={utils.handleStringChange(s => setSearchIdNo(s))} />
                            </FormGroup>
                            <FormGroup className="Col-2-1" label="Tel" labelFor="searchTel">
                                <InputGroup id="searchTel" value={searchTel} onChange={utils.handleStringChange(s => setSearchTel(s))} />
                            </FormGroup>
                            <div className="Col-3-2 Col-H">
                                <FormGroup label="Status" labelFor="searchStatus">
                                    <InputGroup className="Input-XS" id="searchStatus" value={searchStatus} onChange={utils.handleStringChange(s => setSearchStatus(s))} />
                                </FormGroup>
                                {/*<FormGroup label="Limit" labelFor="searchLimit" >
                                    <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                                </FormGroup>*/}
                            </div>
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
         
             

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default ClDocSetMain