import React, { useState, FormEvent, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Dialog, Callout, Tooltip} from "@blueprintjs/core";

import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'
import svgcaretdown from './svg/caret-down.svg'

import { PFTranInfo, AcCodeInfo, StkCodeInfo, PRCodeInfo, CENumINFO } from './Types'   // 20230327
import { SystemHeader, ClientPicker } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

var GsbFlag: any;

var UserID: string;
var PgmCode = "T0010" ;
var isReqAuth2:boolean = false ;
var isReqValueLimitAuth2:boolean = false ;

interface StkDepWithTranEntryViewProps {
    gsb: AxiosInstance,
    StkDepWith: PFTranInfo,
    StkDepWith0: PFTranInfo, 
    baseUrl?: string,
    token?: string | null,
    onPrevRec?: () => void,
    onNew?: () => void,
    onEdit?: () => void,
    onDelete?: () => void,
    onNextRec?: () => void,
    ViewMode: any,
    logKey2: any,
}


function StkDepWithTranEntryView({ gsb, StkDepWith = {} as PFTranInfo, StkDepWith0 = {} as PFTranInfo, baseUrl = "", token = "", onPrevRec = () => { }, onNew = () => { }, onEdit = () => { }, onDelete = () => { }, onNextRec = () => { }, ViewMode, logKey2 }: StkDepWithTranEntryViewProps) {

    const { t, i18n } = useTranslation();    
    
    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)
    const [isCanDelete, setisCanDelete] = useState(true)
    const [isDeletePress2, setisDeletePress2] = useState(false)

    const [AuthUser, setAuthUser] = useState("")
    const [password, setPassword] = useState("")

    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestQtyValue, setLatestQtyValue] = useState(0)
    
    const [PrCodeDescValue, setPrCodeDescValue] = useState("")
    const [AllocationDescValue, setAllocationDescValue] = useState("")
    

    useEffect(() => {
        setisReadOnly(false)
        setisReqAuth(false)
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("StkDepWithTranEntryView UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            //setisReqAuth(true)
                            //isReqAuth2 = true
                        }
                        setisCanDelete(false)
                        if ( res.data.data[0].d_Can_DeleteMaster == "Y" ) {
                            setisCanDelete(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID, StkDepWith])

    const onReqAuthDelRec = () => {
        (async () => {
            try {
                const res2 = await gsb.post('/PgmAccess', {
                    userId: AuthUser,
                    password: password,
                    cIPAddr: "",
                    cReqUser: GsbFlag.UserID,
                } )
                if (res2.data.ok) {
                    setAuthUser("")
                    setPassword("")

                    const res = await gsb.post('/Master', {
                        sb_data: {
                            userId: AuthUser,
                            password: password,
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "ReqUserGroupAccessReqAuth"
                    })
                    console.log("onReqAuthDelRec res.data.data", res.data.data);
                    if ( res.data.ok && res.data.data[0].d_Can_DeleteMaster == "Y") {
                        onDelete();
                        
                    } else {
                        utils.showWarn("Authorization failed")
                        setisShowAlert2(true)
                        setShowAlert2Msg("Authorization failed")
                    }

                } else {
                    utils.showError(res2.data.message)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res2.data.message)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()

    }

    useEffect(() => {
        (async () => {
            try {
                // get Stk Qty Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkQtyInfo", cAcCode: StkDepWith.d_acCode, cStkNo: StkDepWith.d_StkNo})
                //console.log("StkDepWithTranEntryEdit GetStkQtyInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetStkQtyInfo  count: ", res.data.count) 
                
                if ( StkDepWith.d_acCode.trim().length > 0 && StkDepWith.d_StkNo.trim().length > 0 && res.data.count > 0 )
                {
                    setPrevDayValue(res.data.data[0].i_PrevDay_qty) 
                    setLatestQtyValue(res.data.data[0].i_oh_qty) 
                } else { 
                    setPrevDayValue(0) 
                    setLatestQtyValue(0) 
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, StkDepWith.d_acCode, StkDepWith.d_StkNo ,StkDepWith.d_Qty,StkDepWith.d_QtyStmp])

    useEffect(() => {
        (async () => {
            try {
                switch (StkDepWith.d_allocation) {
                    case "1":
                        setAllocationDescValue("STREET NAME")
                        break;
                    case "2":
                        setAllocationDescValue("NOMINEE NAME")
                        break;
                    case "3":
                        setAllocationDescValue("CCASS - STREET")
                        break;
                    case "4":
                        setAllocationDescValue("CCASS - NOMINEE")
                        break;
                    case "5":
                        setAllocationDescValue("REGISTRY")
                        break;
                    case "6":
                        setAllocationDescValue("BANK CUSTODY")
                        break;
                    case "7":
                        setAllocationDescValue("OTHERS")
                        break;
                    default:
                        setAllocationDescValue("")
                        break;
                }
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkDepWith.d_PR_Code])

    useEffect(() => {
        (async () => {
            if (GsbFlag.user_key == "CHEERFUL") {
                try {
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PrCodeMast", cAction: "GetPRCodeInfo", cPRCode: StkDepWith.d_PR_Code})
                    
                    if ( StkDepWith.d_PR_Code.trim().length > 0 && res.data.count > 0 )
                    {
                        setPrCodeDescValue(res.data.data[0].d_PR_Desc) 
                    } else {
                        setPrCodeDescValue("Undefined") 
                    }
                    
                } catch (error) {
                    //showErrorBox(error)
                }
            }
        })()
    }, [gsb, StkDepWith.d_allocation])

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    if (! ("d_Seq" in StkDepWith)) return null


    return (
        <div>
            {/* define add,change,delete button */}
            { ViewMode === "V" && <div className="Row">
                <Button className="Col-1-1" icon="direction-left" onClick={() => onPrevRec()} >Prev</Button>
                
                {/* <Button className="Col-2-1" icon="add" onClick={() => onNew()} >New</Button>
                <Button className="Col-3-1" icon="edit" onClick={() => onEdit()} >Edit</Button>
                <Button className="Col-4-1" icon="delete" onClick={() => onDelete()} >Delete</Button> */}
                <Button className="Col-2-1" disabled={isReadOnly} icon="add" onClick={() => onNew() } >New</Button>
                <Button className="Col-3-1" disabled={isReadOnly} icon="edit" onClick={() => onEdit() } >Edit</Button>
                { isCanDelete && <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress(true) } >Delete</Button> }
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        canEscapeKeyCancel={true}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeletePress}
                        onCancel={ () => setisDeletePress(false) }
                        onConfirm={ () => { onDelete(); setisDeletePress(false) } }
                    >
                        <p>
                            Are you sure to Delete ?
                        </p>
                    </Alert>
                { ! isCanDelete && <Button className="Col-4-1" disabled={isReadOnly} icon="delete" onClick={() => setisDeletePress2(true) } >Delete</Button> }
                <Alert
                    cancelButtonText="Cancel"
                    confirmButtonText="OK"
                    canEscapeKeyCancel={true}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                    isOpen={isDeletePress2}
                    onCancel={ () => setisDeletePress2(false) }
                    onConfirm={ () => { onReqAuthDelRec(); setisDeletePress2(false) } }
                >
                    <div>
                        <div>Authorization Required to Delete</div>
                        <div>Please Input User & Password</div>
                    </div>
                    <div className="Spacing-V-16"></div>
                    <FormGroup label="User" labelFor="inputAuthUser">
                        <InputGroup id="inputAuthUser" autoFocus value={AuthUser} onChange={utils.handleStringChange(s => setAuthUser(s))} />
                    </FormGroup>
                    <FormGroup label="Password" labelFor="inputPassword">
                        <InputGroup id="input2Password" type="password" value={password} onChange={utils.handleStringChange(s => setPassword(s))} />
                    </FormGroup>
                </Alert>
                
                <Button className="Col-5-1" icon="direction-right" onClick={() => onNextRec()} >Next</Button>
                <Button className="Col-9-2" icon="document" onClick={() => { window.open(`/#/ClientPosEnquiry?acCode=${StkDepWith.d_acCode}`, '_blank') } } >Balance & Portfolio</Button>
            </div> }
            { ViewMode === "L" && <div>
                Account No. : {StkDepWith.d_acCode}, {StkDepWith.d_Tran_type} 
                { (logKey2.indexOf("*A1") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Amend</div> }
                { (logKey2.indexOf("*C") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Create</div> }
                { (logKey2.indexOf("*D") > 0 ) && <div id="DispText2">Action : {logKey3a} {logKey3b} User {logKey3c} Delete</div> }
                <div className="Spacing-V-16" />
            </div> }

            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No.">
                    <InputGroup readOnly value={utils.formatNumber2(StkDepWith.d_Seq)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_Seq.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(StkDepWith0.d_Seq)} </div> }
                </FormGroup>
                <FormGroup className="Col-2-1" label="Tran Type">
                    <InputGroup readOnly value={StkDepWith.d_Tran_type} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_Tran_type.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_Tran_type} </div> }
                </FormGroup>
                { GsbFlag.HKIDR_USER1 && <FormGroup className="Col-3-1" label="OTCR Type">
                    <InputGroup readOnly value={StkDepWith.d_OTCR_Type} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_OTCR_Type.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_OTCR_Type} </div> }
                </FormGroup> } 

                {(StkDepWith0.d_OTCR_Type  === "TE" || StkDepWith0.d_OTCR_Type ==="TR") &&
                <FormGroup className="Col-4-2" label="CE no. of counterparty" labelFor="inputCENumCountryParty">
                    <InputGroup readOnly value={StkDepWith.d_CENumCountryParty} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_CENumCountryParty.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_CENumCountryParty} </div> }
                </FormGroup>
                }

                {(StkDepWith0.d_OTCR_Type  === "ER") &&
                <FormGroup className="Col-4-2" label="Both TE & TR Ref." labelFor="inputErRef">
                    <InputGroup readOnly value={StkDepWith.d_ErRef} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_ErRef.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_ErRef} </div> }
                </FormGroup>
                }


                <FormGroup className="Col-7-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup readOnly value={StkDepWith.d_Input_User} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_Input_User.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_Input_User} </div> }
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputacCode">
                    <InputGroup readOnly value={StkDepWith.d_acCode} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_acCode.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_acCode} </div> }
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name">
                    {/* 
                    <InputGroup readOnly value={StkDepWith.d_acName1} />
                    <InputGroup readOnly value={StkDepWith.d_acName2} />
                     */}
                    <div>
                    <div id="DispText">{StkDepWith.d_acName1}</div>
                    <div id="DispText">{StkDepWith.d_acName2}</div>
                    </div>
                </FormGroup>
                { ViewMode === "V" && <FormGroup className="Col-7-3" label="Quantity on Hand">
                    <div>
                    <div id="DispText">Prev. Day : {utils.formatNumber2(PrevDayValue)}</div>
                    <div id="DispText">   Latest : {utils.formatNumber2(LatestQtyValue)}</div>
                    </div>
                </FormGroup> }

 
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo">
                    <InputGroup readOnly value={StkDepWith.d_StkNo} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_StkNo.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_StkNo} </div> }
                </FormGroup>
                <FormGroup className="Col-3-5" label="Stock Code" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkDepWith.d_StkCode}</div>
                    <div id="DispText"></div>
                    </div>
                </FormGroup>
                {/* <FormGroup className="Col-8-2" label="Market">
                    <div>
                    <div id="DispText">{StkDepWith.d_Market}</div>
                    </div>
                    </FormGroup> */}
            </div>
            <div className="Row">
                {(StkDepWith0.d_OTCR_Type  === "ER" || StkDepWith0.d_OTCR_Type  === "TE" || StkDepWith0.d_OTCR_Type  === "TR") &&
                <FormGroup className="Col-1-1" label="Price" labelFor="inputPrice">
                    <InputGroup readOnly value={utils.formatNumber2(StkDepWith.d_ShareTransPrice)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_ShareTransPrice.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(StkDepWith0.d_ShareTransPrice)} </div> }
                </FormGroup>
                }

                <FormGroup className="Col-1-1" label="Quantity" labelFor="inputQty">
                    <InputGroup readOnly value={utils.formatNumber2(StkDepWith.d_Qty)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_Qty.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(StkDepWith0.d_Qty)} </div> }
                </FormGroup>

                {(StkDepWith0.d_OTCR_Type  === "ER" || StkDepWith0.d_OTCR_Type  === "TE" || StkDepWith0.d_OTCR_Type  === "TR") &&            
                <FormGroup className="Col-1-1" label="Stamp Quantity" labelFor="inputQtyStmp">
                    <InputGroup readOnly value={utils.formatNumber2(StkDepWith.d_QtyStmp)} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_QtyStmp.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(StkDepWith0.d_QtyStmp)} </div> }
                </FormGroup>
                }
                { StkDepWith.d_Authority_Limit > 0 && <FormGroup className="Col-3-2" label="." labelFor="">
                    <div id="DispText">** OVER VALUE LIMIT **</div>
                </FormGroup> }
            </div>
            <div className="Row">
                <FormGroup className="Col-1-5" label="Description" labelFor="inputDescription">
                    <InputGroup readOnly value={StkDepWith.d_Description} />
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_Description.toString() == "") && 
                        <div id="DispText2" > {StkDepWith0.d_Description} </div> }
                </FormGroup>
            </div>
            {(GsbFlag.user_key == "CHEERFUL") && 
            <div className="Row">
                    <FormGroup className="Col3-1-2" label="Statement Code" labelFor="inputPR_Code" labelInfo="">
                        <InputGroup readOnly value={StkDepWith.d_PR_Code} />
                        <div>
                        <div id="DispText">{PrCodeDescValue}</div>
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_PR_Code.toString() == "") && 
                        <div id="DispText2" > {StkDepWith0.d_PR_Code} </div> }
                        </div>
                    </FormGroup>
                    <FormGroup className="Col3-3-2" label="WD Type (PSF)" labelFor="inputWD_Type" labelInfo="">
                        <InputGroup readOnly value={StkDepWith.d_WD_type} />
                        { StkDepWith.d_WD_type === "P" && <div id="DispText" >PHYSICAL</div> }
                        { StkDepWith.d_WD_type === "S" && <div id="DispText" >SI OUT</div> }
                        { StkDepWith.d_WD_type === "F" && <div id="DispText" >FREE OF CHARGE</div> }
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_WD_type.toString() == "") && 
                        <div id="DispText2" > {StkDepWith0.d_WD_type} </div> }
                    </FormGroup>
                    <FormGroup className="Col-5-2" label="D/W Handling Fee" labelFor="inputWD_Charge">
                        <InputGroup readOnly value={utils.formatNumber2(StkDepWith.d_WD_Charge)} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_WD_Charge.toString() == "") && 
                    <div id="DispText2" > {utils.formatNumber2(StkDepWith0.d_WD_Charge)} </div> }
                    </FormGroup>
            </div>
            }
            <div className="Row">
                <FormGroup className="Col3-1-1" label="Allocation" labelFor="inputallocation">
                    <InputGroup readOnly value={StkDepWith.d_allocation} />
                    <div>
                    <div id="DispText">{AllocationDescValue}</div>
                    </div>
                    { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_allocation.toString() == "") && 
                    <div id="DispText2" > {StkDepWith0.d_allocation} </div> }
                </FormGroup>
                {(GsbFlag.SL_USER) &&
                    <FormGroup className="Col-2-2" label="Stock Location" labelFor="inputStkLocation">
                        <InputGroup readOnly value={StkDepWith.d_Stk_Location} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_Stk_Location.toString() == "") && 
                        <div id="DispText2" > {StkDepWith0.d_Stk_Location} </div> }
                    </FormGroup>
                }
            </div>
            {(GsbFlag.CTPM_SYS) &&
                <div className="Row">
                    <FormGroup className="Col3-1-2" label="Issue (DBWRSC)" labelFor="inputDividtype">
                        <InputGroup readOnly value={StkDepWith.d_Divid_type} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_Divid_type.toString() == "") && 
                        <div id="DispText2" > {StkDepWith0.d_Divid_type} </div> }
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Stock Value" labelFor="inputStkValue">
                        <InputGroup readOnly value={utils.formatNumber2(StkDepWith.d_StkValue)} />
                        { ViewMode === "L" && (logKey2.indexOf("*A1") > 0 ) && ! (StkDepWith0.d_StkValue.toString() == "") && 
                        <div id="DispText2" > {utils.formatNumber2(StkDepWith0.d_StkValue)} </div> }
                    </FormGroup>
                </div>
            }
            <div className="Spacing-V-16"></div>
                <Alert
                    confirmButtonText="OK"
                    icon="tick-circle"
                    intent={Intent.SUCCESS}
                    isOpen={isShowAlert1}
                    onCancel={ () => setisShowAlert1(false) }
                    onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                >
                    <p>
                        {ShowAlert1Msg}
                    </p>
                </Alert>
                <Alert
                    confirmButtonText="OK"
                    icon="cross"
                    intent={Intent.WARNING}
                    isOpen={isShowAlert2}
                    onCancel={ () => setisShowAlert2(false) }
                    onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                >
                    <p>
                        {ShowAlert2Msg}
                    </p>
                </Alert>

        </div>
    )
}

interface StkDepWithTranEntryEditProps {
    gsb: AxiosInstance,
    StkDepWith?: PFTranInfo,
    CENumList?: CENumINFO[],
    isNew?: boolean,
    onSave?: (stk: PFTranInfo) => void,
    onCancel?: () => void;
}

function StkDepWithTranEntryEdit({ gsb, StkDepWith = {} as PFTranInfo, CENumList = [] as CENumINFO[], isNew = false, onSave = () => { }, onCancel = () => { } }: StkDepWithTranEntryEditProps) {

    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<PFTranInfo>()
 
    const imgCaretdown = <img className="downIcon" src={svgcaretdown} alt="caretdown" />
    const { t, i18n } = useTranslation();    

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)


    const [AcCodeValue, setAcCodeValue] = useState(StkDepWith.d_acCode)
    const [setClCodeValue, setSetClCodeValue] = useState(() => setAcCodeValue)
    const [isClPickerOpen, setIsClPickerOpen] = useState(false)
    const [AcCodeList, setAcCodeList] = useState([] as AcCodeInfo[])
    const [AcName1Value, setAcName1Value] = useState(StkDepWith.d_acName1)
    const [AcName2Value, setAcName2Value] = useState(StkDepWith.d_acName2)
    const [AcStatusValue, setAcStatusValue] = useState("")
    const [StkNoValue, setStkNoValue] = useState(StkDepWith.d_StkNo)
    const [ERRefNoValue, setERRefNoValue] = useState(StkDepWith.d_ErRef)
    
    const [Caretdown2, setCaretdown2] = useState(false)
    const [StkCodeList, setStkCodeList] = useState([] as StkCodeInfo[])
    const [StkCodeValue, setStkCodeValue] = useState(StkDepWith.d_StkCode)
    const [StkNameValue, setStkNameValue] = useState("")
    const [MarketValue, setMarketValue] = useState("")

    const [PriceValue, setPriceValue] = useState(utils.numToString(StkDepWith.d_ShareTransPrice))


    const [QtyValue, setQtyValue] = useState(utils.numToString(StkDepWith.d_Qty))
    const [QtyStmpValue, setQtyStmpValue] = useState(utils.numToString(StkDepWith.d_QtyStmp))

    //const [QtyValue, setQtyValue] = useState(utils.numToString(StkDepWith.))

    const [PrevDayValue, setPrevDayValue] = useState(0)
    const [LatestQtyValue, setLatestQtyValue] = useState(0)


    const [TranTypeValue, setTranTypeValue] = useState(StkDepWith.d_Tran_type)
    const [OTCRtypeValue, setOTCRtypeValue] = useState(StkDepWith.d_OTCR_Type)
   
    const [DescValue, setDescValue] = useState(StkDepWith.d_Description)
    
    const [PrCodeDescValue, setPrCodeDescValue] = useState("")
    const [PRCodeValue, setPRCodeValue] = useState(StkDepWith.d_PR_Code)
    const [WDChargeValue, setWDChargeValue] = useState(StkDepWith.d_WD_Charge)
    const [PRCodeList, setPRCodeList] = useState([] as PRCodeInfo[])
//BothTeTr
  
    const [CENumCountryPart, setCENumCountryPart] = useState((StkDepWith.d_CENumCountryParty)) 

 

   //onst [docset1, setdocset1] = useState(false) 
    const OTCR_TypeTip = 
    <div>
        <div>OTCR Type</div>
        <div>Indicator to report share transfer (TE,TR,ER), acted for the transferee (TE) </div>
        <div>(shares were transferred into an account of the RRI), or the transferor (TR) </div>
        <div>(shares were transferred out of an account of the RRI), or both the transferee</div>
        <div> and transferor (ER) ,a deposit of physical certificate (D),</div> 
        <div>or a withdrawal of physical certificate (W)</div> 
        <div>If Client NOT sign consent, only allow to withdraw stock</div> 
   </div>


    const OTCR_CounterParty = 
    <div>
        <div>Number of shares in the transaction</div>
        <div>Integer greater than 0 </div>
        <div>If the number of shares </div>
        <div>is not an integer, round </div>
        <div>it to the nearest integer</div>
    </div>



    let o_acct = StkDepWith.d_acCode.trim()
    let o_type = StkDepWith.d_Tran_type
    let o_stkno = utils.STRVAL(GsbFlag, StkDepWith.d_StkNo)
    let o_qty = StkDepWith.d_Qty
    let o_qtystamp = StkDepWith.d_QtyStmp

    useEffect(() => {
        // Call reset to get the values of record PFTranInfo 
        if ("d_Seq" in StkDepWith) {
            reset(StkDepWith)
        }
    }, [StkDepWith, reset])


    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it (d_Market) manually and call setValue when item is selected
        //register({ name: "d_Currency" })
        register({name: "d_CENumCountryParty"})
    }, [register])


    useEffect(() => {
        if ( GsbFlag.Enter2tab == "Y" ) {
            let inputs = utils.PerformEnter2Tab()
        }
    }, [])

    
/*    
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch('http://api.ipify.org/?format=json')
                const dataIP = await response.json();
                setIPAddr(dataIP["ip"])
                //console.log("App  IPAddr: ", data["ip"])    
            } catch (error) {
                utils.showError(error)
            }
        })() 
    }, [gsb])
*/

    useEffect(() => {
        (async () => {
            try {
                // get Stk Qty Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkQtyInfo", cAcCode: AcCodeValue, cStkNo: StkNoValue})
                //console.log("StkDepWithTranEntryEdit GetStkQtyInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetStkQtyInfo  count: ", res.data.count) 
                
                if ( AcCodeValue.trim().length > 0 && StkNoValue.trim().length > 0 && res.data.count > 0 )
                {
                    setPrevDayValue(res.data.data[0].i_PrevDay_qty) 
                    setLatestQtyValue(res.data.data[0].i_oh_qty) 
                    let LatestQtyValue2 = res.data.data[0].i_oh_qty
                    if ( o_acct === AcCodeValue.trim()  &&  o_acct.length > 0 && o_stkno === utils.STRVAL(GsbFlag, StkNoValue)  &&  o_stkno.length > 0 ) {
                        if ( o_type === "D" ) {
                            LatestQtyValue2 = LatestQtyValue2 - o_qty
                        } else if ( o_type === "W" ){
                            LatestQtyValue2 = LatestQtyValue2 + o_qty
                        }
                    }
                    if ( TranTypeValue === "D" ) {
                        LatestQtyValue2 = LatestQtyValue2 + utils.StringToNum(QtyValue)
                    } else if ( TranTypeValue === "W" ){
                        LatestQtyValue2 = LatestQtyValue2 - utils.StringToNum(QtyValue)
                    }
                    setLatestQtyValue(LatestQtyValue2)
                } else { 
                    setPrevDayValue(0) 
                    setLatestQtyValue(0) 
                    let LatestQtyValue2 = 0
                    if ( TranTypeValue === "D" ) {
                        LatestQtyValue2 = LatestQtyValue2 + utils.StringToNum(QtyValue)
                    } else if ( TranTypeValue === "W" ){
                        LatestQtyValue2 = LatestQtyValue2 - utils.StringToNum(QtyValue)
                    }
                    setLatestQtyValue(LatestQtyValue2)
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, TranTypeValue, AcCodeValue, StkNoValue, QtyValue,QtyStmpValue])


    useEffect(() => {
        (async () => {
            const res = await gsb.post('/GsbFlag');
            GsbFlag = res.data.Flag;
            if (GsbFlag.user_key == "CHEERFUL") {
                try {
                    // get GetPRCode Info
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PrCodeMast", cAction: "GetPRCodeInfo", cPRCode: PRCodeValue})                    
                    if ( PRCodeValue.trim().length > 0 && res.data.count > 0 )
                    {
                        setPrCodeDescValue(res.data.data[0].d_PR_Desc) 
                    } else {
                        setPrCodeDescValue("Undefined") 
                    }
                    
                } catch (error) {
                    showErrorBox(error)
                }
            }
        })()

    }, [gsb, PRCodeValue])

    const PRCodeRenderer: ItemRenderer<PRCodeInfo> = (PRCodeList, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${PRCodeList.d_PR_Desc}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={PRCodeList.d_PR_Code}
                key={PRCodeList.d_PR_Desc}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const PRCodePredicate: ItemPredicate<PRCodeInfo> = (query, PRCodeList, _index, exactMatch) => {
        const normalizedText = `${PRCodeList.d_PR_Code} - ${PRCodeList.d_PR_Desc}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const PRCodeValueRenderer = (PRCodeList: PRCodeInfo) => {
        return PRCodeList.d_PR_Code
    }

    useEffect(() => {
        (async () => {
            try {
                // get AC Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Clmast", cAction: "GetACInfo", cAcCode: AcCodeValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( AcCodeValue.trim().length > 0 && res.data.count > 0 )
                {
                    //setPrevDayValue(res.data.data[0].acbal_PrevDay) 
                    //setLatestQtyValue(res.data.data[0].acbal_LatestBal) 
                    setAcName1Value(res.data.data[0].d_acName1)
                    setAcName2Value(res.data.data[0].d_acName2)
                    setAcStatusValue(res.data.data[0].d_status)
                   
                    if (CENumCountryPart==="undefined")
                    {
                        setCENumCountryPart("")
                    }
                } else { 
                    setAcName1Value("")
                    setAcName2Value("")
                    setAcStatusValue("")
                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()

    }, [gsb, AcCodeValue])


    useEffect(() => {
        (async () => {
            try {
                // get AC List
                const res = await gsb.post('/Master',
                {   sb_data: {
                    by: "acCode",
                    start: "",
                    nPage: 1,
                    limit: 0,
                    backward: false,
                    SortByName: true
                },
                cPgmId: "Clmast",
                cAction: "ListRec",
            });

                //console.log("res.data.data: ", res.data.data) 
                //setAcCodeList(utils.sortByColumn(res.data.data, "d_acName1"))
                setAcCodeList(res.data.data)
                //console.log("set AcCodeList: ", AcCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            try {
                // get Stk Info
                //console.log("StkDepWithTranEntryEdit StkDepWith.d_acCode: ", StkDepWith.d_acCode, AcCodeValue) 
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "GetStkInfo", cStkNo: StkNoValue})
                //console.log("StkDepWithTranEntryEdit GetACInfo data : ", res.data.data) 
                //console.log("StkDepWithTranEntryEdit GetACInfo  count: ", res.data.count) 
                
                if ( StkNoValue.trim().length > 0 && res.data.count > 0 )
                {
                    setStkCodeValue(res.data.data[0].d_StkCode)
                    setStkNameValue(res.data.data[0].d_StkName)
                    setMarketValue(res.data.data[0].d_Market)

                } else { 
                    setStkCodeValue("")
                    setStkNameValue("")
                    setMarketValue("")

                }
               
            } catch (error) {
                //showErrorBox(error)
            }
        })()
    }, [gsb, StkNoValue])


    useEffect(() => {
        (async () => {
            try {
                // get Stock Code List
                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "Smmast", cAction: "StkCodeList"})
                //console.log("StkCodeList: ", res.data.data) 
                setStkCodeList(res.data.data)
                //console.log("set StkCodeList: ", StkCodeList) 
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        (async () => {
            const res = await gsb.post('/GsbFlag');
            GsbFlag = res.data.Flag;
            if (GsbFlag.user_key == "CHEERFUL") {
                try {
                    // get PRCode List (Statement Code List)
                    const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PrCodeMast", cAction: "GetPRCodeList"})
                    //  console.log("PRCodeList: ", res.data.data) 
                    setPRCodeList(res.data.data)
                    //console.log("set PCCodeList: ", PRCodeList) 
                } catch (error) {
                    utils.showError(error)
                }
            }
        })()
    }, [gsb])




    if (! isNew && ! ("d_Seq" in StkDepWith)) return null

    let acNoLength = 8
    if ( GsbFlag.user_key === "DJSEC" ) {
        acNoLength = 10
    }

    let DescLength = 45
    if ( GsbFlag.LONG_DESC ) {
        DescLength = 60
    }
    
    const onSubmit = (data: PFTranInfo) => {

        //console.log("StkDepWithTranEntryedit onsubmit data: ", data)
        //console.log("StkDepWithTranEntryedit onsubmit StkPrice: ", StkPrice)
        //console.log("StkDepWithTranEntryedit onsubmit data.d_StkPrice: ", data.d_StkPrice)

        // put value to json/field before write database dbf
        if ( typeof(data.d_Seq) === "undefined") {
            data.d_Seq = 0
        }

        data.d_Tran_type = TranTypeValue;
        data.d_Tran_type = data.d_Tran_type.toUpperCase();
//console.log("StkDepWithTranEntryedit onsubmit data.d_Tran_type: ", data.d_Tran_type)

        data.d_acCode = AcCodeValue;
        if ( typeof(data.d_acCode) === "undefined" ) {
            data.d_acCode = ""
        }
        
 

        if (data.d_OTCR_Type ==="ER")
        {
            data.d_CENumCountryParty = "" // AcName1Value;  d_CENumCountryParty
        }
        else
        {
             
            if (typeof(data.d_CENumCountryParty) === "undefined"  || typeof(CENumCountryPart)==="undefined")
            {
                data.d_CENumCountryParty = "" // AcName1Value;  d_CENumCountryParty
            }
            else
            {
                data.d_CENumCountryParty = CENumCountryPart // AcName1Value;  d_CENumCountryParty
            }  
          
             
        }

        
       
        data.d_acCode = data.d_acCode.toUpperCase();
        data.d_acName1 = AcName1Value;
        data.d_acName2 = AcName2Value;

        data.d_StkNo = StkNoValue ;
        data.d_StkCode = StkCodeValue;

        //TE,TR
        if( data.d_OTCR_Type == "TE" ||  data.d_OTCR_Type == "TR" || data.d_OTCR_Type == "ER")
        {
        data.d_ShareTransPrice = utils.StringToNum(PriceValue);
        }
        else
        {
            data.d_ShareTransPrice = 0
        }

        data.d_Qty = utils.StringToNum(QtyValue);
        data.d_QtyStmp =Math.round( utils.StringToNum(QtyStmpValue)) //round to int qtystmp

        //var intvalue = Math.ceil( floatvalue ); 
        data.d_Description = DescValue;
   
        //TE,TR
        if( data.d_OTCR_Type === "ER")
        {
            //this.props.description.name.trim().length==0)
            if (ERRefNoValue===null || ERRefNoValue === undefined )
            {
                data.d_ErRef = "";
            }
            else
            {
             data.d_ErRef = ERRefNoValue;
            }
        }
        if (GsbFlag.user_key == "CHEERFUL") {
            data.d_PR_Code = PRCodeValue;
            data.d_PR_Code = data.d_PR_Code.toUpperCase();
            data.d_WD_Charge = WDChargeValue;
        }        

        data.d_AuthTran = ""
        data.d_Auth_Value_Limit_tran = ""
        
        ;

        (async () => {
            try {
                //console.log("call StkDepWithTranEntryedit data", data)
                //console.log("call StkDepWithTranEntryedit isNew", isNew)
                const res = await gsb.post('/Transaction', {sb_data: data, cPgmId: "StkDepWithTranEntry", cAction: "StkDepWithValidate", isNew: isNew})
                //console.log("StkDepWithTranEntryedit res.data.ok", res.data.ok)
                //console.log("StkDepWithTranEntryedit res.data.data", res.data.data)

                if (! res.data.ok) {
                    for (let j = 0; j < res.data.data.length; j++) {
                      
                        setError(res.data.data[j][0], {
                           message: res.data.data[j][1]
                        }); 
                    }

                    return
                }

                onSave(data)

            } catch (error) {
                showErrorBox(error)
            }
        })()

        //onSave(data)
    }


    
    const acRenderer: ItemRenderer<AcCodeInfo> = (ac, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${ac.d_acName1}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={ac.d_acCode}
                key={ac.d_acCode}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const acPredicate: ItemPredicate<AcCodeInfo> = (query, ac, _index, exactMatch) => {
        const normalizedText = `${ac.d_acCode} - ${ac.d_acName1}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const acValueRenderer = (ac: AcCodeInfo) => {
        return ac.d_acCode
    }


    const StkRenderer: ItemRenderer<StkCodeInfo> = (stk, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${stk.d_StkCode}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={stk.d_StkNo}
                key={stk.d_StkNo}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );
    };

    const StkPredicate: ItemPredicate<StkCodeInfo> = (query, stk, _index, exactMatch) => {
        const normalizedText = `${stk.d_StkNo} - ${stk.d_StkCode}`.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };

    const StkValueRenderer = (stk: StkCodeInfo) => {
        return stk.d_StkNo
    }



    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    const handleChang2e = (field: string) => {
        if (field ==="D")
        {
           
        }
        else if (field ==="W")
        {

        }    
       
    }

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    function showdefDesc() {
        //console.log("showdefDesc :", DescValue, TranTypeValue, StkNoValue, QtyValue)
        if ( DescValue.trim().length == 0 ) {
            if ( TranTypeValue.trim().length > 0 && StkNoValue.trim().length > 0 && utils.StringToNum(QtyValue) > 0 ) {
                let M_DESC = ""
                let OTCRstr = "" //typeValue        

                 
                if ( TranTypeValue === "D" ) {
                    M_DESC = "DEPOSIT"
                } else {
                    M_DESC = "WITHDRAW"
                }
                if (GsbFlag.HKIDR_USER1 ){
                    if ( OTCRtypeValue === "TE" ) {
                        M_DESC = M_DESC + " " +  "TO" + " " + "TE"
                    } else if ( OTCRtypeValue === "TR" ) {
                        M_DESC = M_DESC + " " + "FROM" + " " + "TR"
                    } else if ( OTCRtypeValue === "ER" ) {
                        M_DESC = M_DESC + " " + "ER"
                    }
                }
                //M_DESC = M_DESC + " " + QtyValue.toString().trim() + " SH"
                M_DESC = M_DESC + " " + utils.formatNumber2(utils.StringToNum(QtyValue)) + " SHS"
                M_DESC = M_DESC + " " + StkCodeValue.trim()
                M_DESC = M_DESC + " (" + StkNoValue.trim() + ")"
                if (GsbFlag.HKIDR_USER1 ){
                    if ( OTCRtypeValue === "TE" ) {
                        M_DESC = M_DESC + " " + ",@" + PriceValue + ",OTCR:" + OTCRtypeValue
                    } else if ( OTCRtypeValue === "TR" ) {
                        M_DESC = M_DESC + " " + ",@" + PriceValue + ",OTCR:" + OTCRtypeValue
                    } else if ( OTCRtypeValue === "ER" ) {
                        M_DESC = M_DESC + " " + ",@" + PriceValue + ",OTCR:" + OTCRtypeValue
                    } else if ( OTCRtypeValue === "D" ) {
                        M_DESC = M_DESC + " " + ",OTCR:" + OTCRtypeValue
                    } else if ( OTCRtypeValue === "W" ) {
                        M_DESC = M_DESC + " " + ",OTCR:" + OTCRtypeValue
                    }
                    
                }
             

                setDescValue(M_DESC)
            }
        }
    }

    function showdefPRCode() {
        //console.log("PRCodeValue :", PRCodeValue, TranTypeValue,)
        
        if ( PRCodeValue.trim().length == 0 && ( TranTypeValue === "P" ||TranTypeValue === "R" )) {
            if ( TranTypeValue.trim().length > 0 ) {
                let M_DESC = ""
                if ( TranTypeValue === "P" ) {
                    M_DESC = "CO"
                } else {
                    M_DESC = "CI"
                }
                M_DESC = M_DESC
                setPRCodeValue(M_DESC)
            }
        }
    }

    const CENUMRenderer: ItemRenderer<CENumINFO> = (Cenum, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        //const text = `${ac.acCode} - ${ac.acName1}`;
        const text = `${Cenum.name}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                label={Cenum.ceref}
                key={Cenum.ceref}
                onClick={handleClick}
                text={highlightText(text, query)}
            />
        );        
    };

    const CENUMPredicate: ItemPredicate<CENumINFO> = (query, Cenum, _index, exactMatch) => {
        const normalizedText = `${Cenum.ceref} - ${Cenum.name}`.toLowerCase();
        
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedText === normalizedQuery;
        } else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    };
    
    const CENUMValueRenderer = (Cenum: CENumINFO) => {
        return Cenum.ceref
    }




    /*<form onSubmit={handleSubmit(onSubmit)}>*/
    return (
        <form onKeyDown={handleHotkey}>
            <ClientPicker gsb={gsb} isOpen={isClPickerOpen} onClose={()=>setIsClPickerOpen(false)} onSelect={(ac)=>{setClCodeValue(ac.d_acCode); setIsClPickerOpen(false)}}/>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>Cancel</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>Save</Button>
                { isReqAuth && 
                    <Callout className="Col-6-2"
                        icon="warning-sign"
                        intent={Intent.WARNING}
                    >
                        Authorization Required
                    </Callout>
                }

             </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-1" label="Seq No." labelFor="inputSeqNo" labelInfo="*">
                    <InputGroup className="inputNumberNoSpin" disabled={true} id="inputSeqNo" name="d_Seq" autoFocus={isNew} defaultValue="0" type="number" inputRef={register} />
                    {errors.d_Seq && errors.d_Seq.type === "required" && (<span id="Err_msg">Seq No. cannot be Blank</span>)}
                    {errors.d_Seq && errors.d_Seq.types && <span id="Err_msg">{errors.d_Seq.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col3-2-1" label="Tran Type" labelFor="inputTranType" labelInfo="*">
                    {/*
                    <InputGroup id="inputTranType" name="d_Tran_type" autoFocus={! isNew} defaultValue="" placeholder="Tran Type ..." inputRef={register({ required: true , maxLength: 1 }) } />
                    {errors.d_Tran_type && errors.d_Tran_type.type === "required" && <span id="Err_msg">Tran Type cannot be Blank</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_Tran_type && errors.d_Tran_type.types && <span id="Err_msg">{errors.d_Tran_type.types.message}</span>}
                    */}
                    <select  id="inputTranType"  
                        value={TranTypeValue}
                        autoFocus
                        onChange={utils.handleStringChange(s => setTranTypeValue(s)) }
                    >
                        <option value="D">D: Deposit</option>            
                        <option value="W">W: Withdrawal</option>      

                    </select>
                </FormGroup>
                { GsbFlag.HKIDR_USER1 && <FormGroup className="Col3-3-1" label="OTCR Type" labelFor="inputOTCR_Type" labelInfo="">
                    
                        <select  id="inputOTCR_Type"  name="d_OTCR_Type" 

                            onChange={utils.handleStringChange(v => setOTCRtypeValue(v))}
                        ref={register}>
                            <option value=" "> </option>
                            {TranTypeValue ==="D" && <option value="D">D: Deposit</option> }
                            {TranTypeValue ==="W" && <option value="W">W: Withdrawal</option>}
                            {TranTypeValue ==="D" && <option value="TE">TE:Transfereer</option>}
                            {TranTypeValue ==="W" && <option value="TR">TR:Transferor</option>}
                            {TranTypeValue ==="D" && <option value="ER">ER(Transferor side)</option>}
                            {TranTypeValue ==="W" && <option value="ER">ER(Transfereer side)</option>}
                        </select>
                  
                    {errors.d_OTCR_Type && errors.d_OTCR_Type.types && <span id="Err_msg">{errors.d_OTCR_Type.types.message}</span>}
                </FormGroup> }
 
                

                { GsbFlag.HKIDR_USER1 &&  OTCRtypeValue ==="ER" &&                
                    <FormGroup className="Col-4-2" label="Ref (Both TE & TR Ref.)" labelFor="inputErRef" labelInfo="*">
                        <InputGroup id="inputErSeq"  value={ERRefNoValue} defaultValue="" placeholder="Er Ref." onChange={utils.handleStringChange(s => setERRefNoValue(s.toUpperCase())) } inputRef={register({required:false, maxLength:5}) } />
                        {errors.d_ErRef && errors.d_ErRef.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                        {errors.d_ErRef && errors.d_ErRef.type === "maxLength" && <span id="Err_msg">Max length is 5</span>}
                        {errors.d_ErRef && errors.d_ErRef.types && <span id="Err_msg">{errors.d_ErRef.types.message}</span>}
                    </FormGroup>
                    }
        
                { GsbFlag.HKIDR_USER1 && ((OTCRtypeValue ==="TE" || OTCRtypeValue ==="TR")) &&                 
                    /*
                   <FormGroup className="Col-4-3" label="CE no. of counterparty" labelFor="inputCENumPty">
                        <InputGroup  id="inputInputUser" name="d_CENumCountryParty" value={CENumCountryPart} defaultValue="" placeholder="Input CENUM counterparty"  onChange={utils.handleStringChange(v => setCENumCountryPart(v.toUpperCase()))} inputRef={register({ maxLength: 6}) } />
                        {errors.d_CENumCountryParty && errors.d_CENumCountryParty.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                        {errors.d_CENumCountryParty && errors.d_CENumCountryParty.types && <span id="Err_msg">{errors.d_CENumCountryParty.types.message}</span>}
                    </FormGroup>
                    
                    */
                    <FormGroup style={{justifyContent: 'flex-end'}} className="Col3-4-3" label={t('CE no. of counterparty')} labelFor="inputCENumPty">
                    <Tooltip content={OTCR_CounterParty} position={Position.TOP} ></Tooltip>
                    <Suggest
                        inputProps={{ placeholder: "Please CE no. of counterparty" }}
                        defaultSelectedItem={CENumList.find((aj) => aj.ceref === StkDepWith.d_CENumCountryParty)}
                        items={CENumList}
                        itemRenderer={CENUMRenderer}
                        itemPredicate={CENUMPredicate}
                        inputValueRenderer={CENUMValueRenderer}                   
                        onItemSelect={(StkDepWith) => { setValue("d_CENumCountryParty", StkDepWith.ceref);setCENumCountryPart(StkDepWith.ceref)}}
                    />
                        {errors.d_CENumCountryParty && errors.d_CENumCountryParty.types && <span id="Err_msg">{errors.d_CENumCountryParty.types.message}</span>}
                    </FormGroup>              
                  
                    }
                


                <FormGroup className="Col-7-1" label="Input User" labelFor="inputInputUser">
                    <InputGroup disabled={true} id="inputInputUser" name="d_Input_User" defaultValue="" placeholder="Input User ..." inputRef={register({ maxLength: 10}) } />
                    {errors.d_Input_User && errors.d_Input_User.type === "maxLength" && <span id="Err_msg">Max length is 10</span>}
                    {errors.d_Input_User && errors.d_Input_User.types && <span id="Err_msg">{errors.d_Input_User.types.message}</span>}
                </FormGroup>
             </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label="Account No." labelFor="inputacCode" labelInfo="*">
                    <InputGroup id="inputacCode" value={AcCodeValue} defaultValue="" placeholder="Account No. ..." onChange={utils.handleStringChange(s => setAcCodeValue(s.toUpperCase())) } inputRef={register({ required: true , maxLength: acNoLength}) } 
                        rightElement={<Button icon="double-chevron-down" minimal onClick={() => {setSetClCodeValue(()=>setAcCodeValue); setIsClPickerOpen(true)}}></Button>}
                    />
                    {errors.d_acCode && errors.d_acCode.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                    {errors.d_acCode && errors.d_acCode.type === "maxLength" && <span id="Err_msg">Max length is {acNoLength}</span>}
                    {errors.d_acCode && errors.d_acCode.types && <span id="Err_msg">{errors.d_acCode.types.message}</span>}
                </FormGroup>
                <FormGroup className="Col-3-3" label="Client Name" labelFor="inputAcName1">
                    {/*
                    <InputGroup disabled={true} id="inputAcName1" name="d_acName1" autoFocus={! isNew} defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    <InputGroup disabled={true} id="inputAcName2" name="d_acName2" defaultValue="" placeholder="" inputRef={register({ maxLength: 30}) } />
                    {errors.d_acName1 && errors.d_acName1.types && <span id="Err_msg">{errors.d_acName1.types.message}</span>}
                    {errors.d_acName2 && errors.d_acName2.types && <span id="Err_msg">{errors.d_acName2.types.message}</span>}
                    {errors.d_acName1 && errors.d_acName1.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    {errors.d_acName2 && errors.d_acName2.type === "maxLength" && <span id="Err_msg">Max length is 30</span>}
                    */}
                    <div>
                    <div id="DispText">{AcName1Value}</div>
                    <div id="DispText">{AcName2Value}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-6-1" label="Status" >
                    <div>
                    <div id="DispText">{AcStatusValue}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-7-3" label="Quantity on Hand">
                    <div>
                    <div id="DispText">Prev. Day : {utils.formatNumber2(PrevDayValue)}</div>
                    <div id="DispText">   Latest : {utils.formatNumber2(LatestQtyValue)}</div>
                    </div>
                </FormGroup>
            </div>
            <div className="Row">
                { ! Caretdown2 && 
                    <FormGroup className="Col-1-1" label="Stock No." labelFor="inputStkNo" labelInfo="*">
                        <InputGroup id="inputStkNo" value={StkNoValue} defaultValue="" placeholder="Stock No. ..." onChange={utils.handleStringChange(s => setStkNoValue(s)) } inputRef={register({ required: true , maxLength: 6}) } />
                        {errors.d_StkNo && errors.d_StkNo.type === "required" && <span id="Err_msg">Account No. cannot be Blank</span>}
                        {errors.d_StkNo && errors.d_StkNo.type === "maxLength" && <span id="Err_msg">Max length is 6</span>}
                        {errors.d_StkNo && errors.d_StkNo.types && <span id="Err_msg">{errors.d_StkNo.types.message}</span>}
                    </FormGroup>
                }
                { Caretdown2 && 
                    <FormGroup className="Col3-1-1" label="Stock No." labelFor="inputStkNo" >
                        <Suggest
                            inputProps={{ placeholder: "Please choose Stock No. ..." }}
                            selectedItem={StkCodeList.find((stk1) => stk1.d_StkNo === StkNoValue)}
                            items={StkCodeList}
                            itemRenderer={StkRenderer}
                            itemPredicate={StkPredicate}
                            inputValueRenderer={StkValueRenderer}
                            onItemSelect={(Stk) => { setStkNoValue(Stk.d_StkNo) ; setStkCodeValue(Stk.d_StkCode) ; setStkNameValue(Stk.d_StkName) ; setCaretdown2(!Caretdown2) }}
                        />
                    </FormGroup>
                }
                {/* <span onClick={() => { setCaretdown2(!Caretdown2) } }> {imgCaretdown} </span> */}
                <FormGroup className="Col-3-5" label="Stock Code / Name" labelFor="inputStkName">
                    <div>
                    <div id="DispText">{StkCodeValue}</div>
                    <div id="DispText">{StkNameValue}</div>
                    </div>
                </FormGroup>
                <FormGroup className="Col-8-2" label="Market">
                    <div>
                    <div id="DispText">{MarketValue}</div>
                    </div>
                </FormGroup>
            </div>
            { GsbFlag.HKIDR_USER1 && ((OTCRtypeValue ==="TE" || OTCRtypeValue ==="TR"  || OTCRtypeValue ==="ER" )) &&   
            <div className="Row">
                <FormGroup className="Col-1-1" label="Price" labelFor="inputPrice">
                    {/* <NumericInput fill className="inputNumberNoSpin" id="inputQty"  defaultValue="0" value={QtyValue} onValueChange={(n => setQtyValue(n))} type="number" buttonPosition="none" inputRef={register()} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputPrice" defaultValue={PriceValue} value={PriceValue} type="number" onChange={utils.handleStringChange(n => setPriceValue(n))} min={0}   />
                    {errors.d_ShareTransPrice && errors.d_ShareTransPrice.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_ShareTransPrice && errors.d_ShareTransPrice.types && <span id="Err_msg">{errors.d_ShareTransPrice.types.message}</span>}
                </FormGroup>
            </div> }
         
            <div className="Row">
                <FormGroup className="Col-1-1" label="Quantity" labelFor="inputQty">
                    {/* <NumericInput fill className="inputNumberNoSpin" id="inputQty"  defaultValue="0" value={QtyValue} onValueChange={(n => setQtyValue(n))} type="number" buttonPosition="none" inputRef={register()} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputQty" defaultValue={QtyValue} value={QtyValue} type="number" onChange={utils.handleStringChange(n => setQtyValue(n))}  />
                    {errors.d_Qty && errors.d_Qty.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_Qty && errors.d_Qty.types && <span id="Err_msg">{errors.d_Qty.types.message}</span>}
                </FormGroup>
            </div>

            { GsbFlag.HKIDR_USER1 && ((OTCRtypeValue ==="TE" || OTCRtypeValue ==="TR"  || OTCRtypeValue ==="ER" )) &&               
            <div className="Row">
                <FormGroup className="Col-1-1" label="Stamp Quantity" labelFor="inputQtyStmp">
                    {/* <NumericInput fill className="inputNumberNoSpin" id="inputQty"  defaultValue="0" value={QtyValue} onValueChange={(n => setQtyValue(n))} type="number" buttonPosition="none" inputRef={register()} /> */}
                    <InputGroup className="inputNumberNoSpin" id="inputQtyStmp" defaultValue={QtyStmpValue} value={QtyStmpValue} type="number" onChange={utils.handleStringChange(n => setQtyStmpValue(n))}  />
                    {errors.d_QtyStmp && errors.d_QtyStmp.type === "min" && <span id="Err_msg">Quantity sholud be greater than 0 </span>}
                    {errors.d_QtyStmp && errors.d_QtyStmp.types && <span id="Err_msg">{errors.d_QtyStmp.types.message}</span>}
                </FormGroup>
            </div>
            }
            <div className="Row">
                <FormGroup className="Col-1-5" label="Description" labelFor="inputDescription">
                    {/* <InputGroup id="inputDescription" name="d_Description" defaultValue="" placeholder="Description ..." onFocus={showdefDesc} inputRef={register({ maxLength: DescLength}) } /> */}
                    <InputGroup id="inputDescription" value={DescValue} defaultValue="" placeholder="Description ..." onFocus={showdefDesc} onChange={utils.handleStringChange(s => setDescValue(s)) } inputRef={register({ maxLength: DescLength}) } />
                    {errors.d_Description && errors.d_Description.type === "maxLength" && <span id="Err_msg">Max length is {DescLength}</span>}
                    {errors.d_Description && errors.d_Description.types && <span id="Err_msg">{errors.d_Description.types.message}</span>}
                </FormGroup>
            </div>
            {(GsbFlag.user_key == "CHEERFUL") && 
            <div className="Row">
                {(GsbFlag.user_key == "CHEERFUL") && 
                <FormGroup className="Col3-1-2" label="Statement Code" labelFor="inputPRCode">
                    <Suggest
                            inputProps={{ placeholder: "Statement ..." }}
                            activeItem={PRCodeList.find((PRCode) => PRCode.d_PR_Code === PRCodeValue)}
                            selectedItem={PRCodeList.find((PRCode) => PRCode.d_PR_Code === PRCodeValue)}
                            items={PRCodeList}
                            itemRenderer={PRCodeRenderer}
                            itemPredicate={PRCodePredicate}
                            inputValueRenderer={PRCodeValueRenderer}
                            onItemSelect={(PRCodeList) => { setPRCodeValue(PRCodeList.d_PR_Code) }}
                        />
                    {errors.d_PR_Code && errors.d_PR_Code.types && <span id="Err_msg">{errors.d_PR_Code.types.message}</span>}
                </FormGroup>
                }
                { GsbFlag.user_key == "CHEERFUL" && TranTypeValue === "W" &&
                <FormGroup className="Col3-3-2" label="WD Type (PSF)" labelFor="inputWD_Type">
                    {/* <InputGroup id="inputWD_Type" value={WDTypeValue} defaultValue="" placeholder="WD Type..." onChange={utils.handleStringChange(s => setWDTypeValue(s)) } inputRef={register({ maxLength: 1}) } /> */}
                    <select  id="inputWD_Type" name="d_WD_type" ref={register}>
                        <option value=" "> </option>
                        <option value="P">P: PHYSICAL</option>
                        <option value="S">S: SI OUT</option>
                        <option value="F">F: FREE OF CHARGE</option>
                    </select>
                    {errors.d_WD_type && errors.d_WD_type.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                    {errors.d_WD_type && errors.d_WD_type.types && <span id="Err_msg">{errors.d_WD_type.types.message}</span>}
                </FormGroup>
                }
                <FormGroup className="Col-5-2" label="D/W Handling Fee" labelFor="inputWD_Charge" labelInfo="">
                    <NumericInput disabled={TranTypeValue === "W"} fill className="inputNumberNoSpin" id="inputWD_Charge"  defaultValue="0" value={WDChargeValue} onValueChange={(n => setWDChargeValue(n))} type="number" buttonPosition="none" inputRef={register({min: 0})} /> 
                    {errors.d_WD_Charge && errors.d_WD_Charge.types && <span id="Err_msg">{errors.d_WD_Charge.types.message}</span>}
                </FormGroup>
            </div>}
            <div className="Row">
                <FormGroup className="Col3-1-1" label="Allocation" labelFor="inputallocation">
                    {/* <InputGroup id="inputallocation" name="d_allocation" inputRef={register} /> */}
                    <select  id="inputallocation" name="d_allocation" ref={register}>
                        <option value=" "> </option>
                        <option value="1">1: Street Name</option>
                        <option value="2">2: Nominee Name</option>
                        <option value="3">3: CCASS - Street</option>
                        <option value="4">4: CCASS - Nominee</option>
                        <option value="5">5: Registry</option>
                        <option value="6">6: Bank Custody</option>
                        <option value="7">7: Others</option>
                    </select>
                    {errors.d_allocation && errors.d_allocation.types && <span id="Err_msg">{errors.d_allocation.types.message}</span>}
                </FormGroup>
                {(GsbFlag.SL_USER) &&
                    <FormGroup className="Col-2-2" label="Stock Location" labelFor="inputStkLocation">
                        <InputGroup id="inputStkLocation" name="d_Stk_Location" defaultValue="" placeholder="Stock Location ..." inputRef={register({ maxLength: 8}) } />
                        {errors.d_Stk_Location && errors.d_Stk_Location.type === "maxLength" && <span id="Err_msg">Max length is 8</span>}
                        {errors.d_Stk_Location && errors.d_Stk_Location.types && <span id="Err_msg">{errors.d_Stk_Location.types.message}</span>}
                    </FormGroup>
                }
            </div>
            {(GsbFlag.CTPM_SYS) &&
                <div className="Row">
                    <FormGroup className="Col3-1-2" label="Issue (DBWRSC)" labelFor="inputDividtype">
                        {/* <InputGroup id="inputDividtype" name="d_Divid_type" defaultValue="" placeholder="Issue (DBWRSC) ..." inputRef={register({ maxLength: 1}) } /> */}
                        <select id="inputDividtype" name="d_Divid_type" ref={register}>
                            <option value=" "> </option>
                            <option value="D">D: DIV to Bonus</option>
                            <option value="B">B: Bonus</option>
                            <option value="W">W: Warrant</option>
                            <option value="R">R: Rights</option>
                            <option value="S">S: Split</option>
                            <option value="C">C: Consolidate</option>
                            <option value="P">P: Placing</option>
                            <option value="O">O: IPO</option>
                        </select>
                        {errors.d_Divid_type && errors.d_Divid_type.type === "maxLength" && <span id="Err_msg">Max length is 1</span>}
                        {errors.d_Divid_type && errors.d_Divid_type.types && <span id="Err_msg">{errors.d_Divid_type.types.message}</span>}
                    </FormGroup>
                    <FormGroup className="Col-3-2" label="Stock Value" labelFor="inputStkValue">
                        <InputGroup className="inputNumberNoSpin" id="inputStkValue" name="d_StkValue" defaultValue="0" type="number" inputRef={register({min: 0})} />
                        {errors.d_StkValue && errors.d_StkValue.type === "min" && <span id="Err_msg">Stock Value sholud be greater than 0 </span>}
                        {errors.d_StkValue && errors.d_StkValue.types && <span id="Err_msg">{errors.d_StkValue.types.message}</span>}
                    </FormGroup>
                </div>
            }
        </form>
    )
}

interface StkDepWithTranEntryMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function StkDepWithTranEntryMain({ gsb }: StkDepWithTranEntryMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { StkNo: defaultSearchValue = "" }: { StkNo: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [isReadOnly, setisReadOnly] = useState(false)

    const [data, setData] = useState([] as PFTranInfo[])

    const [searchKey, setSearchKey] = useState("SeqNo")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)
    const [searchAcName, setsearchAcName] = useState("")
    const [searchStkNo, setsearchStkNo] = useState("")
    
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentStkDepWith, setcurrentStkDepWith] = useState({} as PFTranInfo)
    const [BlankStkDepWith, setBlankStkDepWith] = useState({} as PFTranInfo)
    const [CurrentLog1, setCurrentLog1] = useState({} as PFTranInfo)
    const [CurrentLog0, setCurrentLog0] = useState({} as PFTranInfo)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")
    const [isShowAlert1, setisShowAlert1] = useState(false)
    const [ShowAlert1Msg, setShowAlert1Msg] = useState("")
    const [isShowAlert2, setisShowAlert2] = useState(false)
    const [ShowAlert2Msg, setShowAlert2Msg] = useState("")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")

    const [showCheerful, setshowCheerful] = useState(false)
    const [PRCodeList, setPRCodeList] = useState([] as PRCodeInfo[])

    const [CENumList,setCENumList] = useState([] as CENumINFO[])
 
    //gsb default value
    //BlankStkDepWith.d_Currency = "HKD"   //no work
    BlankStkDepWith.d_acCode = ""
    BlankStkDepWith.d_StkNo = ""
    BlankStkDepWith.d_Tran_type = "D"
    BlankStkDepWith.d_StkValue = 0
    BlankStkDepWith.d_Qty = 0
    BlankStkDepWith.d_QtyStmp = 0
    BlankStkDepWith.d_Description = ""

    BlankStkDepWith.d_PR_Code = ""
    BlankStkDepWith.d_WD_type = ""
    BlankStkDepWith.d_WD_Charge = 0


    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("StkDepWithTranEntryMain disp user_key: ", res.data.Flag.user_key)
                //console.log("StkDepWithTranEntryMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("StkDepWithTranEntryMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("StkDepWithTranEntryMain disp GsbFlag: ", GsbFlag)
                //console.log("StkDepWithTranEntryMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("StkDepWithTranEntryMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("StkDepWithTranEntryMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("StkDepWithTranEntryMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("StkDepWithTranEntryMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
                } else {
                    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
                }
                if (GsbFlag.user_key == "CHEERFUL"){
                    setshowCheerful(true);
                }
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])

    useEffect(() => {
        setisReadOnly(false)
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    //console.log("StkDepWithTranEntryMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            setisReadOnly(true)
                        }
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, UserID])

    useEffect(() => {
        (async () => {
            const res = await gsb.post('/GsbFlag');
            GsbFlag = res.data.Flag;
            if (GsbFlag.user_key == "CHEERFUL") {
	            try {
	                // get PRCode List (Statement Code List)
	                const res = await gsb.post('/Master', {sb_data: "", cPgmId: "PrCodeMast", cAction: "GetPRCodeList"})
	                //console.log("PRCodeList: ", res.data.data) 
	                setPRCodeList(res.data.data)
	                //console.log("set PCCodeList: ", PRCodeList) 
	            } catch (error) {
	                utils.showError(error)
	            }
            }
        })()
    }, [gsb])


    useEffect(() => {      

        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                            nPage: 1,
                            limit: 0,
                        },
                        cPgmId: "HkidrMast",
                        cAction: "ListCENum",
                    });
                setCENumList(res.data.data)
                //console.log("ListCENUMList.data.data", res.data.data);
                console.log("ListCENUMList",CENumList);
             
            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])
 

    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }

        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Transaction', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "StkDepWithTranEntry", 
                        cAction: "ViewLogRec"
                    })
                    //console.log("StkDepWithTranEntryMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog0(res.data.data[0])
                        setCurrentLog1(res.data.data[1])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])
 


    const PrevRecProcess = async (StkDepWith: PFTranInfo) => {
        try {
            //console.log("call PrevRecProcess", StkDepWith)
            const res = await gsb.post('/Transaction', {sb_data: StkDepWith, cPgmId: "StkDepWithTranEntry", cAction: "PrevRec"})
            //console.log("PrevRecProcess show 1: ", stk.d_Seq)
            //console.log("PrevRecProcess data : ", data)
            //console.log("PrevRecProcess res.data : ", res.data.data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            //console.log("PrevRecProcess res.data.data : ", res.data.data)
            setcurrentStkDepWith(res.data.data)
            //console.log("PrevRecProcess show 2: ", stk.d_StkNo)
            setCurrentMode("show")
            //console.log("PrevRecProcess show 3: ", stk.d_StkNo)
        } catch (error) {
            utils.showError(error)
        }
    }

    const DeleteProcess = async (StkDepWith: PFTranInfo) => {
        try {
            //console.log("call DeleteProcess", stk)
            const res = await gsb.post('/Transaction', {sb_data: StkDepWith, cPgmId: "StkDepWithTranEntry", cAction: "DelRec"})
            showSuccess("Seq No. " + StkDepWith.d_Seq + " record deleted")
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentStkDepWith(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const NextRecProcess = async (StkDepWith: PFTranInfo) => {
        try {
            //console.log("call NextRecProcess", StkDepWith)
            const res = await gsb.post('/Transaction', {sb_data: StkDepWith, cPgmId: "StkDepWithTranEntry", cAction: "NextRec"})
            for (let i = 0; i < data.length; i++) {
                if (data[i].d_Seq === res.data.data.d_Seq) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setcurrentStkDepWith(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (StkDepWith: PFTranInfo) => {
        try {
            //console.log("call newOnSave", StkDepWith)
            isReqValueLimitAuth2 =  false
            const res = await gsb.post('/Transaction', {sb_data: StkDepWith, cPgmId: "StkDepWithTranEntry", cAction: "AddRec"})
            //showSuccess("Seq No. " + StkDepWith.d_Seq + " record added")
            if ( res.data.data.d_Auth_Value_Limit_tran === "Y" ) {
                isReqValueLimitAuth2 =  true
            }
            if (isReqValueLimitAuth2) {
                showSuccess("Seq No. " + res.data.data.d_Seq + " record adding, over value limit waiting for authorization")
            } else {
                showSuccess("Seq No. " + res.data.data.d_Seq + " record added")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        data[i] = { ...data[i], ...res.data.data }
                        setData(data)
                        break
                    }
                }
            }
            setcurrentStkDepWith(res.data.data)
            setCurrentMode("show")
            if (isReqValueLimitAuth2) {
                //alert("Seq No. " + res.data.data.d_Seq + " record adding, over value limit waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Seq No. " + res.data.data.d_Seq + " record adding, over value limit waiting for authorization")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const editOnSave = async (StkDepWith: PFTranInfo) => {
        try {
            //console.log("call editOnSave", StkDepWith)
            //console.log("stk.d_CcassStk: ", stk.d_CcassStk)
            isReqValueLimitAuth2 =  false
            const res = await gsb.post('/Transaction', {sb_data: StkDepWith, cPgmId: "StkDepWithTranEntry", cAction: "UpdateRec"})
            if ( res.data.data.d_Auth_Value_Limit_tran === "Y" ) {
                isReqValueLimitAuth2 =  true
            }
            if (isReqValueLimitAuth2) {
                showSuccess("Seq No. " + StkDepWith.d_Seq + " record saving, over value limit waiting for authorization")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        setcurrentStkDepWith(data[i])
                        break
                    }
                }
                setCurrentMode("show")
                //alert("Seq No. " + StkDepWith.d_Seq + " record saving, over value limit waiting for authorization")
                setisShowAlert1(true)
                setShowAlert1Msg("Seq No. " + StkDepWith.d_Seq + " record saving, over value limit waiting for authorization")
            } else {
                showSuccess("Seq No. " + StkDepWith.d_Seq + " record saved")
                for (let i = 0; i < data.length; i++) {
                    if (data[i].d_Seq === res.data.data.d_Seq) {
                        data[i] = { ...data[i], ...res.data.data }
                        //console.log("editOnSave data", i, data)
                        setData(data)
                        //setCurrentStk(stk)
                        break
                    }
                }   
                //console.log("editOnSave stk", stk)
                //console.log("editOnSave res.data.data", res.data.data)
                setcurrentStkDepWith(res.data.data)
                setCurrentMode("show")
            }
        } catch (error) {
            //utils.showError(error)
            showErrorBox(error)
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }
        
        // get StkDepWithTranEntry list if StkNo have value
        try {
            //console.log("StkDepWithTranEntryMain onsubmit called", data)
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        filters: {
                            AcName: searchAcName,
                            StkNo: searchStkNo,
                        }, 
                    },
                    cPgmId: "StkDepWithTranEntry",
                    cAction: "ListRec",
                });

                //console.log("onSubmit res.data.data", res.data.data)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentStkDepWith(res.data.data[0])
                }
                
                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
    
            } else {
                const res = await gsb.post('/Transaction',
                {   sb_data: {
                        d_Seq: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "StkDepWithTranEntry",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setcurrentStkDepWith(res.data.data)
                    setCurrentMode("show")
                } else {
                    //alert(res.data.data)
                    setisShowAlert2(true)
                    setShowAlert2Msg(res.data.data)
                }
            }

            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }

    // get one StkDepWithTranEntry record if StkNo have value
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 
    
            (async () => {
                //console.log("StkDepWithTranEntryMain useEffect length > 0  called", data)
                const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: "StkNo",
                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                        },
                        cPgmId: "StkDepWithTranEntry",
                        cAction: "ListRec",
                    });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setcurrentStkDepWith(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])

    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Transaction',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            filters: {
                                AcName: searchAcName,
                                StkNo: searchStkNo,
                            }, 
                        },
                        cPgmId: "StkDepWithTranEntry",
                        cAction: "ListRec",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setcurrentStkDepWith(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])
    

    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }


    // currentMode = show
    let detail = <StkDepWithTranEntryView gsb={gsb} StkDepWith={currentStkDepWith} StkDepWith0={currentStkDepWith} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentStkDepWith)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentStkDepWith)} onNextRec={() => NextRecProcess(currentStkDepWith)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></StkDepWithTranEntryView>
    switch (currentMode) {
        //case "PrevRec":
        // ?? will call 2 times ??
        //    console.log("currentMode = PrevRec")
        //    PrevRecProcess(currentStk)
        //    break
        case "new":
            //console.log("currentMode = new")
            detail = <StkDepWithTranEntryEdit gsb={gsb} StkDepWith={BlankStkDepWith} CENumList={CENumList}  isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); setSelectedTabId("detail") } } ></StkDepWithTranEntryEdit>
            break
        case "edit":
            //console.log("currentMode = edit")
            detail = <StkDepWithTranEntryEdit gsb={gsb} StkDepWith={currentStkDepWith} CENumList={CENumList}  onSave={editOnSave} onCancel={() => setCurrentMode("show")} ></StkDepWithTranEntryEdit>
            
        //case "delete":
        //    console.log("currentMode = delete")
        //    DeleteProcess(currentStk)
        //    break
        }

    let logKeyViewRec = <StkDepWithTranEntryView gsb={gsb} StkDepWith={CurrentLog1} StkDepWith0={CurrentLog0} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} onPrevRec={() => PrevRecProcess(currentStkDepWith)} onNew={() => setCurrentMode("new")} onEdit={() => setCurrentMode("edit")} onDelete={() => DeleteProcess(currentStkDepWith)} onNextRec={() => NextRecProcess(currentStkDepWith)} ViewMode={ViewMode} logKey2={logKey2.trim()} ></StkDepWithTranEntryView>

    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            <Button className="Col-1-1" disabled={isReadOnly} icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button>
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
        {/* display search list */}
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-SeqNo">Seq No</th>
                <th className="TCol-TranType">Tran Type</th>
                <th className="TCol-ACCode">Account No.</th>
                <th className="TCol-ACName">Name</th>
                <th className="TCol-StkNo">Stock No</th>
                <th className="TCol-StkCode">Stock Code</th>
                <th className="TCol-Qty">Qty</th>
                <th className="TCol-Qty">Share Price</th>
                <th className="TCol-Qty"> OTCR Type</th>              
                <th className="TCol-Qty">Er ref</th>     
                <th className="TCol-Qty">CENum Party</th>           
                <th className="TCol-Description">Description</th>
                <th className="TCol-Allocation">Allocation</th>
                {(showCheerful) && <th className="TCol-PRCode">Statement<br/> Code</th>}
                {(showCheerful) && <th className="TCol-WDType">WD<br/>Type</th>}
                {(showCheerful) && <th className="TCol-WDCharge">D/W Fee</th>}
            </thead>
            <tbody id="ma_tr">
                {data.map((StkDepWith, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-SeqNo" onClick={() => { setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_Seq}</td>
                        <td className="TCol-TranType" onClick={() => { setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_Tran_type}</td>
                        <td className="TCol-ACCode" onClick={() => { setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_acCode}</td>
                        <td className="TCol-ACName" onClick={() => { setcurrentStkDepWith(StkDepWith) }}>{StkDepWith.d_acName1}</td>
                        <td className="TCol-StkNo" onClick={() => { setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_StkNo}</td>
                        <td className="TCol-StkCode" onClick={() => { setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_StkCode}</td>
                        <td className="TCol-Qty" onClick={() => { setcurrentStkDepWith(StkDepWith) }}>{utils.formatNumber2(StkDepWith.d_Qty)}</td>
                        <td className="TCol-Qty" onClick={() => { setcurrentStkDepWith(StkDepWith) }}>{utils.formatNumber2(StkDepWith.d_ShareTransPrice)}</td>                    
                        <td className="TCol-Qty" onClick={() => { setcurrentStkDepWith(StkDepWith) }}>{(StkDepWith.d_OTCR_Type)}</td>                  
                        <td className="TCol-Qty" onClick={() => { setcurrentStkDepWith(StkDepWith) }}>{(StkDepWith.d_ErRef)}</td>
                        <td className="TCol-Qty" onClick={() => { setcurrentStkDepWith(StkDepWith) }}>{(StkDepWith.d_CENumCountryParty)}</td>
                 
                        <td className="TCol-Description" onClick={() => { setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_Description}</td>
                        <td className="TCol-Allocation" onClick={() => { setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_allocation}</td>
                        {(showCheerful) &&
                            <td className="TCol-PRCode" onClick={() => {setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_PR_Code}</td>
                        }
                        {(showCheerful) &&
                            <td className="TCol-WDType" onClick={() => {setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_WD_type}</td>
                        }
                        {(showCheerful) &&
                            <td className="TCol-WDCharge" onClick={() => {setcurrentStkDepWith(StkDepWith) ; MoblieDev && setSelectedTabId("detail") }}>{StkDepWith.d_WD_Charge}</td>
                        }
                    </tr>
                )}
            </tbody>
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>

    return (
        <>

            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
                { ! ( logviewonly === "Y" ) && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3>Stock Deposit/ Withdrawal Transaction Entry</H3>
                    <div className="Spacing-V-16" />
                    <div className="Row">
                        {/* define search key */}
                        <FormGroup className="Col-1-5" label="Search for Stock Deposit/ Withdrawal Transaction " labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="SeqNo">Seq No</option>
                                    <option value="acCode">Account No.</option>
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" disabled={(selectedTabId === "detail" && ! (searchKey == "SeqNo"))} type="submit" >{(selectedTabId === "detail") ? "Search" : "List"}</Button>
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                        <Button className="Col-9-1 Button-LabeledFormGroup" icon="document" onClick={() => { window.open(`/#/StkDepWithTranCheckList`, '_blank') } } >Check List</Button>
                        </div>
                    <Collapse isOpen={showFilters}>
                        <div className="Row">
                            {/* define search filter */}
                            <FormGroup className="Col-1-2" label="Account Name" labelFor="searchAcName">
                                <InputGroup id="searchAcName" value={searchAcName} onChange={utils.handleStringChange(s => setsearchAcName(s))} />
                            </FormGroup>
                            <FormGroup className="Col-3-1" label="Stock No." labelFor="searchStkNo">
                                <InputGroup id="searchStkNo" value={searchStkNo} onChange={utils.handleStringChange(s => setsearchStkNo(s))} />
                            </FormGroup>
                            {/* <FormGroup className="Col-6-2" label="No. of record(s) to List" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup> */}
                        </div>
                    </Collapse>
                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="List" panel={list} />
                        <Tab id="detail" title="Detail" panel={detail} />
                    </Tabs>
                </Card> }
                {ViewMode === "L" && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                Stock Deposit/ Withdrawal Transaction Log View
                    <div className="Spacing-V-16" />
                    {logKeyViewRec}
                </Card> }

                <div className="Spacing-V-16"></div>
                    <Alert
                        confirmButtonText="OK"
                        icon="tick-circle"
                        intent={Intent.SUCCESS}
                        isOpen={isShowAlert1}
                        onCancel={ () => setisShowAlert1(false) }
                        onConfirm={ () => { setShowAlert1Msg(""); setisShowAlert1(false) } }
                    >
                        <p>
                            {ShowAlert1Msg}
                        </p>
                    </Alert>
                    <Alert
                        confirmButtonText="OK"
                        icon="cross"
                        intent={Intent.WARNING}
                        isOpen={isShowAlert2}
                        onCancel={ () => setisShowAlert2(false) }
                        onConfirm={ () => { setShowAlert2Msg(""); setisShowAlert2(false) } }
                    >
                        <p>
                            {ShowAlert2Msg}
                        </p>
                    </Alert>
            </form>
        </>
    );
}

export default StkDepWithTranEntryMain