import React, { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Suggest, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, AnchorButton, Button, Tabs, Tab, Collapse, HTMLSelect, HTMLTable, ControlGroup, NumericInput, TextArea, Position, Toaster, Intent, MenuItem } from "@blueprintjs/core";
import { Radio, RadioGroup, Switch, Alert, Callout, Checkbox, Tooltip,ProgressBar } from "@blueprintjs/core";
import { H2, H3, H4, H5, H6 } from "@blueprintjs/core";
import { Classes, Card, Elevation,Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import svgWait2 from './svg/wait2.gif'

import {IPOINFO,  CountryINFO} from './Types'
import { SystemHeader } from './CommonUI';

import i18next from 'i18next';


interface PageInfo {
    n: number
}

interface TaskInfo {
    id: string,
    ref: string,
    description: string,
    startTime: string,
    endTime: string,
    userId: string,
    progressValue: number,
    progressMax: number,
    status: number,
    message: string,
    logFile: string
}


var GsbFlag: any;

var UserID: string;
var PgmCode = "H0002" ;
var isReqAuth2:boolean = false ;


    

interface IPOMastExportProps {
    gsb: AxiosInstance,
    IPO: IPOINFO,
    baseUrl?: string,
    token?: string | null,
    ViewMode: any,
    logKey2: any,
}

interface RptRstInfo {
    Rst_Key: string,
    Rst_Line: string,
}

interface PageInfo {
    n: number
}


function IPOMastExport({ gsb, IPO = {} as IPOINFO, baseUrl = "", token = "",  ViewMode, logKey2 }: IPOMastExportProps) {
 
    let ProgressId2 = ""

    const [taskList, setTaskList] = useState([] as TaskInfo[])
    const [showTasks, setShowTasks] = useState(false)
    const [ImportGSBMsg, setImportGSBMsg] = useState("")


 



    
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0") 
    const [isReplicate, setisReplicate] = useState(false)
    const [isRegenbcan, setisRegenbcan] = useState(false)

    const { t, i18n } = useTranslation();

    const [isReadOnly, setisReadOnly] = useState(false)
    const [isReqAuth, setisReqAuth] = useState(false)
    const [isDeletePress, setisDeletePress] = useState(false)
    const [AcName1Value, setAcName1Value] = useState("")
    const [AcName2Value, setAcName2Value] = useState("")
    const [AcStatusValue, setAcStatusValue] = useState("")

    let logKey3a = logKey2.substr(0,logKey2.indexOf("*")); 
    let logKey3b = logKey2.substr(logKey2.indexOf("*")+1); 
    let logKey3c = logKey3b.substr(logKey3b.indexOf("*")+1); 
    logKey3b = logKey3b.substr(0,logKey3b.indexOf("*")); 
    logKey3c = logKey3c.substr(0,logKey3c.indexOf("*")); 

    const [ProgressId, setProgressId] = useState("")
    const [ProgressValue, setProgressValue] = useState(0)

    const [Rstdata, setRstdata] = useState([] as RptRstInfo[])
    const [Rstdata2, setRstdata2] = useState([] as RptRstInfo[])
    const [RptHead1, setRptHead1] = useState("")
    const [RptHead2, setRptHead2] = useState("")
    const [RptName, setRptName] = useState("")
    const [RptNameNo, setRptNameNo] = useState(0)
    const [aRptNameNo, setaRptNameNo] = useState([] as PageInfo[])
    const [RptLogName, setRptLogName] = useState("")
    const [ShowWait, setShowWait] = useState(false)

    const [ShowRpt, setShowRpt] = useState(false)

    const [ShowNoRecord, setShowNoRecord] = useState(false)
    const [ShowOK, setShowOK] = useState(true)
 
    const [s_StkCode1, sets_StkCode1] = useState("")
    const [DataFormatValue, setDataFormatValue] = useState(true)
    const [CSVFormat, setCSVFormat] = useState("0")
    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    //const [cPrintMode, setcPrintMode] = useState("PS")
    const [LineLimit, setLineLimit] = useState(54 - 7)
    const { register, handleSubmit, reset, setValue, setError, errors } = useForm<IPOINFO>()
  
    const[File_Indicator,setFile_Indicator] = useState("")

    useEffect(() => {
        setShowRpt(false)        
        setShowNoRecord(false)     
    }, [IPO.OSTKNO])

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    useEffect(() => {
        const refreshTasks = async () => {
            ProgressId2 = ProgressId
            if ( ProgressId2.trim().length > 0 ) {
                try {
                    const res = await gsb.post('/Progresslist', {p_data: ProgressId2})
                    let pValue = 0
                    if ( res.data.count > 0 ) {
                        if ( res.data.data[0].id === ProgressId2 ) {
                            pValue = res.data.data[0].progressValue / res.data.data[0].progressMax
                            //console.log("Progresslist: ", res.data.data[0].progressValue, res.data.data[0].progressMax) 
                            //console.log("Progresslist pValue: ", pValue) 
                            setProgressValue(pValue)
                        }
                        if (res.data.data[0].status > 0 ) {
                            clearInterval(timer)
                        }
                    }
                } catch (error) {
                    utils.showError(error)
                }
            }
        }
        let timer = setInterval(refreshTasks, 20*1000)
        return () => {clearInterval(timer)}
    }, [gsb, ProgressId, nPage])


     const onSubmit = (data: IPOINFO) => {
        //console.log("onsubmit called", data)
        // put value to json/field before write database dbf
        setShowOK(false)
        data.OSTKNO = IPO.OSTKNO
        data.OSTK  = IPO.OSTK
        setShowWait(true)
        setShowNoRecord(false)
        setShowRpt(false)
        setRstdata([])
        setRptHead1("")
        setRptHead2("")
        setRptName("")
        setRptNameNo(0)
        setnPage(1)
        ProgressId2 = utils.genUUID()
        setProgressId(ProgressId2)
        setProgressValue(0);

        (async () => {
            try {
                //console.log("IPO-> OSTKNO  ", IPO.OSTKNO)
                //console.log("IPO-> OSTK  ", IPO.OSTK)
              
                //console.log("File_Indicator",File_Indicator)
                const res = await gsb.post('/Master',
                {   sb_data: {
                    ProgressId: ProgressId2,
                    s_StkCode1:IPO.OSTKNO,   
                    s_Stk : IPO.OSTK,                       
                    DataFormat: DataFormatValue,
                    File_Indicator : File_Indicator,
  
                    nPage: 1,
                    cPrintMode: "PS",
                    limit: LineLimit,
                },
                cPgmId: "IPOMast",
                cAction: "ExportIPO",
            });


                //console.log("res.data.data: ", res.data.data) 
                if ( res.data.count > 0 ) {
                    setRstdata2([])
                    for (let j = 0; j < res.data.Rptdata.length; j++) {
                        Rstdata2.push({Rst_Key: res.data.Rptdata[j][0], Rst_Line: res.data.Rptdata[j][1]})
                    }
                    setShowWait(false)
                    setShowNoRecord(false)
                    setShowRpt(true)
                    setRstdata(Rstdata2)
                    console.log("Rstdata: ", Rstdata) 
                    setRptHead1(res.data.Rpthead1)
                    setRptHead2(res.data.Rpthead2)
                    setRptName(res.data.RptName)
                    setRptLogName(res.data.RptLogName)
                    setRptNameNo(res.data.RptNameNo)
                    //console.log("RptName: ", RptName) 
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / LineLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / LineLimit); j++ ) {
                            aPageNumber1.push({n: j});
                       }
                        setaPageNumber(aPageNumber1)
                        
                        if (res.data.RptNameNo > 0 ) {
                            for ( let j = 1 ; j <= res.data.RptNameNo; j++ ){
                                aRptNameNo.push({n: j});
                            }
                            setRptName(res.data.RptName.replace(".pdf",""))
                        }                        
                    }
                } else {
                    setShowWait(false)
                    setShowNoRecord(true)
                    setShowRpt(false)
                }
            } catch (error) {
                utils.showError(error)
            }
        })()


    }


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }
    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }

    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    useEffect(() => {
        (async () => {
            try {
            
                const res = await gsb.post('/Master',
                {   sb_data: {
                    },
                    cPgmId: "IPOMast",
                    cAction: "Files_Indicator",
                });    

                if ( res.data.count > 0 ) {
                    setFile_Indicator(res.data.data[0].d_Files_Indicator)                   
                    //console.log(File_Indicator)

                } else {

                }
            } catch (error) {
                //setCanPorcess(false)
              
            }
        })()



    }, [gsb])



    useEffect(() => {
        if (taskList.findIndex((t: TaskInfo) => t.status === 0) > -1) {
            //console.log("running task(s) found, refresh tasklist in 1s")
            setTimeout(async () => {
                try {
                    const res = await gsb.post('/tasklist', taskList.map((t) => t.id));
                    //console.log("tasklist res.data.data", res.data.data)
                    setTaskList(res.data.data)
                } catch (error) {
                    utils.showError(error)
                }
            }, 3*1000)
        } else {
            //console.log("all task(s) completed.")
        }
    }, [taskList, gsb])

    const onResetRecIDFINI = () => {
        //console.log("onSBReindex fileInfoList", fileInfoList);
        setShowOK(false)
        ;
        
        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                        ProgressId: ProgressId2,
                        s_StkCode1:IPO.OSTKNO, 
                        s_Stk : IPO.OSTK,
                    },                   
                    cPgmId: "IPOMast",
                    cAction: "ResetEIPORecID",
                });                

                 setTaskList(res.data.data)
               //setTaskList(1)
                setShowTasks(true)
                //utils.showSuccess("Reset stock ok")
            } catch (error) {
                utils.showError(error)
            }
        })()
    }
 

    const onResetFINI = () => {
        //console.log("onSBReindex fileInfoList", fileInfoList);
        setShowOK(false)
        ;
        
        (async () => {
            try {
                const res = await gsb.post('/Master',
                    {   sb_data: {
                        ProgressId: ProgressId2,
                        s_StkCode1:IPO.OSTKNO, 
                        s_Stk : IPO.OSTK,
                        s_ResetAll : "Y",
                    },                   
                    cPgmId: "IPOMast",
                    cAction: "ResetEIPO",
                });                

                 setTaskList(res.data.data)
               //setTaskList(1)
                setShowTasks(true)
                //utils.showSuccess("Reset stock ok")
            } catch (error) {
                utils.showError(error)
            }
        })()
    }

    let list = <>
        <div className="Spacing-V-16" />
        {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download</a>}
        &nbsp; &nbsp; {RptLogName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${RptLogName}&inline`}>View Log</a>}
        {ShowRpt && ! DataFormatValue && Pagination}
        {/* display search list */}
     

        <HTMLTable striped interactive condensed>
            <thead id="rpt_th_r">
                <tr >
                    { ! DataFormatValue && <th className="RCol-Head_p">
                            {RptName.trim().length > 0 && <a target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/Rptdownload?filename=${RptName}`}>Download</a>}
                        </th>
                    }
                </tr>                    
                <tr>
                    <td className="RCol-Head1">{RptHead1}</td>
                </tr>                    
            </thead>
            <tbody id="rpt_tr">
                {Rstdata.map((rpt, i) =>
                    <tr key={i} onDoubleClick={() => { }}>
                        <td className="RCol-Rpt" onClick={() => {  }}>{rpt.Rst_Line}</td>
                    </tr>
                )}
            </tbody>
            <div className="Spacing-V-16" />
            {ShowRpt && ! DataFormatValue && Pagination}
        </HTMLTable>
    </>
 
    {/*if (! ("d_OSTKNO" in IPO)) return null*/}

    return (
      
     
        <form onKeyDown={handleHotkey}>
 
            <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >    
                 <h1>Export EIPO list</h1>  {/*IPO.OSTKNO*/}
                <div className="Row">
                    
                    <FormGroup className="Col-1-1" label={t('Stock code.')} labelFor="inputSTKNO">                     
                        <InputGroup   id="inputDoB" readOnly name="OSTKNO" type="text"     defaultValue= {IPO.OSTKNO} /> 
                    </FormGroup>

                    <FormGroup className="Col-1-2" label={t('Stock Name.')} labelFor="inputSTKNAME">                     
                        <InputGroup   id="inputDoB" readOnly name="OSTK" type="text"     defaultValue= {IPO.OSTK} /> 
                    </FormGroup>

                    <div className="Spacing-V-16"></div>
                   {/* <Button className="Col-1-1"  icon="reset" disabled={! ShowOK} onClick={onResetFINI}>Reset EIPO Export</Button>*/}
                    <div className="Spacing-V-16"></div>  
                    <div className="Spacing-V-16"></div>  

                   {/*<Button className="Col-3-1"  onClick={onResetRecIDFINI}>Reset EIPO RecID</Button>*/}
                </div>
              
                <div className="Row">    
                    <div className="Spacing-V-16"></div>  
                    
                    <FormGroup className="Col-1-1" label={t('File Indicator')} labelFor="inputFile_Indicator">
                        <InputGroup   id="inputDoB" name="d_File_Indicator"  disabled={! ShowOK}  type="text"  defaultValue={File_Indicator}  onChange={utils.handleStringChange(s => setFile_Indicator(s))} />     
                    </FormGroup>
                    
                </div>
            
                    <Collapse isOpen={showTasks}>
                        <p>Tasks</p>
                        <HTMLTable striped interactive condensed>
                            <thead>
                                <th className="TCol-Ref">File Name</th>
                                <th className="TCol-Progress">Progress</th>
                                <th className="TCol-Message">Message</th>
                                <th className="TCol-Log">Log</th>
                            </thead>
                            <tbody>
                                {taskList.map((t, i) =>
                                    <tr key={i}>
                                        <td className="TCol-Ref">{t.ref}</td>
                                        <td className="TCol-Progress"><ProgressBar stripes={false} animate={false} value={t.progressValue / t.progressMax} /></td>
                                        <td className="TCol-Message">{t.message}</td>
                                        <td className="TCol-Log">{t.logFile && <a key={i} target="_blank" rel="noopener noreferrer" href={`${gsb.defaults.baseURL}/logdownload?filename=${t.logFile}&inline`}>View</a>}</td>
                                    </tr>
                                )}
                            </tbody>
                        </HTMLTable>
                        {/*
                        <div className="Spacing-V-8"></div>
                        <div className="Row">
                            <Button className="Col-1-1" type="submit" icon={IconNames.CROSS} onClick={() => setShowTasks(!showTasks)}>Tasks</Button>
                        </div>
                        */}
                        <div className="Spacing-V-16"></div>
                    </Collapse>
                <div className="Row">    
                    <div>Text Format</div>              
                     <Button className="Col-1-1" icon="tick" disabled={! ShowOK}  onClick={handleSubmit(onSubmit)}>submit</Button>
                </div>
            </Card>
            {/* {ShowWait && <div>{imgWait}</div> } */}
            {ShowWait && <div className="TCol-Progress2">Progress ...</div> }
            {ShowWait && <div className="TCol-Progress2"><ProgressBar stripes={false} animate={false} value={ProgressValue} /></div> }
            {ShowNoRecord && <p>
                <div className="Spacing-V-16" />
                <div className="Row">
                    <FormGroup className="Col-1-3"  >
                        <span id="Err_msg">No Record found !!!</span>
                    </FormGroup>
                </div>
            </p>
            }
            {ShowRpt && list}
        </form>  
    )
}


interface IPOMastMainProps {
    gsb: AxiosInstance
}

const toaster = Toaster.create({
    position: Position.TOP,
})

function IPOMastMain({ gsb }: IPOMastMainProps) {

    const imgWait = <img className="WaitIcon" src={svgWait2} alt="wait" />
    const coLogo = <img className="coLogo" src="coLogo.gif" alt="Co Logo" />

    let url = document.location.toString()
    let qString = url.substr(url.indexOf("?"))

    let { acCode: defaultSearchValue = "" }: { acCode: string } = useParams()

    let logKey = utils.getUrlParameter('logKey', url); 
    let logKey2 = logKey.substr(0,logKey.indexOf("?")); 
    let logviewonly = utils.getUrlParameter('logviewonly', url); 

    const [data, setData] = useState([] as IPOINFO[])


    const [searchKey, setSearchKey] = useState("StkCode")
    const [searchValue, setSearchValue] = useState(defaultSearchValue)

    const [showFilters, setShowFilters] = useState(false)

    const [searchStkNo, setSearchStkNo] = useState("")
    const [searchStkName, setSearchStkName] = useState("")
    const [searchApplyDate, setsearchApplyDate] = useState("")

    const [def_id_Type, setid_Type] = useState("")

    const [searchTel, setSearchTel] = useState("")


    const [s_AcStatus, sets_AcStatus] = useState("")

    const [nPage, setnPage] = useState(0)
    const [nTotalPage, setnTotalPage] = useState(0)
    const [aPageNumber, setaPageNumber] = useState([] as PageInfo[])
    const [MoblieDev, setMoblieDev] = useState(false)
    const [ShowWait, setShowWait] = useState(false)
    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState("list")
    const [currentAc, setCurrentAc] = useState({} as IPOINFO)
    const [BlankAc, setBlankAc] = useState({} as IPOINFO)
    const [CurrentLog, setCurrentLog] = useState({} as IPOINFO)
    const [ViewMode, setViewMode] = useState("V")

    const [currentMode, setCurrentMode] = useState("show")

    const [CO_NAME, setCO_NAME] = useState("")
    const [companyLogoName, setcompanyLogoName] = useState("")
    const [SBPath, setSBPath] = useState("")
    const [TRAN_DATE, setTRAN_DATE] = useState(utils.formatNumber(new Date()))
    const [SETL_DATE, setSETL_DATE] = useState(utils.formatNumber(new Date()))
    const [cardOpen01, setcardOpen01] = useState("cardOpen01-Theme0")
    const [SUSPEND_AC, setSUSPEND_AC] = useState(false)
    
    const { t, i18n } = useTranslation();
   
    const [CountryList, setCountryList] = useState([] as CountryINFO[])

    useEffect(() => {
        (async () => {
            try {
                // get GsbFlag from SB.XBS
                const res = await gsb.post('/GsbFlag');
                //console.log("HkidrMastMain disp user_key: ", res.data.Flag.user_key)
                //console.log("HkidrMastMain disp MULTI_MKT: ", res.data.Flag.MULTI_MKT)
                //console.log("HkidrMastMain disp data.Flag: ", res.data.Flag)
                GsbFlag = res.data.Flag;
                //console.log("HkidrMastMain disp GsbFlag: ", GsbFlag)
                //console.log("HkidrMastMain disp GsbFlag.lang: ", GsbFlag.lang)
                //console.log("HkidrMastMain disp GsbFlag.dateFormat: ", GsbFlag.dateFormat)
                console.log("GsbFlag.user_key: ", GsbFlag.user_key)
                //console.log("GsbFlag.UserID: ", GsbFlag.UserID)
                UserID = res.data.Flag.UserID
                //console.log("HkidrMastMain disp GsbFlag.MULTI_MKT: ", sbFlag.MULTI_MKT)
                //console.log("HkidrMastMain disp GsbFlag.MGNGP_USER: ", GsbFlag.MGNGP_USER)
                //console.log("HkidrMastMain disp GsbFlag.CO_NAME: ", GsbFlag.CO_NAME)
                setCO_NAME( GsbFlag.CO_NAME) ;
                setcompanyLogoName( GsbFlag.companyLogoName) ;
                setSBPath( GsbFlag.SBPath) ;
                setTRAN_DATE(GsbFlag.TRAN_DATE ) ;
                setSETL_DATE(GsbFlag.SETL_DATE ) ;
                if ( GsbFlag.isProduction == "Y" ) {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME1);
} else {
    setcardOpen01("cardOpen01-Theme"+GsbFlag.DEF_THEME2);
}
                setSUSPEND_AC(GsbFlag.SUSPEND_AC);

            } catch (error) {
                utils.showError(error)
            }
        })()
    }, [gsb])


    useEffect(() => {
        isReqAuth2 = false
        ;
        (async () => {
            try {
                UserID = (typeof UserID === 'string')? UserID.trim() : ''
                if ( UserID.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_Userid2: UserID,
                            d_PgmCode: PgmCode
                        }, 
                        cPgmId: "UserPgmAccess", 
                        cAction: "GetUserPgmAccessInfo2"
                    })
                    console.log("HkidrMastMain UserPgmAccess res.data.data", res.data.data)

                    if (res.data.ok) {
                        if ( res.data.data[0].d_PgmAction == "R" ) {
                            //setisReadOnly(true)
                        }
                        if ( res.data.data[0].d_PgmAction == "M" || res.data.data[0].d_PgmAction == "C") {
                            isReqAuth2 = true
                        }
                    }
                }
            } catch (error) {
                //showErrorBox(error)
                utils.showError(error)
            }
        })()            
    }, [gsb, UserID])


    useEffect(() => {
        if (logviewonly === "Y") {
            setViewMode("L")
        }
        
        ;
        (async () => {
            try {
                if ( logKey.trim().length > 0 ) {
                    const res = await gsb.post('/Master', {
                        sb_data: {
                            d_logKey: logKey,
                        }, 
                        cPgmId: "HkidrMast", 
                        cAction: "ViewLogRec"
                    })
                    console.log("HkidrMastMain ViewLogRec res.data.data", res.data.data)

                    if (res.data.ok) {
                        setCurrentLog(res.data.data[0])
                    } else {
                        utils.showWarn(res.data.data)
                    }
                }
            } catch (error) {
                showErrorBox(error)
            }
        })()            
    }, [gsb, logKey])


    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setShowWait(true)
        setnPage(1)
        let m_limit = searchLimit
        if ( selectedTabId === "detail" ) {
            m_limit = 1
        }

        if ( utils.getDeviceType()) {
            setMoblieDev(true)
        } else {
            setMoblieDev(false)
        }

        try {
            if ( m_limit > 1 ) {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        by: searchKey,
                        start: searchValue,
                        nPage: 1,
                        limit: m_limit,
                        backward: searchBackward,
                        SortByName: false,
                        filters: {
                            StkNo: searchStkNo,
                            StkName: searchStkName,
                            AppyDate:searchApplyDate,
                        },
                    },
                    cPgmId: "IPOMast",
                    cAction: "ListIPO",
                });

                //console.log("ListRec res.data.data", res.data.data)
                //console.log("ListRec res.data.count", res.data.count)
                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }

                if ( res.data.count > 0 ) {
                    const aPageNumber1 = []
                    if ( res.data.count > 0 ) {
                        setnTotalPage(Math.ceil(res.data.count / searchLimit))
                        for ( let j = 1; j <= Math.ceil(res.data.count / searchLimit); j++ ) {
                            aPageNumber1.push({n: j});
                        }
                        setaPageNumber(aPageNumber1)
                    }
                }
            } else {
                const res = await gsb.post('/Master',
                {   sb_data: {
                        d_acCode: searchValue,
                        nPage: 1,
                        limit: m_limit,
                    },
                    cPgmId: "IPOMast",
                    cAction: "GetRec",
                });

                if ( res.data.ok ) {
                    //console.log("GetRec res.data.data", res.data.data)
                    setCurrentAc(res.data.data)
                    setCurrentMode("show")
                } else {
                    alert(res.data.data)
                }
            }
            setShowWait(false)
        } catch (error) {
            setShowWait(false)
            utils.showError(error)
        }
    }





    // lookup account if acCode is passed
    {/*
    useEffect(() => {
        if (defaultSearchValue.length > 0) {
            // console.log("calling API for URL param");

            setShowWait(true)
            setnPage(1)
            if ( nPage > 0 ) {
                //setcPrintMode("PS")
            } 

            (async () => {
                const res = await gsb.post('/Master',
                    {   sb_data: {

                            start: defaultSearchValue,
                            nPage: 1,
                            limit: 1,
                            SortByName: false,
                        },
                        cPgmId: "IPOMast",
                        cAction: "ListRec",
                        });

                setData(res.data.data)
                if (res.data.data.length > 0) {
                    setCurrentAc(res.data.data[0])
                }
                setShowWait(false)
            })()
        }
    }, [gsb, defaultSearchValue])
*/}
    //Press page No 1 2 3 4
    useEffect(() => {
        if ( nPage > 0 && nPage <= nTotalPage && selectedTabId == "list" ) {
    
            setShowWait(true)
            if ( nPage > 0 ) {
                //setcPrintMode("S")
            } 
    
            (async () => {
                try {
                    //console.log("IntRMastMain useEffect nPage > 0  ", nPage)
                    const res = await gsb.post('/Master',
                    {   sb_data: {
                            by: searchKey,
                            start: searchValue,
                            nPage: nPage,
                            limit: searchLimit,
                            backward: searchBackward,
                            SortByName: false,
                            filters: {
                                StkNo: searchStkNo,
                                StkName: searchStkName,
                                AppyDate:searchApplyDate,
                            },
                            },
                        cPgmId: "IPOMast",
                        cAction: "ListIPO",
                    });

                    setData(res.data.data)
                    if (res.data.data.length > 0) {
                        setCurrentAc(res.data.data[0])
                    }
                    setShowWait(false)
        
                } catch (error) {
                    utils.showError(error)
                }
            })()
        }
    }, [gsb, nPage, selectedTabId])


    const showErrorBox = (err: any) => {
        alert("Error:"+err.response.data.subSystem+" - "+err.response.data.description+", OS code:"+err.response.data.osCode)
    }

    const showError = (err: any) => {
        if (err.response) {
            toaster.show({ message: err.response.data.description, intent: Intent.WARNING })
        } else if (err.request) {
            toaster.show({ message: "Request failed", intent: Intent.WARNING })
        } else {
            toaster.show({ message: err.message, intent: Intent.WARNING })
        }
    }

 


    const showSuccess = (message: string) => {
        toaster.show({ message: message, intent: Intent.SUCCESS })
    }

    // currentMode = show
   let detail =<IPOMastExport gsb={gsb} IPO={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')}  ViewMode={ViewMode} logKey2={logKey2.trim()} ></IPOMastExport>
   

    let logKeyViewRec = <IPOMastExport gsb={gsb} IPO={currentAc} baseUrl={gsb.defaults.baseURL} token={localStorage.getItem('token')} ViewMode={ViewMode} logKey2={logKey2.trim()} ></IPOMastExport>


    function PageNo(n: number) {
        if (n > 0 ) {
            if ( nPage+n <= nTotalPage ) {
                setnPage(nPage+n)
            }
        } else
        {
            if ( nPage+n > 0 ) {
                setnPage(nPage+n)
            }
        }
        return null;
    }


  
    let Pagination = <>
        <div className="Row">
            <FormGroup className="Col-1-12" label="">
                Page
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(-1)}>&laquo;</Button>
                }
                { (nTotalPage > 1) && (nPage != 1) && 
                    <Button minimal onClick={() => setnPage(1)}>{1}</Button>
                }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage-10) && (j+1 < nPage) && (j+1 != 1) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage >= 1) && <Button intent='primary'>{nPage}</Button> }
                {aPageNumber.map((no, j) => 
                    (j+1 > nPage) && (j+1 < nPage+10) && (j+1 != nTotalPage) && <Button minimal onClick={() => setnPage(no.n)}>{no.n}</Button>
                )}
                { (nTotalPage > 1) && (nPage != nTotalPage) && 
                    <Button minimal onClick={() => setnPage(nTotalPage)}>{nTotalPage}</Button>
                }
                { (nTotalPage > 1) &&
                    <Button minimal onClick={() =>PageNo(+1)}>&raquo;</Button>
                }
            </FormGroup>
        </div>
    </>


    
    let list = <>
        <div className="Row">
            {/* <Button className="Col-1-1" icon="add" onClick={() => { setCurrentMode("new"); setSelectedTabId("detail") }}>New</Button> */}
        </div>
        <div className="Spacing-V-16" />
        {ShowWait && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}

        
        <HTMLTable striped interactive condensed>
            <thead>
                <th className="TCol-ACCode">Stock</th>
                <th className="TCol-StkName">Stock Name</th>
                 {/*<th className="TCol-ACCode">LOT SIZE</th>*/}
                <th className="TCol-ACCode">Apply date</th>
                <th className="TCol-ACCode">List date</th>    
                <th className="TCol-APrice">Apply Price</th>
                <th className="TCol-ACCode">List PRICE</th>
                <th className="TCol-ACCode">Gen EIPO</th>
            </thead>
            <tbody id="ma_tr">
                {data.map((IPO, i) =>
                    <tr key={i} onDoubleClick={() => { setSelectedTabId("detail") }}>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>{IPO.OSTKNO}</td>
                        <td className="TCol-StkName" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>{IPO.OSTKNAME}</td>
                        {/*<td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>{IPO.OAQTY}</td> */}
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(IPO.OADATE, GsbFlag.dateFormat)}</td>                      
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>{utils.dispDate(IPO.OLDATE, GsbFlag.dateFormat)}</td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>${utils.formatNumber2(IPO.OAPRICE)}</td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>${utils.formatNumber2(IPO.OIPRICE)}</td>
                        <td className="TCol-ACCode" onClick={() => { setCurrentAc(IPO) ; MoblieDev && setSelectedTabId("detail") }}>  {IPO.OGENEPIO ? <Icon  icon="tick"/> : ''}</td>
                        
         
                    </tr>
                )}
            </tbody>
         
        </HTMLTable>
        {ShowWait && nTotalPage > 0 && <div>{imgWait}</div> }
        {nPage > 0 && Pagination}
    </>

    let CoHead = <SystemHeader companyLogo={companyLogoName} companyName={CO_NAME} dataPath={SBPath} tradeDate={TRAN_DATE} settlementDate={SETL_DATE}></SystemHeader>
    return (
        <>
           
            <form onSubmit={onSubmit}>
                <div className="Spacing-V-Menu"></div>                
                <div className="Row">
                    <FormGroup className="Col-1-12"  >
                        {CoHead}
                    </FormGroup>
                </div>
             
                { ! ( logviewonly === "Y" ) &&  (selectedTabId ==="list") && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                    <H3><div style={{justifyContent: 'flex-end'}}>Export Fini EIPO Report File</div></H3>
                    <div className="Row No-Print">
                        <FormGroup className="Col-1-5" label="Search for IPO Master" labelFor="inputSearchValue">
                            <ControlGroup fill>
                                <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                    <option value="StkCode">StockNo</option>
                                    <option value="StkName">StockName</option>
                                    {/* <option value="_recordNo">Record No.</option> */}
                                </HTMLSelect>
                                <InputGroup
                                    id="inputSearchValue"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={utils.handleStringChange(s => setSearchValue(s))}
                                    leftIcon="search"
                                    rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <Button className="Col-6-1 Button-LabeledFormGroup" icon="search" type="submit" >List</Button>
                        {/* <Checkbox className="Col-6-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} /> */}
                        <Button className="Col-7-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>
                    </div>
 
                    <Collapse isOpen={showFilters}>
 
                        <div className="Row">
                           <FormGroup className="Col-1-1" label="Stock Code" labelFor="searchStkNo">
                                <InputGroup id="searchStkNo" value={searchStkNo} onChange={utils.handleStringChange(s => setSearchStkNo(s))} />
                            </FormGroup>   
                            <FormGroup className="Col-1-1" label="Stock Name" labelFor="searchStkName">
                                <InputGroup id="searchStkName" value={searchStkName} onChange={utils.handleStringChange(s => setSearchStkNo(s))} />
                            </FormGroup>

     
                        </div>
                    </Collapse>
                    
                        {list}
                </Card> }
                { (selectedTabId ==="detail") && <Card className={cardOpen01} interactive={false} elevation={Elevation.THREE} >
                <div className="No-Screen" style={{justifyContent: 'flex-end'}}>HKIDR IPO Master</div>

                    <Tabs onChange={(newTabId) => setSelectedTabId(newTabId as string)} selectedTabId={selectedTabId}>
                        <Tab id="list" title="Back" panel={list} />                                      
                    </Tabs>
                    {detail}
                </Card> }



            </form>
        </>
    );
}

export default IPOMastMain